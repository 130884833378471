<template>
  <div v-if="messages.length" class="MBMWidget_Snack MBMWidget_mb-4" :class="colors">
    <div v-for="message in messages" :key="message">* {{ message }}</div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    messages: {
      default: () => [],
      type: Array,
      required: false,
    },
    colors: {
      type: String,
      default: 'error',
      required: false,
    },
  },
});
</script>
