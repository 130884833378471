import { render, staticRenderFns } from "./PhazeCardShoppingCart.vue?vue&type=template&id=6dba6d4e&scoped=true"
import script from "./PhazeCardShoppingCart.vue?vue&type=script&lang=ts"
export * from "./PhazeCardShoppingCart.vue?vue&type=script&lang=ts"
import style0 from "./PhazeCardShoppingCart.vue?vue&type=style&index=0&id=6dba6d4e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dba6d4e",
  null
  
)

export default component.exports