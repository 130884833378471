<template>
  <div class="MBMWidget_Phaze_Card_Checkout MBMWidget_mx-auto">
    <div
      class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Exchange_crypto MBMWidget_GeneralInput MBMWidget_GeneralSelect email"
    >
      <div
        class="
        MBMWidget_col-12
        MBMWidget_col-xs-12
        MBMWidget_py-0
        MBMWidget_text-center
      "
      >
        <ErrorMessage></ErrorMessage>
      </div>
      <AppInputSimple
        class="MBMWidget_Exchange_wallet_input"
        v-model="UserEmail"
        :type="'email'"
        @focus="anyInput()"
        :label="$t('phaze_order_confirm_email')"
        :placeholder="$t('exchange_screen_email_address_placeholder')"
        required
      />
    </div>
    

    <div class="MBMWidget_col-xs-12">
      <div class="MBMWidget_confirmArea">
        <AppCheckbox
          v-model="agree"
          class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_FormCardScreenAppCheckboxConfirmArea MBMWidget_cursor-pointer custom_checkbox"
          required
        >
          <template #label>
            {{ $t('order_details_checkbox') }}
          </template>
        </AppCheckbox>
      </div>
    </div>

    <div
      class="
        MBMWidget_col-12 MBMWidget_col-xs-12
        MBWidget_footer_actions
        MBMWidget_px-0 MBMWidget_full-width
      "
    >
      <div class="seperator">
        <span></span>
      </div>
      <div class="total">
        <div
          class="
            MBMWidget_col-6 MBMWidget_col-xs-6
            MBMWidget_text-left
          "
        >
          <h5>{{ $t('order_details_total') }}</h5>
        </div>
        <div
          class="
            MBMWidget_col-6 MBMWidget_col-xs-6
            MBMWidget_text-right
          "
        >
          <h5 style="font-weight: 700">{{getFiatSymbol()}} {{getProcessedPrice()}} {{this.$store.state.phazeOrder.selectedCurrency}}</h5>
        </div>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12">
        <AppButton
          :disabled="!agree || !UserEmail"
          color="primary"
          class="
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Phaze_Card_List_Next
          "
          @click="next"
        >
          {{ $t('order_details_continue') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Routes } from '@/store/modules/Router';
import Vue from 'vue';
import store from '@/store';
import Button from '../General/Button.vue';
// @ts-ignore
import { dragscroll } from 'vue-dragscroll';
import {processDecimalPlaces, redirectViewString} from '@/utils';
import ErrorMessage from '@/components/Components/ErrorMessage.vue';
import Input from '../General/InputSimple.vue';
import Checkbox from '../General/Checkbox.vue';
import CurrencyList from "@/components/Components/CurrencyList.json";
import {mapGetters} from "vuex";

export default Vue.extend({
  components: {
    AppButton: Button,
    AppInputSimple: Input,
    AppCheckbox: Checkbox,
    ErrorMessage: ErrorMessage,
  },
  directives: {
    dragscroll,
  },
  data() {
    return {
      store,
      UserEmail: '',
      agree: false,
    };
  },
  methods: {
    anyInput() {
      this.$store.commit('personalizedErrors', []);
    },
    next() {
      this.$store.commit('personalizedErrors', []);
        const validEmail = new RegExp(/^\S+@\S+\.\S+$/).test(this.UserEmail);
        if (!validEmail) {
          this.$store.commit('personalizedErrors', [
            { code: '27', message: 'Invalid Email!' },
          ]);
          return;
        }
      this.$store.state.giftCardDeliveryEmail = this.UserEmail;
      this.$emit('nextScreen', {
        screenPosition: 2,
        selectedCrypto: { name: '', ticker: '' },
      });
    },
    getProcessedPrice(){
      return processDecimalPlaces(this.$store.state.phazeOrder.price, 2);
    },
    getFiatSymbol(){
      let fiatSymbol = '';
      // @ts-ignore
      fiatSymbol = CurrencyList['en'][this.$store.state.phazeOrder.selectedCurrency]?.symbol;
      return fiatSymbol;
    }
  },
  mounted() {
    if(!this.$store.state.loggedIn){
      this.$store.commit('router/goTo', Routes.Login);
      return;
    }
    if(!this.isProfileFilled){
      this.$store.commit('router/goTo', Routes.Profile);
      return;
    }
    this.UserEmail = this.$store.state.profile.email;
    this.$store.commit('setPreviousPage', 'Checkout');
  },
  computed: {
    ...mapGetters(['isProfileFilled']),
  }
});
</script>

<style lang="scss" scoped>
.total {
  font-size: 24px;
}
.seperator span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  margin: 0 0 -15px 0;
}
.seperator span::before,
.seperator span::after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #a3b0bf;
  opacity: 40%;
}
.email {
  margin: 30px 0 20px 0;
}
</style>
