<template>
  <div :class="$attrs.class" class="MBMWidget_GeneralSelect">
    <label>{{ label }}</label>
    <br v-if="label" />
    <select
      v-bind="$attrs"
      class="MBMWidget_mt-2 MBMWidget_full-width"
      @change="$emit('input', $event.target.value)"
    >
      <option
        v-for="(item, index) in computedItems"
        :key="item.text + index"
        :value="item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';

export interface SelectItem {
  text: string;
  value: string | number;
}

interface Dictionary {
  [key: string]: string | number;
}

export default Vue.extend({
  props: {
    items: {
      type: Array as PropType<SelectItem[] | Dictionary[]>,
      default: [],
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    label: String,
  },
  computed: {
    computedItems(): SelectItem[] {
      const text = this.itemText;
      const value = this.itemValue;
      // @ts-ignore
      const items = [] as SelectItem[];
      this.items.forEach((item: any) => {
        if (!item) return;
        if (typeof item === 'string') {
          items.push({ text: item, value: item });
        } else {
          items.push({ text: item[text], value: item[value] });
        }
      });
      return items;
    },
  },
});
</script>
