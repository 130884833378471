<template>
  <div class="MBMWidget_Jwt_Expired MBMWidget_mx-auto">
    <div
      class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4 MBMWidget_ml-0"
    >
      <p class="MBMWidget_text-center">
        Your session has expired. Please refresh the app.
      </p>
      <Button
        type="button"
        color="primary"
        class="MBMWidget_GeneralButton 
          MBMWidget_full-width
          MBMWidget_defaultAppButton
        "
        @click="refreshPage"
        >Refresh</Button
      >
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Button from '../General/Button.vue';

export default Vue.extend({
  components: {
    Button,
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
  },
  mounted() {
    this.$store.state.hideMenuButton = true;
    this.$store.state.hideBackButton = true;
  },
});
</script>
