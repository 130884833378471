<template>
  <div class="MBMWidget_Kyc_Progress MBMWidget_mx-auto MBWidget_block MBMWidget_No_Bottom_Padding">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t('kyc_screen_title') }}
    </div>

    <div class="MBMWidget_subtitle MBMWidget_Kyc_Subtitle">
      {{ $t('kyc_screen_subtitle') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          :disabled="loading"
          color="primary"
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Phaze_Card_List_Next"
          @click="redirectFurther()"
        >
          {{ $t('start_kyc_button') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import {checkKyc, checkKycVerified, isKycComplete, performKyc, sendAnalytics} from '@/api';
import Button from '@/components/General/Button.vue';
import { Routes } from '@/store/modules/Router';
  import {redirectViewString, setCookie} from '@/utils';

import Vue from 'vue';
import KycUrl from '../General/KycUrl.vue';
  import ErrorMessage from "@/components/Components/ErrorMessage.vue";
export default Vue.extend({
  components: {
    //ErrorMessage: ErrorMessage,
    AppButton: Button
  },
  data() {
    return {
      waiting: false,
      completed: false,
      interval: null as any,
      Routes: Routes,
      url: '',
    };
  },
  methods: {
    redirectFurther() {
      this.$store.commit('router/goTo', Routes.Kyc);
    }
  },
  mounted() {
    console.log()
  },
  beforeDestroy() {
    console.log()
  },
});
</script>
