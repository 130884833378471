<template>
  <div class="MBMWidget_Forgot MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t(titles[stage]) }}
    </div>
    <div class="MBMWidget_mt-8 MBMWidget_col-xs-12 MBMWidget_text-center MBMWidget_subtitle">
      <span>
        {{ $t(subtitles[stage]) }}
      </span>
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Forgot_Input_Email">
      <form @submit.prevent v-show="stage==0" ref="formEmail">
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
          <ErrorMessage></ErrorMessage>
        </div>
        <AppInput v-model="email" :focus="true" class="MBMWidget_Forgot_Input_Email" :type="'email'" :placeholder="$t('forgot_screen_email')" />
        <AppButton
          class="MBWidget_fake_submit"
          @click="performAction"
        ></AppButton>
      </form>

      <div class="MBWidget_verify_wrap" v-show="stage==1">
        <form @submit.prevent class="MBWidget_verify_v-middle">
          <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
            <ErrorMessage></ErrorMessage>
          </div>
          <VerificationCode ref="validationInput" @codeFilled="codeFilled"></VerificationCode>
          <AppButton
            class="MBWidget_fake_submit"
            @click="performAction"
          ></AppButton>
        </form>
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center MBWidget-nofloat MBMWidget_mx-auto">
          <a class="MBMWidget_Forgot_Resend"
             @click.prevent="requestEmail"
          >
            {{$t('forgot_screen_resend_email')}}
          </a>
        </div>
      </div>

      <form ref="form" @submit.prevent v-show="stage==2">
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
          <ErrorMessage></ErrorMessage>
        </div>
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12">
          <AppInput
            required
            class="MBMWidget_full-width MBMWidget_FormRecoverAppInputPassword"
            v-model="password"
            autocomplete="new-password"
            type="password"
            :label="$t('recover_screen_password_placeholder')"
          />
          <Password
            v-model="password"
            :strength-meter-only="true"
            @score="score = $event"
          />
        </div>

        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12">
          <AppInput
            class="MBMWidget_FormRecoverAppInputRePassword"
            v-model="repassword"
            required
            :label="$t('recover_screen_re_password_placeholer')"
            type="password"
          />
        </div>
        <AppButton
          class="MBWidget_fake_submit"
          @click="performAction"
        ></AppButton>
      </form>

      <div v-if="success" class="MBMWidget_text-center MBMWidget_my-4 MBMWidget_title MBMWidget_title_success MBMWidget_accent--text">
        {{ $t('recover_screen_everything_went_ok_text') }}
      </div>
    </div>

    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-8 MBMWidget_col-md-8 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat" v-show="stage!=1">
        <AppButton
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Forgot_Recover_button"
          color="primary"
          :disabled="disabled"
          @click="performAction"
        >
          {{ $t(buttonText[stage]) }}
        </AppButton>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center MBWidget-nofloat MBMWidget_mx-auto">
        <a class="MBMWidget_Forgot_Back"
           href="/login"
           @click.prevent="$store.commit('router/goTo', Routes.Login)"
        >
          {{$t('forgot_screen_back_to_login_button')}}
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import {forgotPassword, recoverPasswordEmailOnly, requestEmailCodeByEmail, VerifyEmailCodeByEmail} from '@/api';
import { Routes } from '@/store/modules/Router';
import { displayErrors, redirectView } from '@/utils';
import Vue from 'vue';
import Button from '../General/Button.vue';
import Input from '../General/Input.vue';
import VerificationCode from '../General/VerificationCode.vue';
import md5 from "blueimp-md5";
  // @ts-ignore
import Password from 'vue-password-strength-meter';
import ErrorMessage from "@/components/Components/ErrorMessage.vue";

export default Vue.extend({
  components: {
    AppInput: Input,
    AppButton: Button,
    VerificationCode: VerificationCode,
    Password: Password,
    ErrorMessage: ErrorMessage
  },
  data() {
    return {
      email: '',
      code: '',
      Routes: Routes,
      disabled: false,
      emailSuccess: false,
      stage: 0,
      score: 1,
      password: '',
      repassword: '',
      success: false,
      titles: [
        'forgot_screen_recover_password_title',
        'forgot_screen_recover_password_title',
        'forgot_screen_recover_password_title'
      ],
      subtitles: [
        'forgot_screen_recover_password_subtitle',
        'tfa_screen_please_enter_your_code',
        'recover_screen_please_enter_your_new_password_text'
      ],
      buttonText: [
        'forgot_screen_recover_button',
        'card_screen_confirm_button_text',
        'recover_screen_reset_password_button'
      ]
    };
  },
  methods: {
    performAction(){
      this.$store.commit('personalizedErrors', []);
      switch (this.stage) {
        case 0:
          this.sendEmail();
          break;
        case 1:
          this.verifyEmail();
          break;
        case 2:
            this.recover();
            break;
      }
    },
    codeFilled(val: string){
      this.verifyEmail();
    },
    //====== STAGE 0
    async sendEmail() {
      try {
        const form = this.$refs.formEmail as HTMLFormElement;
        if (!form.checkValidity()) return;
        if(this.disabled) return;
        this.disabled = true;
        const response = await forgotPassword(this.email);
        this.$store.commit(
          'personalizedErrors',
          response.data.errors.map((item: { message: string }) => item.message),
        );

        if (response.data.errors.length) {
          this.disabled = false;
          return;
        }
        this.$store.commit('profile', { email: this.email });
        this.disabled = false;
        this.stage = 1;
      } finally {
        this.disabled = false;
      }
    },
    get(): boolean {
      const i : InstanceType<typeof VerificationCode> = this.$refs.validationInput as InstanceType<typeof VerificationCode>;
      // @ts-ignore
      this.code = i.getCode();
      return this.code.length > 0;
    },
    async requestEmail() {
      try {
        this.disabled = true;
        this.$store.commit('personalizedErrors', []);
        const response = await requestEmailCodeByEmail();
        this.$store.commit(
          'personalizedErrors',
          response.data.errors.map((item: { message: string }) => item.message),
        );
      } finally {
        this.disabled = false;
      }
    },
    //====== STAGE 1
    async verifyEmail() {
      if(!this.get()) return;
      try {
        this.disabled = true;
        const response = await VerifyEmailCodeByEmail(this.code);
        if (response.data === true) {
          this.emailSuccess = true;
          this.$store.commit('personalizedErrors', []);
          this.stage = 2;
        } else {
          this.$store.commit('personalizedErrors', ['Wrong email code']);
        }
      } catch {
        this.$store.commit('personalizedErrors', ['Error verifying, please try again.']);
      } finally {
        this.disabled = false;
      }
    },
    //====== STAGE 2
    async recover() {
      const form = this.$refs.form as HTMLFormElement;
      if (!form.checkValidity()) return;
      const errors = [];
      this.password === this.repassword || errors.push('Passwords must match');
      this.score > this.$store.state.passwordStrength ||
      errors.push('Password should be stronger');
      this.$store.commit('personalizedErrors', errors);
      if (errors.length) return;
      try {
        this.disabled = true;
        const response = await recoverPasswordEmailOnly({
          password: md5(this.password),
          email: this.$store.state.profile.email,
          code: this.code,
        });
        const errors = response.data.errors.map(
          (item: { message: string }) => item.message,
        );
        this.$store.commit('personalizedErrors', errors);
        if (errors.length) {
          return;
        }
        this.success = true;
        setTimeout(() => {
          const redirect = redirectView(response.data);
          if (!redirect) {
            this.$store.commit('router/goTo', Routes.Login);
          }
        });
      } finally {
        this.disabled = false;
      }
    },
  },
});
</script>
