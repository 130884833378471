<template>
  <div class="MBMWidget_CardScreen">
    <form ref="form" @submit.prevent class="MBMWidget_FormCardScreen">
      <template v-if="complete">
        <div class="MBMWidget_my-4 MBMWidget_title MBMWidget_text-center MBMWidget_SuccessText">
          {{ $t('transaction_complete') }}
        </div>
        <AppButton
          v-if="config.firstPage !== 'Card'"
          class="MBMWidget_full-width MBMWidget_Go_To_Exchange_Success"
          color="primary"
          @click="$store.commit('router/goTo', Routes.Exchange)"
        >
          {{ $t('buy_more') }}
        </AppButton>
      </template>
      <template v-else-if="failed">
        <div class="MBMWidget_my-4 MBMWidget_title MBMWidget_text-center MBMWidget_FailedText">
          {{ $t('transaction_failed') }}
        </div>
        <AppButton
          v-if="config.firstPage !== 'Card'"
          class="MBMWidget_full-width MBMWidget_Go_To_Exchange_Failed"
          color="primary"
          @click="$store.commit('router/goTo', Routes.Exchange)"
        >
          {{ $t('buy_more') }}
        </AppButton>
      </template>
      <template v-else>
        <AppSnack :messages="messages" :colors="colors" />
        <div class="MBMWidget_col-12 MBMWidget_CardSelection" v-if="allowPreSavedCards">
          <span class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
            {{ $t('available_cards') }}
          </span>
          <div v-for="card in userCards" :key="card.formattedCard">
            <label>
              <input
                type="radio"
                v-model="selectedCardType"
                :value="card.identifier"
              />
              {{ $t('ending') }}:
              {{
                card.formattedCard.slice(
                  card.formattedCard.length - 4,
                  card.formattedCard.length,
                )
              }}
            </label>
          </div>
          <div>
            <label>
              <input type="radio" v-model="selectedCardType" value="Custom" />
              {{ $t('new_card') }}
            </label>
          </div>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_CardScreen_card_placeholer">
          <AppInput
            :label="$t('card_screen_name_on_card_label')"
            v-model="name"
            :placeholder="$t('card_screen_name_on_card_placeholder')"
            required
            readonly
            :disabled="formDisabled"
          />
        </div>
        <div>
          <div class="MBMWidget_col-6 MBMWidget_col-xs-12 MBMWidget_CoverFormCardScreenAppInputCardNumber">
            <AppInput
              v-model="cardNumber"
              :placeholder="$t('card_screen_credit_card_placeholder')"
              required
              :disabled="formDisabled"
              class="FormCardScreenAppInputCardNumber"
            />
          </div>
          <div class="MBMWidget_col-6 MBMWidget_col-xs-12">
            <div
              v-for="card in cards"
              :key="card"
              class="MBMWidget_d-inline-block MBMWidget_mt-4 MBMWidget_py-1"
              :class="getClass(card)"
            />
          </div>
        </div>
        <div class="MBMWidget_col-6 MBMWidget_col-xs-12 MBMWidget_CoverFormCardScreenAppInputCardMonthYear">
          <AppSelect
            v-model="cardMonth"
            :label="$t('card_screen_card_month_placeholder')"
            class="MBMWidget_d-inline-block MBMWidget_expiryInput MBMWidget_FormCardScreenAppInputCardMonth"
            :items="monthItems"
            :disabled="formDisabled"
            required
          />
          <AppSelect
            v-model="cardYear"
            class="MBMWidget_d-inline-block MBMWidget_expiryInput MBMWidget_FormCardScreenAppInputCardYear"
            required
            :disabled="formDisabled"
            :items="yearItems"
          />
        </div>
        <div class="MBMWidget_col-6 MBMWidget_col-xs-12 MBMWidget_CoverFormCardScreenAppInputCardCvv">
          <AppInput
            v-model="cardCvv"
            :label="$t('card_screen_card_cvv_label')"
            required
            :disabled="formDisabled"
            class="FormCardScreenAppInputCardCvv"
          />
        </div>
        <div class="MBMWidget_col-12" v-if="allowPreSavedCards">
          <AppCheckbox
            v-model="SaveCard"
            :disabled="formDisabled"
            :label="$t('save_my_card')"
            class="MBMWidget_defaultAppCheckbox MBMWidget_FormCardScreenAppCheckboxSaveMyCard"
          />
        </div>
        <div class="MBMWidget_col-12">
          <div class="MBMWidget_confirmArea" v-if="load_first_time">
            <AppCheckbox
              v-model="agree"
              class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_FormCardScreenAppCheckboxConfirmArea MBMWidget_cursor-pointer"
              required>

              <template #label>
                {{ checkText1 }}
                <a @click="showIframe('terms_and_conditions')" target="_blank">
                  {{ $t('card_screen_terms_and_conditions') }}
                </a>
                and
                <a @click="showIframe('privacy_policy')" target="_blank">
                  {{ $t('card_screen_privacy_policy') }}.
                </a>
                {{ checkText2 }}
                {{$t('card_screen_kyc_notification')}}
              </template>


            </AppCheckbox>
            <div
              v-if="!config.fiatOnlyMode && config.firstPage !== 'Card'"
              class="MBMWidget_body-2 MBMWidget_my-4 MBMWidget_cursor-pointer MBMWidget_modifyOrder"
              @click="modifyOrder(order)"
            >
              {{ $t('id_like_to_modify_my_order', order) }}
            </div>
          </div>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_Confirm_Button">
          <AppButton
            class="MBMWidget_full-width MBMWidget_defaultAppButton"
            color="accent"
            :disabled="!agree || loadingPayment || !canPay"
            @click="processPayment3DS"
          >
            {{ $t('card_screen_confirm_button') }}
          </AppButton>
        </div>
      </template>
    </form>
  </div>
</template>
<script lang="ts">
import './cards.css';
import {
  checkKycVerified,
  gerOrderDetails,
  getTransactionDetails,
  processPayment,
  processPayment3DSRequest,
  checkTransaction,
  getUserNameAsString,
  getUserCards,
  uploadScreenshot,
  AuthenticateTx, sendAnalytics,
} from '@/api';
import { Routes } from '@/store/modules/Router';
import Button from '../General/Button.vue';
import { mapGetters, mapState, mapMutations } from 'vuex';
import Checkbox from '../General/Checkbox.vue';
import Input from '../General/Input.vue';
import Select, { SelectItem } from '../General/Select.vue';
import Snack from '../General/Snack.vue';
import CardMixin from './CardMixin';
import html2canvas from 'html2canvas';
import { processDecimalPlaces } from '@/utils';
import store from "@/store";

export default CardMixin.extend({
  props: ['showIframe'],
  components: {
    AppSnack: Snack,
    AppInput: Input,
    AppSelect: Select,
    AppCheckbox: Checkbox,
    AppButton: Button,
  },
  data() {
    return {
      name: '',
      colors: 'warning',
      monthItems: [] as string[],
      yearItems: [] as SelectItem[],
      interval: null as any,
      kycInterval: null as any,
      transactionInterval: null as any,
      order: {} as {
        amount: number;
        selectedCryptoCurrency: string;
        price: number;
        selectedCurrency: string;
        CryptoValues: {
            symbol: string,
            cryptoQuantity: number,
            FiatCurrency: string,
          };
        TransactionTotal: {
          TransactionPrice: number
        };
        ProcessingFees: {
          id: number,
          flatRate: number,
          processingFee: number,
          pct_ProcessingFee: number
        };
      },
      agree: false,
      loadingPayment: false,
      messages: [] as string[],
      transactionUuid: '',
      card_authentication_id: '',
      cards: ['mastercard', 'visa', 'amex', 'discover', 'diners', 'jcb'],
      intervals: 0,
      complete: false,
      failed: false,
      Routes: Routes,
      userCards: [],
      selectedCardType: 'Custom',
      SaveCard: false,
      load_first_time: false,
      kycVerified: true,
      canPay: false
    };
  },
  computed: {
    ...mapState(['config']),
    formDisabled(): boolean {
      return this.selectedCardType !== 'Custom';
    },
    checkText1(): string {
      if (this.$store.state.config.showFees) {
        if (this.$store.state.config.fiatOnlyMode) {
          return this.$t(
            'card_screen_confirm_payment_with_fee1_fiat_only_mode',
            this.declare_text_with_fee,
          ).toString();
        } else {
          return this.$t(
            'card_screen_confirm_payment_with_fee1',
            this.declare_text_with_fee,
          ).toString();
        }
      } else {
        if (this.$store.state.config.fiatOnlyMode) {
          return this.$t(
            'card_screen_confirm_payment1_fiat_only_mode',
            this.declare_text_without_fee,
          ).toString();
        } else {
          return this.$t(
            'card_screen_confirm_payment1',
            this.declare_text_without_fee,
          ).toString();
        }
      }
    },
    checkText2(): string {
      if (this.$store.state.config.showFees) {
        return this.$t(
          'card_screen_confirm_payment_with_fee2',
          this.declare_text_with_fee,
        ).toString();
      } else {
        return this.$t(
          'card_screen_confirm_payment2',
          this.declare_text_without_fee,
        ).toString();
      }
    },
    allowPreSavedCards(): boolean {
      return this.$store.state.config.allowPreSavedCards;
    },
    declare_text_with_fee(): any {
      return {
        terms_and_conditions: this.$store.state.config.termsAndConditionsUrl,
        privacy_policy: this.$store.state.config.privacyPolicyUrl,

        amount: processDecimalPlaces(this.order.CryptoValues?.cryptoQuantity, 8),

        amount_fee_amount: processDecimalPlaces(this.order.TransactionTotal?.TransactionPrice + this.order.ProcessingFees.flatRate + this.order.ProcessingFees.processingFee, 2),

        selectedCryptoCurrency: this.order.CryptoValues.symbol,
        selectedCurrency: this.order.CryptoValues?.FiatCurrency,

        price: processDecimalPlaces(this.order.TransactionTotal?.TransactionPrice, 2),

        fee_amount:
          processDecimalPlaces(this.order.ProcessingFees.flatRate + this.order.ProcessingFees.processingFee, 2) +
          ' ' +
          this.order.CryptoValues?.FiatCurrency,

        fee_percent: this.order.ProcessingFees.flatRate
          ? ''
          : '(' +
            processDecimalPlaces(this.order.ProcessingFees.pct_ProcessingFee, 2) +
            '%)',
      };
    },
    declare_text_without_fee(): any {
      return {
        terms_and_conditions: this.$store.state.config.termsAndConditionsUrl,
        privacy_policy: this.$store.state.config.privacyPolicyUrl,
        amount: processDecimalPlaces(this.order.amount, 8),
        selectedCryptoCurrency: this.order.selectedCryptoCurrency,
        price: processDecimalPlaces(this.order.price, 2),
        selectedCurrency: this.order.selectedCurrency,
        amount_charged: processDecimalPlaces(this.order.price, 2),
      };
    },
  },
  methods: {
    ...mapMutations(['modifyOrder']),
    getClass(card: string) {
      let cardClass = 'MBMWidget_card_' + card;
      cardClass += ' MBMWidget_is_' + card;
      if (this.getCardType === card) {
        cardClass += ' MBMWidget_active_card';
      }
      return cardClass;
    },
    async processPayment3DS() {
      const styles = this.$store.state.config.css;

      const template_3d = `
      <html>
        <head>
          <style>
            ${styles}
          </style>
        </head>
        <body>
          <div style="color: #24AE8F; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div style="font-size: 3em; text-align: center;" class="MBMWidget_3DS_title">
              ${this.$t('we_are_waiting_for_the_3ds')}
            </div>
            <div style="font-size: 2em; text-align: center;" class="MBMWidget_3DS_subtitle">
              ${this.$t('we_are_waiting_for_the_3ds')}
            </div>
            <img src="https://mobilum-public.s3-eu-west-1.amazonaws.com/powered_mobilum_big.png">
          </div>
        </body>
      </html>
      `;

      const form = this.$refs.form as HTMLFormElement;
      if (!form.checkValidity()) return;

       await sendAnalytics({
        EventCategory: 'Transaction',
        EventAction: 'send',
        EventLabel: 'Started',
      });
      /*this.$gtag.event('send', {
        'event_category': 'Transaction',
        'event_label': 'Started',
        'value': 1
      });*/

      const myMobilumWindow = window.open('', '_blank');

      if (myMobilumWindow) {
        myMobilumWindow.document.body.innerHTML = template_3d as string;
      }

      this.agreePaymentTx(myMobilumWindow);
    },

    async agreePaymentTx(myMobilumWindow: any) {
      try {
        this.loadingPayment = true;
        const div = document.querySelector('body');
        if (div) {
          const canvas = await html2canvas(div);
          const dataURL = canvas.toDataURL();
          const blobBin = atob(dataURL.split(',')[1]);
          const array = [];
          for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
          const file = new Blob([new Uint8Array(array)], { type: 'image/png' });

          await uploadScreenshot(file);
        }

        const browser_language = this.$i18n.locale;
        const browser_screen_height = window.screen.height;
        const browser_screen_width = window.screen.width;
        const browser_time_zone = (new Date()).getTimezoneOffset();
        const browser_user_agent = navigator.userAgent;
        const browser_screen_color_depth = window.screen.colorDepth;
        let browser_java_enabled_val = ''
        if (navigator.javaEnabled()) {
          browser_java_enabled_val = 'ENABLED';
        } else {
          browser_java_enabled_val = 'DISABLED';
        }
        const browser_java_script_enabled = 'ENABLED';

        let body = {} as { [key: string]: string | number | boolean };
        const params = {} as { [key: string]: string };

        if (this.selectedCardType === 'Custom') {
          body = {
            BrowserLanguage: browser_language,
            BrowserScreenHeight: String(browser_screen_height),
            BrowserScreenWidth: String(browser_screen_width),
            BrowserTimeZone: String(browser_time_zone),
            BrowserUserAgent: browser_user_agent,
            BrowserScreenColorDepth: String(browser_screen_color_depth),
            BrowserJavaEnabledVal: browser_java_enabled_val,
            BrowserJavaScriptEnabled: browser_java_script_enabled,

            Number: this.cardNumber,
            MonthExpiry: this.cardMonth,
            YearExpiry: this.cardYear,
            CVV2: this.cardCvv,
            SaveCard: this.SaveCard,
          };
          params.name = this.name;
        }
        else {
          body = {
            PreSavedCard: this.selectedCardType,
          };
        }

        const response = await processPayment3DSRequest(body, params);
        console.log(response);

        if (response.data.success) {
          const data = response.data;

          let response_type = data.responseType;

          if (response_type === 'PreAuthenticationRequired') {
            console.log("PreAuthenticationRequired")
            const result = data.result;

            // TODO: hiden form
            const card_authentication_id = result.cardAuthenticationId;
            const three_ds_method_data = result.threeDSMethodData;
            const three_ds_method_url = result.threeDSMethodURL;

            const iframe = document.createElement('iframe');
            iframe.id = 'MBMWidget_iframe_hidden';
            iframe.style.cssText = 'height: 0 !important; width: 0 !important; border: 0 !important';

            const html = `
              <body onload='document.getElementById("mobilum_id_3ds").submit()'>
                <form id="mobilum_id_3ds" action="${three_ds_method_url}" method="post">
                  <input name="threeDSMethodData" value="${three_ds_method_data}" />
                </form>
              </body>
              `;

            iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
            document.body.appendChild(iframe);

            const time = new Date().getTime();
            while (new Date().getTime() < time + 2000);

            let body_tx = {} as { [key: string]: string | number | boolean };
            const params_tx = {} as { [key: string]: string };

            body_tx = {
              Number: this.cardNumber,
              MonthExpiry: this.cardMonth,
              YearExpiry: this.cardYear,
              CVV2: this.cardCvv,
            };

            params_tx.cardAuthenticationId = card_authentication_id;

            const responseTx = await AuthenticateTx(body_tx, params_tx);

            console.log(responseTx);

            if (responseTx.data.success) {
              const data = responseTx.data
              response_type = data.responseType;

              if (response_type === 'ThreeDsOneChallengeRequired') {
                if (myMobilumWindow) {
                  myMobilumWindow.document.body.innerHTML = response.data.result.form;
                  setTimeout(() => {
                    // @ts-ignore
                    myMobilumWindow.document.downloadForm.submit();
                  }, 2000);
                }

                this.transactionUuid = response.data.result.transactionUuid;
                this.colors = 'info';
                this.messages = ['Waiting for transaction to complete'];

                let requesting = false;
                this.transactionInterval = setInterval(async () => {
                  this.intervals++;
                  if (requesting) {
                    return;
                  }
                  requesting = true;
                  const response = await getTransactionDetails(this.transactionUuid);
                  console.log(response);
                  if (response.data.success && !response.data.errors.length) {
                    if (response.data.result?.complete === true) {
                      this.messages = [this.$t('transaction_complete') as string];
                      this.complete = true;

                      await sendAnalytics({
                        EventCategory: 'Transaction',
                        EventAction: 'send',
                        EventLabel: 'Positive',
                      });
                      /*this.$gtag.event('send', {
                        'event_category': 'Transaction',
                        'event_label': 'Positive',
                        'value': 1
                      });*/

                      if (this.transactionInterval) {
                        clearInterval(this.transactionInterval);
                      }
                    }
                    if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
                      window.location.href = response.data.followUrl;
                    }
                  }
                  if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60) {
                    this.messages = [this.$t('transaction_failed') as string];
                    this.colors = 'error';
                    this.failed = true;
                    if (this.transactionInterval) {
                      clearInterval(this.transactionInterval);
                    }
                    await sendAnalytics({
                      EventCategory: 'Transaction',
                      EventAction: 'send',
                      EventLabel: 'Negative',
                    });
                    /*this.$gtag.event('send', {
                      'event_category': 'Transaction',
                      'event_label': 'Negative',
                      'value': 1
                    });*/
                    if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
                      window.location.href = data.followUrl;
                    }
                  }
                  requesting = false;
                }, 1000);
              }
              if (response_type === 'ThreeDsTwoChallengeRequired') {
                if (myMobilumWindow) {
                  myMobilumWindow.document.body.innerHTML = responseTx.data.result.form;
                  setTimeout(() => {
                    // @ts-ignore
                    myMobilumWindow.document.downloadForm.submit();
                  }, 2000);
                }

                this.card_authentication_id = response.data.result.cardAuthenticationId;
                this.colors = 'info';
                this.messages = ['Waiting for transaction to complete'];

                let requesting = false;
                this.transactionInterval = setInterval(async () => {
                  if (requesting) {
                    return;
                  }
                  requesting = true;

                  this.intervals++;
                  console.log(this.intervals)
                  const response = await checkTransaction(body_tx, params_tx);

                  const data = response.data;
                  response_type = data.responseType;

                  if (response_type === 'PaymentDone') {
                    this.messages = [this.$t('transaction_complete') as string];
                    this.complete = true;

                    await sendAnalytics({
                      EventCategory: 'Transaction',
                      EventAction: 'send',
                      EventLabel: 'Positive',
                    });
                    /*this.$gtag.event('send', {
                      'event_category': 'Transaction',
                      'event_label': 'Positive',
                      'value': 1
                    });*/

                    if (myMobilumWindow) myMobilumWindow.close();

                    if (data.followUrl !== null && data.followUrl !== undefined) {
                      window.location.href = data.followUrl;
                    }

                    if (this.transactionInterval) {
                      clearInterval(this.transactionInterval);
                    }
                  }
                  if (response_type === 'PaymentInProgress') {
                    console.log('PaymentInProgress');
                  }
                  if (response_type === 'PaymentError') {
                    console.log('PaymentError');

                    this.messages = [this.$t('transaction_failed') as string];
                    this.colors = 'error';
                    this.failed = true;
                    if (this.transactionInterval) {
                      clearInterval(this.transactionInterval);
                    }

                    await sendAnalytics({
                      EventCategory: 'Transaction',
                      EventAction: 'send',
                      EventLabel: 'Negative',
                    });
                    /*this.$gtag.event('send', {
                      'event_category': 'Transaction',
                      'event_label': 'Negative',
                      'value': 1
                    });*/

                    if (data.followUrl !== null && data.followUrl !== undefined) {
                      window.location.href = data.followUrl;
                    }
                  }
                  if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60) {
                    this.messages = [this.$t('transaction_failed') as string];
                    this.colors = 'error';
                    this.failed = true;
                    if (this.transactionInterval) {
                      clearInterval(this.transactionInterval);
                    }

                    await sendAnalytics({
                      EventCategory: 'Transaction',
                      EventAction: 'send',
                      EventLabel: 'Negative',
                    });
                    /*this.$gtag.event('send', {
                      'event_category': 'Transaction',
                      'event_label': 'Negative',
                      'value': 1
                    });*/
                  }
                  requesting = false;
                }, 3000);
              }
              if (response_type === 'PaymentDone') {
                this.messages = [this.$t('transaction_complete') as string];
                this.complete = true;

                await sendAnalytics({
                  EventCategory: 'Transaction',
                  EventAction: 'send',
                  EventLabel: 'Positive',
                });
                /*this.$gtag.event('send', {
                  'event_category': 'Transaction',
                  'event_label': 'Positive',
                  'value': 1
                });*/

                if (myMobilumWindow) myMobilumWindow.close();

                if (data.followUrl !== null && data.followUrl !== undefined) {
                  window.location.href = data.followUrl;
                }
              }
            } else {
              this.messages = [responseTx.data.errors[0].message as string];
              this.colors = 'error';

              if (myMobilumWindow) myMobilumWindow.close();
            }
          }
          else if (response_type === 'ThreeDsOneChallengeRequired') {
            console.log("ThreeDsOneChallengeRequired")
            if (myMobilumWindow) {
              myMobilumWindow.document.body.innerHTML = response.data.result.form;
              setTimeout(() => {
                // @ts-ignore
                myMobilumWindow.document.downloadForm.submit();
              }, 2000);
            }

            this.transactionUuid = response.data.result.transactionUuid;
            this.colors = 'info';
            this.messages = ['Waiting for transaction to complete'];

            let requesting = false;
            this.transactionInterval = setInterval(async () => {
              this.intervals++;
              if (requesting) {
                return;
              }
              requesting = true;
              const response = await getTransactionDetails(this.transactionUuid);
              console.log(response);
              if (response.data.success && !response.data.errors.length) {
                if (response.data.result?.complete === true) {
                  this.messages = [this.$t('transaction_complete') as string];
                  this.complete = true;

                  await sendAnalytics({
                    EventCategory: 'Transaction',
                    EventAction: 'send',
                    EventLabel: 'Positive',
                  });
                  /*this.$gtag.event('send', {
                    'event_category': 'Transaction',
                    'event_label': 'Positive',
                    'value': 1
                  });*/
                  if (this.transactionInterval) {
                    clearInterval(this.transactionInterval);
                  }
                }
                if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
                  window.location.href = response.data.followUrl;
                }
              }
              if (response.data.errors.length > 0) {
                this.$store.commit(
                    'errors',
                    response.data.errors.map((item: { message: string }) => item.message),
                );
                if (this.transactionInterval) {
                  clearInterval(this.transactionInterval);
                }
                if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
                  window.location.href = response.data.followUrl;
                }
              }
              if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60) {
                this.messages = [this.$t('transaction_failed') as string];
                this.colors = 'error';
                this.failed = true;
                if (this.transactionInterval) {
                  clearInterval(this.transactionInterval);
                }
                await sendAnalytics({
                  EventCategory: 'Transaction',
                  EventAction: 'send',
                  EventLabel: 'Negative',
                });
                /*this.$gtag.event('send', {
                  'event_category': 'Transaction',
                  'event_label': 'Negative',
                  'value': 1
                });*/
                if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
                  window.location.href = data.followUrl;
                }
              }
              requesting = false;
            }, 1000);
          }
          else if (response_type === 'ThreeDsTwoChallengeRequired') {
            console.log("ThreeDsTwoChallengeRequired")
            if (myMobilumWindow) {
              myMobilumWindow.document.body.innerHTML = response.data.result.form;
              setTimeout(() => {
                // @ts-ignore
                myMobilumWindow.document.downloadForm.submit();
              }, 2000);
            }

            let body_tx = {} as { [key: string]: string | number | boolean };
            const params_tx = {} as { [key: string]: string };

            body_tx = {
              Number: this.cardNumber,
              MonthExpiry: this.cardMonth,
              YearExpiry: this.cardYear,
              CVV2: this.cardCvv,
            };

            params_tx.cardAuthenticationId = response.data.result.cardAuthenticationId;

            this.card_authentication_id = response.data.result.cardAuthenticationId;
            this.colors = 'info';
            this.messages = ['Waiting for transaction to complete'];

            let requesting = false;
            this.transactionInterval = setInterval(async () => {
              if (requesting) {
                return;
              }
              requesting = true;

              this.intervals++;
              console.log(this.intervals)
              const response = await checkTransaction(body_tx, params_tx);

              const data = response.data;
              response_type = data.responseType;

              if (response_type === 'PaymentDone') {
                this.messages = [this.$t('transaction_complete') as string];
                this.complete = true;

                await sendAnalytics({
                  EventCategory: 'Transaction',
                  EventAction: 'send',
                  EventLabel: 'Positive',
                });
                /*this.$gtag.event('send', {
                  'event_category': 'Transaction',
                  'event_label': 'Positive',
                  'value': 1
                });*/
                if (myMobilumWindow) myMobilumWindow.close();

                console.log(data)
                if (data.followUrl !== null && data.followUrl !== undefined) {
                  window.location.href = data.followUrl;
                }
                if (this.transactionInterval) {
                  clearInterval(this.transactionInterval);
                }
              }
              if (response_type === 'PaymentInProgress') {
                console.log('PaymentInProgress');
              }
              if (response_type === 'PaymentError') {
                console.log('PaymentError');

                this.messages = [this.$t('transaction_failed') as string];
                this.colors = 'error';
                this.failed = true;
                if (this.transactionInterval) {
                  clearInterval(this.transactionInterval);
                }

                await sendAnalytics({
                  EventCategory: 'Transaction',
                  EventAction: 'send',
                  EventLabel: 'Negative',
                });
                /*this.$gtag.event('send', {
                  'event_category': 'Transaction',
                  'event_label': 'Negative',
                  'value': 1
                });*/

                if (data.followUrl !== null && data.followUrl !== undefined) {
                  window.location.href = data.followUrl;
                }
              }
              if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60) {
                this.messages = [this.$t('transaction_failed') as string];
                this.colors = 'error';
                this.failed = true;
                if (this.transactionInterval) {
                  clearInterval(this.transactionInterval);
                }

                await sendAnalytics({
                  EventCategory: 'Transaction',
                  EventAction: 'send',
                  EventLabel: 'Negative',
                });
                /*this.$gtag.event('send', {
                  'event_category': 'Transaction',
                  'event_label': 'Negative',
                  'value': 1
                });*/
              }
              requesting = false;
            }, 3000);
          }
          else if (response_type === 'PaymentDone') {
            this.messages = [this.$t('transaction_complete') as string];
            this.complete = true;

            await sendAnalytics({
              EventCategory: 'Transaction',
              EventAction: 'send',
              EventLabel: 'Positive',
            });
            /*this.$gtag.event('send', {
              'event_category': 'Transaction',
              'event_label': 'Positive',
              'value': 1
            });*/

            if (myMobilumWindow) myMobilumWindow.close();

            if (data.followUrl !== null && data.followUrl !== undefined) {
              window.location.href = data.followUrl;
            }
          }
        } else {
          this.$store.commit(
              'errors',
              response.data.errors.map((item: { message: string }) => item.message),
          );

          if (myMobilumWindow) myMobilumWindow.close();

          const data = response.data;
          const response_type = data.responseType;
          if (response_type === 'PaymentError') {
            console.log('PaymentError');

            if (data.followUrl !== null && data.followUrl !== undefined) {
              window.location.href = data.followUrl;
            }
          } else {
            return;
          }
        }
      } finally {
        this.loadingPayment = false;
      }
    },

    async processPayment() {
      this.messages = [];

      const styles = this.$store.state.config.css;

      const template_3d = `
      <html>
        <head>
          <style>
            ${styles}
          </style>
        </head>
        <body>
          <div style="color: #24AE8F; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div style="font-size: 3em; text-align: center;" class="MBMWidget_3DS_title">
              ${this.$t('we_are_waiting_for_the_3ds')}
            </div>
            <div style="font-size: 2em; text-align: center;" class="MBMWidget_3DS_subtitle">
              ${this.$t('we_are_waiting_for_the_3ds')}
            </div>
            <img src="https://mobilum-public.s3-eu-west-1.amazonaws.com/powered_mobilum_big.png">
          </div>
        </body>
      </html>
      `;

      const form = this.$refs.form as HTMLFormElement;
      if (!form.checkValidity()) return;

      await sendAnalytics({
        EventCategory: 'Transaction',
        EventAction: 'send',
        EventLabel: 'Started',
      });
      /*this.$gtag.event('send', {
        'event_category': 'Transaction',
        'event_label': 'Started',
        'value': 1
      });*/

      const myMobilumWindow = window.open('', '_blank');

      if (myMobilumWindow) {
        myMobilumWindow.document.body.innerHTML = template_3d as string;
      }

      this.agreePayment(myMobilumWindow);
    },
    async agreePayment(myMobilumWindow: any) {
      try {
        this.loadingPayment = true;
        const div = document.querySelector('body');
        if (div) {
          const canvas = await html2canvas(div);
          const dataURL = canvas.toDataURL();
          const blobBin = atob(dataURL.split(',')[1]);
          const array = [];
          for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
          const file = new Blob([new Uint8Array(array)], { type: 'image/png' });

          await uploadScreenshot(file);
        }
        let body = {} as { [key: string]: string | number | boolean };
        const params = {} as { [key: string]: string };
        if (this.selectedCardType === 'Custom') {
          body = {
            Number: this.cardNumber,
            MonthExpiry: this.cardMonth,
            YearExpiry: this.cardYear,
            CVV2: this.cardCvv,
            SaveCard: this.SaveCard,
          };
          params.name = this.name;
        } else {
          body = {
            PreSavedCard: this.selectedCardType,
          };
        }
        const response = await processPayment(body, params);
        this.$store.commit(
          'errors',
          response.data.errors.map((item: { message: string }) => item.message),
        );
        if (response.data.errors.length) {
          if (myMobilumWindow) myMobilumWindow.close();
          return;
        }

        if (myMobilumWindow) {
          myMobilumWindow.document.body.innerHTML = response.data.result.form;
          setTimeout(() => {
            // @ts-ignore
            myMobilumWindow.document.downloadForm.submit();
          }, 2000);
        }
        
        this.transactionUuid = response.data.result.transactionUuid;
        this.colors = 'info';
        this.messages = ['Waiting for transaction to complete'];

        let requesting = false;
        this.transactionInterval = setInterval(async () => {
          this.intervals++;
          if (requesting) {
            return;
          }
          requesting = true;
          const response = await getTransactionDetails(this.transactionUuid);
          if (response.data.success && !response.data.errors.length) {
            if (response.data.result?.complete === true) {
              this.messages = [this.$t('transaction_complete') as string];
              this.complete = true;

              await sendAnalytics({
                EventCategory: 'Transaction',
                EventAction: 'send',
                EventLabel: 'Positive',
              });
              
              /*this.$gtag.event('send', {
                'event_category': 'Transaction',
                'event_label': 'Positive',
                'value': 1
              });*/

              if (this.transactionInterval) {
                clearInterval(this.transactionInterval);
              }
            }
            if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
              window.location.href = response.data.followUrl;
            }
          }
          if (response.data.errors.length > 0) {
            this.$store.commit(
                'errors',
                response.data.errors.map((item: { message: string }) => item.message),
            );
            if (this.transactionInterval) {
              clearInterval(this.transactionInterval);
            }
            if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
              window.location.href = response.data.followUrl;
            }
          }
          if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60) {
            this.messages = [this.$t('transaction_failed') as string];
            this.colors = 'error';
            this.failed = true;
            if (this.transactionInterval) {
              clearInterval(this.transactionInterval);
            }

            await sendAnalytics({
              EventCategory: 'Transaction',
              EventAction: 'send',
              EventLabel: 'Negative',
            });
            /*this.$gtag.event('send', {
              'event_category': 'Transaction',
              'event_label': 'Negative',
              'value': 1
            });*/
            if (response.data.followUrl !== null && response.data.followUrl !== undefined) {
              window.location.href = response.data.followUrl;
            }
          }
          requesting = false;
        }, 1000);
      } finally {
        this.loadingPayment = false;
      }
    },
  },
  watch: {
    selectedCardType(value: string) {
      if (value !== 'Custom') {
        const item = this.userCards.find(
          (item: { identifier: string }) => item.identifier === value,
        );
        if (item) {
          // @ts-ignore
          this.cardNumber = (item as { formattedCard: string }).formattedCard;
        }
      } else {
        // @ts-ignore
        this.cardNumber = '';
      }
    },
  },
  created() {
    for (let index = 1; index <= 12; index++) {
      if (index < 10) {
        this.monthItems.push('0' + index);
      } else {
        this.monthItems.push(index.toString());
      }
    }
    for (let index = 2020; index <= 2030; index++) {
      this.yearItems.push({
        text: index.toString(),
        value: (index % 100).toString(),
      });
    }
  },
  async mounted() {
    const cards = await getUserCards();
    this.userCards = cards.data.result;

    this.name = (await getUserNameAsString()).data.result;

    console.log(store.state.order)
    this.kycInterval = setInterval(async () => {
      const kyc = await checkKycVerified(this.$store.state.order.uuid);

      if (kyc.data) {
        this.canPay = kyc.data.result.canPay;
        if(kyc.data.result.waitForKyc){
          if(!this.canPay)
            this.messages = [this.$t('your_kyc_status_is_still_pending') as string];
            //this.messages = [this.$t('your_kyc_status_is_still_pending_but_can_proceed', {coin: this.order.CryptoValues.symbol, amount: this.order.Price.quantity.toString()} ) as string];
          //else
            //this.messages = [this.$t('your_kyc_status_is_still_pending') as string];
            
          this.kycVerified = false;
        }
        else {
          this.messages = [];
          this.kycVerified = true;
          if (this.kycInterval) {
            clearInterval(this.kycInterval);
          }
        }
      }
    }, 1000);
    const body_tx = {
      cardNumber : null,
      cardId : null
    }
    const response = await gerOrderDetails(body_tx);
    if (this.$store.state.config.showFees) {
      this.order = response.data.result;
    } else {
      this.order = response.data.result.order;
    }
    this.load_first_time = true;

    this.interval = setInterval(async () => {
      const body_tx = {
        cardNumber : null,
        cardId : null
      }
      const response = await gerOrderDetails(body_tx);
      if (this.$store.state.config.showFees) {
        this.order = response.data.result;
      } else {
        this.order = response.data.result.order;
      }
    }, 10000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.kycInterval) {
      clearInterval(this.kycInterval);
    }
    if (this.transactionInterval) {
      clearInterval(this.transactionInterval);
    }
  },
});
</script>
