var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"MBMWidget_Phaze_Card_Breadcrumb MBMWidget_mx-auto MBWidget_block"},[(_vm.store.state.popup == '')?_c('div',{staticClass:"MBWidget_general_back_button"},[_c('a',{staticClass:"MBWidget_general_back",on:{"click":function($event){$event.preventDefault();return _vm.goToPreviousScreen()}}},[_c('ChevronLeftIcon',{attrs:{"fillColor":"#444","size":40}})],1)]):_vm._e(),_c('div',{staticClass:"_div"},[_c('div',{staticClass:"MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4"},[_vm._v(" "+_vm._s(_vm.activeScreen === 1 ? _vm.$t('order_details_title') : _vm.activeScreen === 2 ? _vm.$t('order_details_choose_crypto') : _vm.cryptoName + ' (' + _vm.cryptoTicker + ')')+" ")]),_c('RedirectToBasketButton',{staticClass:"basket_button"})],1),_c('div',{staticClass:"MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Exchange_crypto MBMWidget_GeneralInput MBMWidget_GeneralSelect email"},[_c('div',{staticClass:"MBWidget_breadcrumb"},[_c('div',{staticClass:"breadcrumb_item"},[_c('div',{staticClass:"circle",staticStyle:{"background-color":"#458fe6"}},[_vm._v("1")]),_c('span',{staticStyle:{"color":"#458fe6"}},[_vm._v(_vm._s(_vm.$t('phaze_card_breadcrumb_contact_info')))]),_c('RightArrow',{staticClass:"button_icon",attrs:{"alt":"chevron-right"}})],1),_c('div',{staticClass:"breadcrumb_item"},[_c('div',{staticClass:"circle",style:({
            backgroundColor:
              _vm.activeScreen === 2 || _vm.activeScreen === 3
                ? '#458FE6'
                : '#A3B0BF',
          })},[_vm._v(" 2 ")]),_c('span',{style:({
            color:
              _vm.activeScreen === 2 || _vm.activeScreen === 3
                ? '#458FE6'
                : '#A3B0BF',
          })},[_vm._v(_vm._s(_vm.$t('phaze_card_breadcrumb_cryptocurrency')))]),_c('RightArrow',{staticClass:"button_icon",attrs:{"alt":"chevron-right"}})],1),_c('div',{staticClass:"breadcrumb_item"},[_c('div',{staticClass:"circle",style:({
            backgroundColor: _vm.activeScreen === 3 ? '#458FE6' : '#A3B0BF',
          })},[_vm._v(" 3 ")]),_c('span',{style:({ color: _vm.activeScreen === 3 ? '#458FE6' : '#A3B0BF' })},[_vm._v(_vm._s(_vm.$t('phaze_card_breadcrumb_payment')))])])])]),(_vm.activeScreen === 1)?_c('order-details',{on:{"nextScreen":_vm.goToNextScreen}}):(_vm.activeScreen === 2)?_c('choose-crypto',{on:{"nextScreen":_vm.goToNextScreen}}):_c('review-transaction',{attrs:{"cryptoTicker":_vm.cryptoTicker}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }