<template>
  <div class="MBMWidget_AddCard MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t(title[step]) }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4"></div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Loading MBMWidget_text-center" v-if="loading">
      {{$t('loading')}}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
      <ErrorMessage></ErrorMessage>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0" v-if="step==0 && !loading">
      <form ref="form" @submit.prevent class="MBMWidget_Add_Address_form">
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_mb-2">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat MBMWidget_GeneralInput">
            <AppInputSimple
              @input="addressInput()"
              v-model="address"
              required
              :label="$t('add_card_address')"
              :placeholder="$t('add_card_address_placeholder')"
              class="MBMWidget_AddCard_AppInputName"
            />
          </div>
        </div>
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_mb-2" v-if="!loading">
          <div class="MBMWidget_col-6 MBMWidget_col-xs-6">
          <AppInputSimple
            @input="addressInput()"
            v-model="city"
            :label="$t('add_card_city')"
            :placeholder="$t('add_card_city')"
            class="MBMWidget_AddCard_AppInputName"
          />
          </div>
          <div class="MBMWidget_col-6 MBMWidget_col-xs-6">
            <AppInputSimple
              @input="addressInput()"
              v-model="state"
              required
              :label="$t('add_card_state')"
              :placeholder="$t('add_card_state')"
              class="MBMWidget_AddCard_AppInputName"
            />
          </div>
        </div>
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_mb-2" v-if="!loading">
          
          <div class="MBMWidget_col-6 MBMWidget_col-xs-6">
            <AppInputSimple
              @input="addressInput()"
              v-model="postalCode"
              required
              :label="$t('add_card_postal_code')"
              :placeholder="$t('add_card_postal_code_placeholder')"
              class="MBMWidget_AddCard_AppInputName"
            />
          </div>
          <div class="MBMWidget_col-6 MBMWidget_col-xs-6" v-if="!loading && countries.length">
            <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0 MBMWidget_Select_Label">{{$t('add_card_country_select')}}</div>
            <cool-select
              @select="addressInput()"
              v-model="selectedCountry"
              :items="countries"
              item-value="alpha2Code"
              item-text="name"
              :menuItemsMaxHeight="'125px'"
              class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
            >
              <template slot="item" slot-scope="{ item: i }">
                <div style="display: flex; align-items: center;">
                  <span :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+i.alpha2Code+'.png)'" class="country-flag"></span>
                  <div><b>{{ i.name }}</b></div>
                </div>
              </template>

              <template slot="selection" slot-scope="{ item: i }">
                <span :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+i.alpha2Code+'.png)'" class="country-flag"></span>
                <div><b>{{ i.name }}</b></div>
                <ChevronDownIcon :size="20" fillColor="#333"></ChevronDownIcon>
              </template>
            </cool-select>
          </div>
        </div>
        
        <div class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_mx-auto MBWidget-nofloat" v-if="store.state.editSaved || store.state.editUnsaved">
          <a @click.prevent="deleteCard">{{$t('add_card_delete_card')}}</a>
        </div>

        <AppButton
          class="MBWidget_fake_submit"
          @click="create()"
        ></AppButton>
      </form>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0" v-if="step==1 && !loading">
      <form ref="form" @submit.prevent class="MBMWidget_Add_Card_form">
        <!--<div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0">
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat MBMWidget_GeneralInput">
          <AppInputSimple
            readonly
            v-model="name"
            :value="name"
            :label="$t('add_card_name_on_card')"
            :placeholder="$t('add_card_name_on_card')"
            class="MBMWidget_AddCard_AppInputName"
          />
        </div>
        </div>-->
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat MBMWidget_GeneralInput">
          <AppInputSimple
            class="MBMWidget_AddCard_AppInputName"
            :placeholder="$t('add_card_card_number')"
            :label="$t('add_card_card_number')"
            required
            v-model="shownCardNumber"
            @input="formatCardNumber()"
          />
          </div>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4 MBMWidget_mt-2">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_pt-1 MBMWidget_Select_Label">{{$t('add_card_expiry')}}</div>
          <!--<DateInput :validateRuntime="true" :showDay="false" :init-month="cardMonth" :init-year="cardYear" @monthFilled="monthFilled" @yearFilled="yearFilled"></DateInput>-->
          <div class="MBMWidget_col-np-3 MBMWidget_col-np-xs-3 MBMWidget_Select_AddCard">
          <cool-select
            
            v-model="cardMonth"
            :items="months"
            :placeholder="cardMonth == '' ? 'MM' : ''"
            :menuItemsMaxHeight="'215px'"
            :disableSearch="true"
            class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
          >
            <template slot="item" slot-scope="{ item: i }">
              <div style="display: flex; align-items: center;">
                <div><b>{{ i }}</b></div>
              </div>
            </template>

            <template slot="selection" slot-scope="{ item: i }">
              <div><b>{{ i }}</b></div>
              
            </template>
            
          </cool-select>
            <ChevronDownIcon :size="20" fillColor="#333"></ChevronDownIcon>
          </div>
          <div class="MBMWidget_col-5 MBMWidget_col-xs-5 MBMWidget_Select_AddCard_Right">
            <cool-select

              v-model="cardYear"
              :items="years"
              :placeholder="cardYear == '' ? 'YYYY' : ''"
              :menuItemsMaxHeight="'215px'"
              :disableSearch="true"
              class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
            >
              <template slot="item" slot-scope="{ item: i }">
                <div style="display: flex; align-items: center;">
                  <div><b>{{ i }}</b></div>
                </div>
              </template>

              <template slot="selection" slot-scope="{ item: i }">
                <div><b>{{ i }}</b></div>
              </template>
            </cool-select>
            <ChevronDownIcon :size="20" fillColor="#333"></ChevronDownIcon>
          </div>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_mx-auto MBWidget-nofloat" v-if="!store.state.editSaved">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_pl-0">
            <!--<AppCheckbox
              v-model="saveCard"
              class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_cursor-pointer MBWidget_multiline_checkbox"
            >
              <template #label>
                {{$t('add_card_save')}}
              </template>
            </AppCheckbox>-->
          </div>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_mx-auto MBWidget-nofloat MBMWidget_AddCard_Delete" v-if="store.state.editSaved || store.state.editUnsaved">
          <a @click.prevent="deleteCard">{{$t('add_card_delete_card')}}</a>
        </div>

        <AppButton
          class="MBWidget_fake_submit"
          @click="create()"
        ></AppButton>
      </form>
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-auto">

      <div class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          :disabled="disabled || address == '' || postalCode == '' || state == ''"
          color="primary"
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Login_AppButtonLogin"
          @click="create()"
        >
          {{ $t('add_card_continue_button') }}
        </AppButton>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
 import CardMixin from "./CardMixin";
 import Button from '../General/Button.vue';
 import Input from '../General/Input.vue';
 import InputSimple from '../General/InputSimple.vue';
 import Select from '../General/Select.vue';
 import Checkbox from "@/components/General/Checkbox.vue";
 // @ts-ignore
 import {SelectItem} from "@/components/General/Select.vue";
 import {
   addPaymentMethod,
   getCardsForUser,
   getCountries,
   getUserNameAsString,
   saveBillingAddress,
   updatePaymentMethod, validateCardCheckout
 } from "@/api";
 import store from "@/store";
 import {CardModel} from "@/models/CardModel";
 import {Routes} from "@/store/modules/Router";
 import ErrorMessage from "@/components/Components/ErrorMessage.vue";
 import { CoolSelect } from "vue-cool-select";
 import ChevronDownIcon from "vue-material-design-icons/ChevronDown.vue";
 import DateInput from "@/components/General/DateInput.vue";
export default CardMixin.extend({
  components: {
    AppInput: Input,
    AppInputSimple: InputSimple,
    AppButton: Button,
    AppSelect: Select,
    AppCheckbox: Checkbox,
    ErrorMessage: ErrorMessage,
    CoolSelect: CoolSelect,
    ChevronDownIcon: ChevronDownIcon,
    DateInput: DateInput
  },
  data() {
    return {
      loading: true,
      disabled: false,
      monthItems: [] as string[],
      yearItems: [] as SelectItem[],

      store,
      saveCard: false,
      name: '',

      countries: [],
      address: '',
      city: '',
      selectedCountry: '',
      state: '',
      postalCode: '',
      step: 0,
      title: ["add_card_title_address", "add_card_title_card"],
      addressId : -1,
      addressChanged : false,
      cardId : -3,
      hiddenNumber : '',
      shownCardNumber : '',
      months: [] as string[],
      years: [] as string[]
    }
  },
  methods: {

    clearNumber(num: string){
      return num.replace(/\D/g, "");
    },
    formatCardNumber(){
      this.$store.commit('personalizedErrors', []);
      const val = this.clearNumber(this.shownCardNumber);
      this.cardNumber = val;
      this.shownCardNumber = '';

      for(let i = 0; i < val.length; i++){
        if(i > 0 && i%4==0 && i < val.length) this.shownCardNumber += ' ';
        this.shownCardNumber += val[i];
      }
    },
    monthFilled(val: string){
      this.$store.commit('personalizedErrors', []);
      this.cardMonth = val;
    },
    yearFilled(val: string){
      this.$store.commit('personalizedErrors', []);
      this.cardYear = val;
    },
    addressInput(){
      this.addressChanged = true;
    },
    reportValidity() : boolean {
      const form : HTMLFormElement = this.$refs.form as HTMLFormElement;
      return form.reportValidity();
    },
    deleteCard(){
      this.$store.commit('personalizedErrors', []);
      const id = this.cardId;
      this.$store.state.card = {
        cardId : id,
        hiddenCardNumber: this.hiddenNumber
      };
      this.$store.state.addCard = false;
      store.state.popup='addRemoveCard';
    },
    async saveAddress(){
      if(!this.reportValidity()) return;
      this.$store.commit('personalizedErrors', []);

      if(!this.addressChanged) {
        this.step = 1;
        return;
      }
      this.disabled = true;
      const body = {
        Country : this.selectedCountry,
        City : this.city,
        Street: this.address,
        PostalCode : this.postalCode,
        State : this.state
      };
      const params = {
        userUuid: this.$store.state.profile.userUUid
      };
      const response = await saveBillingAddress(body, params);
      if(response.data >= 0) {
        this.addressId = response.data;
        this.step = 1;
      } else {
        this.$store.commit('personalizedErrors', [{ code: '02', message: "An error occurred while updating the address, please try again!"}]);
      }
      this.disabled = false;
    },
    async create() {
      if(this.step == 0) {
        this.saveAddress();
        return;
      }
      this.$store.commit('personalizedErrors', []);
      if (!(this.$refs.form as HTMLFormElement).reportValidity()) return;
      let tempNumber = this.cardNumber;
      tempNumber = tempNumber.replaceAll(" ", "");
      tempNumber = tempNumber.replaceAll("-", "");
      if(!/^\d+$/.test(tempNumber)){
        this.$store.commit('personalizedErrors', [{code: '03', message:"Invalid characters in card number"}]);
        return;
      } else {
        this.cardNumber = tempNumber;
      }
      if(this.cardNumber.length > 16) {
        //this.cardNumber = this.cardNumber.substring(0, 16)
      }
      if(this.cardNumber.length < 13 || this.cardNumber.length > 16) {
        this.$store.commit('personalizedErrors', [{code: '04', message: "Invalid card number"}]);
        return;
      }

      const currDate = new Date();
      const currMonth = currDate.getMonth()+1;
      const currYear = currDate.getFullYear();
      if(Number(this.cardYear) < currYear || (Number(this.cardYear) == currYear && Number(this.cardMonth) < currMonth)){
        this.$store.commit('personalizedErrors', [{code: '05', message: "Card is expired"}]);
        return;
      }

      const card = {
        hiddenCardNumber: this.cardNumber,
        expiry: this.cardMonth+'/'+this.cardYear,
        firstName: this.name,
        lastName: '',
        Number: this.cardNumber,
        MonthExpiry: this.cardMonth,
        YearExpiry: this.cardYear.slice(-2),
        SaveCard: this.saveCard,
        cardId: this.cardId,
        addressId: this.addressId,
        address: {}
      };

      if(this.saveCard || this.$store.state.editSaved) {
        this.$store.state.card = card;
        this.$store.state.addCard = true;

        this.disabled = true;
        let response = null;
        if(this.$store.state.editSaved){
          response = await updatePaymentMethod(this.$store.state.card, this.$store.state.card.addressId);
        } else {
          response = await addPaymentMethod(this.$store.state.card, this.$store.state.card.addressId);
        }
        if(response != null) {
          const errors = response.data.errors;

          if (errors.length) {
            this.$store.commit('personalizedErrors', errors);

            this.disabled = false;
            this.store.state.popup = '';
            return;
          }
          this.$store.commit('personalizedErrors', []);
        }
        this.$store.state.pageQueue.pop();
        const cards = await getCardsForUser(this.$store.state.profile.userUUid);
        this.disabled = false;
        this.store.state.popup='addRemoveCard';
      } else {
        const response = await validateCardCheckout(this.$store.state.profile.userUUid, this.cardNumber);
        if(response != null && !response.data.success){
            const errors = response.data.errors;
            if (errors.length) {
              this.$store.commit('personalizedErrors', errors);

              this.disabled = false;
              this.store.state.popup = '';
              return;
            }
        }
        
        card.cardId = -2;
        this.$store.state.defaultCardId = -2;
        card.address = {
          address: this.address,
          city: this.city,
          selectedCountry: this.selectedCountry,
          state: this.state,
          postalCode: this.postalCode
        };
        this.$store.state.unsavedCard = card;
        let got = false;
        for(let i = this.$store.state.pageQueue.length -1; i >= 0; i--){
          if(this.$store.state.pageQueue[i].includes('Phaze')) {
            this.$store.commit('router/goTo', Routes.PhazeCardCheckout);
            got = true;
            break;
          } else if(this.$store.state.pageQueue[i].includes('Order') || this.$store.state.pageQueue[i].includes('Purchase')) {
            this.$store.commit('router/goTo', Routes.PurchaseSummary);
            got = true;
            break;
          }
        }
        if(!got) {
          if(this.$store.state.order.uuid != '') {
            this.$store.commit('router/goTo', Routes.PurchaseSummary);
          } else if(this.$store.state.phazeOrder.uuid != '') {
            this.$store.commit('router/goTo', Routes.PhazeCardCheckout);
          } else {
            this.$store.commit('router/goTo', Routes.Exchange);
          }
        }
      }

    }
  },
  created() {
    this.$store.commit('personalizedErrors', []);
    for (let index = 1; index <= 12; index++) {
      if (index < 10) {
        this.months.push('0' + index);
      } else {
        this.months.push(index.toString());
      }
    }
    
    for (let index = this.minCardYear; index <= 2035; index++) {
      this.years.push(index.toString());
    }
  },
  async mounted() {
    this.$store.commit('setPreviousPage', 'AddCard');
    if(this.$store.state.editUnsaved || this.$store.state.editSaved) {

      let card = null;
      let expiry : any[] = [];
      if(this.$store.state.editUnsaved) {
        card = this.$store.state.unsavedCard;
        this.cardYear = card.YearExpiry;
        if(this.cardYear.length == 2)
          this.cardYear = '20'+this.cardYear;
        this.cardMonth = card.MonthExpiry;
        this.cardId = -2;
        this.hiddenNumber = card.hiddenCardNumber.substr(card.hiddenCardNumber.length - 4);
      } else {
        this.$store.state.userCards.forEach((i: any) => {
          if(i.cardId == this.$store.state.editSavedId) {
            this.cardId = i.cardId;
            expiry = i.expiry.split('/');
            this.hiddenNumber = i.hiddenCardNumber.substr(i.hiddenCardNumber.length - 4);
            this.cardYear = expiry.length ? expiry[1] : '';
            if(this.cardYear.length == 2)
              this.cardYear = '20'+this.cardYear;
            this.cardMonth = expiry.length ? expiry[0] : '';
            card = i;
          }
        })
      }

      this.cardNumber = card.Number;
      this.address = card.address.address || card.address.street;
      this.city = card.address.city;
      this.selectedCountry = card.address.selectedCountry || card.address.country;
      this.addressId = card.address.addressId || card.address.id;
      this.state = card.address.state;
      this.postalCode = card.address.postalCode;
    }
    if(!this.$store.state.countries.length) {
      const response = await getCountries();
      this.countries = response.data.result.Countries;
      this.$store.state.countries = response.data.result.Countries;
    } else {
      this.countries = this.$store.state.countries;
    }
    if(this.selectedCountry == '' || this.selectedCountry == null) {
      this.countries.forEach((i: any) => {
        if (this.$store.state.profile.country == i.name) {
          this.selectedCountry = i.alpha2Code;
        }
      })
      if (this.selectedCountry == '' || this.selectedCountry == null)
        this.selectedCountry = 'AF';
    }
    this.name = (await getUserNameAsString()).data.result;
    this.loading = false;
  },
 });
</script>

<style scoped>

</style>