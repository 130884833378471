<template>
  <div
    id="app"
    v-bind:class="[this.$store.state.isMobile ? 'mobile_content' : '']"
  >
    <div class="MBMWidget_d-flex">
      <div class="MBMWidget_General">
        <div
          class="MBWidget_general_back_button"
          :class="store.state.pageQueue.length > 0 && store.state.pageQueue[this.$store.state.pageQueue.length - 1] == 'PhazeCardList' ? 'MBWidget_phaze_back_button' : ''"
          v-if="
            store.state.pageQueue.length > 1 &&
            !iframe &&
            store.state.popup == '' &&
            !store.state.hideBackButton
          "
        >
          <a class="MBWidget_general_back" @click.prevent="back()">
            <ChevronLeftIcon fillColor="#444" :size="40" />
          </a>
        </div>
        <div
          class="MBWidget_general_menu_button"
          v-show="
            !iframe &&
            store.state.popup == '' &&
            isFeature(Feature.Menu) &&
            !this.$store.state.hideMenuButton
          "
        >
          <a
            class="MBWidget_menu_button"
            @click.prevent="store.state.popup = 'menu'"
          >
            <MenuIcon fillColor="#888" :size="36" />
          </a>
        </div>
        <div id="ThreeDS_iframe"></div>
        <!--<div class="MBWidget_Logo MBMWidget_my-5" v-show="store.state.popup == ''">
          <img style="margin-right: 1em; margin-left: 1em;" src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/MBLogo.png" />
        </div>-->
        <AppSnack
          :messages="errors"
          v-bind:style="{
            display: getDisplay(!iframe && store.state.popup == ''),
          }"
        />
        <component
          :is="component"
          v-bind:style="{
            display: getDisplay(!iframe && store.state.popup == ''),
          }"
          :showIframe="showIframe"
        />

        <div
          style="margin: 16px"
          v-bind:style="{ display: getDisplayOrFlex(iframe) }"
        >
          <div class="MBMWidget_cursor-pointer" @click="closeIframe">
            {{ $t('go_back_to_the_widget') }}
          </div>
          |
          <div>
            <a
              :href="mobilumPdf"
              target="_blank"
              class="MBMWidget_mobilumPdf MBMWidget_cursor-pointer"
              >{{ $t('download_in_pdf') }}</a
            >
          </div>
        </div>

        <div
          class="MBMWidget_iframe_wrap"
          v-bind:style="{ display: getDisplay(iframe) }"
        >
          <iframe
            v-if="iframe"
            :src="mobilumSrc"
            frameborder="0"
            style="height: 100%"
            marginheight="0"
            marginwidth="0"
            width="100%"
            height="100%"
            class="MBMWidget_iframe"
            scrolling="auto"
          ></iframe>
        </div>

        <!--<div style="display: flex; margin-top: 10px; align-items: center; align-self: center;" v-show="store.state.popup == ''">
          <img class="MBMWidget_App_LightMode" style="display: none; height: 15px !important;" src="https://mobilum-public.s3-eu-west-1.amazonaws.com/visa_blue.png" />
          <img class="MBMWidget_App_DarkMode" style="display: none; height: 15px !important;" src="https://mobilum-public.s3-eu-west-1.amazonaws.com/visa_white.png" />

          <img class="MBMWidget_App_LightMode" style="display: none; height: 20px !important;" src="https://mobilum-public.s3-eu-west-1.amazonaws.com/master_new.svg" />
          <img class="MBMWidget_App_DarkMode" style="display: none; height: 20px !important; background: rgb(50, 67, 162);" src="https://mobilum-public.s3-eu-west-1.amazonaws.com/master_new.svg" />
        </div>-->

        <Popup :showIframe="showIframe" v-show="!iframe"></Popup>

        <!--<div class="MBMWidget_App_langs" v-show="store.state.popup == '' && !iframe">-->
        <!--<AppSelect
            v-model="$i18n.locale"
            class="MBMWidget_d-inline-block"
            :items="langs"
            color="primary"
          />-->
        <!--<div class="MBWidget_terms_wrap">

            <div class="MBMWidget_cursor-pointer MBWidget_terms" @click="showIframe('privacy_policy')">
              {{ $t('privacy_policy') }}
            </div>

            <div class="MBMWidget_cursor-pointer MBWidget_terms" @click="showIframe('terms_and_conditions')">
              {{ $t('terms_and_conditions') }}
            </div>

          </div>-->
        <!--<a @click.prevent="store.state.popup = 'lang'" class="MBWidget_App_langs_link"><WebIcon fillColor="#333" :size="20"></WebIcon>{{$t('lang_name_'+langNames[$i18n.locale])}}<span><MenuSwapIcon fillColor="#333"></MenuSwapIcon></span></a>

        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import Snack from './components/General/Snack.vue';
import Select from './components/General/Select.vue';
import Popup from './components/Components/Popup.vue';
import { languages, names, defaultLocale } from './i18n/index';
import { Routes } from '@/store/modules/Router';
import store from '@/store';
import { redirectView, isFeature } from '@/utils';
import { sendAnalytics } from '@/api';
import { getCookie } from '@/utils';

import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import WebIcon from 'vue-material-design-icons/Web.vue';
import MenuSwapIcon from 'vue-material-design-icons/MenuSwap.vue';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import { Feature } from '@/components/Enums/StepType';

export default Vue.extend({
  name: 'App',
  components: {
    ChevronLeftIcon: ChevronLeftIcon,
    WebIcon: WebIcon,
    MenuSwapIcon: MenuSwapIcon,
    MenuIcon: MenuIcon,
    AppSelect: Select,
    AppSnack: Snack,
    Popup: Popup,
    Exchange: () => import('./components/Exchange/Exchange.vue'),
    Login: () => import('./components/Login/Login.vue'),
    Register: () => import('./components/Register/Register.vue'),
    Verify: () => import('./components/Verify/Verify.vue'),
    CardScreen: () => import('./components/CardScreen/CardScreen.vue'),
    CardRedirect: () => import('./components/CardScreen/CardRedirect.vue'),
    CheckKyc: () => import('./components/Kyc/CheckKyc.vue'),
    AddCard: () => import('./components/CardScreen/AddCard.vue'),
    CardList: () => import('./components/CardScreen/CardList.vue'),
    Tfa: () => import('./components/Tfa/Tfa.vue'),
    Kyc: () => import('./components/Kyc/Kyc.vue'),
    Forgot: () => import('./components/Forgot/Forgot.vue'),
    Recover: () => import('./components/Recover/Recover.vue'),
    RegionBlocked: () => import('./components/RegionBlocked/RegionBlocked.vue'),
    PurchaseSummary: () =>
      import('./components/Transactions/PurchaseSummary.vue'),
    PurchaseSuccess: () =>
      import('./components/Transactions/PurchaseSuccess.vue'),
    TransactionHistory: () =>
      import('./components/Transactions/TransactionHistory.vue'),
    PhazeCardList: () => import('./components/Phaze/PhazeCardList.vue'),
    PhazeCardSummary: () => import('./components/Phaze/PhazeCardSummary.vue'),
    PhazeCardShoppingCart: () =>
      import('./components/Phaze/PhazeCardShoppingCart.vue'),
    PhazeCardPurchased: () =>
      import('./components/Phaze/PhazeCardPurchased.vue'),
    PhazeCardPendingPayment: () =>
      import('./components/Phaze/PhazeCardPendingPayment.vue'),
    PhazeCardCheckout: () => import('./components/Phaze/PhazeCardCheckout.vue'),
    PhazeCardPendingDelivery: () => import('./components/Phaze/PhazeCardPendingDelivery.vue'),
    Checkout: () => import('./components/Checkout/BreadCrumbScreen.vue'),
    OrderDetails: () => import('./components/Checkout/OrderDetails.vue'),
    ChooseCrypto: () => import('./components/Checkout/ChooseCrypto.vue'),
    KycInProgress: () => import('./components/Kyc/KycInProgress.vue'),
    PhazeCardHistory: () => import('./components/Phaze/PhazeCardHistory.vue'),
    Profile: () => import('./components/Profile/Profile.vue'),
    ProfileCryptoFlow: () =>
      import('./components/Profile/ProfileCryptoFlow.vue'),
    ProfileGiftcardFlow: () =>
      import('./components/Profile/ProfileGiftcardFlow.vue'),
    JwtExpired: () =>
      import('./components/JwtExpired/JwtExpired.vue'),
    SelectMethod: () =>
      import('./components/CardScreen/SelectMethod.vue'),
    Bridge: () =>
      import('./components/Bridge/Bridge.vue'),
  },
  computed: {
    ...mapState(['config']),
    ...mapState(['errors']),
    ...mapState('router', ['route']),
    component() {
      return this.route;
    },
  },
  data() {
    return {
      langs: Object.keys(languages),
      langNames: names,
      iframe: false,
      mobilumSrc: '',
      mobilumPdf: '',
      popup: false,
      prevBackPage: '',
      Routes,
      store,
      redirectView,
      isFeature,
      Feature,
    };
  },
  mounted() {
    console.log('show-widget');

    if (process.env.NODE_ENV === 'development') {
      localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
      localStorage.removeItem('walletconnect');
    }
    sendAnalytics({
      EventCategory: 'general',
      EventAction: 'show-widget',
      EventLabel: 'Widget Shown',
    });
    /*this.$gtag.event('show-widget', {
      'event_label': 'Widget Shown',
      'event_domain': window.location.host,
    });*/
  },
  beforeDestroy() {
    console.log('widget-closed');
    sendAnalytics({
      EventCategory: 'general',
      EventAction: 'widget-closed',
      EventLabel: 'Widget closed',
    });
    /*this.$gtag.event('widget-closed', {
      'event_label': 'Widget closed',
      'event_domain': window.location.host,
    });*/
  },
  methods: {
    showIframe(name: string) {
      if(store.state.config.pdfPath != null) {
        // @ts-ignore
        this.mobilumSrc = store.state.config.pdfPath + name + '.html';
      } else {
        // @ts-ignore
        this.mobilumSrc = 'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/' + name + '.html';
      }
      
      // @ts-ignore
      this.iframe = true;

      if (name === 'privacy_policy') {
        // @ts-ignore
        this.mobilumPdf = store.state.config.privacyPolicyUrl;
      }
      if (name === 'terms_and_conditions') {
        // @ts-ignore
        this.mobilumPdf = store.state.config.termsAndConditionsUrl;
      }
    },
    closeIframe() {
      // @ts-ignore
      this.iframe = false;
    },
    getDisplay(value: boolean) {
      if (!value) {
        return 'none';
      } else {
        return 'block';
      }
    },
    getDisplayOrFlex(value: boolean) {
      if (!value) {
        return 'none';
      } else {
        return 'flex';
      }
    },
    back() {
      if (this.$store.state.pageQueue.length > 1) {
        this.$store.state.pageQueue.pop();
        this.$store.commit('personalizedErrors', []);
        redirectView({
          redirectUrl:
            this.$store.state.pageQueue[this.$store.state.pageQueue.length - 1],
        });
      }
    },
  },
  /*watch: {
    this.store.popup(val: string){

    }
  }*/
});
</script>
<style lang="sass" src="./styles/style.sass" />
