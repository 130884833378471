<template>
  <div class="MBMWidget_Card_List MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_title MBMWidget_Header">
      {{ $t('card_list_title') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4"></div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Loading MBMWidget_text-center" v-if="loading">
      {{$t('loading')}}
    </div>
    <div
      class="MBWidget_Card_List_No_Results_Section"
      v-if="!loading && !store.state.userCards.length && store.state.unsavedCard == null"
    >
      <div
        class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-3 MBMWidget_text-center"
      >
        <button
          type="button"
          @click="$store.commit('router/goTo', Routes.AddCard)"
          class="external_wallet_button metamask_button add_card_button"
        >
          <CreditCardOutlineIcon :size="80" fillColor="#888"/>
        </button>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center">
        {{ $t('card_list_add_any') }}
      </div>
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBWidget_Card_List_Parent"
         v-if="store.state.userCards.length || store.state.unsavedCard != null">
      <div
        v-if="store.state.unsavedCard != null"
        class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12 MBWidget_Card_List_Item MBMWidget_mx-auto MBWidget-nofloat">

        <div class="MBMWidget_col-np-10 MBMWidget_col-np-xs-10 MBWidget_Card_List_Item_Texts" @click="route(-2)">
          <span class="MBWidget_Card_List_Item_Icon"><span
            :class="'MBMWidget_card_'+getCardIcon(store.state.unsavedCard.hiddenCardNumber)+' MBWidget_Card_List_Item_Icon_Card'"></span></span>
          <span class="MBMWidget_Card_List_Item_Card">*** *** *** {{store.state.unsavedCard.hiddenCardNumber.substr(store.state.unsavedCard.hiddenCardNumber.length - 4)}}</span>
        </div>
        <div class="MBMWidget_col-np-2 MBMWidget_col-np-xs-2">
          <div class="MBMWidget_Card_List_Item_Arrow"><a @click.prevent="editUnsaved()">
            <ChevronRightIcon fillColor="#666" :size="24"></ChevronRightIcon>
          </a></div>
        </div>
      </div>
      <div v-for="c in store.state.userCards" v-bind:key="c.cardId"
           class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12 MBWidget_Card_List_Item MBMWidget_mx-auto MBWidget-nofloat">
        <div class="MBMWidget_col-np-10 MBMWidget_col-np-xs-10 MBWidget_Card_List_Item_Texts" @click="route(c.cardId)">
          <span class="MBWidget_Card_List_Item_Icon"><span
            :class="'MBMWidget_card_'+getCardIcon(c.hiddenCardNumber)+' MBWidget_Card_List_Item_Icon_Card'"></span></span>
          <span class="MBMWidget_Card_List_Item_Card">*** *** *** {{c.hiddenCardNumber.substr(c.hiddenCardNumber.length - 4)}}</span>
        </div>
        <div class="MBMWidget_col-np-2 MBMWidget_col-np-xs-2">
          <div class="MBMWidget_Card_List_Item_Arrow"><a @click.prevent="editSaved(c.cardId)">
            <ChevronRightIcon fillColor="#666" :size="24"></ChevronRightIcon>
          </a></div>
        </div>
      </div>
    </div>

    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          color="primary"
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Card_List_Action"
          :disabled="(!store.state.canAddNewCard) || (store.state.unsavedCard != null && store.state.userCards.length >= 2)"
          @click="$store.commit('router/goTo', Routes.AddCard)"
        >
          {{ $t('card_list_add_new_card') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Button from '../General/Button.vue';
  import CardMixin from "./CardMixin";
  import { Routes } from '@/store/modules/Router';
  import './cards.css';
  import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
  import TrashCanOutlineIcon from 'vue-material-design-icons/TrashCanOutline.vue';
  import CreditCardOutlineIcon from 'vue-material-design-icons/CreditCardOutline.vue';
  import store from "@/store";
  import {getCardsForUser, shouldPerformKyc} from "@/api";
  import md5 from "blueimp-md5";
  import {redirectView, redirectViewString} from "@/utils";
  import {Feature} from "@/components/Enums/StepType";
  
  export default CardMixin.extend({
    components: {
      AppButton: Button,
      ChevronRightIcon: ChevronRightIcon,
      CreditCardOutlineIcon: CreditCardOutlineIcon,
      //TrashCanOutline: TrashCanOutlineIcon
    },
    data(){
      return {
        Routes,
        store,
        disabled: false,
        loading: false,
        cards: [],
        kycStartedInterval: null as any,
        kycStarted: true,
      }
    },
    methods: {
      async loadData(){
        this.shouldPerformKyc();
        this.kycStartedInterval = setInterval(async () => {
          this.shouldPerformKyc();
        }, 3000);
        if(!this.$store.state.userCards.length) {
          this.disabled = true;
          this.loading = true;
          await getCardsForUser(this.$store.state.profile.userUUid);
          this.disabled = false;
          this.loading = false;
        }

        this.checkBackPage();

        // @ts-ignore
        this.cards = this.$store.state.userCards;
        
       
      },
     getCardIcon(t: string) {
       let re = new RegExp('^4');
       if (t.match(re) != null) return 'visa';
       
       re = new RegExp('^5[1-5]');
       if (t.match(re) != null) return 'mastercard';
       
       return 'mastercard';
     },
      async shouldPerformKyc() {
        const kyc = await shouldPerformKyc();
        if (kyc.data != null) {
          if(kyc.data == true){
            this.kycStarted = false;
          }
          else {
            this.kycStarted = true;
            if (this.kycStartedInterval) {
              clearInterval(this.kycStartedInterval);
            }
          }
        }
      },
      getClass(id: string){
        if(id == this.$store.state.defaultCardId)
          return 'MBWidget_Card_List_Item_selected';
        else
          return '';
      },
      editUnsaved() {
        this.$store.state.editUnsaved = true;
        this.$store.commit('router/goTo', Routes.AddCard)
      },
      editSaved(id: any){
        this.$store.state.editSaved = true;
        this.$store.state.editSavedId = id;
        this.$store.commit('router/goTo', Routes.AddCard)
      },
      setDefault(id: string) {
        //this.$store.state.unsavedCard = null;
        this.$store.state.selectedCardId = id;
        this.$store.state.defaultCardId = id;
      },
      getNextButtonText() {
        if(this.$store.state.order.uuid != '' || this.$store.state.phazeOrder.uuid != '')
          return 'card_list_view_order';
        else
          return 'card_list_buy_crypto';
      },
      route(id: string) {
        this.$store.state.selectedCardId = id;
        this.$store.state.defaultCardId = id;
        this.$store.state.paymentOption = Feature.Card;
        if(this.$store.state.order.uuid != '') {
          this.$store.commit('router/goTo', Routes.PurchaseSummary);
        }
        else {
          this.$store.commit('router/goTo', Routes.Exchange);
        }
      },
      deleteCard(card: any) {
        this.$store.state.card = card == -2 ? this.$store.state.unsavedCard  : card; 
        this.$store.state.addCard = false;
        store.state.popup='addRemoveCard';
      },
      checkBackPage() {
        if(this.$store.state.order.uuid != '') {
          if(this.$store.state.userCards.length > 0 || this.$store.state.unsavedCard != null) {
            store.state.backPage = 'PurchaseSummary';
          } else {
            store.state.backPage = 'AddCard';
          }

        } else {
          store.state.backPage = 'Order';
        }
      }
    },
    mounted(){
      /*if(this.$store.state.userCards.length == 0 && this.$store.state.unsavedCard == null) {
        this.$store.commit('router/goTo', Routes.AddCard);
        return;
      }*/
      this.$store.commit('setPreviousPage', 'CardList');

      this.$store.state.editUnsaved = false;
      this.$store.state.editSaved = false;
      this.loadData();
    },
    beforeDestroy() {
      if (this.kycStartedInterval)
        clearInterval(this.kycStartedInterval);
    },
  });
</script>

<style scoped>

</style>
