<template>
  <div class="MBMWidget_Transaction_History MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t('tx_list_title') }}
    </div>
    <!--<div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0 MBMWidget_my-0">
      <div class="MBMWidget_KYC_Notice_Button" @click="store.commit('router/goTo', Routes.Kyc)" v-if="!kycStarted">
        {{ $t('perform_kyc_general_button') }}
      </div>
    </div>-->
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center MBMWidget_mt-4" v-if="loading">
      {{$t('loading')}}
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center MBMWidget_mt-4" v-if="!loading && (items == null || items.length == 0)">
      {{$t('tx_list_no_tx')}}
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Transaction_History_List MBMWidget_mt-4">
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Transaction_History_Item" @click="viewTransaction(i.transactionUuid)" v-for="i in items" v-bind:key="i.order.index">

        <div class="MBMWidget_col-np-8 MBMWidget_col-np-md-8 MBMWidget_col-np-xs-8 MBMWidget_Transaction_History_Item_Details">
          <div class="MBMWidget_Transaction_History_Item_Icon">
            <span
              class="fiat_select_icon_flag"
              :style="
                    'background-image:url(' +
                      getCryptoIcon(i.order.selectedCryptoCurrency) +
                      ');'
                  "
            ></span>
          </div>
          <div class="MBMWidget_Transaction_History_Item_Details_Data">
            <p class="MBMWidget_Transaction_History_Item_Details_Title">{{$t('tx_history_bought')}} {{i.order.selectedCryptoCurrency}}</p>
            <p class="MBMWidget_Transaction_History_Item_Details_Date">{{getDate(i.order.createdDate)}}</p>
            <p class="MBMWidget_Transaction_History_Item_Details_Status">{{$t('tx_history_item_status')}} : {{(i.order.status).replace(/([A-Z])/g, ' $1').trim()}}</p>
          </div>
        </div>
        <div class="MBMWidget_col-np-4 MBMWidget_col-np-md-4 MBMWidget_col-np-xs-4 MBMWidget_Transaction_History_Item_Prices">
          <p class="MBMWidget_Transaction_History_Item_Details_Crypto">{{i.order.cryptoAmount}} {{i.order.selectedCryptoCurrency}}</p>
          <p class="MBMWidget_Transaction_History_Item_Details_Fiat">{{getFiatSymbol(i.order.fiatCurrency)}}{{formatCurrency(i.order.transactionTotal)}} {{i.order.fiatCurrency}}</p>
        </div>
        
        

      </div>
    </div>

    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-auto"  v-if="!loading && (items == null || items.length == 0)">
      <div class="MBMWidget_col-8 MBMWidget_col-xs-8 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Forgot_Recover_button"
          color="primary"
          @click="store.commit('router/goTo', Routes.Exchange)"
        >
          {{$t('tx_list_buy')}}
        </AppButton>
      </div>
    </div>
    

  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import {getTransactionDetailsForHistory, getUserTransactions, shouldPerformKyc} from "@/api";
  import ArrowBottomLeftIcon from 'vue-material-design-icons/ArrowBottomLeft.vue';
  import store from "@/store";
  import {Routes} from "@/store/modules/Router";
  import Button from '../General/Button.vue';
  import {processDecimalPlaces} from "@/utils";
  import CurrencyList from "@/components/Components/CurrencyList.json";
  
  export default Vue.extend({
    components: {
      AppButton: Button,
    },
    data(){
      return {
        items: null,
        loading: false,
        store,
        Routes,
        kycStartedInterval: null as any,
        kycStarted: true,
      }
    },
    methods: {
      getCryptoIcon(ticker: string | undefined): any {
        const t = ticker?.replace(' ', '+');

        return (
          'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/cryptoicons/' +
          t +
          '.png'
        );
      },
      formatCurrency(amount : any){
        return processDecimalPlaces(amount, 2);
      },
      async shouldPerformKyc() {
        const kyc = await shouldPerformKyc();
        if (kyc.data != null) {
          if(kyc.data == true){
            this.kycStarted = false;
          }
          else {
            this.kycStarted = true;
            if (this.kycStartedInterval) {
              clearInterval(this.kycStartedInterval);
            }
          }
        }
      },
      async loadData() {
        this.loading = true;
        this.shouldPerformKyc();
        this.kycStartedInterval = setInterval(async () => {
          this.shouldPerformKyc();
        }, 3000);
        
        const id = this.$store.state.profile.userUUid;
        const response = await getUserTransactions(id);
        if(response.data) {
          this.items = response.data;
        }
        this.loading = false;
      },
      async viewTransaction(tx: string){
        this.$store.state.currentTx = null;
        this.$store.state.popup = 'transactionView';
        const response = await getTransactionDetailsForHistory(tx);
        if(response.data.success) {
          this.$store.state.currentTx = response.data.result;
        } else {
          this.$store.state.popup = '';
        }
      },
      getFiatSymbol(code: string) {
        // @ts-ignore
        return CurrencyList[this.$i18n.locale][code].symbol;
      },
      getFormatedWallet(val: string){
        if(val == null || val.length <= 20)
          return val;
        
        const first = val.substr(0, 8);
        const second = val.substr(val.length - 8);
        return first+"..."+second;
      },
      getDate(val: string){
        const date = new Date(Date.parse(val));
        return date.toLocaleString('en-GB');
      }
    },
    mounted(){
      this.$store.commit('setPreviousPage', 'TransactionHistory');
      this.loadData();
    },
    beforeDestroy() {
      if (this.kycStartedInterval)
        clearInterval(this.kycStartedInterval);
    },
  });
</script>

<style scoped>

</style>