import Vue from 'vue';
import {Routes} from "@/store/modules/Router";
import store from "@/store";
import {
  AuthenticateTx,
  checkTransaction,
  getTransactionDetails, getTransactionStatus,
  processPayment,
  processPayment3DSRequest, ProcessPaymentNewVolt,
  sendAnalytics,
  uploadScreenshot
} from "@/api";
import html2canvas from "html2canvas";
import {Feature} from "@/components/Enums/StepType";
export default Vue.extend({
  data() {
    return {
      CVV: '',
      Routes,
      disabled: false,
      messages: [] as string[],
      colors: 'warning',
      store,
      interval: null as any,
      transactionInterval: null as any,
      statusInterval: null as any,
      loadFirstTime: false,
      card: null,
      loadingPayment: false,
      complete: false,
      failed: false,
      intervals: 0,
      transactionUuid: '',
      card_authentication_id: '',
      unsavedCard: false,
      dateInterval: null as any,
      currentDate: '',
      agreeCheck: false,
      currentOrderUuid: '',
      requestUuid: ''
    };
  },
  methods: {
    routeToSuccess() { console.log();}, //overriden
    async processPaymentWire(myMobilumWindow: any) {
      console.log("Process payment Wire")
      const response = await ProcessPaymentNewVolt(this.currentOrderUuid);
      if (response.data.success) {
        const data = response.data;
        const response_type = data.responseType;
        this.requestUuid = data.result?.requestUuid;
        const url = data.redirectUrl;
        myMobilumWindow.location.replace(url);

        let body_tx = {} as { [key: string]: string | number | boolean };
        const params_tx = {} as { [key: string]: string };
        body_tx = this.populateBodyCard(body_tx);
        
        this.threeDsTwoChallenge(body_tx, params_tx, myMobilumWindow);
      }
      else {
        this.$store.commit(
            'personalizedErrors',
            response.data.errors
        );

        if (myMobilumWindow) {
          this.closeWindow(myMobilumWindow);
          console.log('3DS close - 4')

        }
        this.loadingPayment = false;
        this.disabled = false;
        const data = response.data;
        const response_type = data.responseType;
        if (response_type === 'PaymentError') {
          console.log('PaymentError');
          if (data.followUrl !== null && data.followUrl !== undefined)
            window.location.href = data.followUrl;
        } else {
          return;
        }
      }
      console.log(response);
    },
    processPayment3DS(orderUuid: string) {
      this.disabled = true;
      const file = this.getScreenshot();
      if(file != null) {
        // @ts-ignore
         uploadScreenshot(file);
      }
      
      this.currentOrderUuid = orderUuid;
      const styles = this.$store.state.config.css;
      const template_3d = `
        <html>
          <head>
            <style>
              ${styles}
            </style>
          </head>
          <body>
            <div style="color: #24AE8F; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <div style="font-size: 36px; text-align: center; padding: 10px 20px;" class="MBMWidget_3DS_title">
                ${this.$t('we_are_waiting_for_the_3ds')}
              </div>
              <img src="https://mobilum-public.s3-eu-west-1.amazonaws.com/powered_mobilum_big.png">
            </div>
          </body>
        </html>
        `;
      sendAnalytics({EventCategory: 'Transaction', EventAction: 'send', EventLabel: 'Started',});

      let myMobilumWindow = null;

      if(this.$store.state.config.show3dsInNewTab) {
        myMobilumWindow = window.open('', '_blank');
        if (myMobilumWindow)
          myMobilumWindow.document.body.innerHTML = template_3d as string;
      } else {
        myMobilumWindow = document.createElement('iframe');
        
        
        myMobilumWindow.id = 'MBMWidget_iframe_3DS';
        if (myMobilumWindow)
          myMobilumWindow.src = 'data:text/html;charset=utf-8,' + escape(template_3d);
        // @ts-ignore
        document.getElementById('ThreeDS_iframe').style.display = 'block';
        // @ts-ignore
        document.getElementById('ThreeDS_iframe').appendChild(myMobilumWindow);
      }
      if(this.$store.state.paymentOption == Feature.OpenBanking.toString()) {
        this.processPaymentWire(myMobilumWindow)
      } else {
        this.agreePaymentTx(myMobilumWindow);
      }
    },
    async agreePaymentTx(myMobilumWindow: any) {
      try {
        this.loadingPayment = true;
        const browser_language = this.$i18n.locale;
        const browser_screen_height = window.screen.height;
        const browser_screen_width = window.screen.width;
        const browser_time_zone = (new Date()).getTimezoneOffset();
        const browser_user_agent = navigator.userAgent;
        let browser_screen_color_depth = window.screen.colorDepth;
        let browser_java_enabled_val = ''
        if (navigator.javaEnabled()) {
          browser_java_enabled_val = 'ENABLED';
        } else {
          browser_java_enabled_val = 'DISABLED';
        }
        const browser_java_script_enabled = 'ENABLED';

        let body = {} as { [key: string]: string | number | boolean };
        const params = {} as { [key: string]: string };

        const depths = [1, 4, 8, 15, 16, 24, 32, 48];
        if (!depths.includes(browser_screen_color_depth)) {
          browser_screen_color_depth = depths.reduce((prev, curr) => {
            return (Math.abs(curr - browser_screen_color_depth) < Math.abs(prev - browser_screen_color_depth) ? curr : prev);
          });
        }

        body = {
          BrowserLanguage: browser_language,
          BrowserScreenHeight: String(browser_screen_height),
          BrowserScreenWidth: String(browser_screen_width),
          BrowserTimeZone: String(browser_time_zone),
          BrowserUserAgent: browser_user_agent,
          BrowserScreenColorDepth: String(browser_screen_color_depth),
          BrowserJavaEnabledVal: browser_java_enabled_val,
          BrowserJavaScriptEnabled: browser_java_script_enabled,
        };
        body = this.populateBodyCard(body);
        params.orderUuid = this.currentOrderUuid;
        
        const response = await processPayment3DSRequest(body, params);

        if (response.data.success) {
          const data = response.data;
          let response_type = data.responseType;
          this.requestUuid = data.result.requestUuid;
          
          if (response_type === 'PreAuthenticationRequired') {
            console.log("PreAuthenticationRequired")
            const result = data.result;

            // TODO: hiden form
            const card_authentication_id = result.cardAuthenticationId;
            const three_ds_method_data = result.threeDSMethodData;
            const three_ds_method_url = result.threeDSMethodURL;

            const iframe = document.createElement('iframe');
            iframe.id = 'MBMWidget_iframe_hidden';
            iframe.style.cssText = 'height: 0 !important; width: 0 !important; border: 0 !important';

            const html = `
              <body onload='document.getElementById("mobilum_id_3ds").submit()'>
                <form id="mobilum_id_3ds" action="${three_ds_method_url}" method="post">
                  <input name="threeDSMethodData" value="${three_ds_method_data}" />
                </form>
              </body>
              `;

            iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
            document.body.appendChild(iframe);

            const time = new Date().getTime();
            while (new Date().getTime() < time + 2000);

            let body_tx = {} as { [key: string]: string | number | boolean };
            const params_tx = {} as { [key: string]: string };

            body_tx = this.populateBodyCard(body_tx);
            params_tx.cardAuthenticationId = card_authentication_id;
            params_tx.orderUuid = this.currentOrderUuid;
            
            const responseTx = await AuthenticateTx(body_tx, params_tx);
            
            if (responseTx.data.success) {
              const data = responseTx.data
              response_type = data.responseType;
             
              await this.processResponse(data, response_type, responseTx, myMobilumWindow, body_tx, params_tx);
              
            } else {
              this.$store.commit(
                'personalizedErrors',
                responseTx.data.errors
              );
              if (myMobilumWindow) {
                this.closeWindow(myMobilumWindow);
                  console.log('3DS close - 2')
                
              }
              this.loadingPayment = false;
              this.disabled = false;
            }
          }
          else {
            await this.processResponse(data, response_type, response, myMobilumWindow, null, null);
          }
        } else {
          this.$store.commit(
            'personalizedErrors',
            response.data.errors
          );

          if (myMobilumWindow) {
            this.closeWindow(myMobilumWindow);
              console.log('3DS close - 4')
            
          }
          this.loadingPayment = false;
          this.disabled = false;
          const data = response.data;
          const response_type = data.responseType;
          if (response_type === 'PaymentError') {
            console.log('PaymentError');
            if (data.followUrl !== null && data.followUrl !== undefined) 
              window.location.href = data.followUrl;
          } else {
            return;
          }
        }
      } finally {
        //this.loadingPayment = false;
        //this.disabled = false;
      }
    },
    async statusCheck(myMobilumWindow: any) {
        const response = await getTransactionStatus(this.requestUuid);

        if (response.data.success && !response.data.errors.length) {
          this.processResponse(response.data, response.data.responseType, response, myMobilumWindow, null, null);
        } 
        if (response.data.errors.length > 0) {
          this.loadingPayment = false;
          this.disabled = false;
          this.$store.commit(
            'personalizedErrors',
            response.data.errors
          );
          if (this.transactionInterval)
            clearInterval(this.transactionInterval);
          if (response.data.followUrl !== null && response.data.followUrl !== undefined)
            window.location.href = response.data.followUrl;
        }
    },
    async processResponse(data: any, response_type: string, response : any, myMobilumWindow: any, body_tx: any, params_tx: any) {
      if (response_type === 'ThreeDsOneChallengeRequired') {
        this.setAndSubmitForm(myMobilumWindow, response);
        this.$store.state.currentTxId = response.data.result.transactionUuid;
        this.transactionUuid = response.data.result.transactionUuid;
        this.threeDsOneChallenge();
      }
      if (response_type === 'ThreeDsTwoChallengeRequired') {
        this.setAndSubmitForm(myMobilumWindow, response);

        if(body_tx == null || params_tx == null) {
          body_tx = {} as { [key: string]: string | number | boolean };
          params_tx = {} as { [key: string]: string };
          body_tx = this.populateBodyCard(body_tx);
          params_tx.cardAuthenticationId = response.data.result.cardAuthenticationId;
          this.card_authentication_id = response.data.result.cardAuthenticationId;
        }

        this.threeDsTwoChallenge(body_tx, params_tx, myMobilumWindow);
      }
      if (response_type === 'PaymentDone') {
        if(response.data.result.requestUuid != null)
          this.$store.state.currentTxId = response.data.result.requestUuid;
        this.setPaymentDone();
        if (myMobilumWindow) {
          this.closeWindow(myMobilumWindow);
          console.log('3DS close - 1')

        }
        if (data.followUrl !== null && data.followUrl !== undefined)
          window.location.href = data.followUrl;
      }
      if(response_type === "StatusNotYetAvailable") {
        setTimeout(() => { this.statusCheck(myMobilumWindow);}, 3500);
      }
    },
    threeDsOneChallenge() {
      this.colors = 'info';
      this.messages = ['Waiting for transaction to complete'];

      let requesting = false;
      this.transactionInterval = setInterval(async () => {
        this.intervals++;
        if (requesting) return;
        
        requesting = true;
        const response = await getTransactionDetails(this.transactionUuid);
        if (response.data.success && !response.data.errors.length) {
          if (response.data.result?.complete === true) 
            this.setPaymentDone();
          if (response.data.followUrl !== null && response.data.followUrl !== undefined) 
            window.location.href = response.data.followUrl;
        }
        if (response.data.errors.length > 0) {
          this.loadingPayment = false;
          this.disabled = false;
          this.$store.commit(
            'personalizedErrors',
            response.data.errors
          );
          if (this.transactionInterval) 
            clearInterval(this.transactionInterval);
          if (response.data.followUrl !== null && response.data.followUrl !== undefined) 
            window.location.href = response.data.followUrl;
        }
        if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60 || response.data.responseType == "PaymentError")
          this.setPaymentFail(response);
        requesting = false;
      }, 1000);
    },
    threeDsTwoChallenge(body_tx: any, params_tx: any, myMobilumWindow: any){
      this.colors = 'info';
      this.messages = ['Waiting for transaction to complete'];

      let requesting = false;
      this.transactionInterval = setInterval(async () => {
        if (requesting) return;
        requesting = true;

        this.intervals++;
        params_tx.orderUuid = this.currentOrderUuid;
        params_tx.requestUuid = this.requestUuid;
        const response = await checkTransaction(body_tx, params_tx);
        const data = response.data;
        const response_type = data.responseType;

        if (response_type === 'PaymentDone') {
          if(response.data.result != null && response.data.result.requestUuid != null)
            this.$store.state.currentTxId = response.data.result.requestUuid;
          else
            this.$store.state.currentTxId = this.requestUuid;
          
          this.setPaymentDone();
          if (myMobilumWindow) {
            this.closeWindow(myMobilumWindow);
              console.log('3DS close - 5')
            
          }
          if (data.followUrl !== null && data.followUrl !== undefined) 
            window.location.href = data.followUrl;
        }
        if (response_type === 'PaymentInProgress') {
          console.log('PaymentInProgress 3DS');
        }
        if (response_type === 'PaymentError') {
          this.setPaymentFail(response)
          if (myMobilumWindow) {
            this.closeWindow(myMobilumWindow);
              console.log('3DS close - 6')
            
          }
        }
        if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60) {
          this.setPaymentFail(null);
          if (myMobilumWindow) {
            this.closeWindow(myMobilumWindow);
              console.log('3DS close - 7')
            
          }
        }
        requesting = false;
      }, 5000);
    },
    async agreePayment(myMobilumWindow: any) {
      try {
        this.disabled = true;
        const file = this.getScreenshot();
        if(file != null) {
          // @ts-ignore
          await uploadScreenshot(file);
        }
        this.loadingPayment = true;
        
        let body = {} as { [key: string]: string | number | boolean };
        const params = {} as { [key: string]: string };
        body = this.populateBodyCard(body);

        params.orderUuid = this.currentOrderUuid;
        
        const response = await processPayment(body, params);
        this.$store.commit(
          'personalizedErrors',
          response.data.errors
        );
        if (response.data.errors.length) {
          if (myMobilumWindow) this.closeWindow(myMobilumWindow);
            console.log('3DS close - 8')
          this.loadingPayment = false;
          this.disabled = false;
          return;
        }
        this.setAndSubmitForm(myMobilumWindow, response);

        this.$store.state.currentTxId = response.data.result.transactionUuid;
        this.transactionUuid = response.data.result.transactionUuid;
        this.colors = 'info';
        this.messages = ['Waiting for transaction to complete'];

        let requesting = false;
        this.transactionInterval = setInterval(async () => {
          this.intervals++;
          if (requesting) return;
          requesting = true;
          
          const response = await getTransactionDetails(this.transactionUuid);
          if (response.data.success && !response.data.errors.length) {
            if (response.data.result?.complete === true)
              this.setPaymentDone();
            if (response.data.followUrl !== null && response.data.followUrl !== undefined)
              window.location.href = response.data.followUrl;
          }
          if (response.data.errors.length > 0) {
            this.$store.commit(
              'personalizedErrors',
              response.data.errors
            );
            this.loadingPayment = false;
            this.disabled = false;
            if (this.transactionInterval)
              clearInterval(this.transactionInterval);
            if (response.data.followUrl !== null && response.data.followUrl !== undefined)
              window.location.href = response.data.followUrl;
          }
          if (this.intervals >= this.$store.state.config.transactionWaitingTimeInMin * 60 || response.data.responseType == 'PaymentError')
            this.setPaymentFail(response);
          requesting = false;
        }, 1000);
      } finally {
        this.loadingPayment = false;
        this.disabled = false;
      }
    },
    setPaymentDone() {
      this.messages = [this.$t('transaction_complete') as string];
      this.complete = true;
      sendAnalytics({EventCategory: 'Transaction', EventAction: 'send', EventLabel: 'Positive'});

      if (this.transactionInterval)
        clearInterval(this.transactionInterval);
      if(this.dateInterval)
        clearInterval(this.dateInterval);

      this.routeToSuccess();
    },
    setPaymentFail(response: any) {
      this.$store.commit('personalizedErrors', [{code: '3', message:'Unable to process the transaction. Please check the details or try another card'}]);
      this.loadingPayment = false;
      this.disabled = false;
      this.colors = 'error';
      this.failed = true;
      if (this.transactionInterval)
        clearInterval(this.transactionInterval);

      this.checkKyc();
      sendAnalytics({EventCategory: 'Transaction', EventAction: 'send', EventLabel: 'Negative'});
      if (response != null && response.data.followUrl !== null && response.data.followUrl !== undefined)
        window.location.href = response.data.followUrl;
    },
    closeWindow(myMobilumWindow: any) {
      if(this.$store.state.config.show3dsInNewTab) {
        myMobilumWindow.close();
      } else {
        // @ts-ignore
        document.getElementById('ThreeDS_iframe').style.display = 'none';
        myMobilumWindow.remove();
      }
    },
    setAndSubmitForm(myMobilumWindow: any, response: any) {
      if (myMobilumWindow) {
        if(this.$store.state.config.show3dsInNewTab) {
          myMobilumWindow.document.body.innerHTML = response.data.result.form;
        } else {
          myMobilumWindow.src = "data:text/html;charset=utf-8," + escape(response.data.result.form);
        }
        setTimeout(() => {
          if(this.$store.state.config.show3dsInNewTab) {
            // @ts-ignore
            myMobilumWindow.document.getElementsByTagName("form")[0].submit();
          } 
          else {
            const frame = document.getElementById('ThreeDS_iframe');
            // @ts-ignore
            const innerDoc = frame.contentDocument || frame.contentWindow.document;
            innerDoc.getElementsByTagName("form")[0].submit();
          }
          
        }, 2000);
      }
    },
    async getScreenshot() {
      const div = document.querySelector('body');
      if (div) {
        const canvas = await html2canvas(div);
        const dataURL = canvas.toDataURL();
        const blobBin = atob(dataURL.split(',')[1]);
        const array = [];
        for (let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/png' });
      } else {
        return null;
      }
    },
    setCard() {
      if(this.$store.state.unsavedCard != null && this.$store.state.defaultCardId == -2) {
        this.card = this.$store.state.unsavedCard;
        this.unsavedCard = true;
      } else {
        this.unsavedCard = false;
        this.$store.state.userCards.forEach((value: any) => {
          if(this.$store.state.selectedCardId == value.cardId)
            this.card = value;
        })
      }
    },
    populateBodyCard(body: { [key: string]: string | number | boolean }): { [key: string]: string | number | boolean } {
      body.Number, body.MonthExpiry, body.YearExpiry = '';
      body.PreSavedCard = !this.unsavedCard && this.$store.state.paymentOption == Feature.Card.toString() ? this.$store.state.defaultCardId.toString() : '';
      body.CVV2 = this.CVV;
      if(this.unsavedCard) {
        // @ts-ignore
        body.Number = this.card != null ? this.card.Number : '';
        // @ts-ignore
        body.MonthExpiry = this.card != null ? this.card.MonthExpiry : '';
        // @ts-ignore
        body.YearExpiry = this.card != null ? this.card.YearExpiry : '';
      }
      return body;
    },
    async checkKyc(){console.log();} //overriden
  },
  beforeDestroy() {
    if (this.interval)
      clearInterval(this.interval);
    if (this.transactionInterval)
      clearInterval(this.transactionInterval);
    if(this.dateInterval)
      clearInterval(this.dateInterval); 
    if(this.statusInterval)
      clearInterval(this.statusInterval);
  },
});