<template>
  <div class="MBMWidget_Exchange_General blocked_region">
    {{ $t('blocked_by_region', {region:region}) }}
  </div>
  
</template>
<script lang="ts">
  import { recoverPassword } from '@/api';
  import { Routes } from '@/store/modules/Router';
  import { redirectView } from '@/utils';
  import md5 from 'blueimp-md5';
  import Vue from 'vue';
  // @ts-ignore
  import Password from 'vue-password-strength-meter';
  import Button from '../General/Button.vue';
  import Input from '../General/Input.vue';

  export default Vue.extend({
    components: {
      
    },
    data() {
      return {
        region: ""
      };
    },
    methods: {
      
    },
    mounted() {
      this.$store.state.hideMenuButton = true;
      this.$store.state.hideBackButton = true;
      let region = this.$store.state['profile'].blockedRegion;
      if(region !== undefined) {
        region = region as string;
        if(region.length > 0) 
          this.region = region;
        else
          this.region = this.$t('blocked_by_region_default_region').toString();
      } else {
        this.region = this.$t('blocked_by_region_default_region').toString();
      }
    }
  });
</script>
<style scoped>
  .blocked_region { margin: 60px auto 0; text-align: center}
</style>