<template>
  <div class="MBMWidget_Profile MBMWidget_mx-auto MBWidget_block">
    <div class="MBWidget_general_back_button MBWidget_general_back_button_inner" v-if="store.state.popup == '' && activeScreen == 1">
      <a class="MBWidget_general_back" @click.prevent="[activeScreen = 0, store.state.hideBackButton = false, store.commit('personalizedErrors', [])]">
        <ChevronLeftIcon fillColor="#444" :size="40" />
      </a>
    </div>
    <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4" :class="isKycNotStarted() ? 'MBMWidget_has_subtitle' : ''" v-if="firstTimeTitle && !canPay && !loading">
      {{ $t('profile_title_first_time') }}
    </div>
    <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4" v-else>
      {{ $t('profile_title') }}
    </div>
    <div class="MBMWidget_subtitle" v-if="isKycNotStarted() && !canPay && !loading">
      {{ $t('profile_subtitle') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
      <ErrorMessage></ErrorMessage>
    </div>

    <form ref="form1" class="MBMWidget_Profile_form MBMWidget_mt-8" @submit.prevent v-if="!loading && activeScreen == 0">
      <div class="MBMWidget_Profile_KycStatus" v-if="!canPay">
        <div class="MBMWidget_Profile_KycDone" v-if="isKycDone()"><CheckIcon :size="14" fillColor="#fff"></CheckIcon> {{$t('profile_kyc_done')}}</div>
        <div class="MBMWidget_Profile_KycPending" v-if="isKycInProgress()"><TimerSandEmptyIcon :size="14" fillColor="#fff"></TimerSandEmptyIcon> {{$t('profile_kyc_pending')}}</div>
        <div class="MBMWidget_Profile_KycFail" v-if="isKycFailed()"><span>X</span> {{$t('profile_kyc_failed_status')}}</div>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4">
        <AppInput
          @input="profileInput()"
          v-model="name"
          type="text"
          required
          :label="$t('profile_name')"
          :placeholder="$t('profile_name')"
          class="MBMWidget_Profile_AppInputName"
        />
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4">
        <AppInput
          @input="profileInput()"
          v-model="surname"
          type="text"
          required
          :label="$t('profile_surname')"
          :placeholder="$t('profile_surname')"
          class="MBMWidget_Profile_AppInputName"
        />
      </div>
      <AppButton
        class="MBWidget_fake_submit"
        @click="nextScreen"
      ></AppButton>
    </form>
    <form ref="form2" class="MBMWidget_Profile_form MBMWidget_mt-7" @submit.prevent v-if="!loading && activeScreen == 1">
      <div class="MBMWidget_col-np-12 MBMWidget_col-xs-np-12 MBMWidget_mb-4">
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_pt-1 MBMWidget_Select_Label MBMWidget_profile_dob_block">{{$t('profile_dob')}}</div>
        <!--<DateInput :showDay="true" :validateRuntime="false" :init-day="dobDay" :init-month="dobMonth" :init-year="dobYear" @dayFilled="dayFilled" @monthFilled="monthFilled" @yearFilled="yearFilled"></DateInput>-->
        <div class="MBMWidget_col-3 MBMWidget_col-xs-4">
        <cool-select

            v-model="dobDay"
            :items="days"
            :placeholder="dobDay == '' ? 'MM' : ''"
            :menuItemsMaxHeight="'215px'"
            :disableSearch="true"
            class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
        ><template slot="item" slot-scope="{ item: i }">
          <div style="display: flex; align-items: center;">
            <div><b>{{ i }}</b></div>
          </div>
        </template>

          <template slot="selection" slot-scope="{ item: i }">
            <div><b>{{ i }}</b></div>
            <ChevronDownIcon :size="22" fillColor="#444"></ChevronDownIcon>
          </template>

        </cool-select>
        </div>
        <div class="MBMWidget_col-3 MBMWidget_col-xs-4">
          <cool-select

              v-model="dobMonth"
              :items="months"
              :placeholder="dobMonth == '' ? 'MM' : ''"
              :menuItemsMaxHeight="'215px'"
              :disableSearch="true"
              class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
          >
            <template slot="item" slot-scope="{ item: i }">
              <div style="display: flex; align-items: center;">
                <div><b>{{ i }}</b></div>
              </div>
            </template>

            <template slot="selection" slot-scope="{ item: i }">
              <div><b>{{ i }}</b></div>
              <ChevronDownIcon :size="22" fillColor="#444"></ChevronDownIcon>
            </template>

          </cool-select>
        </div>
        <div class="MBMWidget_col-5 MBMWidget_col-xs-4">
            <cool-select

                v-model="dobYear"
                :items="years"
                :placeholder="dobYear == '' ? 'MM' : ''"
                :menuItemsMaxHeight="'215px'"
                :disableSearch="true"
                class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
            >
              <template slot="item" slot-scope="{ item: i }">
                <div style="display: flex; align-items: center;">
                  <div><b>{{ i }}</b></div>
                </div>
              </template>

              <template slot="selection" slot-scope="{ item: i }">
                <div><b>{{ i }}</b></div>
                <ChevronDownIcon :size="22" fillColor="#444"></ChevronDownIcon>
              </template>

            </cool-select>
        </div>
      </div>

      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-1" v-if="countries.length">
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0 MBMWidget_Select_Label">{{$t('profile_country')}}</div>
        <cool-select
          @select="profileInput()"
          v-model="selectedCountry"
          :items="countries"
          :menuItemsMaxHeight="'220px'"
          item-value="name"
          item-text="name"
          class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
        >
          <template slot="item" slot-scope="{ item: i }">
            <div style="display: flex; align-items: center;">
              <span :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+i.alpha2Code+'.png)'" class="country-flag"></span>
              <div><b>{{ i.name }}</b></div>
            </div>
          </template>

          <template slot="selection" slot-scope="{ item: i }">
            <span :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+i.alpha2Code+'.png)'" class="country-flag"></span>
            <div><b>{{ i.name }}</b></div>
            <ChevronDownIcon :size="22" fillColor="#444"></ChevronDownIcon>
          </template>
        </cool-select>
      </div>
      <AppButton
        class="MBWidget_fake_submit"
        @click="save"
      ></AppButton>
    </form>

    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
          <AppButton
            :disabled="disabled || name == '' || surname ==''"
            class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_FormTfaAppButtonVerify"
            color="primary"
            @click="nextScreen"
            v-if="activeScreen == 0"
          >
            {{ $t('profile_continue') }}
          </AppButton>
          <AppButton
            :disabled="disabled || dobDay == '' || dobMonth == '' || dobYear == '' || dobYear.length < 4 || selectedCountry == '' || selectedCountry == null"
            class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_FormTfaAppButtonVerify"
            color="primary"
            @click="save"
            v-if="activeScreen == 1"
          >
            {{ $t(getButtonText()) }}
          </AppButton>
        </div>

    </div>
  </div>
</template>
<script lang="ts">
  import {checkKyc, getCountries, getUserProfileData, requestEmailCode, updateUserDetails, verifyAnyCode} from '@/api';
  import {Routes} from '@/store/modules/Router';
  import Vue from 'vue';
  import Button from '../General/Button.vue';
  import VerificationCode from '../General/VerificationCode.vue';
  import Input from "@/components/General/Input.vue";
  import {displayErrors, redirectViewString} from "@/utils";
  import ErrorMessage from "@/components/Components/ErrorMessage.vue";
  import Checkbox from "@/components/General/Checkbox.vue";
  import {CoolSelect} from "vue-cool-select";
  import ChevronDownIcon from "vue-material-design-icons/ChevronDown.vue";
  import CheckIcon from "vue-material-design-icons/Check.vue";
  import TimerSandEmptyIcon from "vue-material-design-icons/TimerSandEmpty.vue";
  import Select from "@/components/General/Select.vue";
  import DateInput from "@/components/General/DateInput.vue";
  import store from "@/store";
  import './../Components/CoolSelect.css';
  import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";

  export default Vue.extend({
    props: {
      canPay: Boolean
    },
    components: {
      AppButton: Button,
      ErrorMessage: ErrorMessage,
      AppInput: Input,
      CoolSelect,
      ChevronDownIcon: ChevronDownIcon,
      CheckIcon: CheckIcon,
      TimerSandEmptyIcon: TimerSandEmptyIcon,
      //AppSelect: Select,
      //DateInput: DateInput,
      ChevronLeftIcon: ChevronLeftIcon,
    },
    data() {
      return {
        activeScreen: 0,
        loading: true,
        disabled: false,
        name: '',
        surname: '',
        selectedCountry: '',
        dob: '',
        countries: [],
        dobDay : '',
        dobMonth : '',
        dobYear : '',
        store,
        days: [] as string[],
        months: [] as string[],
        years: [] as string[],

        profileChanged: false,
        firstTimeTitle: false
      };
    },
    methods: {
      nextScreen(){
        if(this.loading || this.disabled || this.name == '' || this.surname =='') return;
        this.$store.state.hideBackButton = true;
        this.$store.commit('personalizedErrors', []);
        this.activeScreen++;
      },
      dayFilled(val: string){
        this.dobDay = val;
        this.profileInput();
      },
      monthFilled(val: string){
        this.dobMonth = val;
        this.profileInput();
      },
      yearFilled(val: string){
        this.dobYear = val;
        this.profileInput();
      },
      profileInput(){
        this.profileChanged = true;
        this.$store.commit('personalizedErrors', []);
      },
      reportValidity() : boolean {
        const form : HTMLFormElement = this.$refs.form2 as HTMLFormElement;
        return form.reportValidity();
      },
      isKycDone(){
        return this.$store.state.profile.kycVerified;
      },
      isKycInProgress(){
        return !this.$store.state.profile.kycVerified && this.$store.state.profile.kycInProgress;
      },
      isKycFailed(){
        return this.$store.state.profile.kycFailed && !this.$store.state.profile.kycInProgress && !this.$store.state.profile.kycVerified;
      },
      isKycNotStarted(){
        return !this.$store.state.profile.kycFailed && !this.$store.state.profile.kycInProgress && !this.$store.state.profile.kycVerified;
      },
      validateDate() : boolean {
        const d = Number(this.dobDay);
        const m = Number(this.dobMonth);
        const month30 = [4, 6, 9, 11];

        let valid = true;
        if(d < 1 || d > 31)
          valid = false;
        if(m < 1 || m > 12)
          valid = false;
        if(this.dobYear.length != 4 || (this.dobYear.slice(0, 2) != '19' && this.dobYear.slice(0, 2) != '20'))
          valid = false;
        if(Number(this.dobYear) > new Date().getFullYear())
          valid = false;
        if(month30.includes(m) && d > 30)
          valid = false;
        if(m == 2 && d > 29)
          valid = false;

        const stamp = new Date(Number(this.dobYear), m-1, d).getTime();
        const now = new Date().getTime();
        const grownup = 1000 * 60 * 60 * 24 * 365 * 18;
        if(now - grownup < stamp)
          valid = false;

        if(!valid)
          this.$store.commit('personalizedErrors', [{code: '06', message:'Invalid Date'}]);
        return valid;
      },
      async save() {
        if(!this.reportValidity() || !this.validateDate()) return;


        if(this.dobDay == '' || this.dobMonth == '' || this.dobYear == '' || this.dobYear.length < 4) return;
        if(!this.profileChanged){
          if(!this.isKycDone() && !this.canPay){
            this.routeNext('PerformKYC')
          } else {
            this.routeNext(null);
          }
          return;
        }
        try {
          this.disabled = true;
          const body = {
            Name: this.name,
            Surname : this.surname,
            Country : this.selectedCountry,
            DOB : this.dobDay+'/'+this.dobMonth+'/'+this.dobYear
          };
          const response = await updateUserDetails(body);
          if (response.data.success == true) {
            this.$store.commit('personalizedErrors', []);

            this.$store.state.profile.name = response.data.result.name;
            this.$store.state.profile.surname = response.data.result.surname;
            this.$store.state.profile.country = response.data.result.country;
            this.$store.state.profile.dob = response.data.result.dob;

            this.$store.state.loggedIn = true;

            //TODO: Implement API Journey Service
            this.routeNext(response.data.redirectUrl);

          } else {

            this.$store.commit('personalizedErrors', [{code: '07', message:'An error occured while saving profile data, please try again!'}]);
          }
        } finally {
          this.disabled = false;
        }
      },
      routeNext(url: any){
        this.$emit('routeNext', url);
      },
      getButtonText(){
        if(this.canPay)
          return 'profile_continue';
          
        if(this.isKycNotStarted())
          return 'profile_kyc_not_started';
        if(this.isKycFailed())
          return 'profile_kyc_failed';

        return 'profile_continue';
      }
    },
    async mounted() {
      //this.$store.commit('setPreviousPage', 'Profile');

      await getUserProfileData();
      
      //those date things not used anymore, carefully refactor, when free time
      const date = new Date();
      this.days.push('');
      this.months.push('');
      this.years.push('');
      for (let index = 1; index <= 12; index++) {
        this.months.push(String(index).padStart(2, '0'));
      }
      for (let index = date.getFullYear(); index > date.getFullYear() - 100; index--) {
        this.years.push(
          index.toString()
        );
      }
      for (let index = 1; index <= 31; index++) {
          this.days.push(String(index).padStart(2, '0'));
      }

      this.name = this.$store.state.profile.name || '';
      this.surname = this.$store.state.profile.surname || '';
      this.selectedCountry = this.$store.state.profile.country || '';
      if(this.$store.state.profile.dob != null && this.$store.state.profile.dob != ''){
        const date = new Date(this.$store.state.profile.dob);
        this.dobDay = String(date.getDate()).padStart(2, '0');
        this.dobMonth = String(date.getMonth()+1).padStart(2, '0');
        this.dobYear = date.getFullYear().toString();
      }

      if(!this.$store.state.countries.length) {
        const response = await getCountries();
        this.countries = response.data.result.Countries;
        this.$store.state.countries = response.data.result.Countries;
      } else {
        this.countries = this.$store.state.countries;
      }
      if(this.selectedCountry == '' || this.selectedCountry == null) {
        this.countries.forEach((i: any) => {
          if (this.$store.state.config.countryCode == i.alpha2Code) {
            this.selectedCountry = i.name;
          }
        })
        if (this.selectedCountry == '' || this.selectedCountry == null)
          this.selectedCountry = 'Afghanistan';
      }

      this.firstTimeTitle = this.dobDay == '' || this.dobMonth == '' || this.dobYear == '' || this.name == '' || this.surname == '';

      this.loading = false;

    },
    beforeDestroy(): void {
      store.state.hideBackButton = false;
    }
  });
</script>
