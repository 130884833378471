<template>
  <div class="MBWidget_Select_Language">
    <div class="MBWidget_general_back_button">
      <a class="MBWidget_Popup_back"
         @click.prevent="store.state.popup = 'menu'"
      ><ChevronLeftIcon fillColor="#333" :size="32"></ChevronLeftIcon></a>
    </div>
    <div class="MBWidget_general_menu_button">
      <a class="MBWidget_menu_button"
         @click.prevent="store.state.popup = ''"
      ><CloseIcon fillColor="#333" :size="30"></CloseIcon></a>
    </div>
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header">
      {{ $t('language_select_title') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_Select_Language_Holder  MBMWidget_mt-8">
      <a class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_Select_Language_Item MBMWidget_py-4" @click.prevent="setVal(l)" v-for="l in langs" v-bind:key="l">
        <span class="MBWidget_Select_Language_Item_Flag" :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+getFlag(l)+'.png)'"></span>
        <span class="MBWidget_Select_Language_Item_Name">{{$t('lang_name_'+getName(l))}}</span>
        <CheckIcon fillColor="#0BB07E" v-if="isSelected(l)" :size="28"></CheckIcon>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import store from "@/store";
  import {languages} from "../../i18n";
  import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
  import CheckIcon from 'vue-material-design-icons/Check.vue';
  import CloseIcon from 'vue-material-design-icons/Close.vue';

  export default Vue.extend({
    components: {
      ChevronLeftIcon: ChevronLeftIcon,
      CheckIcon: CheckIcon,
      CloseIcon: CloseIcon
    },
    data () {
      return {
        store,
        langs: Object.keys(languages),
        names: {
          en: 'English',
          zh: 'Chinese',
          pl: 'Polish'
        },
        flags: {
          en: 'GB',
          zh: 'CN',
          pl: 'PL'
        }
      }
    },
    methods: {
      setVal(val: string){
        this.$i18n.locale = val;
        //this.$store.state.popup = '';
      },
      getName(code: string){
        // @ts-ignore
        return this.names[code];
      },
      getFlag(code: string){
        // @ts-ignore
        return this.flags[code];
      },
      isSelected(code: string){
        return  this.$i18n.locale == code ? true : false;
      }
    }
    
  });
</script>

<style scoped>

</style>