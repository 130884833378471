export enum StepType {
  None = "None",
  BasicUserInfoRegistered = "BasicUserInfoRegistered",
  PhoneNumberVerification = "PhoneNumberVerification",
  PhoneNumberVerifiedAsDisabled = "PhoneNumberVerifiedAsDisabled",
  PhoneNumberVerified = "PhoneNumberVerified",
  EmailVerification = "EmailVerification",
  EmailVerifiedAsDisabled = "EmailVerifiedAsDisabled",
  EmailVerified = "EmailVerified",
  RegistrationCompleted = "RegistrationCompleted",
}

export enum Feature {
  Menu = "Menu", //if user menu should be displayed at all
  MenuCrypto = "MenuCrypto", //
  MenuPhaze = "MenuPhaze", //if phaze list/history be displayed in menu
  MenuPhazeHistory = "MenuPhazeHistory", //if Phaze cards history be in menu
  MenuContact = "MenuContact", //if contact us page be displayed in menu
  MenuFaq = "MenuFaq", //if FAQ be displayed in menu
  MenuAbout = "MenuAbout", //if about Mobilum be displayed in menu
  MenuTxHistory = "MenuTxHistory", //if tx history be displayed in menu
  MenuPaymentMethods = "MenuPaymentMethods", //if payment methods be available in menu
  Crypto = "Crypto", //if whole crypto currency purchase should be available (also in screens)
  CryptoCard = "CryptoCard", //if buy crypto with bank card be available
  CryptoBankTransfer = "CryptoBankTransfer", //if bank transfers be available
  Phaze = "Phaze", //if whole Phaze gift card system be available
  PhazeCard = "PhazeCard", //if can buy Phaze cards with bank card
  PhazeCrypto = "PhazeCrypto", //if can buy Phaze cards with crypto
  PhazeBankTransfer = "PhazeBankTransfer", //if can buy Phaze cards with bank transfer
  Logout = "Logout", //if logout be available
  Polygon = "Polygon",
  Swap = "Swap",
  MenuTos = "MenuTos",
  MenuWebsite = "MenuWebsite",
  MenuPrivacyPolicy = "MenuPrivacyPolicy",
  Bridge = "Bridge",
  OpenBanking = "OpenBanking",
  Card = "Card",
  Blik = "Blik"
}