var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[this.$store.state.isMobile ? 'mobile_content' : ''],attrs:{"id":"app"}},[_c('div',{staticClass:"MBMWidget_d-flex"},[_c('div',{staticClass:"MBMWidget_General"},[(
          _vm.store.state.pageQueue.length > 1 &&
          !_vm.iframe &&
          _vm.store.state.popup == '' &&
          !_vm.store.state.hideBackButton
        )?_c('div',{staticClass:"MBWidget_general_back_button",class:_vm.store.state.pageQueue.length > 0 && _vm.store.state.pageQueue[this.$store.state.pageQueue.length - 1] == 'PhazeCardList' ? 'MBWidget_phaze_back_button' : ''},[_c('a',{staticClass:"MBWidget_general_back",on:{"click":function($event){$event.preventDefault();return _vm.back()}}},[_c('ChevronLeftIcon',{attrs:{"fillColor":"#444","size":40}})],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.iframe &&
          _vm.store.state.popup == '' &&
          _vm.isFeature(_vm.Feature.Menu) &&
          !this.$store.state.hideMenuButton
        ),expression:"\n          !iframe &&\n          store.state.popup == '' &&\n          isFeature(Feature.Menu) &&\n          !this.$store.state.hideMenuButton\n        "}],staticClass:"MBWidget_general_menu_button"},[_c('a',{staticClass:"MBWidget_menu_button",on:{"click":function($event){$event.preventDefault();_vm.store.state.popup = 'menu'}}},[_c('MenuIcon',{attrs:{"fillColor":"#888","size":36}})],1)]),_c('div',{attrs:{"id":"ThreeDS_iframe"}}),_c('AppSnack',{style:({
          display: _vm.getDisplay(!_vm.iframe && _vm.store.state.popup == ''),
        }),attrs:{"messages":_vm.errors}}),_c(_vm.component,{tag:"component",style:({
          display: _vm.getDisplay(!_vm.iframe && _vm.store.state.popup == ''),
        }),attrs:{"showIframe":_vm.showIframe}}),_c('div',{staticStyle:{"margin":"16px"},style:({ display: _vm.getDisplayOrFlex(_vm.iframe) })},[_c('div',{staticClass:"MBMWidget_cursor-pointer",on:{"click":_vm.closeIframe}},[_vm._v(" "+_vm._s(_vm.$t('go_back_to_the_widget'))+" ")]),_vm._v(" | "),_c('div',[_c('a',{staticClass:"MBMWidget_mobilumPdf MBMWidget_cursor-pointer",attrs:{"href":_vm.mobilumPdf,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('download_in_pdf')))])])]),_c('div',{staticClass:"MBMWidget_iframe_wrap",style:({ display: _vm.getDisplay(_vm.iframe) })},[(_vm.iframe)?_c('iframe',{staticClass:"MBMWidget_iframe",staticStyle:{"height":"100%"},attrs:{"src":_vm.mobilumSrc,"frameborder":"0","marginheight":"0","marginwidth":"0","width":"100%","height":"100%","scrolling":"auto"}}):_vm._e()]),_c('Popup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.iframe),expression:"!iframe"}],attrs:{"showIframe":_vm.showIframe}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }