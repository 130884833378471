<template>
  <div class="MBMWidget_Tx_History_View MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-2">
      {{ $t('tx_history_item_title') }}
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center MBMWidget_mt-4" v-if="store.state.currentTx == null">
      {{$t('loading')}}
    </div>
    
    <div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBMWidget_Tx_History_View_Item MBMWidget_mt-4" v-if="store.state.currentTx != null">
      <span
        class="MBMWidget_Tx_History_View_Icon"
        :style="
                    'background-image:url(' +
                      getCryptoIcon(store.state.currentTx.order.symbol) +
                      ');'
                  "
      ></span>
      <div class="MBMWidget_col--np-xs-12 MBMWidget_col-np-12 MBMWidget_Tx_History_View_Item MBMWidget_mt-4">
        <div class="MBMWidget_col--np-xs-9 MBMWidget_col-np-9">
          <span class="MBMWidget_history_label">Order ID</span>
          <span class="MBMWidget_history_uuid">{{store.state.currentTx.transaction.transactionUuid}}
          <AppButton
            @click="copy(store.state.currentTx.transaction.transactionUuid)"
            style="background-color: transparent; height: 22px; width: 22px; color: #333"
          ><ContentCopyIcon :size="18" fillColor="#333"/></AppButton></span>
        </div>
        <div class="MBMWidget_col--np-xs-3 MBMWidget_col-np-3">
          <span class="MBMWidget_history_label">Status</span>
          <span>{{store.state.currentTx.order.status == "PaymentFailed" ? $t('tx_status_fail') : store.state.currentTx.order.status == "Complete" ?  $t('tx_status_done') : $t('tx_status_pending')}}</span>
        </div>
      </div>
      <div class="MBMWidget_col--np-xs-12 MBMWidget_col-np-12 MBMWidget_Tx_History_View_Item">
          <span class="MBMWidget_history_label">Payment Method</span>
          <span v-if="store.state.currentTx.transaction.cardIdentificationNumber != null && store.state.currentTx.transaction.cardIdentificationNumber != ''">
            <img> ***{{store.state.currentTx.transaction.cardIdentificationNumber}}
          </span>
          <span v-else-if="store.state.currentTx.transaction.cardPaymentType?.toLowerCase() == 'bank'">
              {{$t('payment_method_name_wire')}}
            </span>
          <span v-else-if="store.state.currentTx.transaction.paymentOption?.toLowerCase() == 'blik'">
              <img src="https://assets.mobilum.com/vendors/blik.png" style="background: #000; padding: 3px;">
               
          </span>
      </div>

      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12">
        
        <div class="MBMWidget_Purchase_Summary_Details_Block" @click="showFees = !showFees">
            <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order">{{$t('exchange_order_details_you_got')}}<strong> {{store.state.currentTx.order.cryptoQuantity}} {{store.state.currentTx.order.symbol}}</strong> {{$t('exchange_order_details_for')}} <strong>{{ getFiatSymbol(store.state.currentTx.order.fiatCurrency) }} {{store.state.currentTx.order.cryptoValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}}</strong>
              <ChevronUpIcon fillColor="#444" :size="20" v-if="showFees" /><ChevronDownIcon fillColor="#444" :size="20" v-if="!showFees" />
            </span>
        </div>

        <div class="MBMWidget_Purchase_Summary_Details_Block MBMWidget_Purchase_Summary_Details_Block_Additional" :style="showFees ? 'max-height: 130px;' : 'max-height: 0px;'">
            <span class="MBMWidget_Purchase_Summary_Price">
              <strong>{{store.state.currentTx.order.cryptoQuantity}} {{ store.state.currentTx.order.symbol}}</strong> @
              {{ getFiatSymbol(store.state.currentTx.order.fiatCurrency) }} 
              {{
                store.state.currentTx.order.unitPriceQuoted.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 5,
                })
              }}
              <span>{{getFiatSymbol(store.state.currentTx.order.fiatCurrency)}}{{
                (store.state.currentTx.order.cryptoValue - (store.state.currentTx.order.flatRate + store.state.currentTx.order.processingFee)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }} {{store.state.currentTx.order.fiatCurrency}}</span>
            </span>
          <span class="MBMWidget_Purchase_Summary_Fees"><strong>{{$t('purchase_summary_fee')}}</strong><span>{{ getFiatSymbol(store.state.currentTx.order.fiatCurrency) }}{{(store.state.currentTx.order.flatRate + store.state.currentTx.order.processingFee)}} {{store.state.currentTx.order.fiatCurrency}}<!-- {{ getFeeDetails() }}--></span></span>
          <span class="MBMWidget_Purchase_Summary_Fees MBMWidget_Purchase_Summary_Fees_Total"><strong>{{$t('purchase_summary_total_cost')}}</strong><span>{{ getFiatSymbol(store.state.currentTx.order.fiatCurrency) }}{{store.state.currentTx.order.cryptoValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}} {{store.state.currentTx.order.fiatCurrency}}</span></span>
        </div>
      </div>
      <div class="MBMWidget_col--np-xs-12 MBMWidget_col-np-12 MBMWidget_Tx_History_View_Item MBMWidget_mt-2">
        <div class="MBMWidget_col--np-xs-8 MBMWidget_col-np-8">
          <span class="MBMWidget_history_label">Delivered to Wallet address</span>
          <span class="MBMWidget_history_uuid">{{store.state.currentTx.order.userWallet}}
          <AppButton
            @click="copy(store.state.currentTx.order.userWallet)"
            style="background-color: transparent; height: 22px; width: 22px; color: #333"
          ><ContentCopyIcon :size="18" fillColor="#333"/></AppButton>
          </span>
        </div>
        <div class="MBMWidget_col--np-xs-4 MBMWidget_col-np-4">
          <span class="MBMWidget_history_label">Transaction Hash</span>
          <span>{{store.state.currentTx.order.transactionHash != null ? store.state.currentTx.order.transactionHash : "-"}}
          <AppButton
            @click="copy(store.state.currentTx.order.transactionHash)"
            style="background-color: transparent; height: 22px; width: 22px; color: #333"
            v-if="store.state.currentTx.order.transactionHash != null"
          ><ContentCopyIcon :size="18" fillColor="#333"/></AppButton></span>
        </div>
      </div>
      
    </div>
    
    
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import {processDecimalPlaces, redirectViewString} from "@/utils";
  import './../CardScreen/cards.css';
  import store from "@/store";
  import ContentCopyIcon from "vue-material-design-icons/ContentCopy.vue";
  import CurrencyList from "@/components/Components/CurrencyList.json";
  import ChevronDownIcon from "vue-material-design-icons/ChevronDown.vue";
  import ChevronUpIcon from "vue-material-design-icons/ChevronUp.vue";
  import Button from "@/components/General/Button.vue";

  export default Vue.extend({
    components: {
      ChevronDownIcon,
      ChevronUpIcon,
      ContentCopyIcon: ContentCopyIcon,
      AppButton: Button,
    },
    data () {
      return {
        current: null,
        store,
        showFees: false
      }
    },
    methods: {
      getFiatSymbol(fiatCode: string) {
        // @ts-ignore
        if (CurrencyList.en[fiatCode] !== undefined) {
          // @ts-ignore
          if (CurrencyList.en[fiatCode] !== undefined) {
            // @ts-ignore
            return CurrencyList.en[fiatCode].symbol;
          }
        }
      },
      formatCurrency(amount : any, min = 2){
        return processDecimalPlaces(amount, 5, min);
      },
      copy(val: string){
        navigator.clipboard.writeText(val)
      },
      getCryptoIcon(ticker: string | undefined): any {
        const t = ticker?.replace(' ', '+');

        return (
          'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/cryptoicons/' +
          t +
          '.png'
        );
      },
      getPrice(){
        if(this.$store.state.currentTx != null){
          const fees = this.$store.state.currentTx.order.commission.commissionAmount + this.$store.state.currentTx.order.commission.flatRate;
          const price =  (this.$store.state.currentTx.order.actualPrice - fees) / this.$store.state.currentTx.order.amount;
          return this.formatCurrency(price);
        }
      },
      getCardIcon(t: string | null) {
        console.log("t:", t)
        if(t == null)
          return 'mastercard';

        let re = new RegExp('^4');
        if (t.match(re) != null) return 'visa';

        re = new RegExp('^5[1-5]');
        if (t.match(re) != null) return 'mastercard';

        return 'mastercard';
      },
      getDate(val: string){
        const date = new Date(Date.parse(val));
        return date.toLocaleString('en-GB');
      },
      getOnlyDate(val: string) {
        const date = new Date(Date.parse(val));
        return date.toLocaleDateString('en-GB');
      }
    }

  });
</script>

<style scoped>
  .MBMWidget_history_label .MBMWidget_GeneralButton {
    padding: 5px;
    display: inline-block;
    position: absolute;
    margin-top: -9px;
  }
  
  
</style>