<template>
  <div id="external_wallet_container" class="external_wallet_container">
    <div class="external_wallet_modal">
      <div class="modal_mask" @click="hideQrCodeModal">
        <div class="modal_wrapper">
          <transition name="fade" appear>
            <div class="modal_container qr_modal_container">
              <button
                type="button"
                class="modal-close-button"
                aria-label="Close"
                @click="showModal = false"
              >
                <span aria-hidden="true">X</span>
              </button>
              <div v-if="!error" class="modal_header">
                <h4>Scan QR code</h4>
              </div>
              <div class="modal_body">
                <div class="modal_body_row">
                  <div class="modal_body_col">
                    <div>
                      <qrcode-stream
                        :camera="camera"
                        @decode="onDecode"
                        @init="onInit"
                        v-if="!destroyed"
                      >
                        <div class="loading-indicator" v-if="loading">
                          Loading...
                        </div>
                        <div
                          v-if="error"
                          style="text-align: center; margin-top: 5px;"
                        >
                          <Camera :size="35" />
                          <div v-html="error"></div>
                        </div>
                      </qrcode-stream>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Camera from 'vue-material-design-icons/Camera.vue';

export default {
  name: 'QrCodeModal',
  components: {
    QrcodeStream,
    Camera,
  },
  data() {
    return {
      error: false,
      loading: false,
      destroyed: false,
      camera: 'auto',
    };
  },
  methods: {
    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = `<h3> ${this.$t('qr_camera_access')}</h3><p>${this.$t(
            'qr_code_notAllowedError',
          )}</p>`;
        } else if (error.name === 'NotFoundError') {
          this.error = `<h2> ${this.$t('qr_code_notFoundError')}</h2>`;
        } else if (error.name === 'NotReadableError') {
          this.error = `<h2> ${this.$t('qr_code_notReadableError')}</h2>`;
        } else if (error.name === 'OverconstrainedError') {
          this.error = `<h2> ${this.$t('qr_code_overconstrainedError')}</h2>`;
        } else this.error = `<h2> ${this.$t('api_error_5')}</h2>`;
      } finally {
        this.loading = false;
      }
    },
    hideQrCodeModal() {
      this.$emit('hideQrCodeModal');
      this.turnCameraOff();
    },
    onDecode(content) {
      this.$emit('contentScanned', content);
      this.turnCameraOff();
    },
    turnCameraOff() {
      this.camera = 'off';
    },
  },
};
</script>
<style>
.qr_modal_container {
  min-height: 200px !important;
}
</style>
