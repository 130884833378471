import { render, staticRenderFns } from "./CardRedirect.vue?vue&type=template&id=c5c80568&scoped=true"
import script from "./CardRedirect.vue?vue&type=script&lang=ts"
export * from "./CardRedirect.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5c80568",
  null
  
)

export default component.exports