<template>
  <div class="MBMWidget_AddCard MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center">
      {{$t('loading')}}
    </div>


  </div>
</template>

<script lang="ts">
  import {checkKycVerified} from "@/api";
  import {Routes} from "@/store/modules/Router";
  import Vue from "vue";
  import store from "@/store";

  export default Vue.extend({
    components: {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    async mounted() {
      const response = await checkKycVerified(store.state.order.uuid);
      if (response.data.result.waitForKyc) {
        this.$store.commit('router/goTo', Routes.Kyc)
      } else {
        this.$store.commit('router/goTo', Routes.CardRedirect)
      }
    }
  });
</script>

<style scoped>

</style>