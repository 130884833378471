<template>
  <div class="MBMWidget_Purchase_Success MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t('purchase_success_title') }}
    </div>
    <div
      class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center MBMWidget_mt-4"
      v-if="loading"
    >
      {{ $t('loading') }}
    </div>
    <div
      class="MBMWidget_col-xs-12 MBMWidget_col-12 MBMWidget_Tx_History_View_Item MBMWidget_mt-4"
      v-if="tx"
    >
      <div class="MBMWidget_Purchase_Summary_Details_Block">
        <span class="MBMWidget_Purchase_Summary_Date"
          >{{ $t('purchase_success_item_date_submit')
          }}<span>{{ getDate(tx.transaction.createdDate) }}</span></span
        >
        <span class="MBMWidget_Purchase_Summary_Total"
          >{{ $t('tx_history_view_spent')
          }}<span
            >{{ formatCurrency(tx.order.cryptoValue) }}
            {{ tx.order.fiatCurrency }}</span
          ></span
        >
        <span class="MBMWidget_Purchase_Summary_Amount"
          >{{ $t('tx_history_success_view_bought')
          }}<span
            >{{ tx.order.cryptoQuantity }}
            {{ tx.order.symbol }}</span
          ></span
        >
        <span
          class="MBMWidget_Purchase_Summary_Wallet"
          v-if="tx.order.userWallet != ''"
          >{{ $t('wallet_address')
          }}<span
            >{{ tx.order.userWallet
            }}<ContentCopyIcon
              @click="copy(tx.order.userWallet)"
              :size="20"
              fillColor="#333"
            ></ContentCopyIcon></span
        ></span>
        <span
          class="MBMWidget_Purchase_Summary_Hash"
          v-show="
            tx.order.transactionHash != null && tx.order.transactionHash != ''
          "
          >{{ $t('tx_history_item_tx_hash')
          }}<span
            >{{ tx.order.transactionHash }}
            <ContentCopyIcon
              @click="copy(tx.order.transactionHash)"
              :size="20"
              fillColor="#333"
            ></ContentCopyIcon> </span
        ></span>
        <span class="MBMWidget_Purchase_Summary_Payment">
          {{ $t('tx_payment_method')}}
          <span v-if="tx.transaction.cardIdentificationNumber != null && tx.transaction.cardIdentificationNumber != ''">
            <span class="MBMWidget_Purchase_Summary_Card_Icon" :class="'MBMWidget_card_'+tx.transaction.cardPaymentType.toLowerCase()"></span>
            ****{{tx.transaction.cardIdentificationNumber.substr(tx.transaction.cardIdentificationNumber.length - 4,)}}
          </span>
          <span v-else-if="tx.transaction.cardPaymentType?.toLowerCase() == 'bank'">
            {{$t('payment_method_name_wire')}}
          </span>
          <span v-else-if="tx.transaction.paymentOption?.toLowerCase() == 'blik'">
            <img src="https://assets.mobilum.com/vendors/blik.png" style="background: #000; padding: 3px;">
             
          </span>
        </span>
        <div
          class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_pb-3"
          @click="showFees = !showFees"
        >
          <strong>{{ $t('exchange_order_details') }} </strong> <span class="MBMWidget_Purchase_Summary_Chevron_Icons">
            <ChevronUpIcon fillColor="#101011" :size="20" v-if="!showFees" />
            <ChevronDownIcon fillColor="#101011" :size="20" v-if="showFees" />
          </span>
        </div>
        <div
          v-if="showFees"
          class="MBMWidget_col-np-12 MBMWidget_px-0 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Success_Block_Additional"
        >
          <span>
            {{ tx.order.cryptoQuantity }} {{ tx.order.symbol }} @
            {{ getFiatSymbol(tx.order.fiatCurrency) }}
            {{ tx.order.unitPriceQuoted }}
            <span
              >{{
                formatCurrency(
                  tx.order.cryptoValue -
                    tx.order.flatRate -
                    tx.order.processingFee,
                )
              }}
              {{ tx.order.fiatCurrency }}
            </span>
          </span>
          <span>
            {{ $t('purchase_summary_fee') }}
            <span>
              {{ formatCurrency(tx.order.processingFee + tx.order.flatRate) }}
              {{ tx.order.fiatCurrency }}
            </span>
          </span>
        </div>
        <span
          class="MBMWidget_col-np-12 MBMWidget_px-0 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Success_Total">
          {{ $t('purchase_summary_total_cost') }}
          <span
            >{{ formatCurrency(tx.order.cryptoValue) }}
            {{ tx.order.fiatCurrency }}</span
          >
        </span>
      </div>
    </div>

    <div
      class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto"
      v-show="!loading"
    >
      <div
        class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat"
      >
        <AppButton
          :disabled="disabled || loading"
          color="primary"
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Purchase_Summary_AppButtonSummary"
          @click="store.commit('router/goTo', 'Exchange')"
        >
          {{ $t('purchase_success_buy_more') }}
        </AppButton>
      </div>
      <!--<div class="MBMWidget_col-6 MBMWidget_col-xs-6">
          <AppButton
            :disabled="disabled || loading"
            color="accent"
            class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Purchase_Summary_AppButtonSummary"
          >
            {{ $t('purchase_success_share') }}
          </AppButton>
        </div>-->
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import Button from '../General/Button.vue';
  import './../CardScreen/cards.css';
  import store from "@/store";
  import {getTransactionDetailsForHistory} from "@/api";
  import {processDecimalPlaces} from "@/utils";
  import ContentCopyIcon from "vue-material-design-icons/ContentCopy.vue";
  import CurrencyList from '@/components/Components/CurrencyList.json';
  import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
  import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';

  export default Vue.extend({
    components: {
      AppButton: Button,
      ContentCopyIcon: ContentCopyIcon,
      ChevronDownIcon,
      ChevronUpIcon,
    },
    data() {
      return {
        disabled: false,
        tx: null as any,
        loading: true,
        store,
        interval: null as any,
        showFees: true,
      }
    },
    methods: {
      getFiatSymbol(fiatCode: string) {
        // @ts-ignore
        if (CurrencyList.en[fiatCode] !== undefined) {
          // @ts-ignore
          if (CurrencyList.en[fiatCode] !== undefined) {
            // @ts-ignore
            return CurrencyList.en[fiatCode].symbol;
          }
        }
      },
      getPrice(){
        if(this.tx != null){
          // @ts-ignore
          const fees = this.tx.order.commission.commissionAmount + this.tx.order.commission.flatRate;
          // @ts-ignore
          const price =  (this.tx.order.actualPrice - fees) / this.tx.order.amount;
          return this.formatCurrency(price);
        }
      },
      formatCurrency(amount : any){
        return processDecimalPlaces(amount, 2);
      },
      copy(val: string){
        navigator.clipboard.writeText(val)
      },
      async loadData(){
        const response = await getTransactionDetailsForHistory(this.$store.state.currentTxId);
        if(response.data.success) {
          this.tx = response.data.result;
          // @ts-ignore
          if(this.tx != null && this.tx.transactionHash != null) {
            if (this.interval) {
              clearInterval(this.interval);
            }
          }
        }
        this.loading = false;
      },
      getDate(val: string){
        const date = new Date(Date.parse(val));
        return date.toLocaleString('en-GB');
      }
    },
    async mounted(){
      this.$store.commit('setPreviousPage', 'PurchaseSuccess');
      await this.loadData();
      
      let waitingForRequest = false;
      this.interval = setInterval(async () => {
        if (waitingForRequest) {
          return;
        }
        waitingForRequest = true;
        await this.loadData();
        waitingForRequest = false;
      }, 10000);
    },
    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },

  });
</script>

<style scoped>

</style>