<template>
    <div :class="inputClass" class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBWidget_date_input">
      <AppInput
        v-model="day"
        :autoComplete="'nope'"
        :maxLength="3"
        v-if="showDay"
        required
        :placeholder="'DD'"
        class="MBMWidget_AppInputDay"
        @keydown="input('day')"
        @blur="blur('day')"
        ref="day"
        type="number" 
        pattern="\d*"
      /><span v-if="showDay">/</span>
      <AppInput
        v-model="month"
        :autoComplete="'nope'"
        :maxLength="3"
        type="number" 
        pattern="\d*"
        required
        :placeholder="'MM'"
        class="MBMWidget_AppInputMonth"
        @keydown="input('month')"
        @blur="blur('month')"
        ref="month"
      />/
      <AppInput
        v-model="year"
        :autoComplete="'nope'"
        :maxLength="4"
        :minLength="4"
        required
        type="number" 
        pattern="\d*"
        :placeholder="'YYYY'"
        class="MBMWidget_AppInputYear"
        @keydown="input('year')"
        @blur="blur('year')"
        ref="year"
      />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Input from "./Input.vue";
  import InputSimple from "@/components/General/InputSimple.vue";
  export default Vue.extend({
    components: {
      AppInput: InputSimple
    },
    props : {
      inputClass : String,
      initDay: String,
      initMonth: String,
      initYear: String,
      showDay: Boolean,
      validateRuntime: Boolean
    },
    data() {
      return {
        day: '',
        month: '',
        year: '',
        date: '',
      };
    },
    methods: {
      getCode(): string {
        if(!this.validateFields()) return "";
          return this.date;
      },
      validateFields(): boolean {
        const valid = true;
        
        return valid;
      },
      blur(fieldRef: string) {
        switch(fieldRef) {
          case 'day':
            if(this.day.length < 2 && this.day != '') {
             if(this.day == '0')
               this.day = '1';
              this.day = '0' + this.day;
            }
            this.$emit('dayFilled', this.day);
            break;
          case 'month':
            if(this.month.length < 2 && this.month != '') {
              if(this.month == '0')
                this.month = '1';
              this.month = '0' + this.month;
            }
            this.$emit('monthFilled', this.month);
            break;
          case 'year':
            this.$emit('yearFilled', this.year);
            break;
        }
      },
      input(fieldRef: string | number) {
        // setTimeout(() => {
          switch(fieldRef) {
            case 'day': {
              const leadingZero = this.day.length > 0 && this.day[0] == '0';
              this.day = this.clearNumber(this.day).substring(0, 2);

              if(this.validateRuntime) {
                this.day = this.clamp(this.day, 0, 31);
                if (leadingZero && this.day.length == 1 && this.day != '0') {
                  this.day = '0' + this.day;
                }
              }

              if (this.day.length == 2) {
                // @ts-ignore
                this.$refs['month'].doFocus(true);
              }
              this.$emit('dayFilled', this.day);
              break;
            }
            case 'month': {
              const leadingZero = this.month.length > 0 && this.month[0] == '0';
              this.month = this.clearNumber(this.month).substring(0, 2);
              if(this.validateRuntime) {
                this.month = this.clamp(this.month, 0, 12);
                if (leadingZero && this.month.length == 1 && this.month != '0') {
                  this.month = '0' + this.month;
                }
              }
              if (this.month.length == 2) {
                // @ts-ignore
                this.$refs['year'].doFocus(true);
              } else if(this.month.length == 0 && this.showDay){
                // @ts-ignore
                this.$refs['day'].doFocus(true);
              }
              this.$emit('monthFilled', this.month);
              break;
            }
            case 'year':
              this.year = this.clearNumber(this.year).substring(0, 4);
              if(this.year.length == 0){
                // @ts-ignore
                this.$refs['month'].doFocus(true);

              }
              this.$emit('yearFilled', this.year);
          }
        // }, 2)
        
      },
      clearNumber(num: string){
        return num.replace(/\D/g, "");
      },
      clamp(num: string, min: number, max: number){
        if(num == null || num == '')
          return num;
        const parsed = parseInt(num);
        return Math.min(Math.max(parsed, min), max).toString();
      }
    },
    mounted(): void {
      this.day = this.initDay;
      this.month = this.initMonth;
      this.year = this.initYear;
    },/*
    watch: {
      codeParts(val: []) {
        
        
      }
    }*/
  });
</script>