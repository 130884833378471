<template>
  <div>
    <div
      :class="inputClass"
      class="
        MBMWidget_col-np-2 MBMWidget_col-np-md-2 MBMWidget_col-np-xs-2
        MBWidget_verify_input
      "
      v-for="(item, index) in CODE"
      :key="index"
    >
      <AppInput
        v-model="CODE[index]"
        v-bind:value="item"
        required
        :autoComplete="'nope'"
        :maxLength="1"
        :min="0"
        :max="9"
        :type="'number'"
        :ref="`c${index}`"
        :id="`c${index}`"
        @input="input(index, $event)"
        @paste="paste"
        @keydown="keyDown($event, index)"
        :placeholder="0"
        class="MBMWidget_FormTfaAppInputCode"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import InputTest from './InputTest.vue';
export default Vue.extend({
  components: {
    AppInput: InputTest,
  },
  props: {
    inputClass: String,
  },
  data() {
    return {
      CODE: ['', '', '', '', '', ''],
      refs: ['c0', 'c1', 'c2', 'c3', 'c4', 'c5'],
      code: '',
    };
  },
  methods: {
    getCode(): string {
      if (!this.validateFields()) return '';
      return this.code;
    },

    validateFields(): boolean {
      let valid = true;

      this.CODE.forEach((i) => {
        if (isNaN(parseInt(i))) {
          valid = false;
        }
      });

      if (valid) {
        this.code = '';

        this.CODE.forEach((i) => {
          this.code += i.substring(0, 1);
        });
      }

      return valid;
    },

    number(x: any) {
      return x.replace(/[^0-9 ]/, '');
    },

    input(index: number, value: string) {
      value = this.number(value);

      // Reduction of the string length to one character
      if (value.length > 1) {
        value = value[0];
      }

      // Set value
      this.CODE[index] = value;

      // Set focuse on next input
      if (value && index < 6) {
        this.setFocuseOnElementWithID('c' + (index + 1).toString());
      }

      // Call external function
      setTimeout(() => {
        this.$emit('codeFilled', this.code);
      }, 1);
    },

    paste(value: string) {
      value = this.number(value);

      for (let i = 0; i < value.length && i < 6; i++) {
        this.CODE[i] = value[i];

        this.getInput(i).setValue(value[i]);

        this.setFocuseOnElementWithID(`c${i + 1}`);
      }
    },

    getInput(i: number): InstanceType<typeof InputTest> {
      const refArray: InstanceType<typeof Array> = this.$refs[
        this.refs[i]
      ] as InstanceType<typeof Array>;
      return refArray[0] as InstanceType<typeof InputTest>;
    },

    setFocuseOnElementWithID(x: string) {
      const input: any = document.getElementById(x);

      input && input.focus();

      return true;
    },

    keyDown(e: any, index: number) {
      const changeFocus = this.CODE[index] == '';

      let value = this.number(e.key);

      if (this.CODE[index] && value == 'ackspace') {
        value = '';
      }
      if (changeFocus && value == 'ackspace') {
        this.setFocuseOnElementWithID(`c${index - 1}`);

        value = '';
      }

      // Set value
      this.CODE[index] = value;
    },
  },
  mounted(): void {
    this.setFocuseOnElementWithID('c0');
  },
  watch: {
    codeParts(value: []) {
      setTimeout(() => {
        for (let i = 0; i < value.length; i++) {
          if (value[i] == '' && i < value.length + 1) {
            this.setFocuseOnElementWithID(`c${i}`);
            break;
          }
        }
      }, 2);
    },
  },
});
</script>