import {Feature} from "@/components/Enums/StepType";
<template>
  <div class="MBWidget_User_Menu" v-observe-visibility="visibilityChanged">
    <div class="MBWidget_general_back_button" v-show="level > 0">
      <a class="MBWidget_Popup_back"
         @click.prevent="level = 0"
      ><ChevronLeftIcon fillColor="#333" :size="32"></ChevronLeftIcon></a>
    </div>
    <div class="MBWidget_general_menu_button">
      <a class="MBWidget_menu_button"
         @click.prevent="store.state.popup = ''"
      ><CloseIcon fillColor="#333" :size="30"></CloseIcon></a>
    </div>
    
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header" v-show="level == 0">
      {{ $t('user_menu_title') }}
    </div>
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_title_padding MBMWidget_Header" v-show="level == 1">
      <span>{{ $t('user_menu_about') }}</span>
    </div>
    <div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBWidget_User_Menu_Items_Container MBMWidget_mt-8" v-show="level == 0">
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="goTo(getItem(0).item.route)" v-if="getItem(0) != null && getItem(0).show">
        <span class="MBWidget_User_Menu_Icon"><CreditCardOutlineIcon :size="28"></CreditCardOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(0).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>
    
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="goTo(getItem(1).item.route)" v-if="getItem(1) != null && getItem(1).show">
        <span class="MBWidget_User_Menu_Icon"><SquareEditOutlineIcon :size="28"></SquareEditOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(1).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>
    
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="goTo(getItem(2).item.route)" v-if="getItem(2) != null && getItem(2).show">
        <span class="MBWidget_User_Menu_Icon"><HistoryIcon :size="28"></HistoryIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(2).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>

      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="languageSelection()" v-if="getItem(3) != null && getItem(3).show">
        <span class="MBWidget_User_Menu_Icon"><FlagOutlineIcon :size="28"></FlagOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(3).item.title)}}</span>
        <span class="MBWidget_User_Menu_Language">{{$t('lang_name_'+langNames[$i18n.locale])}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>

      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="showAbout()" v-if="getItem(4) != null && getItem(4).show">
        <span class="MBWidget_User_Menu_Icon"><InformationOutlineIcon :size="28"></InformationOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link MBWidget_About"><span>{{$t(getItem(4).item.title)}}</span></span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>
      
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="window.open(store.state.config.menuContactUrl, '_blank')" v-if="getItem(10) != null && getItem(10).show">
        <span class="MBWidget_User_Menu_Icon"><ChatOutlineIcon :size="28"></ChatOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(10).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>
      
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="goTo(getItem(8).item.route)" v-if="getItem(8) != null && getItem(8).show">
        <span class="MBWidget_User_Menu_Icon"><WalletGiftcardIcon :size="28"></WalletGiftcardIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(8).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>

      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="goTo(getItem(9).item.route)" v-if="getItem(9) != null && getItem(9).show">
        <span class="MBWidget_User_Menu_Icon"><WalletMembershipIcon :size="28"></WalletMembershipIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(9).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"></span>
      </div>
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="getWalletMetamask()" v-if="!store.state.config.fiatOnlyMode" :class="[canConnectWallet() ? 'MBM_Menu_Metamask_Can' : 'MBM_Menu_Metamask_Cant', connectedWallet != '' ? 'MBM_Menu_Metamask_Connected' : '']">
        <span class="MBWidget_User_Menu_Icon MBM_Menu_Metamask_Icon">
          <img src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/MetaMask.png"/>
        </span>
        <span class="MBWidget_User_Menu_Link" v-if="connectedWallet == ''">{{$t('menu_connect_metamask')}}</span>
        <span class="MBWidget_User_Menu_Link" v-else>{{$t('menu_connect_metamask_connected')}} 
          <span class="MBM_Menu_Metamask_Addr">{{connectedWallet.slice(0, 8) + "..." + connectedWallet.substr(connectedWallet.length - 8)}}</span>
          <span class="MBM_Menu_Metamask_Disconnect" v-if="canDisconnectWallet()" @click="disconnectWallet()">{{$t('menu_connect_metamask_disconnect')}}</span>
        </span>
        <p class="MBM_Menu_Metamask_Error" v-if="modalError != ''">{{$t(modalError)}}</p>
      </div>
    </div>
    <div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBWidget_User_Menu_Items_Container MBMWidget_mt-8" v-show="level == 1">
      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="window.open(store.state.config.menuCompanyUrl, '_blank')" v-if="getItem(5) != null && getItem(5).show">
        <span class="MBWidget_User_Menu_Icon"><ArrowTopRightIcon :size="28"></ArrowTopRightIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(5).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>

      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="showIframe('terms_and_conditions')" v-if="getItem(6) != null && getItem(6).show">
        <span class="MBWidget_User_Menu_Icon"><FileDocumentOutlineIcon :size="28"></FileDocumentOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(6).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>

      <div class="MBMWidget_col-np-xs-12 MBMWidget_col-np-12 MBWidget_User_Menu_Item MBWidget-nofloat MBMWidget_mx-auto MBMWidget_py-2" @click="showIframe('privacy_policy')" v-if="getItem(7) != null && getItem(7).show">
        <span class="MBWidget_User_Menu_Icon"><ShieldOutlineIcon :size="28"></ShieldOutlineIcon></span>
        <span class="MBWidget_User_Menu_Link">{{$t(getItem(7).item.title)}}</span>
        <span class="MBWidget_User_Menu_Arrow"><ChevronRightIcon :size="28" fillColor="#999"></ChevronRightIcon></span>
      </div>
    </div>
    
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import {isFeature, redirectViewString} from "@/utils";
  import {Feature} from "@/components/Enums/StepType";
  import store from "@/store";
  import CreditCardOutlineIcon from "vue-material-design-icons/CreditCardOutline.vue";
  import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
  import SquareEditOutlineIcon from "vue-material-design-icons/SquareEditOutline.vue";
  import HistoryIcon from "vue-material-design-icons/History.vue";
  import {names} from "@/i18n";
  import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
  import CloseIcon from 'vue-material-design-icons/Close.vue';
  import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline.vue';
  import ArrowTopRightIcon from 'vue-material-design-icons/ArrowTopRight.vue';
  import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
  import ShieldOutlineIcon from 'vue-material-design-icons/ShieldOutline.vue';
  import WalletGiftcardIcon from 'vue-material-design-icons/WalletGiftcard.vue';
  import WalletMembershipIcon from 'vue-material-design-icons/WalletMembership.vue';
  import FlagOutlineIcon from 'vue-material-design-icons/FlagOutline.vue';
  import ChatOutlineIcon from 'vue-material-design-icons/ChatOutline.vue';
  import Web3 from "web3";

  export default Vue.extend({
    props: ['showIframe'],
    components: {
      CreditCardOutlineIcon : CreditCardOutlineIcon,
      ChevronRightIcon: ChevronRightIcon,
      SquareEditOutlineIcon: SquareEditOutlineIcon,
      HistoryIcon: HistoryIcon,
      ChevronLeftIcon: ChevronLeftIcon,
      CloseIcon: CloseIcon,
      InformationOutlineIcon: InformationOutlineIcon,
      ArrowTopRightIcon: ArrowTopRightIcon,
      FileDocumentOutlineIcon: FileDocumentOutlineIcon,
      ShieldOutlineIcon: ShieldOutlineIcon,
      WalletGiftcardIcon: WalletGiftcardIcon,
      WalletMembershipIcon: WalletMembershipIcon,
      FlagOutlineIcon: FlagOutlineIcon,
      ChatOutlineIcon: ChatOutlineIcon
    },
    methods: {
      
      languageSelection(){
        this.$store.state.popup = 'lang';
      },
      showAbout(){
        this.level = 1;
      },
      goTo(page: string | null){
        if(page == null) return;
        this.$store.commit('personalizedErrors', []);
        redirectViewString(page);
        this.$store.state.popup = '';
        this.$store.state.selectedPhazeCountry = '';
        this.modalError = '';
      },
      action(action: string | null, route : string | null){
        if(action == null) {
          this.$store.commit('personalizedErrors', []);
          this.goTo(route);
        }
      },
      getItem(id: number): object | null {
        const logged : boolean = this.$store.state.loggedIn;
        const isFiat : boolean = this.$store.state.config.fiatOnlyMode;
        const dev : boolean = this.$store.state.profile.userUUid == '9a2e8814-0bb1-4eb9-b9b2-7e17ce02cb10' ||
          this.$store.state.profile.userUUid == 'f7232991-0f1e-49b7-ae25-a4684ed7f433' ||
          this.$store.state.profile.userUUid == '6d2b7162-35de-4a07-b33b-78fcf8ae4357';
        
        for (const i of this.items) {
          if(i.id == id){
            const show = !(!i.forFiat && isFiat) && !(i.dev && !dev) && !(i.feature != null && !isFeature(i.feature)) && ((i.loggedIn && logged) || !i.loggedIn);
            const item = {
              show : show,
              item: i
            };
            return  item;
          }
        }
        return null;
      },
      async getWalletMetamask() {
        if(this.connectedWallet != '' || !this.canConnect)
          return;
        
        if(!this.canConnectWallet())
          return;
        //this.walletButton = true;
        this.modalError = '';
        //this.modalLoader = true;

        let web3: Web3 | undefined = undefined; // Will hold the web3 instance

        if (!(window as any).ethereum || !(window as any).ethereum.isMetaMask) {
          this.modalError = 'external_wallet_error_metamask_provider';
          //this.modalLoader = false;
          //this.walletButton = false;
          return;
        }

        if (!web3) {
          try {
            await (window as any).ethereum.enable();
            web3 = new Web3((window as any).ethereum);
          } catch (error) {
            this.modalError = 'external_wallet_error_metamask_not_allow';
            //this.modalLoader = false;
            //this.walletButton = false;
            return;
          }
        }

        /*
        // detect wallet network
        const networkId = await web3.eth.net.getId();
        // development env requierd testnet
        if (process.env.NODE_ENV === 'development' && networkId === 1) {
          try {
            await (window as any).ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: Web3.utils.toHex(5) }],
            });
          } catch (e) {
            this.modalError = 'external_wallet_testnet_requierd';
            //this.modalLoader = false;
            //this.walletButton = false;
            return;
          }
        }
        // production env requierd mainent
        if (process.env.NODE_ENV !== 'development' && networkId > 1) {
          try {
            await (window as any).ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: Web3.utils.toHex(1) }],
            });
          } catch (e) {
            this.modalError = 'external_wallet_mainnet_requierd';
            //this.modalLoader = false;
            //this.walletButton = false;
            return;
          }
        }
        // production env requierd mainent
        if (networkId !== 1 && networkId !== 5) {
          try {
            await (window as any).ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: Web3.utils.toHex(5) }],
            });
          } catch (e) {
            this.modalError =
              process.env.NODE_ENV !== 'development'
                ? 'external_wallet_net_supporting'
                : 'external_wallet_net_supporting_dev';
            //this.modalLoader = false;
            //this.walletButton = false;
            return;
          }
        }
        
         */

        try {
          const account = await web3.eth.getAccounts();
          // setting states
          this.$store.state.userWallet = account[0];
          this.connectedWallet = account[0];
          //this.UserWallet = account[0];
        } catch (e) {
          window.alert('Something went wrong. Please try again.');
        }
        //this.walletButton = false;
        //this.showModal = false;
        //this.modalLoader = false;
        return true;
      },
      visibilityChanged () {
        this.modalError = '';
        if(this.connectedWallet != this.store.state.userWallet || this.$store.state.selectedCryptoChain != 'ETH') {
          this.connectedWallet = '';
        }
        
      },
      canConnectWallet() {
        if(this.$store.state.order.uuid != '' && this.$store.state.pageQueue[this.$store.state.pageQueue.length-1] != 'Order')
          return false;
        
        if(this.$store.state.selectedCryptoChain != 'ETH')
          return false;
        
        if(this.connectedWallet == '')
          return true;
        
        if(this.$store.state.userWallet != this.connectedWallet)
          return false;
        
        return true;
      },
      canDisconnectWallet() {
        if(this.$store.state.order.uuid != '' && this.$store.state.pageQueue[this.$store.state.pageQueue.length-1] != 'Order')
          return false;
        
        return true;
      },
      disconnectWallet () {
        this.connectedWallet = '';
        this.$store.state.userWallet = '';
        this.canConnect = false;
        setTimeout(() => { this.canConnect = true;}, 50);
      }
    },
    data () {
      return {
        level: 0,
        langNames: names,
        store,
        window,
        modalError : '',
        connectedWallet: '',
        canConnect : true,
        items: [
          {
            id: 0,
            title: "user_menu_buy",
            route: "Order",
            forFiat: false,
            loggedIn: false,
            dev: false,
            feature: Feature.MenuCrypto
          },
          {
            id: 1,
            title: "user_menu_cards",
            route: "CardList",
            loggedIn: true,
            forFiat: true,
            dev: false,
            feature: Feature.MenuPaymentMethods
          },
          {
            id: 2,
            title: "user_menu_history",
            route: "TransactionHistory",
            loggedIn: true,
            forFiat: false,
            dev: false,
            feature: Feature.MenuTxHistory
          },
          {
            id: 3,
            title: "user_menu_language",
            route: null,
            loggedIn: false,
            forFiat: true,
            dev: true,
            feature: null
          },
          {
            id: 4,
            title: "user_menu_about",
            route: null,
            loggedIn: false,
            forFiat: true,
            dev: false,
            feature: Feature.MenuAbout
          },
          {
            id: 5,
            title: "user_menu_website",
            route: null,
            loggedIn: false,
            forFiat: true,
            dev: false,
            feature: Feature.MenuWebsite
          },
          {
            id: 6,
            title: "user_menu_tos",
            route: null,
            loggedIn: false,
            forFiat: true,
            dev: false,
            feature: Feature.MenuTos
          },
          {
            id: 7,
            title: "user_menu_privacy",
            route: null,
            loggedIn: false,
            forFiat: true,
            dev: false,
            feature: Feature.MenuPrivacyPolicy
          },

          {
            id: 8,
            title: "user_menu_phaze_list",
            route: "PhazeCardList",
            loggedIn: false,
            forFiat: true,
            icon: '',
            dev: false,
            feature: Feature.MenuPhaze
          },
          {
            id: 9,
            title: "user_menu_phaze_history",
            route: "PhazeCardHistory",
            loggedIn: true,
            forFiat: true,
            icon: '',
            dev: false,
            feature: Feature.MenuPhazeHistory
          },
          {
            id: 10,
            title: "user_menu_contact",
            route: null,
            loggedIn: false,
            forFiat: true,
            dev: false,
            feature: Feature.MenuContact
          },

        ]
      }
    }
    
  });
</script>

<style scoped>

</style>