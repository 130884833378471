<template>
  <div class="MBMWidget_Phaze_Chose_Crypto MBMWidget_mx-auto MBWidget_block">
    <div
      class="
        MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Phaze_Card_List_Wrap MBMWidget_Phaze_Crypto_Wrap
      "
      ref="outerScroll"
      style="max-height: 100%; margin: 30px 0 0 0;"
      v-dragscroll
    >
      <div
        class="
          MBMWidget_col-np-xs-12
          MBMWidget_col-np-md-6
          MBMWidget_col-np-6
          MBMWidget_Phaze_Card_List_Item
        "
        v-for="(i, index) in crypto"
        v-bind:key="i.ticker"
      >
        <div
          :class="index % 2 === 0 ? 'tile' : 'tile tileRight'"
          v-on:click="click(i.name, i.ticker, i.crypto)"
        >
          <div
            class="
              MBMWidget_col-np-4
              MBMWidget_col-np-xs-4
              image
            "
          >
            <span
              :style="'background-image:url(' + getCryptoIcon(i.ticker) + ');'"
            ></span>
          </div>
          <div class="MBMWidget_col-np-8 MBMWidget_col-np-xs-8 name">
            <span class="title">
              {{ i.name }}
            </span>
            <span class="MBMWidget_Phaze_Card_List_Item_Price symbol">
              {{ '(' + i.ticker + ')' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import store from '@/store';
import './../Components/CoolSelect.css';
// @ts-ignore
import { dragscroll } from 'vue-dragscroll';

export default Vue.extend({
  components: {},
  directives: {
    dragscroll,
  },
  data() {
    return {
      store,
      crypto: [] as any[]
    };
  },
  methods: {
    getCryptoIcon(ticker: string): any {
      const t = ticker.replace(' ', '+');
      return (
        'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/cryptoicons/' +
        t +
        '.png'
      );
    },
    click(name: string, ticker: string, crypto: string) {
      console.log(name);
      this.$store.state.phazeOrder.selectedCryptoCurrency = ticker;
      this.$store.state.phazeOrder.networkType = crypto;
      this.$emit('nextScreen', { screenPosition: 3, selectedCrypto: { name: name, ticker: ticker, crypto: crypto } });
    },
  },
  mounted() {
    console.log()
    //this.$store.commit('setPreviousPage', 'ChooseCrypto');
    const available = ['BTC', 'ETH', 'BCH', 'LTC', 'MATIC ERC20', 'SOL', 'USDC', 'USDT ERC20', 'USDT TRC20'];
    this.$store.state.config.cryptoCurrencies.forEach((i: any) => {
      if(available.includes(i.ticker))
        this.crypto.push(i);
    })
    const usdt1 = {
      additionalFields:null,
      defaultDisplayAmount:0,
      minimumOrder:0,
      name:"USDT TRC20",
      ticker:"USDT",
      validationRegex:"",
      validationType:"",
      crypto: 'tron'
    };
    this.crypto.push(usdt1);
    const usdt2 = {
      additionalFields:null,
      defaultDisplayAmount:0,
      minimumOrder:0,
      name:"USDT ERC20",
      ticker:"USDT",
      validationRegex:"",
      validationType:"",
      crypto: 'eth'
    };
    this.crypto.push(usdt2);
  }
});
</script>

<style scoped>
.tile {
  height: 60px;
  border-radius: 10px;
  border-width: 1px;
  border-color: rgba(163, 176, 191, 0.35);
  border-style: solid;
}
@media screen and (min-width: 500px) {
  .tileRight {
    height: 60px;
    border-radius: 10px;
    border-width: 1px;
    border-color: rgba(163, 176, 191, 0.35);
    border-style: solid;
    margin: 0 0 0 10px;
  }
}
.image span {
  width: 35px;
  height: 35px;
  display: block;
  background-size: cover;
  background-position: center center;
  margin: 7px 0 0 16px;
}
.title {
  font-size: 16px;
  font-family: 'FuturaRegular';
  font-weight: 700;
  display: block;
  padding: 2px 0 0 10px;
}
.name {
  margin: 2px 0 0 0;
}
.symbol {
  font-family: 'FuturaRegular';
  font-size: 14px;
  font-weight: 500;
  color: #a3b0bf;
  padding: 4px 0 0 10px;
  display: block;
}
  
</style>
