<template>
  <div
    class="
      MBMWidget_Phaze_Card_Checkout
      MBMWidget_Phaze_Card_Review
      MBMWidget_mx-auto
    "
    v-bind:class="[
            $store.state.phazeOrder.selectedCryptoCurrency != 'BTC' &&
            $store.state.phazeOrder.selectedCryptoCurrency != 'BCH'
              ? 'MBWidget_footer_actions_wallet'
              : '',
              
             showQRCode ? 'MBWidget_footer_actions_qr' : '' 
          ]"
  >
    <div
      class="
        MBMWidget_Phaze_Card_List_Expired
      "
     
      v-if="timer == null && aquaTx != null"
    >
      <div class="MBMWidget_Phaze_Card_List_Expired_Icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 50 50" fill="none">
          <path d="M26.9749 32C25.6436 32.0063 24.3624 31.475 23.4186 30.5313L15.9999 23.1063L8.58111 30.5313C6.62486 32.5 3.44361 32.5125 1.47486 30.5563C-0.493886 28.6 -0.512636 25.4188 1.44361 23.45C1.45611 23.4375 1.46236 23.4313 1.47486 23.4188L8.89361 16L1.47486 8.58125C-0.481386 6.60625 -0.468886 3.425 1.50611 1.46875C3.46236 -0.474997 6.62486 -0.474997 8.58111 1.46875L15.9999 8.89375L23.4186 1.46875C25.3749 -0.499997 28.5561 -0.512497 30.5311 1.44375C32.5061 3.4 32.5186 6.58125 30.5624 8.55C30.5499 8.5625 30.5436 8.56875 30.5311 8.58125L23.1124 16L30.5311 23.4188C32.4936 25.3813 32.4936 28.5625 30.5311 30.525C29.5874 31.4688 28.3061 32 26.9749 32Z" fill="#EB5757"/>
        </svg>
        <!--<WindowCloseIcon :size="50" fillColor="#EB5757"></WindowCloseIcon>-->
      </div>
      <p style="font-size: 18px;"><strong>{{$t('phaze_tx_canceled_title')}}</strong></p>
      <p>{{$t('phaze_tx_canceled_long')}}</p>

      <div
        class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-0"
      >
        <AppButton
          @click="failedBack()"
          style="margin-bottom: 10px; background-color: #458fe6"
          class="MBMWidget_full-width MBMWidget_second_button"
        >
          {{$t('phaze_tx_canceled_refresh')}}
        </AppButton>
      </div>
    </div>
    
    <div
      class="
        MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Phaze_Card_List_Wrap
      "
      ref="outerScroll"
      style="max-height: 100%"
      v-if="timer != null || aquaTx == null"
    >
      <p class="paragraph" v-if="canPay">
        {{ $t('phaze_card_payment_title', { cryptoTicker }) }}
      </p>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Loading
          MBMWidget_text-center
          loading_style
        "
        v-if="aquaTx == null"
      >
        {{ $t('loading') }}
      </div>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Purchase_Summary_KYC
          MBMWidget_mb-3
        "
        v-if="!canPay"
      >
        <span
          ><strong>{{ $t('purchase_summary_kyc_title') }}</strong></span
        >
        <span>{{ $t('purchase_summary_kyc_description') }}</span>
      </div>
      <div class="qrCode" v-if="showQRCode && canPay && aquaTx != null">
        <AppQrCode class="MBMWidget_Qrcode" :value="address" size="150" />
      </div>
      <div class="section" v-if="canPay && aquaTx != null">
        <span>
          {{ $t('phaze_card_payment_amount') }}
        </span>
        <div style="margin-top: 5px">
          <div style="display: flex; flex-direction: row">
            <input
              v-model="amount"
              type="number"
              style="width: 100%"
              disabled
            />
            <AppButton
              @click="copy(amount)"
              style="background-color: transparent; height: 22px; width: 22px"
            >
              <PasteIcon />
            </AppButton>
          </div>
          <div class="separator" />
        </div>
      </div>
      <div class="section" v-if="!showQRCode && canPay && aquaTx != null">
        <span>
          {{ $t('phaze_card_payment_address') }}
        </span>
        <div style="margin-top: 5px">
          <div style="display: flex; flex-direction: row">
            <input
              v-model="address"
              style="width: 100%"
              ref="textToCopy"
              disabled
            />
            <AppButton
              @click="copy(address)"
              style="background-color: transparent; height: 22px; width: 22px"
            >
              <PasteIcon
            /></AppButton>
          </div>
          <div class="separator" />
        </div>
      </div>
      <div class="section" v-if="!showQRCode && canPay && aquaTx != null">
        <span>
          {{ $t('phaze_card_payment_orderId') }}
        </span>
        <div style="margin-top: 5px">
          <input v-model="orderId" style="width: 100%" disabled />
          <div class="separator" />
        </div>
      </div>
      <div
        v-if="canPay && aquaTx != null"
        style="
          margin-top: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
        "
      >
        <span class="time-remaining">
          {{ $t('phaze_card_payment_time_remaining') }}</span
        >
        <TimerIcon style="margin-left: 5px; margin-right: 5px" />
        <span class="time-remaining" v-if="timer != null"> {{ timer }}</span>
        <span class="time-remaining" v-if="timer == null"> {{ $t('phaze_tx_canceled') }}</span>
      </div>
      <div
        class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-0"
         v-bind:class="[
            $store.state.phazeOrder.selectedCryptoCurrency != 'BTC' &&
            $store.state.phazeOrder.selectedCryptoCurrency != 'BCH'
              ? ''
              : 'flex-layout',
          ]"
      >
        <div
          v-bind:class="[
            $store.state.phazeOrder.selectedCryptoCurrency != 'BTC' &&
            $store.state.phazeOrder.selectedCryptoCurrency != 'BCH'
              ? 'external_wallet_wrapper'
              : 'external_wallet_wrapper_content',
          ]"
          id="external_wallet_container_summary"
          v-if="canPay && aquaTx != null"
        >
          <div>
            <AppButton
              v-if="!showQRCode"
              @click="showQRCode = true"
              style="margin-bottom: 10px"
              class="MBMWidget_full-width MBMWidget_second_button"
            >
              <span>
                {{ $t('phaze_buttons_show_qr_code') }}
              </span>
            </AppButton>
            <AppButton
              v-if="showQRCode"
              @click="showQRCode = false"
              style="margin-bottom: 10px"
              class="MBMWidget_full-width MBMWidget_second_button"
            >
              <span>
                {{ $t('phaze_buttons_show_wallet') }}
              </span>
            </AppButton>
          </div>
          <div
            v-if="
              $store.state.phazeOrder.selectedCryptoCurrency != 'BTC' &&
              $store.state.phazeOrder.selectedCryptoCurrency != 'BCH'
            "
          >
            <AppButton
              style="background-color: #458fe6"
              class="MBMWidget_full-width"
              @click="showModalWallet"
            >
              {{ $t('external_wallet_pay_by_wallet') }}
            </AppButton>
          </div>
        </div>
        <AppButton
          v-if="!showQRCode && canPay && aquaTx != null"
          @click="store.commit('router/goTo', Routes.PhazeCardList)"
          style="margin-bottom: 10px; background-color: #458fe6"
          class="MBMWidget_full-width MBMWidget_second_button"
        >
          {{ $t('phaze_buttons_keep_shopping') }}
        </AppButton>
      </div>
      <div class="external_wallet_container">
        <div
          v-if="
            showModal &&
            $store.state.phazeOrder.selectedCryptoCurrency != 'BTC' &&
            $store.state.phazeOrder.selectedCryptoCurrency != 'BCH'
          "
          class="external_wallet_modal"
        >
          <div class="modal_mask" @click="hideModalWallet($event)">
            <div class="modal_wrapper">
              <transition name="fade" appear>
                <div class="modal_container">
                  <button
                    type="button"
                    class="modal-close-button"
                    aria-label="Close"
                    @click="showModal = false"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="modal_header">
                    <h4>{{ $t('external_wallet_modal_header') }}</h4>
                  </div>
                  <div class="modal_body">
                    <div class="modal_body_row">
                      <div
                        class="modal_body_col"
                        v-if="!$store.state.isMobile"
                      >
                        <button
                          :disabled="walletButton"
                          type="button"
                          @click="payByMetamask"
                          class="external_wallet_button metamask_button"
                        >
                          <IconMetaMask />
                          <span> Metamask </span>
                        </button>
                      </div>
                      <div class="modal_body_col">
                        <button
                          :disabled="walletButton"
                          type="button"
                          @click="payByWalletConnect"
                          class="external_wallet_button walletconnect_button"
                        >
                          <IconWalletConnect />
                          <span> WalletConnect </span>
                        </button>
                      </div>
                    </div>
                    <div class="modal_error" v-if="modalError">
                      {{ $t(modalError) }}
                    </div>
                    <div class="lds-ring modal_loader" v-if="modalLoader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import WindowCloseIcon from "vue-material-design-icons/WindowClose.vue";
import Qrcode from 'qrcode.vue';
import Button from '../General/Button.vue';
// @ts-ignore
import PasteIcon from '../../assets/gift-cards-icons/paste-icon.svg';
// @ts-ignore
import TimerIcon from '../../assets/gift-cards-icons/icon-stopwatch.svg';
// @ts-ignore
import {
  checkKycVerified,
  CheckTransactionAqua,
  processPaymentNewAqua,
  uploadScreenshot,
  // @ts-ignore
} from '@/api';
// @ts-ignore
import PaymentProcessor from '@/components/CardScreen/PaymentProcessor';
// @ts-ignore
import store from '@/store';
// @ts-ignore
import { Routes } from '@/store/modules/Router';
// @ts-ignore
import Input from '@/components/General/Input.vue';
// @ts-ignore
import { zeroPad } from '@/utils';

// @ts-ignore
import IconMetaMask from '../../assets/external_wallets/metamask.svg';
// @ts-ignore
import IconWalletConnect from '../../assets/external_wallets/walletconnect.svg';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { IModalActions } from '../../_common/interfaces/IModalActions.interface';

// @ts-ignore
import cryptoAddressEtherNet from '@/utils/cryptoAddressEtherNet.json';
// @ts-ignore
import abi from '@/utils/mobilumABI.json';
  import {Exception} from "vue-gtag";

export default PaymentProcessor.extend({
  components: {
    AppQrCode: Qrcode,
    AppButton: Button,
    PasteIcon: PasteIcon,
    TimerIcon: TimerIcon,
    IconMetaMask: IconMetaMask,
    IconWalletConnect: IconWalletConnect,
    WindowCloseIcon: WindowCloseIcon,
  },
  props: {
    cryptoTicker: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      amount: '',
      address: '',
      orderId: '',
      store,
      Routes,
      loading: true,
      currentCard: null,
      selectedPrice: '',
      card: null,
      CVV: '',
      unsavedCard: false,
      kycInterval: null as any,
      checkTxInterval: null as any,
      timeInterval: null as any,
      kycVerified: false,
      crypto: false,
      aquaTx: null as object | null,
      timeLeft: 0,
      timeEndTimestamp: 0,
      checkDisabled: false,
      purchaseId: '',
      amountLeft: '',
      kycStartedInterval: null as any,
      kycStarted: true,
      loadedFirstTime: false,
      canPay: true,
      showQRCode: false,
      showModal: false,
      walletButton: false,
      modalError: false as boolean | string,
      modalLoader: false,
      txStarted: false,
    };
  },
  computed: {
    timer(): string | null {
      if (this.timeLeft < 0) return null;

      const min = Math.floor(this.timeLeft / 60);
      const sec = this.timeLeft - min * 60;
      return zeroPad(min, 2) + ':' + zeroPad(sec, 2);
    },
  },
  methods: {
    failedBack(){
      const collection = document.getElementsByClassName("MBWidget_general_back");
      if(collection.length > 0) {
        const event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
          clientX: 10,
          clientY: 10,
        });

        collection[0].dispatchEvent(event);
      }
    },
    copy(val: string) {
      navigator.clipboard.writeText(val);
    },
    async createPhazeOrder() {
      this.loading = true;
      this.txStarted = true;
      const body = {
        OrderUuid: this.$store.state.phazeOrder.uuid,
        UserUuid: this.$store.state.profile.userUUid,
        CryptoType: this.$store.state.phazeOrder.selectedCryptoCurrency,
        Fiat: this.$store.state.phazeOrder.selectedCurrency,
        DeliveryEmail: this.$store.state.giftCardDeliveryEmail,
        NetworkType: this.$store.state.phazeOrder.networkType,
      };
      const response = await processPaymentNewAqua(body);
      if (response.data.success) {
        this.aquaTx = response.data.result.aquaTransaction;
        // @ts-ignore
        this.amountLeft = this.aquaTx.expectedAmount;
        // @ts-ignore
        this.amount = this.aquaTx?.expectedAmount;
        // @ts-ignore
        this.address = this.aquaTx?.depositAddress;
        // @ts-ignore
        this.orderId = this.aquaTx?.orderUuid;
        this.purchaseId = response.data.result.purchaseUuid;
        this.$store.state.currentPhazePurchaseId = response.data.result.purchaseUuid;
        // @ts-ignore
        const dTo = this.aquaTx.expiryTime;
        this.timeEndTimestamp = new Date(dTo).getTime();
        this.timeLeft = Math.floor(
          (new Date(dTo).getTime() - new Date().getTime()) / 1000,
        );
        this.timeInterval = setInterval(async () => {
          this.timeLeft = Math.floor(
            (new Date(this.timeEndTimestamp).getTime() - new Date().getTime()) / 1000,
          );
        }, 1000);

        const file = this.getScreenshot();
        if (file != null) {
          // @ts-ignore
          await uploadScreenshot(file);
        }
        this.checkDisabled = true;
        this.checkTxInterval = setInterval(async () => {
          this.checkTx();
        }, 3000);
      } else {
        console.log();
      }
      this.loading = false;
    },
    async checkTx() {
      const response = await CheckTransactionAqua(this.purchaseId);
      if (response.data.result && (response.data.result.UnconfirmedAmount > 0 || response.data.result.amount <= 0)) {
        this.$store.commit('router/goTo', 'PhazeCardPendingPayment');
      }
    },
    routeToSuccess() {
      this.$store.state.currentPhazeOrderUuid =
        this.$store.state.phazeOrder.uuid;
      this.$store.commit('phazeOrder', { uuid: '' });
      this.$store.state.giftCardList = [];
      this.$store.state.selectedGiftCardId = -1;
      this.$store.state.selectedGiftCardValue = '';
      this.$store.state.giftCardPage = 0;
      this.$store.state.phazeCountries = [];
      this.$store.state.selectedGiftCardsCart = [] as any;
      this.$store.state.giftCardDeliveryEmail = '';
      this.$store.state.selectedPhazeCountry = '' as string;
      this.$store.state.selectedPhazeCurrency = '' as string;
      this.$store.state.currentPhazePurchaseId = null;

      setTimeout(() => {
        this.$store.state.phazePurchaseSuccess = true;
        this.$store.commit('router/goTo', 'PhazeCardPurchased');
      }, 2000);
    },
    async checkKyc() {
      const kyc = await checkKycVerified(this.$store.state.phazeOrder.uuid);
      if (kyc.data) {
        if (!kyc.data.result.canPay) {
          this.canPay = false;
        } else if (kyc.data.result.canPay == true && !this.txStarted) {
          this.canPay = true;
          this.createPhazeOrder();
        }
        if (kyc.data.result.waitForKyc) {
          this.kycVerified = false;
        } else {
          this.kycVerified = true;
          if (this.kycInterval) {
            clearInterval(this.kycInterval);
          }
        }
      }
    },
    async payByWalletConnect() {
      this.walletButton = true;
      this.modalError = false;
      this.modalLoader = true;
      localStorage.removeItem('walletconnect');

      const providerWalletConnect: any = new WalletConnectProvider({
        rpc: {
          1: 'https://eth.getblock.io/mainnet/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          3: 'https://eth.getblock.io/ropsten/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          5: 'https://eth.getblock.io/goerli/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          42: 'https://eth.getblock.io/kovan/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          4: 'https://eth.getblock.io/rinkeby/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
        },
      });
      let web3;

      try {
        await providerWalletConnect.enable();
        web3 = new Web3(providerWalletConnect);
      } catch (error) {
        this.modalError = 'external_wallet_error_walletconnect_provider';
        this.modalLoader = false;
        this.walletButton = false;
        return;
      }

      // detect wallet network
      const networkId = await web3.eth.net.getId();
      // development env requierd testnet
      if (process.env.NODE_ENV === 'development' && networkId === 1) {
        this.modalActions({
          modalError: 'external_wallet_testnet_requierd',
        });
        return;
      }
      // production env requierd mainent
      if (process.env.NODE_ENV !== 'development' && networkId > 1) {
        this.modalActions({
          modalError: 'external_wallet_mainnet_requierd',
        });
        return;
      }
      // production env requierd mainent
      if (networkId !== 1 && networkId !== 3) {
        this.modalActions({
          modalError:
            process.env.NODE_ENV !== 'development'
              ? 'external_wallet_net_supporting'
              : 'external_wallet_net_supporting_dev',
        });
        return;
      }

      // get the crypto contract address
      const tokenAddress: string =
        // @ts-ignore
        cryptoAddressEtherNet[networkId][this.cryptoTicker];

      // get the gas price and limit
      let gasPrice: any = undefined;
      let gasLimit: any = undefined;
      try {
        gasPrice = await web3.eth.getGasPrice();
        gasLimit = await web3.eth.getBlock('latest');
      } catch (e) {
        console.error(e);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      ////////////
      //// check balance feature !!!!!
      ///////////

      /// get user wallet address
      let walletAddress: any = '';
      try {
        const walletAddress_ = await web3.eth.getAccounts();
        walletAddress = walletAddress_[0];
        this.$store.state.userWallet = walletAddress;
      } catch (e) {
        console.error(e);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      /// get nonce
      let nonce = 0;
      try {
        nonce = await web3.eth.getTransactionCount(
          this.$store.state.userWallet,
        );
      } catch (e) {
        console.error(e);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      // try {
      //   const accounts = await web3.eth.getAccounts();
      //   // setting states
      //   this.$store.state.userWallet = accounts[0];
      //   // this.UserWallet = accounts[0];
      // } catch (e) {
      //   this.modalError = 'external_wallet_error_walletconnect_not_allow';
      // }

      // transaction in ETH
      const transactionParameters = {
        nonce: nonce.toString(),
        gasPrice: web3.utils.toHex(gasPrice.toString()),
        gas: web3.utils.toHex(gasLimit.gasLimit.toString()),
        // to: '0x9177a64f1A6CB1a07062B9a554c26A91749A3d08',
        to: this.address,
        from: walletAddress,
        value: web3.utils.toHex(
          web3.utils.toWei(this.amount.toString(), 'ether'),
        ),
        // data: web3.utils.toHex(this.orderId),
        chainId: web3.utils.toHex(networkId.toString()),
        input: web3.utils.toHex(this.orderId),
        data: web3.utils.toHex(this.orderId),
      };

      try {
        // const txHash = await (window as any).ethereum.request({
        //   method: 'eth_sendTransaction',
        //   params: [transactionParameters],
        // });
        // @ts-ignore
        const txHash = web3.eth.sendTransaction(transactionParameters);
        // redirectViewString(Routes.PurchaseSuccess);
      } catch (e : any) {
        console.error(e.messages);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      this.walletButton = false;
      this.showModal = false;
      this.modalLoader = false;
      return true;
    },
    //// external wallets connect
    modalActions(
      obj: IModalActions | any = {
        modalError: false,
        modalLoader: false,
        walletButton: false,
      },
    ) {
      this.walletButton = obj.walletButton;
      this.modalError = obj.modalError;
      this.modalLoader = obj.modalLoader;
    },
    async payByMetamask() {
      // clearing states
      this.modalActions({
        modalError: false,
        modalLoader: true,
        walletButton: true,
      });

      // Will hold the web3 instance
      let web3: Web3 | undefined = undefined;

      // Detect any wallet provider
      if (!(window as any).ethereum || !(window as any).ethereum.isMetaMask) {
        this.modalActions({
          modalError: 'external_wallet_error_metamask_provider',
        });
        return;
      }
      /// setting provider
      if (!web3) {
        try {
          await (window as any).ethereum.enable();
          web3 = new Web3((window as any).ethereum);
        } catch (error) {
          this.modalActions({
            modalError: 'external_wallet_error_metamask_not_allow',
          });
          return;
        }
      }

      // detect wallet network
      const networkId = await web3.eth.net.getId();
      // development env requierd testnet
      if (process.env.NODE_ENV === 'development' && networkId === 1) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(3) }],
          });
        } catch (e) {
          this.modalActions({
            modalError: 'external_wallet_testnet_requierd',
          });
          return;
        }
      }
      // production env requierd mainent
      if (process.env.NODE_ENV !== 'development' && networkId > 1) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(1) }],
          });
        } catch (e) {
          this.modalActions({
            modalError: 'external_wallet_mainnet_requierd',
          });
          return;
        }
      }
      // production env requierd mainent
      if (networkId !== 1 && networkId !== 3) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(1) }],
          });
        } catch (e) {
          this.modalActions({
            modalError:
              process.env.NODE_ENV !== 'development'
                ? 'external_wallet_net_supporting'
                : 'external_wallet_net_supporting_dev',
          });
          return;
        }
      }
      // get the crypto contract address
      const tokenAddress: string =
        // @ts-ignore
        cryptoAddressEtherNet[networkId][this.cryptoTicker];

      // get the gas price and limit
      let gasPrice: any = undefined;
      let gasLimit: any = undefined;
      try {
        gasPrice = await web3.eth.getGasPrice();
        gasLimit = await web3.eth.getBlock('latest');
      } catch (e) {
        console.error(e);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      ////////////
      //// check balance feature !!!!!
      ///////////

      /// get user wallet address
      let walletAddress: any = '';
      try {
        const walletAddress_ = await web3.eth.getAccounts();
        walletAddress = walletAddress_[0];
        this.$store.state.userWallet = walletAddress;
      } catch (e) {
        console.error(e);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      /// get nonce
      let nonce = 0;
      try {
        nonce = await web3.eth.getTransactionCount(
          this.$store.state.userWallet,
        );
      } catch (e) {
        console.error(e);
        this.modalActions({
          modalError: 'external_wallet_error_walletconnect_provider',
        });
        return;
      }

      // create contract
      const estimateGas = await web3.eth.estimateGas({
        from: walletAddress,
        data: web3.utils.toHex(this.orderId),
        to: this.address,
      });

      if (this.cryptoTicker !== 'ETH') {
        // @ts-ignore
        const contract = new web3.eth.Contract(
          // @ts-ignore
          abi,
          tokenAddress,
          // '0xaD6D458402F60fD3Bd25163575031ACDce07538D',
          {
            from: walletAddress,
          },
        );
        /////////////

        const d = await contract.methods
          .transfer(walletAddress, this.address)
          .estimateGas({ from: walletAddress });
        console.log(d, estimateGas);

        const transaction = new Promise((resolve, reject) => {
          contract.methods
            .transfer(
              this.address,
              // @ts-ignore
              web3.utils.toHex(web3.utils.toWei(this.amount.toString())),
            )
            .send(
              {
                from: walletAddress,
                // @ts-ignore
                gasPrice: web3.utils.toHex(gasPrice.toString()),
                // @ts-ignore
                gas: web3.utils.toHex(estimateGas.toString()),
                // @ts-ignore
                data: web3.utils.toHex(this.orderId),
              },
              // @ts-ignore
              function (error, result) {
                //get callback from function which is your transaction key
                if (!error) {
                  resolve(result);
                } else {
                  reject(error);
                }
              },
            );
        });
        if (transaction != null) {
          this.walletButton = false;
          this.showModal = false;
          this.modalLoader = false;
          // redirectViewString(Routes.PurchaseSuccess);
          return;
        } else {
          this.modalActions({
            modalError: 'external_wallet_error_walletconnect_provider',
          });
          return;
        }
      } else {
        // transaction in ETH

        const transactionParameters = {
          nonce: nonce.toString(),
          gasPrice: web3.utils.toHex(gasPrice.toString()),
          gas: web3.utils.toHex(estimateGas.toString()),
          // to: '0x9177a64f1A6CB1a07062B9a554c26A91749A3d08',
          to: this.address,
          from: walletAddress,
          value: web3.utils.toHex(
            web3.utils.toWei(this.amount.toString(), 'ether'),
          ),
          // data: web3.utils.toHex(this.orderId),
          chainId: web3.utils.toHex(networkId.toString()),
          input: web3.utils.toHex(this.orderId),
          data: web3.utils.toHex(this.orderId),
        };

        try {
          const txHash = await (window as any).ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
          });
          // redirectViewString(Routes.PurchaseSuccess);
        } catch (e : any) {
          console.error(e.messages);
          this.modalActions({
            modalError: 'external_wallet_error_walletconnect_provider',
          });
          return;
        }
      }
      this.walletButton = false;
      this.showModal = false;
      this.modalLoader = false;
      return true;
    },
    showModalWallet() {
      this.showModal = true;
    },
    hideModalWallet(event: any) {
      if (event.target.className === 'modal_wrapper') {
        this.showModal = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    //this.$store.commit('setPreviousPage', 'Checkout');

    this.$store.state.giftCardList.forEach((i: any) => {
      if (i.productId == this.$store.state.selectedGiftCardId) {
        this.currentCard = i;
      }
    });
    await this.checkKyc();
    this.kycInterval = setInterval(async () => {
      this.checkKyc();
    }, 3000);
    this.selectedPrice = this.$store.state.selectedGiftCardValue;

    if (this.canPay && !this.txStarted) {
      // @ts-ignore
      await this.createPhazeOrder();
    }
    this.loading = false;
    this.loadedFirstTime = true;
  },
  beforeDestroy(): void {
    this.messages = [];
    if (this.kycInterval) {
      clearInterval(this.kycInterval);
    }
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
    if (this.checkTxInterval) {
      clearInterval(this.checkTxInterval);
    }
    if (this.kycStartedInterval) clearInterval(this.kycStartedInterval);
  },
});
</script>

<style lang="scss" scoped>
.MBMWidget_Phaze_Card_List_Expired {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #fff;
  z-index: 9999;
}
.paragraph {
  color: #a3b0bf;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}
.section {
  margin-top: 10px;
  > span {
    color: #a3b0bf;
    font-size: 16px;
    font-weight: 500;
  }
}
@media screen and (min-width: 500px) {
  .section {
    margin-top: 10px;
  }
}
.MBWidget_footer_actions_wallet {
  padding-bottom: 60px !important;
}
.MBWidget_footer_actions_qr {
  padding-bottom: 0px !important;
  margin-bottom: -30px !important;
}

.qrCode {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;

  &:disabled {
    background-color: transparent;
  }
}
textarea:focus,
input:focus {
  outline: none;
}
.time-remaining {
  color: #a3b0bf;
  font-size: 16px;
  font-weight: 500;
}
.separator {
  border: 1px solid #a3b0bf;
  opacity: 0.4;
  margin-top: 10px;
}

.flex-layout {
  column-gap: 20px;
  display: flex;
}

.flex-layout > * {
  flex: 1;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.MBMWidget_Phaze_Card_List_Expired_Icon {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  border: 10px solid #EB5757;
  margin: 165px auto 30px;
}
.MBMWidget_Phaze_Card_List_Expired_Icon svg {
  margin: 27px;
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
}
.MBMWidget_Phaze_Card_List_Expired p {
  text-align: center;
  margin: 10px 0;
}
</style>
