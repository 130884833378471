import { IBasketOrderItem } from '../interfaces/IBasketOrderItem';

export const createOrderListFromBasket = (basketItems: any[]) => {
    let orderList: IBasketOrderItem[] = [];

    basketItems.map((x: any) => {
        orderList = [
            ...orderList,
            {
                deliveryCategory: 2, // Gift card
                productCode: x.giftCardInf.productId,
                amount: x.selectedPrice,
                quantity: x.quantity
            }
        ];
    });

    return orderList;
}