<template>
  <div class="MBMWidget_Profile_Parent MBMWidget_mx-auto">
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center MBMWidget_mt-4" v-if="loading">
      {{$t('loading')}}
    </div>
    <div v-if="!loading" class="MBMWidget_Profile_Parent">
      <Profile @routeNext="routeNext" :canPay="canPay"></Profile>
    </div>
  </div>
</template>
<script lang="ts">
  import {Routes} from '@/store/modules/Router';
  import Vue from 'vue';
  import Button from '../General/Button.vue';
  import {redirectViewString} from "@/utils";
  import Profile from '@/components/Profile/Profile.vue';
  import {checkKycVerified} from "@/api";

  export default Vue.extend({
    props: ['showIframe'],
    components: {
      Profile: Profile
    },
    data() {
      return {
        canPay: true,
        loading: true
      };
    },
    methods: {
      
      routeNext(url: any){
        if(url == 'PerformKYC' && !this.canPay) {
          this.$store.commit('router/goTo', Routes.Kyc);
        }  else if(this.$store.state.order.uuid != '') {
            this.$store.commit('router/goTo', Routes.SelectMethod); // crypto order and no payment methods - add card
        } else {
          if(this.$store.state.config.firstPage != 'Login') {
            redirectViewString(this.$store.state.config.firstPage); // no order (shouldn't happen, tho), so start with first page from config
          } else {
            this.$store.commit('router/goTo', Routes.Exchange); // default to exchange, while we don't think of smth better
          }
        }
      },
     
    },
    async mounted() {
      this.$store.commit('setPreviousPage', 'ProfileCryptoFlow');
      console.log("- " + this.canPay);
      if(!this.$store.state.profile.kycVerified){
        const kyc = await checkKycVerified(this.$store.state.order.uuid);
        if (kyc.data) {
          this.canPay = kyc.data.result.canPay;
          console.log("is " + this.canPay);
        } else {
          console.log("not");
        }
      }
      this.loading = false;
    }
  });
</script>
