import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: '',
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      cardCountry: '',
      minCardYear: new Date().getFullYear(),
      amexCardMask: '#### ###### #####',
      otherCardMask: '#### #### #### ####',
      cardNumberTemp: '',
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
    };
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
  },
  computed: {
    getCardType() {
      const number = this.cardNumber;
      let re = new RegExp('^4');
      if (number.match(re) != null) return 'visa';

      re = new RegExp('^(34|37)');
      if (number.match(re) != null) return 'amex';

      re = new RegExp('^5[1-5]');
      if (number.match(re) != null) return 'mastercard';

      re = new RegExp('^6011');
      if (number.match(re) != null) return 'discover';

      // re = new RegExp('^9792');
      // if (number.match(re) != null) return 'troy';

      re = new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{11}$');
      if (number.match(re) != null) return 'diners';

      re = new RegExp('^(?:2131|1800|35d{3})d{11}$');
      if (number.match(re) != null) return 'jbc';

      return 'visa'; // default type
    },
    generateCardNumberMask(): string {
      return this.getCardType === 'amex'
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (Number(this.cardYear) === Number(this.minCardYear))
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  
});
