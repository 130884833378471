<template>
  <div class="MBMWidget_Phaze_Card_Summary MBMWidget_mx-auto MBWidget_block">
    <div
      class="
        MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4
        custom_title
      "
    >
      {{ $t('phaze_shopping_cart_title') }}
    </div>
    <div v-if="isMounted" class="content_div shopping_cart_list">
      <div
        class="
          MBMWidget_col-np-12 MBMWidget_col-np-xs-12
          custom_shopping_cart_response
        "
        v-for="x in shoppingCartItems"
        v-bind:key="x.productId"
      >
        <div class="_img">
          <img class="img_style" :src="x.giftCardInf.productImage" />
        </div>
        <div class="title_and_description">
          <div class="_grid_col_2">
            <div class="futura_18_bold">
              {{ x.giftCardInf.name }}
            </div>
            <img
              @click="removeGroupOfItemsFromShoppingCart(x)"
              class="delete_icon"
              src="../../img/delete.png"
              alt="cart"
            />
          </div>
          <div class="_quantity">
            <div class="buttons">
              <img
                @click="removeItemFromShoppingCart(x)"
                class="button_icon"
                src="../../img/minus.png"
                alt="cart"
              />
              <div class="futura_11_normal">
                {{ x.quantity }}
              </div>
              <img
                @click="addItemToShoppingCart(x)"
                class="button_icon"
                src="../../img/plus.png"
                alt="cart"
              />
            </div>
          </div>
          <div class="_amount">
            <cool-select
              v-if="x.cardVersion == 1"
              class="custom_selector_style"
              v-model="x.selectedPrice"
              @select="changePrice(x)"
              :items="[...x.giftCardInf.denominations]"
              :menuItemsMaxHeight="'100px'"
            >
              <template slot="item" slot-scope="{ item }">
                <b>
                  {{ x.currencySymbol }}{{ item }}
                  {{ x.giftCardInf.currency }}
                </b>
              </template>

              <template slot="selection" slot-scope="{ item }">
                <b>
                  {{ x.currencySymbol }}{{ item }}
                  {{ x.giftCardInf.currency }}
                </b>
              </template>

              <template slot="input-end">
                <ChevronDownIcon :size="20" fillColor="#333" />
              </template>
            </cool-select>
            <div
              v-if="x.cardVersion == 1"
              class="
                MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0
                error_message
              "
            >
              <span v-if="!x.selectedPrice">
                {{ $t('phaze_error_mesages_value_is_required') }}
              </span>
            </div>
            <AppInputSimple
              v-if="x.cardVersion == 2"
              v-model="x.selectedPrice"
              type="number"
              class="custom_input_style"
              :min="x.giftCardInf.minPriceVal"
              :max="x.giftCardInf.maxPriceVal"
              @input="updateAmountInOrderItem(x, $event)"
            />
            <div
              class="
                MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0
                error_message
              "
            >
              <span v-if="x.errorMessage">
                {{ x.errorMessage }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="shopping_action_buttons MBWidget-nofloat">
        <AppButton
          class="
            button
            futura_16_bold
            keep_shopping_bottom
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Phaze_Card_List_Next
          "
          :disabled="disableButtons || itemsInBasketWithNoValue > 0"
          @click="redirectToCardList"
        >
          {{ $t('phaze_buttons_keep_shopping') }}
        </AppButton>
        <AppButton
          color="primary"
          class="
            checkout_button
            button
            futura_16_bold
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Phaze_Card_List_Next
          "
          @click="redirectTocheckout"
          :disabled="
            !this.$store.state.selectedGiftCardsCart.length ||
            disableButtons ||
            itemsInBasketWithNoValue > 0
          "
        >
          {{ $t('phaze_buttons_checkout') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import store from '@/store';
// @ts-ignore
import { mapGetters } from 'vuex';
import { CoolSelect } from 'vue-cool-select';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import Button from '../General/Button.vue';
import { redirectViewString } from '@/utils';
import { Routes } from '@/store/modules/Router';
import { createOrderListFromBasket } from '../Common/helpers/BasketOrderHelpers';
import { createBasketOrderService, updateBasketOrderService } from '@/api';
import Input from '../General/InputSimple.vue';

export default Vue.extend({
  components: {
    AppButton: Button,
    AppInputSimple: Input,
    CoolSelect: CoolSelect,
    ChevronDownIcon: ChevronDownIcon,
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    itemsInBasketWithNoValue() {
      let count = 0;

      this.$store.state.selectedGiftCardsCart?.filter(
        (x: any) => !x.selectedPrice && count++,
      );

      return count;
    },
  },
  data() {
    return {
      store,
      isMounted: false,
      selectedPrice: 1,
      disableButtons: false,
      //
      shoppingCartItems: [] as any[],
    };
  },
  methods: {
    //#region __ Shopping cart methods __

    addItemToShoppingCart(item: any) {
      const shoppingCartItems: any[] = this.shoppingCartItems;

      // Find the order group index
      const index = this.findIndexHelper(shoppingCartItems, item);

      // Update quantity
      shoppingCartItems[index].quantity = shoppingCartItems[index].quantity + 1;

      // Update data
      this.updateShoppingCartHelper(shoppingCartItems);
    },

    changePrice(item: any) {
      let shoppingCartItems: any[] = this.shoppingCartItems;
      let quantity = 0;

      // Filter order groups with the same params
      const foundedItems = this.filterItemsHelper(shoppingCartItems, item);

      // Verify if multiple order groups
      if (foundedItems.length > 1) {
        // Remove upgrated order group
        shoppingCartItems = this.removeItemsWithQuantityHelper(
          shoppingCartItems,
          item,
        );

        // Copy the quantity from the deleted order group
        quantity = item.quantity;
      }

      // Find the order group index
      const index = this.findIndexHelper(shoppingCartItems, item);

      // Upgrade order quantity in existed order group
      shoppingCartItems[index].quantity =
        shoppingCartItems[index].quantity + quantity;

      // Update data
      this.updateShoppingCartHelper(shoppingCartItems);
    },

    updateAmountInOrderItem(item: any, value: number) {
      let shoppingCartItems: any[] = this.shoppingCartItems;

      const { minPriceVal, maxPriceVal } = item.giftCardInf;

      let errorMessage = undefined;

      // Validate selected amount
      if (value < minPriceVal) {
        errorMessage = this.$t(
          'phaze_error_mesages_value_is_too_low',
        ).toString();
      }
      if (value > maxPriceVal) {
        errorMessage = this.$t(
          'phaze_error_mesages_value_is_too_high',
        ).toString();
      }

      let quantity = 0;

      // Find the order group index
      const index = this.findIndexHelper(shoppingCartItems, item);

      // Update error message in order group
      shoppingCartItems[index].errorMessage = errorMessage;

      // Check if error exist
      if (errorMessage) {
        // Update local data
        this.shoppingCartItems = shoppingCartItems;
      } else {
        // Filter order groups with the same params
        const foundedItems = this.filterItemsHelper(shoppingCartItems, item);

        // Verify if multiple order groups
        if (foundedItems.length > 1) {
          // Remove upgrated order group
          shoppingCartItems = this.removeItemsWithQuantityHelper(
            shoppingCartItems,
            item,
          );

          // Copy the quantity from the deleted order group
          quantity = item.quantity;
        }

        // Upgrade order quantity in existed order group
        shoppingCartItems[index].quantity =
          shoppingCartItems[index].quantity + quantity;

        // Update data
        this.updateShoppingCartHelper(shoppingCartItems);
      }

      this.checkIfButtonsShouldBeDisabledHelper();
    },

    removeItemFromShoppingCart(item: any) {
      let shoppingCartItems: any[] = this.shoppingCartItems;

      // Find the order group index
      const index = this.findIndexHelper(shoppingCartItems, item);

      // Check if last item in order group
      if (shoppingCartItems[index].quantity == 1) {
        // Remove item from shopping cart
        shoppingCartItems = this.removeItemsHelper(shoppingCartItems, item);
      } else {
        // Reduce quantity
        shoppingCartItems[index].quantity =
          shoppingCartItems[index].quantity - 1;
      }

      this.updateShoppingCartHelper(shoppingCartItems);
    },

    removeGroupOfItemsFromShoppingCart(item: any) {
      // Remove items from shopping cart
      const shoppingCartItems = this.removeItemsHelper(
        this.shoppingCartItems,
        item,
      );

      this.updateShoppingCartHelper(shoppingCartItems);
    },

    //#endregion __ Shopping cart methods __

    //#region __ Helpers __

    checkIfButtonsShouldBeDisabledHelper() {
      const disableButtons =
        this.shoppingCartItems.filter((x) => x.errorMessage).length > 0;

      this.disableButtons = disableButtons;
    },

    removeItemsHelper(shoppingCartItems: any[], item: any) {
      return shoppingCartItems.filter((x) => {
        return x.giftCardInf.productId == item.giftCardInf.productId &&
          x.selectedPrice == item.selectedPrice
          ? undefined
          : x;
      });
    },

    removeItemsWithQuantityHelper(shoppingCartItems: any[], item: any) {
      // Counter flag - prevent deletion of two objects with identical parameters
      let counter = 0;

      return shoppingCartItems.filter((x) => {
        if (
          x.giftCardInf.productId == item.giftCardInf.productId &&
          x.selectedPrice == item.selectedPrice &&
          x.quantity == item.quantity &&
          counter == 0
        ) {
          // First object omitted
          counter++;

          return;
        } else {
          return x;
        }
      });
    },

    findIndexHelper(shoppingCartItems: any[], item: any) {
      return shoppingCartItems.findIndex(
        (x) =>
          x.giftCardInf.productId == item.giftCardInf.productId &&
          x.selectedPrice == item.selectedPrice,
      );
    },

    filterItemsHelper(shoppingCartItems: any[], item: any) {
      return shoppingCartItems.filter(
        (x) =>
          x.giftCardInf.productId == item.giftCardInf.productId &&
          x.selectedPrice == item.selectedPrice,
      );
    },

    updateShoppingCartHelper(shoppingCartItems: any) {
      // Update local data
      this.shoppingCartItems = shoppingCartItems;

      // Update data in store
      this.$store.state.selectedGiftCardsCart = shoppingCartItems;
    },

    checkItemsWithErrorMessageOrEmptySelectedPrice() {
      // Filter items without errors
      const selectedGiftCardsCart = this.$store.state.selectedGiftCardsCart.map(
        (x: any) => {
          if (x.errorMessage) {
            // Set minimum prtice
            x.selectedPrice = x.giftCardInf.minPriceVal;

            // Clear error
            x.errorMessage = undefined;
          }

          // Check if the price is selected - for a type 1 card
          if (!x.selectedPrice && x.cardVersion == 1) {
            x.selectedPrice = x.giftCardInf.denominations[0];
          }

          return x;
        },
      );

      // Update data in store
      this.$store.state.selectedGiftCardsCart = selectedGiftCardsCart;
    },

    //#endregion __ Helpers __

    //#region __ Common __

    redirectToCardList() {
      redirectViewString(Routes.PhazeCardList);
    },

    async redirectTocheckout() {
      const selectedGiftCardsCart = this.$store.state.selectedGiftCardsCart;

      let giftCardOrderInf = this.$store.state.giftCardOrderInf;

      try {
        // Crate order list
        const orderList = createOrderListFromBasket(selectedGiftCardsCart);

        // Check if order exist
        if (giftCardOrderInf) {
          await updateBasketOrderService(orderList).then((response) => {
            // Update informations about order
            giftCardOrderInf = response.data.result;

            // Update order inf in store
            this.$store.state.giftCardOrderInf = giftCardOrderInf;

            this.$store.commit('phazeOrder', {
              uuid: giftCardOrderInf.orderUUID,
              selectedCurrency: giftCardOrderInf.selectedCurrency,
              selectedCryptoCurrency: giftCardOrderInf.selectedCryptoCurrency,
              price: giftCardOrderInf.price,
            });

            // Change view
            redirectViewString(Routes.Checkout);

            this.$store.commit('router/goTo', Routes.Checkout);
          });
        } else {
          await createBasketOrderService(orderList).then((response) => {
            // Add informations about order
            giftCardOrderInf = response.data.result;

            // Update order inf in store
            this.$store.state.giftCardOrderInf = giftCardOrderInf;

            this.$store.commit('phazeOrder', {
              uuid: giftCardOrderInf.orderUUID,
              selectedCurrency: giftCardOrderInf.selectedCurrency,
              selectedCryptoCurrency: giftCardOrderInf.selectedCryptoCurrency,
              price: giftCardOrderInf.price,
            });

            // Change view
            redirectViewString(Routes.Checkout);

            this.$store.commit('router/goTo', Routes.Checkout);
          });
        }
      } catch (e) {
        // TODO: Add error inf
        console.log('Error orderCheckout()', e);
      }
    },

    //#endregion __ Common __
  },
  async mounted() {
    // Set prev page
    this.$store.commit('setPreviousPage', 'PhazeCardShoppingCart');

    // Hide menu button
    this.$store.state.hideMenuButton = true;

    // Init shopping cart items
    this.shoppingCartItems = this.$store.state.selectedGiftCardsCart.map(
      (x: any) => {
        if (x.cardVersion == 2) {
          x.selectedPrice = parseFloat(x.selectedPrice).toFixed(2);
        }

        return x;
      },
    );

    this.isMounted = true;

    this.checkIfButtonsShouldBeDisabledHelper();
  },
  beforeDestroy() {
    // Show menu button
    this.$store.state.hideMenuButton = false;

    this.checkItemsWithErrorMessageOrEmptySelectedPrice();
  },
});
</script>

<style scoped>
.error_message {
  color: red;
}
.content_div {
  padding: 20px;
}

.shopping_cart_list {
  height: 348px;
  overflow-x: hidden;
  overflow-y: auto;
}

.shopping_action_buttons {
  display: flex;
}

.keep_shopping_bottom {
  margin-right: 20px;
  color: #f19a53;
  background: #fef5ea;
}

.keep_shopping_bottom:hover {
  color: #f19a53;
  background: #fef5ea;
}
</style>