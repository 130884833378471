<template>
  <div class="MBMWidget_GeneralCheckbox">
    <label class="checkbox_label">
      <input
        class="MBMWidget_pb-4 checkbox_input"
        type="checkbox"
        :disabled="disabled ? disabled : false"
        v-bind="$attrs"
        @change="$emit('input', $event.target.checked)"
      />
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <p>
    <slot name="text">
      
    </slot>
    </p>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    label: String,
    text : String,
    disabled: Boolean
  },
});
</script>
