<template>
  <div class="MBWidget_Add_Remove_Card_Container">
    <div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBMWidget_mt-4">
      
        <div class="MBWidget_Add_Remove_Card_Block" :class="getClass()">
          <div class="MBWidget_Add_Remove_Card_Block_Remove_Icon">
            <TrashCanOutlineIcon :size="50" fillColor="#EB5757"></TrashCanOutlineIcon>
          </div>
          <div class="MBWidget_Add_Remove_Card_Block_Add_Icon">
            <CheckIcon :size="50" fillColor="#0BB07E"></CheckIcon>
          </div>
          
          <div class="MBMWidget_col-xs-8 MBMWidget_col-8 MBMWidget_mx-auto MBWidget-nofloat MBMWidget_pt-75">
            <p class="MBWidget_Add_Remove_Card_Title">{{$t(getText()[0])}}</p>
            <p class="MBWidget_Add_Remove_Card_Description">{{$t(getText()[1])}} <span v-if="!store.state.addCard">{{store.state.card.hiddenCardNumber}}</span></p>
          </div>
      </div>
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
      <ErrorMessage></ErrorMessage>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget-nofloat MBMWidget_mx-auto MBMWidget_mb-4">

        <!--<div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBWidget_Add_Remove_Card_Cancel">
          <AppButton
            :disabled="loading"
            class="MBMWidget_full-width MBWidget_Button_Plain MBMWidget_Add_Remove_Card_Cancel"
            @click="cancel()"
          >
            {{ $t('button_cancel') }}
          </AppButton>
        </div>-->
        <div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBWidget_Add_Remove_Card_Submit">
          <AppButton
            :color="'primary'"
            :disabled="loading"
            class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Add_Remove_Card_Button"
            @click="performAction()"
          >
            {{ $t(getText()[2]) }}
          </AppButton>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center MBWidget-nofloat MBMWidget_mx-auto">
          <a class="MBWidget_Add_Card_back"
             v-if="!store.state.addCard"           
             @click.prevent="goBack()"
          >
            {{$t('delete_card_delete_cancel_button')}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import {redirectViewString} from "@/utils";
  import Button from '../General/Button.vue';
  import store from "@/store";
  import {addPaymentMethod, deleteCard, getCardsForUser, updatePaymentMethod} from "@/api";
  import {Routes} from "@/store/modules/Router";
  import ErrorMessage from "@/components/Components/ErrorMessage.vue";
  import TrashCanOutlineIcon from "vue-material-design-icons/TrashCanOutline.vue";
  import CheckIcon from "vue-material-design-icons/Check.vue";


  export default Vue.extend({
    components: {
      AppButton: Button,
      ErrorMessage: ErrorMessage,
      TrashCanOutlineIcon: TrashCanOutlineIcon,
      CheckIcon: CheckIcon
    },
    data () {
      return {
        store,
        loading: false,
        card: null,
        error: false
      }
    },
    methods: {
      cancel() {
        this.store.state.popup = '';
      },
      async performAction(){
        this.loading = true;
        let response = null;
        if(this.$store.state.addCard){
          if(this.$store.state.editUnsaved)
            this.$store.state.unsavedCard = null;
          this.loading = false;
          this.store.state.popup = '';

          this.$store.commit('router/goTo', Routes.CardList);
          return;
        } else {
          if(this.$store.state.card.cardId == -2) {
            this.$store.state.unsavedCard = null;
          } else {
            response = await deleteCard();  
          }
          const cards = await getCardsForUser(this.$store.state.profile.userUUid);
        }
        if(response != null) {
            const errors = response.data.errors;
  
            if (errors.length) {
              this.$store.commit('personalizedErrors', errors);
              this.loading = false;
              this.store.state.popup = '';
              return;
            }
            this.$store.commit('personalizedErrors', []);
          }
        //this.store.state.backPage = '';
        if(this.$store.state.editUnsaved)
          this.$store.state.unsavedCard = null;


        this.$store.state.pageQueue.pop();
        this.loading = false;
        this.store.state.popup = '';
        
        this.$store.commit('router/goTo', Routes.CardList);
        
      },
      goBack(){
        this.$store.commit('personalizedErrors', []);
        if(this.$store.state.editUnsaved)
          this.$store.state.unsavedCard = null;
        this.loading = false;
        this.store.state.popup = '';
        this.$store.state.pageQueue.pop();
        this.$store.commit('router/goTo', Routes.CardList);
      },
      getClass() : string {
        return this.$store.state.addCard ? 'MBWidget_Add_Remove_Card_Block_Add' : 'MBWidget_Add_Remove_Card_Block_Remove';
      },
      getText() : any[] {
        if(this.$store.state.addCard) {
          if(this.$store.state.editSaved){
            return [
              'add_card_edit_confirm_title',
              'add_card_edit_subtitle',
              'add_card_edit_add_button'
            ]
          } else {
            return [
              'add_card_title',
              'add_card_subtitle',
              'add_card_add_button'
            ]
          }
        } else {
          return [
            'delete_card_title',
            'delete_card_subtitle',
            'delete_card_delete_button'
          ]
        }
      }
      
    },
    beforeDestroy(): void {
      this.loading = false;
    }
  });
</script>

<style scoped>

</style>