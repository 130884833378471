<template>
  <div class="MBMWidget_Phaze_Card_Checkout MBMWidget_mx-auto MBWidget_block">
    <div
      class="
        MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4
      "
    >
      {{ $t('phaze_card_list_title') }}
    </div>
    <div
      class="
        MBMWidget_col-np-12
        MBMWidget_col-np-xs-12
        MBMWidget_Loading
        MBMWidget_text-center
      "
      v-if="loading"
    >
      {{ $t('loading') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
      <ErrorMessage></ErrorMessage>
    </div>

    <div
      class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12"
      v-if="currentCard != null"
    >
      <div
        class="MBMWidget_KYC_Notice_Button"
        @click="store.commit('router/goTo', Routes.Kyc)"
        v-if="!kycStarted"
      >
        {{ $t('perform_kyc_general_button') }}
      </div>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Phaze_Card_Checkout_KYC
          MBMWidget_mb-3
        "
        v-if="!kycVerified && loadedFirstTime"
      >
        <span
          ><strong>{{ $t('purchase_summary_kyc_title') }}</strong></span
        >
        <span>{{ $t('purchase_summary_kyc_description') }}</span>
      </div>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Phaze_Card_Checkout_Title
          MBMWidget_mb-4
        "
      >
        <span class="MBMWidget_Phaze_Card_Checkout_Selected_Title"
          >{{ currentCard.name
          }}<span
            ><span class="MBMWidget_Phaze_Card_List_symbol">{{
              getFiatSymbol(currentCard.currency)
            }}</span>
            {{ Number(selectedPrice).toFixed(2) }}</span
          ></span
        >
        <span
          class="MBMWidget_Phaze_Card_Checkout_Image"
          :style="'background-image: url(' + currentCard.productImage + ')'"
        ></span>
      </div>

      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Phaze_Card_Checkout_Subtitle
          MBMWidget_mt-2
          MBMWidget_pb-0
        "
      >
        {{ $t('phaze_card_checkout_summary') }}
      </div>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Phaze_Card_Checkout_Total
          MBMWidget_my-4
          MBMWidget_py-4
        "
      >
        <div
          class="
            MBMWidget_col-np-6
            MBMWidget_col-np-xs-6
            MBMWidget_Phaze_Card_Checkout_Total_Title
          "
        >
          {{ $t('phaze_card_checkout_total') }}
        </div>
        <div
          class="
            MBMWidget_col-np-6
            MBMWidget_col-np-xs-6
            MBMWidget_Phaze_Card_Checkout_Total_Price
          "
        >
          <span class="MBMWidget_Phaze_Card_List_symbol">{{
            getFiatSymbol(currentCard.currency)
          }}</span>
          {{ Number(selectedPrice).toFixed(2) }}
        </div>
      </div>
      <div
        class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_mb-4"
        v-if="kycVerified && !loading"
      >
        <div
          class="
            MBMWidget_col-np-8
            MBMWidget_col-np-md-6
            MBMWidget_col-np-xs-4
            MBMWidget_Phaze_Card_Checkout_Payment_Title
          "
        >
          {{ $t('phaze_card_checkout_payment_method') }}
        </div>
        <div
          class="
            MBMWidget_col-np-4
            MBMWidget_col-np-md-6
            MBMWidget_col-np-xs-8
            MBMWidget_py-0
            MBMWidget_Phaze_Card_Checkout_Payment_Block
          "
        >
          <div
            class="
              MBMWidget_col-np-12
              MBMWidget_col-np-md-12
              MBMWidget_col-np-xs-12
              MBMWidget_Phaze_Card_Checkout_Card
              MBMWidget_pt-0
            "
            v-if="card"
          >
            <span class="MBMWidget_Phaze_Card_Checkout_Card_Title"
              >{{ $t('payment_method') }}
              <a @click.prevent="changeCard()"
                ><PencilOutline
                  title="Edit"
                  :size="16"
                  fillColor="#333"
                ></PencilOutline></a
            ></span>
            <span class="MBMWidget_Phaze_Card_Checkout_Card_Name"
              >{{ $t('card_name') }}
              <span>{{ card.firstName }} {{ card.lastName }}</span></span
            >
            <span class="MBMWidget_Phaze_Card_Checkout_Card_Number"
              >{{ $t('card_card') }}
              <span
                ><span
                  class="
                    MBMWidget_card_mastercard
                    MBMWidget_Phaze_Card_Checkout_Card_Icon
                  "
                ></span
                >***{{
                  card.hiddenCardNumber.substr(card.hiddenCardNumber.length - 4)
                }}</span
              ></span
            >
            <span class="MBMWidget_Phaze_Card_Checkout_Card_Expiry"
              >{{ $t('card_expire') }} <span>{{ card.expiry }}</span></span
            >
            <span class="MBMWidget_Phaze_Card_Checkout_Card_CVV"
              >{{ $t('card_cvv') }}
              <span>
                <form @submit.prevent>
                  <AppInput
                    class="MBMWidget_Login_AppInputPassword"
                    v-model="CVV"
                    required
                    ref="CVV"
                    :focus="true"
                    type="password"
                  />
                </form>
              </span>
            </span>
          </div>
          <div
            class="
              MBMWidget_col-np-12
              MBMWidget_col-np-md-12
              MBMWidget_col-np-xs-12
              MBMWidget_Phaze_Card_Checkout_Card
              MBMWidget_pt-0
            "
            v-if="!card && kycVerified && !crypto"
          >
            <span class="MBMWidget_Phaze_Card_Checkout_Card_Title"
              >{{ $t('payment_method') }}
              <a @click.prevent="store.commit('router/goTo', Routes.CardList)"
                ><PencilOutline
                  title="Edit"
                  :size="16"
                  fillColor="#333"
                ></PencilOutline></a
            ></span>
            <span
              class="MBMWidget_Phaze_Card_Checkout_Card_Add_Card"
              @click="store.commit('router/goTo', Routes.CardList)"
            >
              {{ $t('add_payment_method') }}
            </span>
          </div>
          <div
            class="
              MBMWidget_col-np-12
              MBMWidget_col-np-md-12
              MBMWidget_col-np-xs-12
              MBMWidget_Phaze_Card_Checkout_Crypto
              MBMWidget_pt-0
            "
            v-if="crypto"
          >
            <span
              ><span
                class="fiat_select_icon_symbol"
                :class="
                  'MBMWidget_' +
                  getCrypto(store.state.selectedPhazeCurrency).ticker
                "
                style="
                  background-image: url('https://s3.eu-west-1.amazonaws.com/com.mobilum.public/cryptoIcons_v2.png');
                "
              ></span
              >{{ getCrypto(store.state.selectedPhazeCurrency).name }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_mb-4
          MBMWidget_Phaze_Card_Checkout_Crypto_Notice
        "
        v-if="crypto && !aquaTx"
      >
        <span>{{ $t('phaze_card_checkout_instruction') }}</span>
      </div>
      <div
        class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_mb-4
          MBMWidget_Phaze_Card_Checkout_Crypto_Wallet
        "
        v-if="aquaTx"
      >
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Notice">{{
          $t('phaze_card_checkout_payment_instruction')
        }}</span>
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Address"
          >{{ $t('phaze_card_checkout_wallet')
          }}<span>{{ aquaTx.depositAddress }}</span></span
        >
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Amount"
          >{{ $t('phaze_card_checkout_amount')
          }}<span
            >{{ aquaTx.expectedAmount }}
            {{ store.state.selectedPhazeCurrency }}</span
          ></span
        >
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Amount"
          >{{ $t('phaze_card_checkout_amount_left')
          }}<span
            >{{ amountLeft }} {{ store.state.selectedPhazeCurrency }}</span
          ></span
        >
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Date"
          >{{ $t('phaze_card_checkout_time_left')
          }}<span>{{ formatTime(timeLeft) }}</span></span
        >
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Notice">{{
          $t('phaze_card_checkout_payment_instruction_footer')
        }}</span>
        <span class="MBMWidget_Phaze_Card_Checkout_Crypto_Wallet_Notice">
          {{
            $t(
              'phaze_card_checkout_payment_instruction_buy1',
              getCryptoCurrency(),
            )
          }}
          <a href="https://lp.mobilum.com/buy" target="_blank"
            >lp.mobilum.com</a
          >
          {{
            $t(
              'phaze_card_checkout_payment_instruction_buy2',
              getCryptoCurrency(),
            )
          }}
        </span>
      </div>
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_mb-4">
        <AppCheckbox
          v-model="agreeCheck"
          :disabled="checkDisabled || !kycVerified"
          class="
            MBMWidget_body-2
            MBMWidget_defaultAppCheckbox
            MBMWidget_cursor-pointer
            MBWidget_multiline_checkbox
            custom_checkbox
          "
        >
          <template #label>
            {{ $t('phaze_card_checkout_agree_terms') }}
            <a @click="showIframe('terms_and_conditions')" target="_blank">{{
              $t('card_screen_terms_and_conditions')
            }}</a>
            &
            <a @click="showIframe('privacy_policy')" target="_blank"
              >{{ $t('card_screen_privacy_policy') }}.</a
            >
          </template>
        </AppCheckbox>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0">
        <AppSnack :messages="messages" :colors="colors" />
      </div>
    </div>

    <div
      class="
        MBMWidget_col-12 MBMWidget_col-xs-12
        MBWidget_footer_actions
        MBMWidget_px-0 MBMWidget_full-width
      "
    >
      <div class="MBMWidget_col-6 MBMWidget_col-md-6 MBMWidget_col-xs-12">
        <AppButton
          :disabled="complete || loadingPayment || loading"
          color="accent"
          class="
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Purchase_Summary_AppButtonSummary
          "
          @click="store.commit('router/goTo', Routes.PhazeCardSummary)"
        >
          {{ $t('purchase_summary_edit_button') }}
        </AppButton>
      </div>
      <div class="MBMWidget_col-6 MBMWidget_col-md-6 MBMWidget_col-xs-12">
        <AppButton
          :disabled="
            loading ||
            ((CVV == '' || CVV.length != 3) && !crypto) ||
            complete ||
            loadingPayment ||
            !agreeCheck ||
            !kycVerified
          "
          color="primary"
          class="
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Phaze_Card_Checkout_Next
          "
          @click="createPhazeOrder()"
        >
          {{ $t('phaze_card_checkout_button') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import store from '@/store';
import Checkbox from '@/components/General/Checkbox.vue';
import Button from '../General/Button.vue';
import Input from '../General/Input.vue';
import PencilOutlineIcon from 'vue-material-design-icons/PencilOutline.vue';
import { Routes } from '@/store/modules/Router';
import {
  checkKycVerified,
  getPhazeOrder,
  processPaymentNewAqua,
  uploadScreenshot,
  CheckTransactionAqua,
  shouldPerformKyc,
} from '@/api';
import PaymentProcessor from '@/components/CardScreen/PaymentProcessor';
import Snack from '@/components/General/Snack.vue';
import ErrorMessage from '@/components/Components/ErrorMessage.vue';
import CurrencyList from '@/components/Components/CurrencyList.json';
import { zeroPad } from '@/utils';
export default PaymentProcessor.extend({
  components: {
    PencilOutline: PencilOutlineIcon,
    AppButton: Button,
    AppInput: Input,
    AppSnack: Snack,
    ErrorMessage: ErrorMessage,
    AppCheckbox: Checkbox,
  },
  data() {
    return {
      store,
      Routes,
      loading: true,
      currentCard: null,
      selectedPrice: '',
      card: null,
      CVV: '',
      unsavedCard: false,
      kycInterval: null as any,
      checkTxInterval: null as any,
      timeInterval: null as any,
      kycVerified: false,
      crypto: false,
      aquaTx: null as object | null,
      timeLeft: 0,
      checkDisabled: false,
      purchaseId: '',
      amountLeft: '',
      kycStartedInterval: null as any,
      kycStarted: true,
      loadedFirstTime: false,
    };
  },
  methods: {
    getCryptoCurrency() {
      return {
        cryptoCurrency: this.$store.state.selectedPhazeCurrency,
      };
    },
    getCrypto(ticker: string) {
      let result = {};
        this.$store.state.config.cryptoCurrencies.forEach((i: any) => {
          if(i.ticker == ticker) {

            result = {
              name: i.name,
              ticker: i.ticker
            };
          }
        });
      return result;
    },
    formatTime(time: number) {
      if(time < 0) return '00:00';

      const min = Math.floor(time / 60);
      const sec = time - min * 60;
      return zeroPad(min, 2)+':'+zeroPad(sec, 2);
    },
    async createPhazeOrder() {
      this.processPayment3DS(this.$store.state.phazeOrder.uuid);
    },
    async checkTx() {
      const response = await CheckTransactionAqua(this.purchaseId);

      if (response.data.result && response.data.result.amount) {
        if (Number(response.data.result.amount) <= 0) {
          this.amountLeft = '';
          if (this.checkTxInterval) {
            clearInterval(this.checkTxInterval);
          }
          this.checkTxInterval = setInterval(async () => {
            this.checkDelivery();
          }, 5000);
        } else {
          this.amountLeft = response.data.result.amount;
        }
      }
    },
    async checkDelivery() {
      const response = await getPhazeOrder(this.$store.state.phazeOrder.uuid);

      if (
        response.data.length &&
        response.data[0].voucherInfo != null &&
        response.data[0].voucherInfo != ''
      ) {
        if (this.checkTxInterval) {
          clearInterval(this.checkTxInterval);
        }
        this.routeToSuccess();
      }
    },
    input() {
      console.log();
    },
    changeCard() {
      store.commit('router/goTo', Routes.CardList);
    },
    getFiatSymbol(fiatCode: string) {
      // @ts-ignore
      if (CurrencyList.en[fiatCode] !== undefined) {
        // @ts-ignore
        if (CurrencyList.en[fiatCode] !== undefined) {
          // @ts-ignore
          return CurrencyList.en[fiatCode].symbol;
        }
      }
    },
    routeToSuccess(){
      this.$store.state.currentPhazeOrderUuid = this.$store.state.phazeOrder.uuid;
      this.$store.commit('phazeOrder', { uuid: '' });
      this.$store.state.giftCardList = [];
      this.$store.state.selectedGiftCardId = -1;
      this.$store.state.selectedGiftCardValue = '';
      this.$store.state.giftCardPage = 0;
      this.$store.state.phazeCountries = [];
      this.$store.state.selectedPhazeCountry = '' as string;
      this.$store.state.selectedPhazeCurrency = '' as string;

      setTimeout(() => {
        this.$store.state.phazePurchaseSuccess = true;
        this.$store.commit('router/goTo', 'PhazeCardPurchased');
      }, 2000)
    },
    async checkKyc() {
      const kyc = await checkKycVerified(this.$store.state.phazeOrder.uuid);
      if (kyc.data) {
        if(kyc.data.result.waitForKyc){
          this.kycVerified = false;
        }
      }
    },
    async shouldPerformKyc() {
      const kyc = await shouldPerformKyc();
      console.log('KYC data::', kyc, kyc.data);
      if (kyc.data != null) {
        if (kyc.data == true) {
          this.kycStarted = false;
        } else {
          this.kycStarted = true;
          if (this.kycStartedInterval) {
            clearInterval(this.kycStartedInterval);
          }
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.$store.commit('setPreviousPage', 'PhazeCardCheckout');
    this.crypto = this.$store.state.selectedPhazeCurrency != 'Card';
    this.shouldPerformKyc();
    this.kycStartedInterval = setInterval(async () => {
      this.shouldPerformKyc();
    }, 3000);
    if (!this.crypto) this.setCard();
    this.$store.state.giftCardList.forEach((i: any) => {
      if (i.productId == this.$store.state.selectedGiftCardId) {
        this.currentCard = i;
      }
    });
    await this.checkKyc();
    this.kycInterval = setInterval(async () => {
      this.checkKyc();
    }, 3000);
    this.selectedPrice = this.$store.state.selectedGiftCardValue;

    const i: InstanceType<typeof Input> = this.$refs['CVV'] as InstanceType<
      typeof Input
    >;
    if (i !== undefined) {
      // @ts-ignore
      i.doFocus(true);
    }
    this.loading = false;
    this.loadedFirstTime = true;
  },
  beforeDestroy(): void {
    this.messages = [];
    if (this.kycInterval) {
      clearInterval(this.kycInterval);
    }
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
    if (this.checkTxInterval) {
      clearInterval(this.checkTxInterval);
    }
    if (this.kycStartedInterval) clearInterval(this.kycStartedInterval);
  },
});
</script>

<style scoped>
</style>