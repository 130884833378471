<template>
  <div class="MBMWidget_Phaze_Card_Success MBMWidget_mx-auto MBWidget_block">
    
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Phaze_Card_Success_Block">
     <img src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/giftcard-success-icon.png">
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 ">
        <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
          {{ $t('phaze_card_purchased_title') }}
        </div>
        <div class="MBMWidget_subtitle MBMWidget_text-center">
          {{ $t('phaze_card_purchased_subtitle') }}
        </div>
      </div>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          class="MBMWidget_full-width MBMWidget_defaultAppButton"
          color="primary"
          @click="buyMore"
        >
          {{ $t('phaze_card_purchased_button') }}
        </AppButton>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import store from "@/store";
  import Button from "@/components/General/Button.vue";

  export default Vue.extend({
    components: {
      AppButton: Button,
    },
    data() {
      return {
        store,
        loading: false,
        
      }
    },
    methods: {
      buyMore() {
        this.$store.commit('router/goTo', 'PhazeCardList');
      },
    },
    mounted() {
      this.$store.state.hideBackButton = true;
      this.$store.state.hideMenuButton = true;
      this.$store.state.pageQueue = [] as string[];
      this.$store.commit('setPreviousPage', 'PhazeCardList');
      this.$store.commit('setPreviousPage', 'PhazeCardList');
    },
    beforeDestroy() {
      this.$store.state.hideBackButton = false;
      this.$store.state.hideMenuButton = false;
    }
  });
</script>

<style scoped>
  
</style>