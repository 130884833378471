<template>
  <div class="MBMWidget_Select-Method MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_title MBMWidget_Header">
      {{ $t('card_list_title') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4"></div>
    
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Select-Method-Wrap">
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBMWidget_Select-Method-Item" @click="goTo('CardList', 'Card')">
        <CreditCardOutline fillColor="#666" :size="64"></CreditCardOutline>
        <span class="MBMWidget_Select-Method-Name">{{$t('payment_method_name_card')}}</span>
      </div>
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBMWidget_Select-Method-Item" @click="goTo('PurchaseSummary', 'OpenBanking')" v-if="isFeature(Feature.OpenBanking) && validateOption(Feature.OpenBanking)">
        <BankOutline fillColor="#666" :size="64"></BankOutline>
        <span class="MBMWidget_Select-Method-Name">{{$t('payment_method_name_wire')}}</span>
      </div>
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBMWidget_Select-Method-Item MBMWidget_Select-Method-Item-Black" @click="goTo('PurchaseSummary', 'Blik')" v-if="isFeature(Feature.Blik) && validateOption(Feature.Blik)">
        <img src="https://assets.mobilum.com/vendors/blik.png">
      </div>
    </div>
  </div>
  
</template>
<script lang="ts">
import Vue from "vue";
import BankOutlineIcon from "vue-material-design-icons/BankOutline.vue";
import CreditCardOutlineIcon from "vue-material-design-icons/CreditCardOutline.vue";
import store from "@/store";
import {Routes} from "@/store/modules/Router";
import {isFeature} from "@/utils";
import {Feature} from "@/components/Enums/StepType";

export default Vue.extend({
  computed: {
    Feature() {
      return Feature
    }
  },
  
  components: {
    BankOutline: BankOutlineIcon,
    CreditCardOutline: CreditCardOutlineIcon
  },
  data() {
    return {
      loading: true,
      
    };
  },
  methods: {
    isFeature,
    goTo(path: string, option : string) {
      if(this.$store.state.order.uuid == '') {
        this.$store.commit('router/goTo', Routes.Exchange);
        return;
      }
      this.$store.state.paymentOption = option;
      this.$store.commit('router/goTo', path);
    },
    validateOption(option: Feature) {
      //option specific rules
      if(option == Feature.Blik){
        if(this.$store.state.order.uuid == '' || this.$store.state.order.selectedFiat != 'PLN') {
          return false;
          
        }
      }
      
      return true;
    }
  },
  mounted(){
    this.$store.commit('setPreviousPage', 'SelectMethod');
  },
});
</script>
<style scoped>

</style>