<template>
  <div class="MBMWidget_Phaze_Card_Summary MBMWidget_mx-auto MBWidget_block custom_Widget_Phaze_Card_Summary">
    <div
      class="
        MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4 custom_title
      "
    >
      {{ $t('phaze_card_summary_title') }}
    </div>
    <RedirectToBasketButton class="basket_button" />
    <div
      class="
        MBMWidget_col-np-12
        MBMWidget_col-np-xs-12
        MBMWidget_Loading
        MBMWidget_text-center
        loading_style
      "
      v-if="loading"
    >
      {{ $t('loading') }}
    </div>

    <div v-if="!loading & !showSummary">
      <div class="card_img_section">
        <div>
          <img class="card_img" :src="currentCard.productImage" />
        </div>
      </div>
      <div class="MBMWidget_col-14 MBMWidget_col-xs-14">
        <cool-select
          v-if="(options.length > 0) & (cardVersion == 1)"
          v-model="selectedPrice"
          :items="options"
          class="MBMWidget_full-width"
          disableSearch="true"
          menuItemsMaxHeight="230px"
          menuDynamicPosition="false"
          :placeholder="selectedPrice != null && selectedPrice != '' ? '' : 'Please select a value'"

        >
          <template slot="item" slot-scope="{ item }">
            <b>{{ currencySymbol }} {{ item }} {{ currency }}</b>
          </template>

          <template slot="selection" slot-scope="{ item }">
            <b>{{ currencySymbol }} {{ item }} {{ currency }}</b>
          </template>

          <template slot="input-end">
            <ChevronDownIcon :size="20" fillColor="#333" />
          </template>
        </cool-select>
        <div
          class="
            MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0
            error_message
          "
        >
          
        </div>
        <div v-if="cardVersion == 2" class="tooltip_style">
          <div class="float_left">{{ $t('phaze_labels_enter_amount') }}</div>
          <div class="tooltip float_left">
            <img class="info_img" src="../../img/info.png" alt="info" />
            <span class="tooltiptext">{{
              `${$t('phaze_labels_enter_an_amount_between')} ${currencySymbol}${
                currentCard.minPriceVal
              } ${$t('phaze_labels_and')} ${currencySymbol}${
                currentCard.maxPriceVal
              } ${currency}`
            }}</span>
          </div>
        </div>
        <AppInputSimple
          v-if="cardVersion == 2"
          v-model="selectedPrice"
          type="number"
          class="MBMWidget_full-width input_style"
          :min="currentCard.minPriceVal"
          :max="currentCard.maxPriceVal"
          @input="validateInputNumber"
          :placeholder="$t('phaze_labels_enter_an_amount_between') +' '+currencySymbol+''+currentCard.minPriceVal+' ' + $t('phaze_labels_and') + ' ' +currencySymbol+''+currentCard.maxPriceVal+' '+currency"
        />
        <div
          class="
            MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0
            error_message
          "
        >
          <span v-if="errorMessage">
            {{ errorMessage }}
          </span>
        </div>
      </div>
      <div class="gift_card_title">
        <span v-html="currentCard.name" class="futura_20_bold" />
      </div>
      <div
        class="
          MBMWidget_col-np-12 MBMWidget_col-np-xs-12
          card_descr
          parent-element
        "
        v-dragscroll
        v-if="currentCard != null"
      >
        <vuescroll :ops="ops" class="futura_14_normal">
          <div class="child-element">
            <p v-html="currentCard.productDescription" />
            <p v-for="d in currentCard.details" v-bind:key="d" v-html="d" />
          </div>
        </vuescroll>
      </div>
    </div>

    <div class="summary" v-if="showSummary">
      <div class="summary_content">
        <span
          class="MBMWidget_Phaze_Card_Summary_Image"
          :style="'background-image: url(' + currentCard.productImage + ')'"
        />
        <div class="_summary_header">
          <div class="futura_24_bold _card_name">{{ currentCard.name }}</div>
          <div class="futura_18_bold _card_name">
            {{ currencySymbol }} {{ parseFloat(selectedPrice).toFixed(2) }}
            {{ currency }}
          </div>
        </div>
      </div>
      
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat" v-if="!showSummary">
        <AppButton
          :disabled="
              loading || selectedPrice == null || errorMessage || !selectedPrice
            "
          color="primary"
          class="
              MBMWidget_full-width
              MBMWidget_defaultAppButton
              MBMWidget_Phaze_Card_List_Next
              futura_16_bold
              add_to_cart_button
            "
          @click="addToCart"
        >
          {{ $t('phaze_buttons_add_to_cart') }}
        </AppButton>
      </div>
      <div class="MBWidget-nofloat" v-if="showSummary">
        <div class="_summary_body">
          <AppButton
            :disabled="loading"
            class="
            futura_16_bold
            keep_shopping_bottom
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Phaze_Card_List_Next
          "
            @click="redirectToGiftCardList"
          >
            {{ $t('phaze_buttons_keep_shopping') }}
          </AppButton>
          <AppButton
            :disabled="loading"
            color="primary"
            class="
            futura_16_bold
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Phaze_Card_List_Next
          "
            @click="orderCheckout"
          >
            {{ $t('phaze_buttons_checkout') }}
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import store from '@/store';
import Button from '../General/Button.vue';
// @ts-ignore
import { dragscroll } from 'vue-dragscroll';
import { redirectViewString } from '@/utils';
import { mapGetters } from 'vuex';
import { CoolSelect } from 'vue-cool-select';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import vuescroll from 'vuescroll';
import { Routes } from '@/store/modules/Router';
import CurrencyList from '@/components/Components/CurrencyList.json';
import {
  GetDeliveryByProductId,
  createBasketOrderService,
  updateBasketOrderService,
} from '@/api';
import { createOrderListFromBasket } from '../Common/helpers/BasketOrderHelpers';
import RedirectToBasketButton from '../RedirectToBasketButton.vue';
import Input from '../General/InputSimple.vue';

export interface GiftCardInfInterface {
  name: string;
  currency: string;
  denominations?: number[];
  details?: [];
  productDescription?: string;
  productId: number | string;
  productImage: string;
  maxPriceVal?: number;
  minPriceVal?: number;
}

export default Vue.extend({
  components: {
    vuescroll,
    AppButton: Button,
    AppInputSimple: Input,
    CoolSelect: CoolSelect,
    ChevronDownIcon: ChevronDownIcon,
    RedirectToBasketButton: RedirectToBasketButton,
  },
  directives: {
    dragscroll,
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  data() {
    return {
      store,
      loading: false,
      isMounted: false,
      errorMessage: undefined as undefined | string,
      // Current card data
      currentCard: {} as GiftCardInfInterface | null | any,
      selectedPrice: '',
      currency: undefined,
      currencySymbol: undefined,
      options: [] as any,
      cardVersion: 1, // Flag indicating the type of the card ( 1 - input / 2 select )
      // Summary
      showSummary: false,
      searchText: null,
      // Scroll options
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          locking: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
        },
        rail: {},
        bar: {},
      },
    };
  },
  methods: {
    getFiatSymbol(fiatCode: string) {
      // @ts-ignore
      if (CurrencyList.en[fiatCode] !== undefined) {
        // @ts-ignore
        return CurrencyList.en[fiatCode].symbol;
      }
    },
    addToCart() {
      // Create object
      const selectedGiftCard: any = {
        selectedPrice:
          this.cardVersion == 1
            ? this.selectedPrice
            : parseFloat(this.selectedPrice).toFixed(2),
        giftCardInf: this.currentCard,
        quantity: 1,
        currencySymbol: this.currencySymbol,
        cardVersion: this.cardVersion,
      };

      let selectedGiftCardsCart: any[] =
        this.$store.state.selectedGiftCardsCart;

      // Check if card exist in shopping cart
      const index = selectedGiftCardsCart.findIndex(
        (x) =>
          x.giftCardInf.productId == selectedGiftCard.giftCardInf.productId &&
          x.selectedPrice == selectedGiftCard.selectedPrice &&
          x.cardVersion == selectedGiftCard.cardVersion,
      );

      // Update quantity in existed order
      if (index >= 0) {
        selectedGiftCardsCart[index].quantity =
          selectedGiftCardsCart[index].quantity + 1;
      } else {
        // Add as new order
        selectedGiftCardsCart = [...selectedGiftCardsCart, selectedGiftCard];
      }

      // Update data in store
      this.$store.state.selectedGiftCardsCart = selectedGiftCardsCart;

      // Show the final summary
      this.showSummary = true;
    },
    redirectToGiftCardList() {
      redirectViewString(Routes.PhazeCardList);
    },
    async orderCheckout() {
      const selectedGiftCardsCart = this.$store.state.selectedGiftCardsCart;
      let giftCardOrderInf = this.$store.state.giftCardOrderInf;

      try {
        // Crate order list
        const orderList = createOrderListFromBasket(selectedGiftCardsCart);

        // Check if order exist
        if (giftCardOrderInf) {
          await updateBasketOrderService(orderList).then((response) => {
            // Update informations about order
            giftCardOrderInf = response.data.result;

            // Update order inf in store
            this.$store.state.giftCardOrderInf = giftCardOrderInf;

            this.$store.commit('phazeOrder', {
              uuid: giftCardOrderInf.orderUUID,
              selectedCurrency: giftCardOrderInf.selectedCurrency,
              selectedCryptoCurrency: giftCardOrderInf.selectedCryptoCurrency,
              price: giftCardOrderInf.price,
            });

            // Change view
            redirectViewString(Routes.Checkout);

            //this.$store.commit('router/goTo', Routes.Checkout);
          });
        } else {
          await createBasketOrderService(orderList).then((response) => {
            // Add informations about order
            giftCardOrderInf = response.data.result;

            // Update order inf in store
            this.$store.state.giftCardOrderInf = giftCardOrderInf;

            this.$store.commit('phazeOrder', {
              uuid: giftCardOrderInf.orderUUID,
              selectedCurrency: giftCardOrderInf.selectedCurrency,
              selectedCryptoCurrency: giftCardOrderInf.selectedCryptoCurrency,
              price: giftCardOrderInf.price,
            });

            // Change view
            redirectViewString(Routes.Checkout);

            this.$store.commit('router/goTo', Routes.Checkout);
          });
        }
      } catch (e) {
        // TODO: Add error inf
        console.log('Error orderCheckout()', e);
      }
    },
    setInitPrice() {
      if (this.options && this.options.length) {
        this.selectedPrice = '';
      } else {
        // TODO: Add error information
      }
    },
    generateOptions() {
      // Sort data desc
      const sortedDenominations = this.currentCard.denominations?.sort(
        (a: any, b: any) => a - b,
      );

      // Prepare a list of options
      this.options = sortedDenominations;
    },
    async getCardInf() {
      this.loading = true;

      await GetDeliveryByProductId(this.$store.state.selectedGiftCardId)
        .then((response) => {
          // Set currency
          this.currency = response.data.currency;

          // Set currency symbol / fiat symbol
          this.currencySymbol = this.getFiatSymbol(response.data.currency);

          // Creation "currentCard" from the response data
          this.currentCard = response.data;

          // Check card version
          if (response.data.denominations) {
            // Prepare options
            this.generateOptions();

            // Set init price
            this.setInitPrice();
          } else {
            // Set min price
            this.selectedPrice = '';

            // Set "cardVersion" flag
            this.cardVersion = 2;
          }
        })
        .finally(() => (this.loading = false));
    },

    //#region ___ Validation helpers ___

    validateInputNumber(value: number) {
      const { maxPriceVal, minPriceVal } = this.currentCard;

      let errorMessage = undefined;

      if (value < minPriceVal) {
        errorMessage = this.$t(
          'phaze_error_mesages_value_is_too_low',
        ).toString();
      }
      if (value > maxPriceVal) {
        errorMessage = this.$t(
          'phaze_error_mesages_value_is_too_high',
        ).toString();
      }

      this.errorMessage = errorMessage;
    },

    //#endregion ___ Validation helpers ___
  },
  async mounted() {
    // Set prev page
    this.$store.commit('setPreviousPage', 'PhazeCardSummary');

    // Hide menu button
    this.$store.state.hideMenuButton = true;

    try {
      // Get card informations
      await this.getCardInf();
    } catch (e) {
      console.log('Error message ---', e);
      // TODO: Add error information
    }
  },
  beforeDestroy() {
    // Show menu button
    this.$store.state.hideMenuButton = false;
  },
});
</script>

<style scoped>
.error_message {
  color: red;
}

.card_img_section {
  text-align: center;
}

.card_img_section > div {
  height: 114px;
  width: 230px;
  margin: 13px auto 10px auto;
}

.card_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.card_descr {
  width: calc(100% - 30px);
  margin: 0px 15px 0px 15px;
  max-height: 125px;
  overflow-y: auto;
  color: rgb(92, 103, 112, 0.6);
  border-bottom: 1px solid rgb(163, 176, 191, 0.4);
}

.loading_style {
  margin-top: 100px;
}

.select_price {
  margin-bottom: 20px;
  padding: 0px 15px;
}

.gift_card_title {
  margin: 0px 15px;
  overflow: hidden;
}

/* #region ___ Summary styles ___ */

.summary {
  position: relative;
  height: 100%;
}

.summary_content {
  display: grid;
  column-gap: 16px;
  padding: 49px 0px 0px 15px;
  grid-template-columns: 70px calc(100% - 70px) !important;
}

._card_name {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cart_img {
  margin-top: -4px;
  margin-left: 12px;
  width: 48px;
  height: 48px;
}

.cart_img:hover {
  cursor: pointer;
}

._summary_header {
  padding: 10px;
}

.futura_18_bold {
  color: rgb(92, 103, 112, 0.6);
}

.keep_shopping_bottom {
  margin-right: 20px;
  color: #f19a53;
  background: #fef5ea;
}

.keep_shopping_bottom:hover {
  color: #f19a53;
  background: #fef5ea;
}

._summary_body {
  display: flex;
}

/* #endregion ___ Modal styles ___ */

.basket_button {
  position: relative;
  float: right;
  top: -36px;
  right: 0px;
}

/* #endregion ___ Tooltip ___ */

.tooltip_style{
  margin-left: 15px;
  margin-right: 15px;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 24px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000bf;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 5px);
  left: -46px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.float_left {
  float: left;
}

.info_img {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
/* #endregion ___ Tooltip ___ */
</style>
