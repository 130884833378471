<template>
  <div>
    <div class="MBWidget_Popup" v-show="show != ''">
      <div class="MBWidget_general_back_button" v-show="show != 'menu' && show != 'lang' && show != 'addRemoveCard'">
        <a class="MBWidget_Popup_back" 
           @click.prevent="store.state.popup = ''"
        ><ChevronLeftIcon fillColor="#444" :size="40"></ChevronLeftIcon></a>
      </div>
      <div class="MBWidget_general_menu_button MBWidget_popup_general_menu_button" v-show="store.state.loggedIn && show != 'menu' && show != 'lang'">
        <a class="MBWidget_menu_button"
           @click.prevent="store.state.popup = 'menu'"
        ><MenuIcon fillColor="#888" :size="36"></MenuIcon></a>
      </div>
      <!--<div class="MBWidget_Logo MBMWidget_my-4">
        <img style="margin-right: 1em; margin-left: 1em;" src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/MBLogo.png" />
      </div>-->
      
      <LanguageSelect v-show="show == 'lang'"></LanguageSelect>
      <UserMenu :showIframe="showIframe" v-show="show == 'menu'"></UserMenu>
      <AddRemoveCard v-show="show == 'addRemoveCard'"></AddRemoveCard>
      <TransactionView v-show="show == 'transactionView'"></TransactionView>
      
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import store from "@/store";
  import LanguageSelect from '@/components/Components/LanguageSelect.vue';
  import UserMenu from '@/components/Components/UserMenu.vue';
  import AddRemoveCard from '@/components/Components/AddRemoveCard.vue';
  import TransactionView from '@/components/Components/TransactionView.vue';
  import MenuIcon from 'vue-material-design-icons/Menu.vue';
  import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';


  export default Vue.extend({
    components: {
      LanguageSelect: LanguageSelect,
      UserMenu: UserMenu,
      AddRemoveCard: AddRemoveCard,
      TransactionView: TransactionView,
      MenuIcon: MenuIcon,
      ChevronLeftIcon: ChevronLeftIcon
    },
    props: ['showIframe'],
    computed: {
      popup() {
        return store.state.popup;
      }
    },
    mounted() {
      this.show = this.store.state.popup;
    },
    data() {
      return {
        store,
        show: ''
      }
    },
    watch: {
      popup(val: string) {
        this.show = val;
      }
    }
    
  });
</script>

<style scoped>

</style>