<template>
  <div class="MBMWidget_Bridge MBMWidget_mx-auto">
    <div
        class="MBMWidget_text-center MBMWidget_title MBMWidget_Header"
    >
      <img
          src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/MBLogo.png"
      />
      
    </div>

    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Top_Menu MBMWidget_mt-2" v-if="!isFeature(Feature.Polygon)" >
      <span v-if="isFeature(Feature.MenuCrypto)" @click="switchOrder(Routes.Exchange)">
        {{$t('top_menu_buy')}}
        <img width="12" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADGSURBVHgB7ZOxDcIwEEXvHAagYQeSihEoKBgAKZSMQBe6mA6LghlSElNAjwRTQMkANEzgw2cpEoHEiUSFxCtc/NP7usIHUEOo1SbMV3vw0KkbCBIDAwbBg4Av+Mu/I4e5uvW1StsKkV6n7DgZCTJBKNsUsAhEEgwdnHyJE2mDZVNBIRJRdp0u5k7mp6mgJMbJrMhLHz/aKgmIqUGSAYkhHwaiOFWJH/JrgZ08gKDLWZXIBO/BfXc89yYjW4pjn+iFN3BbeHgChuBn+Rl2+OcAAAAASUVORK5CYII=" alt="">
      </span>
      <span v-if="isFeature(Feature.MenuPhaze)" @click="switchOrder(Routes.PhazeCardList)">
        {{$t('top_menu_sell')}}
        <img width="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACJSURBVHgB7dTBCYAwDIXhpzhAR3AER3EDR9UNdANHECeoQXKosQqljXjIDw+kUD68FLC08kBH66EVAxvN0waUTgC+OBQAK2/mlYEE0NJG2kRzRSAJ8NmJ8HceFAMkkg3xxQsQQySElPhPXOT8hoR38FATO6yABYm93anxQYYY8h9kh3b8EjtYmh3F32mQhLJUFAAAAABJRU5ErkJggg==" alt="">
      </span>
      <span v-if="isFeature(Feature.Bridge)" class="MBMWidget_Top_Menu_Selected">
        {{$t('top_menu_bridge')}}
        <img width="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAPCAYAAAARZmTlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALHSURBVHgBnVTPaxNBFH5v9kfTH5BdMIKnbPEPaD0Jas2GttBjvFQ8SLPgpSfTvyBtbz01BaX2lNbgSdAWL0ULSaRIb+lVPDQFRbCpuxGbmGR3nrOhCUltoPGDZWbevDffvPfmW4AO3JuyTdO0NfgP/Hj1fLzXHutaEcQ8ia1Cn7DTaU3mLHuSXjcv28c70/Y4I9DAFQtJjIhpAkyhx3JELu7n9Fzvw1dF1gEtoCp0Bo0o8zCpqhBrVKvHurXgtEnuT/88Ao5hREASuQgCMT3PC0hYoSgRJLJ7+k778MyLp4h8EZEFiQjbhaBmEDDWHPwFEvJbODPz3WwaZBk8F42aK60qCliK5DrgujCgUlqcYiiE5ut3N/JfNjJJCV1BQHmF0bYoePPGClJEkV2z1mBLHpc0YhAEztFlcgo705+YcrIIlP+4py+2bPHYkSYP8ixDbkSMr9GboZOCIEjdTjxc6OwJSTXfbumP53MXy9rVeMmlB50EPja3Rx19oGqFhitBWa4XSr9HihVHXer00S3L6UXQ7AlcEc8WPm0G1PqcKHTiyUp0DfoAu4pTIl7QyrWBiF0dhBHVjUGf6CKZmLRjE1Plf3TieYPJk7OhcFiz10IjlUh+5c3cRZ/TzEaylyC7e+JBTtQ2dnfyV/uQ2di35GllMFGqBpZnl2cSoaFKXh+upj5vbM0VRMNbfkT8WPakt3aHrQWMTpfEy2Fh8BVBQJxA9wA18TyLIlTzPwn51vvd63E/4CidNgLoZYXdaOlJjGWhCFv4jRIwh3M4FyKBqkim7HrKoQe8iH5SyIUScUyoUfvTYDlhcYjxnf29a7nWrUYtq+gPdmbdZIyPibnOSAoSkuZfUIizKCn80BepAHn1SrnrdYmfo8EVzNYlih7s6kXoA6WX63HGMIkiVn803xUrdy7qCmiSR0sHH/oj8CGyNy4j8PEXCrQyybcOdUIAAAAASUVORK5CYII=" alt="">
      </span>
    </div>
    
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center MBMWidget_mt-4" v-if="loading">
      {{$t('loading')}}
    </div>
    <div v-if="!loading">

      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12">
        <div class="MBMWidget_col-6 MBMWidget_col-xs-6">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0 MBMWidget_Select_Label">{{$t('bridge_from')}}</div>
          <cool-select
              
              v-model="bridgeFrom"
              :items="bridgePatforms"
              item-value="name"
              item-text="name"
              class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
          >
            <template slot="item" slot-scope="{ item: i }">
              <div style="display: flex; align-items: center;">
                <div><b>{{ i.name }}</b></div>
              </div>
            </template>
  
            <template slot="selection" slot-scope="{ item: i }">
              <div><b>{{ i.name }}</b></div>
              <ChevronDownIcon :size="20" fillColor="#333"></ChevronDownIcon>
            </template>
          </cool-select>
        </div>
        <div class="MBMWidget_col-6 MBMWidget_col-xs-6">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0 MBMWidget_Select_Label">{{$t('bridge_to')}}</div>
          <cool-select

              v-model="bridgeTo"
              :items="bridgePatforms"
              item-value="name"
              item-text="name"
              class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
          >
            <template slot="item" slot-scope="{ item: i }">
              <div style="display: flex; align-items: center;">
                <div><b>{{ i.name }}</b></div>
              </div>
            </template>

            <template slot="selection" slot-scope="{ item: i }">
              <div><b>{{ i.name }}</b></div>
              <ChevronDownIcon :size="20" fillColor="#333"></ChevronDownIcon>
            </template>
          </cool-select>
        </div>
      </div>
      
      <div>
        <form @submit.prevent ref="form1" class="MBMWidget_Exchange_form">
          <div
              class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
          "
          >
          <div
              class="
            MBMWidget_col-np-12
            MBMWidget_col-np-xs-12
            MBMWidget_Exchange_crypto
            MBMWidget_GeneralInput
            MBMWidget_GeneralSelect
            MBMWidget_mt-4
            bridge_recieve_block
          "
          >
            <span class="bridge_recieve_label">{{$t('bridge_i_will_send')}}</span>
            <AppInputSimple
                v-model="amountSend"
                class="
              MBMWidget_d-inline-block
              MBMWidget_inputWithSelect
              MBMWidget_Exchange_crypto_amount
            "
                type="number"
                data-rules=""
            />
            <vue-multi-select
                class="MBMWidget_Phaze_Select_Country_Dropdown"
                id="cryptoSearch"
                v-model="selectedToken"
                search
                :btnLabel="tokenLabel"
                :selectOptions="tokens"
                style=""
                
                :popoverClass="'MBMWidget_multiselect'"
            >
              <template v-slot:option="{ option }">
                <div
                    style="display: flex; align-items: center; margin-left: 10px"
                    class="MBMWidget_Exchange_fiat_selector_option"
                >
                <span
                    class="fiat_select_icon_flag"
                    :style="
                    'background-image:url(' +
                      getCryptoIcon(option.ticker) +
                      ');'
                  "
                ></span>
                  <div>
                    <span>{{ option.name }} {{ option.ticker }}</span>
                  </div>
                </div>
              </template>
            </vue-multi-select>
          </div>
          </div>
        </form>
      </div>
      <div
          class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_mt-2
          "
      >
        <div class="bridge_recieve_block">
          <span class="bridge_recieve_label">{{$t('bridge_i_will_recieve')}}</span>
          <span class="bridge_recieve_token">
            <span class="fiat_select_icon_flag"
              :style="
                'background-image:url(' +
                  getCryptoIcon(selectedToken[0].ticker) +
                  ');'
              "
            ></span> 
            <span class="bridge_recieve_ticker">{{selectedToken[0].ticker}}</span>
          </span>
        <span class="bridge_recieve_amount">{{amountRecieve}}</span>
        </div>
      </div>

      <div class="MBMWidget_col-12 MBMWidget_col-xs-12">
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_ShowDetails" @click="showFees = !showFees">
          {{$t('exchange_order_details')}}<span><ChevronUpIcon fillColor="#444" :size="20" v-if="showFees" /><ChevronDownIcon fillColor="#444" :size="20" v-if="!showFees" /></span>
        </div>
        <div class="MBMWidget_Purchase_Summary_Details_Block">
          <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order" v-if="loading">{{ $t('loading') }}...</span>
          <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order" v-if="!loading">{{$t('exchange_order_details_you_get')}}<span> {{amountRecieve}}</span> {{selectedToken[0].ticker}} {{$t('exchange_order_details_for')}} <span>{{ amountSend }} {{selectedToken[0].ticker}}</span></span>
        </div>

        <div class="MBMWidget_Purchase_Summary_Details_Block MBMWidget_Purchase_Summary_Details_Block_Additional" :style="showFees ? 'max-height: 90px;' : 'max-height: 0px;'">
          <span class="MBMWidget_Purchase_Summary_Price" v-if="loading">{{ $t('loading') }}...</span>
          
          <span class="MBMWidget_Purchase_Summary_Fees" v-if="!loading"><strong>{{$t('purchase_summary_fee')}}</strong><span>{{ feeAmount}} {{feeToken}}<!-- {{ getFeeDetails() }}--></span></span>
          <span class="MBMWidget_Purchase_Summary_Fees MBMWidget_Purchase_Summary_Fees_Total" v-if="!loading"><strong>{{$t('purchase_summary_total_cost')}}</strong><span>{{ feeAmount}} {{feeToken}}</span></span>
        </div>
      </div>
      
    </div>

    <div
        class="
        MBMWidget_col-12
        MBMWidget_col-xs-12
        MBMWidget_py-0
        MBMWidget_text-center
      "
        style="margin:20px 0 -25px 0"
    >
      <ErrorMessage></ErrorMessage>
    </div>
    <div
        class="
        MBMWidget_col-np-12 MBMWidget_col-np-xs-12
        MBWidget_footer_actions
        MBMWidget_px-auto
      "
    >
      <p class="MBM_Menu_Metamask_Error" v-if="modalError != ''">{{$t(modalError)}}</p>
      <p class="MBMWidget_Bridge_wallet_text"><span v-if="connectedWallet != ''" @click="disconnectWallet()">{{$t('bridge_connected') + " " + this.connectedWallet.slice(0, 8) + "..." + this.connectedWallet.substr(this.connectedWallet.length - 8)}} {{$t("bridge_disconnect")}}</span></p>
      <div
          class="
          MBMWidget_col-12
          MBMWidget_col-md-12
          MBMWidget_col-xs-12
          MBMWidget_mx-auto
          MBWidget-nofloat
        "
      >
        <AppButton
            :disabled="disabled || loading"
            class="
            MBMWidget_full-width
            MBMWidget_Exchange_ButtonCheckout
            MBMWidget_defaultAppButton
          "
            :class="connectedWallet != '' ? 'Wallet_Connected' : ''"
            color="primary"
            @click="getWalletMetamask()"
        >
          {{ getWalletButtonText() }}
        </AppButton>
      </div>
      <div
          class="
          MBMWidget_col-12
          MBMWidget_col-md-12
          MBMWidget_col-xs-12
          MBMWidget_mx-auto
          MBMWidget_mb-2
          MBWidget-nofloat
        "
      >
        <AppButton
            :disabled="disabled || loading"
            class="
            secondButton
            MBMWidget_full-width
            MBMWidget_Exchange_ButtonCheckout
            MBMWidget_defaultAppButton
          "
            color="primary"
            
        >
          {{ $t('bridge_claim') }} Test XYZ
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Routes} from '@/store/modules/Router';
import Vue from 'vue';
import Button from '../General/Button.vue';
import {isFeature, redirectViewString} from "@/utils";
import {checkKycVerified} from "@/api";
import AppInputSimple from "@/components/General/InputSimple.vue";
// @ts-ignore
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import {CoolSelect} from "vue-cool-select";
import ChevronDownIcon from "vue-material-design-icons/ChevronDown.vue";
import './../Components/CoolSelect.css';
import ChevronUpIcon from "vue-material-design-icons/ChevronUp.vue";
import AppButton from "@/components/General/Button.vue";
import ErrorMessage from "@/components/Components/ErrorMessage.vue";
import store from "@/store";
import Web3 from "web3";
import {Feature} from "@/components/Enums/StepType";
import BridgeService from "@/components/Bridge/BridgeService";
export default BridgeService.extend({
  components: {
    ErrorMessage, AppButton,
    CoolSelect: CoolSelect,
    AppInputSimple,
    vueMultiSelect,
    ChevronDownIcon: ChevronDownIcon,
    ChevronUpIcon
  },
  data() {
    return {
      loading: true,
      amountSend : 12,
      amountRecieve : 10,
      feeAmount : 0.001,
      feeToken: "ETH",
      bridgePatforms : [] as any,
      bridgeFrom : "",
      bridgeTo : "",
      tokens : [] as any,
      selectedToken : [{
        ticker : "",
        name: ""
      }],
      showFees: false,
      disabled : false,
      connectedWallet: "",
      modalError : '',
      canConnect : true,
    };
  },
  computed: {
    Feature() {
      return Feature
    },
    store() {
      return store
    },
    Routes() {
      return Routes
    },
    tokenLabel(): any {
      return () => `<div class="MBMWidget_Exchange_fiat_selector_slot">
           <b>${this.selectedToken[0].ticker}</b>
      <span
        class="fiat_select_icon_flag"
        style=background-image:url(${this.getCryptoIcon(
          this.selectedToken[0].ticker,
      )});></span>
         </div>`;
    },
  },
  methods: {
    isFeature,
    getCryptoIcon(ticker: string | undefined): any {
      let t = ticker?.replace(' ', '%20');
      t = t?.replace('(', '%28');
      t = t?.replace(')', '%29');
      return (
          'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/cryptoicons/' +
          t +
          '.png'
      );
    },
    disconnectWallet () {
      this.connectedWallet = '';
      this.$store.state.userWallet = '';
      this.canConnect = false;
      setTimeout(() => { this.canConnect = true;}, 50);
    },
    async getWalletMetamask() {
      
      
      if(!this.canConnect)
        return;
      
      //this.walletButton = true;
      this.modalError = '';
      //this.modalLoader = true;

      let web3: Web3 | undefined = undefined; // Will hold the web3 instance

      if (!(window as any).ethereum || !(window as any).ethereum.isMetaMask) {
        this.modalError = 'external_wallet_error_metamask_provider';
        //this.modalLoader = false;
        //this.walletButton = false;
        return;
      }

      if (!web3) {
        try {
          await (window as any).ethereum.enable();
          web3 = new Web3((window as any).ethereum);
        } catch (error) {
          this.modalError = 'external_wallet_error_metamask_not_allow';
          //this.modalLoader = false;
          //this.walletButton = false;
          return;
        }
      }
      try {
        const account = await web3.eth.getAccounts();
        // setting states
       // this.$store.state.userWallet = account[0];
        this.connectedWallet = account[0];
        //this.UserWallet = account[0];
      } catch (e) {
        window.alert('Something went wrong. Please try again.');
      }
      
      return true;
    },
    getWalletButtonText() {
      if(this.connectedWallet == "")
        return this.$t('bridge_connect_wallet');
      
      return "Bridge"
    },
    switchOrder(route : Routes) {
      this.store.commit('router/goTo', route);
      this.$store.state.pageQueue.pop();
    }
  },
  async mounted() {
    this.$store.commit('setPreviousPage', 'Bridge');
    this.bridgePatforms = [
      {name : "Goerli" },
      {name : "Sepolia" }
    ];
    this.bridgeFrom = this.bridgePatforms.length > 0 ? this.bridgePatforms[0] : "";
    this.bridgeTo = this.bridgePatforms.length > 1 ? this.bridgePatforms[1] : "";
    this.loading = false;
    this.tokens = [
      {
        ticker : "AAVE",
        name : "Aave"
      },
      {
        ticker : "STX",
        name : "Stacks"
      },
      {
        ticker : "XTZ",
        name : "Tezos"
      }      
    ]
    this.selectedToken = [this.tokens[0]];
    //this.loadChainData();
  }
});
</script>
<style scoped lang="scss">
::v-deep .select {
  .checkboxLayer {
    width: 99%;
  }
  
}
.MBMWidget_Exchange_Swap .select .checkboxLayer {
  box-shadow: none !important;
}
.MBMWidget_Exchange .select .checkBoxContainer {
  max-height: 75px !important;
  min-height: auto !important;
}
</style>

