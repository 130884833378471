import en from './en.json';
import pl from './pl.json';
import zh from './zh.json';

export const defaultLocale = 'en';

export const languages = {
  en: en,
  pl: pl,
  zh: zh,
};

export const names = {
  en: 'English',
  zh: 'Chinese',
  pl: 'Polish'
};
