<template>
  <div class="MBMWidget_AddCard MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center">
      {{$t('loading')}}
    </div>
    
    
  </div>
</template>

<script lang="ts">
  import {getCardsForUser} from "@/api";
  import {Routes} from "@/store/modules/Router";
  import Vue from "vue";
  import store from "@/store";

  export default Vue.extend({
    components: {
      
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    },
    async mounted() {
      await getCardsForUser(this.$store.state.profile.userUUid);
      if(!this.$store.state.userCards.length) {
        this.$store.commit('router/goTo', Routes.AddCard)
      } else {
        this.$store.commit('router/goTo', Routes.CardList)
      }
    }
  });
</script>

<style scoped>

</style>