<template>
  <div @click="redirectToShoppingCart" class="basket_button">
    <img class="cart_img" src="../img/cart.png" alt="cart" />
    <span class="_badge roboto_9_normal" v-if="itemsInBasket > 0">
      {{ itemsInBasket }}
    </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { redirectViewString } from '@/utils';
import { Routes } from '@/store/modules/Router';

export default Vue.extend({
  computed: {
    itemsInBasket() {
      let count = 0;

      this.$store.state.selectedGiftCardsCart?.map(
        (x: any) => (count = count + x.quantity),
      );

      return count;
    },
  },
  methods: {
    redirectToShoppingCart() {
      redirectViewString(Routes.PhazeCardShoppingCart);
    },
  },
});
</script>

<style scoped>
.basket_button {
  position: relative;
  width: 40px;
  height: 40px;
}

.cart_img:hover {
  cursor: pointer;
}

._badge {
  position: absolute;
  right: 4px;
  top: 4px;
  color: white;
  border-radius: 50%;
  background: #eb5757;
  border: 2px solid #ffffff;
  padding: 1px 3px 0.5px 3px;
}
</style>