<template>
  <div
    class="MBMWidget_Tfa MBMWidget_text-center MBMWidget_mx-auto MBWidget_block"
  >
    <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4 custom_title">
      {{ $t('tfa_screen_title') }}
    </div>
    <div class="MBMWidget_subtitle">
      {{ $t('tfa_screen_subtitle') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0">
      <ErrorMessage></ErrorMessage>
    </div>
    <div class="MBWidget_verify_wrap MBMWidget_col-12 MBMWidget_col-xs-12">
      <form @submit.prevent class="MBWidget_verify_v-middle">
        <VerificationCode
          :inputClass="inputClass"
          ref="validationInput"
          @codeFilled="codeFilled"
        />

        <AppButton class="MBWidget_fake_submit" @click="verify"></AppButton>
      </form>
    </div>
    <div class="MBMWidget_col-6 MBMWidget_col-xs-6 MBMWidget_text-left">
      <a
        v-if="minutesLeft === 0"
        class="MBMWidget_Tfa_Resend_Code"
        @click.prevent="requestEmail"
      >
        {{ $t('verify_resend_email') }}
      </a>
    </div>
    <!--<div class="MBMWidget_col-6 MBMWidget_col-xs-6 MBMWidget_text-right">
      <a class="MBMWidget_Tfa_Resend_Code"
         @click.prevent="$refs.validationInput.pasteWithButton()"
      >
        {{$t('verify_paste',) }}
      </a>
    </div>-->
    <div
      class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-4 MBMWidget_my-4 MBMWidget_text-center"
      v-if="resent"
    >
      <p>{{ $t('verify_email_text') }}</p>
    </div>
    <div
      class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto"
    >
      <div
        class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget-nofloat MBMWidget_mx-auto"
      >
        <AppCheckbox
          v-model="agreeCheck"
          required
          class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_cursor-pointer MBWidget_multiline_checkbox"
        >
          <template #text>
            {{ $t('register_i_accept') }}
            <a @click="showIframe('terms_and_conditions')" target="_blank">
              {{ $t('card_screen_terms_and_conditions') }}
            </a>
            {{ $t('tfa_and') }}
            <a @click="showIframe('privacy_policy')" target="_blank">
              {{ $t('card_screen_privacy_policy') }}.
            </a>
          </template>
        </AppCheckbox>
      </div>
      <div
        class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat"
      >
        <AppButton
          :disabled="disabled || code.length != 6 || !agreeCheck"
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_FormTfaAppButtonVerify"
          color="primary"
          @click="verify"
        >
          {{ $t('tfa_screen_verify') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { requestEmailCode, verifyAnyCode } from '@/api';
import { Routes } from '@/store/modules/Router';
import Vue from 'vue';
import Button from '../General/Button.vue';
import VerificationCode from '../General/VerificationCode.vue';
import ErrorMessage from '@/components/Components/ErrorMessage.vue';
import Checkbox from '@/components/General/Checkbox.vue';
import { mapGetters } from 'vuex';
import { errors } from 'web3-core-helpers';
import moment from 'moment';

export default Vue.extend({
  props: ['showIframe'],
  components: {
    AppButton: Button,
    VerificationCode: VerificationCode,
    ErrorMessage: ErrorMessage,
    AppCheckbox: Checkbox,
  },
  data() {
    return {
      code: '',
      disabled: false,
      cardsLoaded: false,
      agreeCheck: false,
      inputClass: '',
      resent: false,
      blockedUntil: 0 as any,
      minutesLeft: 0,
      currentDate: moment.utc(),
      timeInterval: null as any,
    };
  },
  computed: {
    ...mapGetters(['isProfileFilled']),
  },
  watch: {
    // whenever question changes, this function will run
    async currentDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.minutesLeft > 0) {
          this.calculateTimeLeft();
        } else {
          clearInterval(this.timeInterval);
          this.minutesLeft = 0;
          this.$store.commit('personalizedErrors', []);
        }
      }
    },
  },
  methods: {
    calculateTimeLeft(blockedDate?: any) {
      const diffMinutes =
        blockedDate?.diff(this.currentDate, 'minutes') ??
        this.blockedUntil?.diff(this.currentDate, 'minutes') ??
        0;
      this.minutesLeft = Math.round(diffMinutes);
      if (this.minutesLeft > 0) {
        this.$store.commit('personalizedErrors', [
          `Too many failed attempts. Please try again in: ${this.minutesLeft} minutes`,
        ]);
      } else {
        this.$store.commit('personalizedErrors', []);
      }
    },
    codeFilled(val: string) {
      this.get();
      this.inputClass = '';
      this.$store.commit('personalizedErrors', []);
    },
    get(): boolean {
      const i: InstanceType<typeof VerificationCode> = this.$refs
        .validationInput as InstanceType<typeof VerificationCode>;
      if (i == null) return false;
      // @ts-ignore
      this.code = i.getCode();
      return this.code.length > 0;
    },
    async verify() {
      if (!this.get()) {
        return;
      }
      try {
        this.disabled = true;
        const response = await verifyAnyCode(this.code);
        if (response.data.success == true) {
          this.$store.commit('personalizedErrors', []);

          this.$store.state.jwt = response.data.result.JwtToken;
          this.$store.state.profile.name = response.data.result.name;
          this.$store.state.profile.userUUid = response.data.result.userUuid;
          this.$store.state.profile.surname = response.data.result.surname;
          this.$store.state.profile.country = response.data.result.country;
          this.$store.state.profile.dob = response.data.result.dob;

          this.$store.state.profile.kycVerified =
            response.data.result.kycVerified;
          this.$store.state.profile.kycInProgress =
            response.data.result.kycInProgress;
          this.$store.state.profile.kycFailed = response.data.result.kycFailed;

          //TODO: Implement API Journey Service
          if (response.data.redirectUrl == 'PerformKYC') {
            this.$store.commit('router/goTo', Routes.Kyc);
          } else {
            let isGiftCards = false;
            for (let i = this.$store.state.pageQueue.length - 1; i > 0; i--) {
              if (this.$store.state.pageQueue[i].includes('Exchange')) break;
              if (this.$store.state.pageQueue[i].includes('Phaze')) {
                isGiftCards = true;
                break;
              }
            }
            if (isGiftCards) {
              this.$store.commit('router/goTo', Routes.ProfileGiftcardFlow);
            } else {
              this.$store.commit('router/goTo', Routes.ProfileCryptoFlow);
            }
          }
          if (this.isProfileFilled) this.$store.state.loggedIn = true;
        } else {
          this.inputClass = 'MBWidget_Wrong_Code';
          if (response.data.errors.length) {
            if (response.data.result.BlockedUntil) {
              this.blockedUntil = moment.utc(
                response.data.result.BlockedUntil,
                'YYYY-MM-DD HH:mm:ss',
              );
              this.calculateTimeLeft(
                moment.utc(
                  response.data.result.BlockedUntil,
                  'YYYY-MM-DD HH:mm:ss',
                ),
              );
            } else {
              this.$store.commit(
                'personalizedErrors',
                response.data.errors.map(
                  (item: { message: string }) => item.message,
                ),
              );
            }
          } else {
            this.$store.commit('personalizedErrors', [
              { code: '01', message: 'Please re-enter the code' },
            ]);
          }
        }
      } finally {
        this.disabled = false;
      }
    },
    async requestEmail() {
      try {
        const response = await requestEmailCode();

        this.$store.commit('personalizedErrors', response.data.errors);
        this.resent = true;
        setTimeout(() => {
          this.resent = false;
        }, 3000);
      } finally {
        //this.disableds.email = false;
        this.code = '';
        // @ts-ignore
        this.$refs['validationInput'].reset();
      }
    },
  },
  mounted() {
    this.$store.commit('setPreviousPage', 'Tfa');
    this.timeInterval = window.setInterval(() => {
      this.currentDate = moment.utc();
    }, 60000);
  },
  beforeDestroy() {
    if (this.$store.state.pageQueue.length > 1)
      this.$store.state.pageQueue.pop();
  },
});
</script>
