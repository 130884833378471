<template>
  <div
    class="MBMWidget_Exchange_General MBMWidget_mx-auto MBWidget_block"
    v-observe-visibility="visibilityChanged"
  >
    <div
      class="MBWidget_general_back_button MBWidget_general_back_button_inner"
      v-if="store.state.popup == '' && activeScreen == 1"
    >
      <a
        class="MBWidget_general_back"
        @click.prevent="
          [(activeScreen = 0), (store.state.hideBackButton = false)]
        "
      >
        <ChevronLeftIcon fillColor="#444" :size="40" />
      </a>
    </div>
    <div
      class="MBMWidget_text-center MBMWidget_title MBMWidget_Header"
      :class="
        store.state.pageQueue.length > 1 ? 'MBMWidget_Header_Logo_right' : ''
      "
    >
      <img
        v-if="activeScreen == 0"
        src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/MBLogo.png"
      />
      <!--<span
        class="MBMWidget_Header_Swith"
        @click="switchOrder()"
        v-if="isFeature(Feature.MenuPhaze) && activeScreen == 0"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0199 12.0755L10.3458 3.74202C10.3272 3.51183 10.1349 3.33454 9.90401 3.33454H8.58069V3.22758C8.58069 1.80468 7.42298 0.646973 5.99992 0.646973C4.57687 0.646973 3.41916 1.80468 3.41916 3.22774V3.33469H2.09583C1.86492 3.33469 1.67269 3.51198 1.65407 3.74217L0.97991 12.0755C0.953319 12.4036 1.06591 12.7304 1.289 12.9721C1.51224 13.2141 1.829 13.3529 2.15801 13.3529H9.84183C10.171 13.3529 10.4876 13.2141 10.7108 12.9721C10.9339 12.7303 11.0464 12.4035 11.0199 12.0755ZM4.30561 3.22774C4.30561 2.29356 5.06574 1.53342 5.99992 1.53342C6.9341 1.53342 7.69423 2.29356 7.69423 3.22774V3.33469H4.30561V3.22774ZM10.0592 12.3711C10.0264 12.4068 9.95454 12.4664 9.84183 12.4664H2.15801C2.0453 12.4664 1.97347 12.4069 1.94068 12.3711C1.90789 12.3356 1.8544 12.2593 1.86356 12.147L2.50475 4.22102H3.41913V6.41824C3.41913 6.66306 3.61756 6.86147 3.86236 6.86147C4.10715 6.86147 4.30558 6.66304 4.30558 6.41824V4.22102H7.69421V6.41824C7.69421 6.66306 7.89264 6.86147 8.13743 6.86147C8.38226 6.86147 8.58066 6.66304 8.58066 6.41824V4.22102H9.49504L10.1362 12.1471C10.1454 12.2594 10.0918 12.3356 10.0592 12.3711Z"
            fill="white"
          />
        </svg>
        <span>{{ $t('icon_buy_phaze') }}</span>
      </span>-->
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Top_Menu MBMWidget_mt-4" v-if="!isFeature(Feature.Polygon)" >
      <span v-if="isFeature(Feature.MenuCrypto)" class="MBMWidget_Top_Menu_Selected">
        {{$t('top_menu_buy')}}
        <img width="12" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADGSURBVHgB7ZOxDcIwEEXvHAagYQeSihEoKBgAKZSMQBe6mA6LghlSElNAjwRTQMkANEzgw2cpEoHEiUSFxCtc/NP7usIHUEOo1SbMV3vw0KkbCBIDAwbBg4Av+Mu/I4e5uvW1StsKkV6n7DgZCTJBKNsUsAhEEgwdnHyJE2mDZVNBIRJRdp0u5k7mp6mgJMbJrMhLHz/aKgmIqUGSAYkhHwaiOFWJH/JrgZ08gKDLWZXIBO/BfXc89yYjW4pjn+iFN3BbeHgChuBn+Rl2+OcAAAAASUVORK5CYII=" alt="">
      </span>
      <span v-if="isFeature(Feature.MenuPhaze)" @click="switchOrder(Routes.PhazeCardList)">
        {{$t('top_menu_sell')}}
        <img width="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACJSURBVHgB7dTBCYAwDIXhpzhAR3AER3EDR9UNdANHECeoQXKosQqljXjIDw+kUD68FLC08kBH66EVAxvN0waUTgC+OBQAK2/mlYEE0NJG2kRzRSAJ8NmJ8HceFAMkkg3xxQsQQySElPhPXOT8hoR38FATO6yABYm93anxQYYY8h9kh3b8EjtYmh3F32mQhLJUFAAAAABJRU5ErkJggg==" alt="">
      </span>
      <span v-if="isFeature(Feature.Bridge)" @click="switchOrder(Routes.Bridge)">
        {{$t('top_menu_bridge')}}
        <img width="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAPCAYAAAARZmTlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALHSURBVHgBnVTPaxNBFH5v9kfTH5BdMIKnbPEPaD0Jas2GttBjvFQ8SLPgpSfTvyBtbz01BaX2lNbgSdAWL0ULSaRIb+lVPDQFRbCpuxGbmGR3nrOhCUltoPGDZWbevDffvPfmW4AO3JuyTdO0NfgP/Hj1fLzXHutaEcQ8ia1Cn7DTaU3mLHuSXjcv28c70/Y4I9DAFQtJjIhpAkyhx3JELu7n9Fzvw1dF1gEtoCp0Bo0o8zCpqhBrVKvHurXgtEnuT/88Ao5hREASuQgCMT3PC0hYoSgRJLJ7+k778MyLp4h8EZEFiQjbhaBmEDDWHPwFEvJbODPz3WwaZBk8F42aK60qCliK5DrgujCgUlqcYiiE5ut3N/JfNjJJCV1BQHmF0bYoePPGClJEkV2z1mBLHpc0YhAEztFlcgo705+YcrIIlP+4py+2bPHYkSYP8ixDbkSMr9GboZOCIEjdTjxc6OwJSTXfbumP53MXy9rVeMmlB50EPja3Rx19oGqFhitBWa4XSr9HihVHXer00S3L6UXQ7AlcEc8WPm0G1PqcKHTiyUp0DfoAu4pTIl7QyrWBiF0dhBHVjUGf6CKZmLRjE1Plf3TieYPJk7OhcFiz10IjlUh+5c3cRZ/TzEaylyC7e+JBTtQ2dnfyV/uQ2di35GllMFGqBpZnl2cSoaFKXh+upj5vbM0VRMNbfkT8WPakt3aHrQWMTpfEy2Fh8BVBQJxA9wA18TyLIlTzPwn51vvd63E/4CidNgLoZYXdaOlJjGWhCFv4jRIwh3M4FyKBqkim7HrKoQe8iH5SyIUScUyoUfvTYDlhcYjxnf29a7nWrUYtq+gPdmbdZIyPibnOSAoSkuZfUIizKCn80BepAHn1SrnrdYmfo8EVzNYlih7s6kXoA6WX63HGMIkiVn803xUrdy7qCmiSR0sHH/oj8CGyNy4j8PEXCrQyybcOdUIAAAAASUVORK5CYII=" alt="">
      </span>
    </div>

    <div
      class="
        MBMWidget_col-np-12
        MBMWidget_col-np-xs-12
        MBMWidget_my-2
        MBMWidget_Polygon
      "
      v-if="isFeature(Feature.Polygon)"
    >
      <div
        :class="
          stateMatic
            ? 'MBMWidget_Polygon_Active MBMWidget_Polygon_Erc_Label'
            : 'MBMWidget_Polygon_Non_Active MBMWidget_Polygon_Erc_Label'
        "
      >
        Matic<br />(ERC-20)
      </div>
      <div
        class="
          MBMWidget_Exchange_Polygon_Togle MBMWidget_mx-auto
          MBWidget-nofloat
        "
        @click="switchMatic"
      >
        <img
          v-if="stateMatic"
          class="MBMWidget_Polygon_Erc"
          src="https://polygon.mobilum.com/polygon.png"
        />
        <img
          v-else
          class="MBMWidget_Polygon_Native"
          src="https://polygon.mobilum.com/polygon.png"
        />
      </div>
      <div
        :class="
          stateMatic
            ? 'MBMWidget_Polygon_Non_Active MBMWidget_Polygon_Native_Label'
            : 'MBMWidget_Polygon_Active MBMWidget_Polygon_Native_Label'
        "
      >
        Matic<br />(Native)
      </div>
    </div>

    <span class="MBMWidget_Exchange_General_Matic_Notice" v-if="maticNotice">{{
      $t('exchange_screen_matic_notice')
    }}</span>

    <div
      class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center"
      v-if="!stateBuy"
    >
      <p>{{ $t('exchange_screen_coming_soon') }}</p>
    </div>
    <div v-if="activeScreen == 0 && stateBuy">
      <form @submit.prevent ref="form1" class="MBMWidget_Exchange_form">
        <div
            class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_Exchange_fiat
            MBMWidget_GeneralInput
            MBMWidget_GeneralSelect
          "
        >
          <AppInputSimple
              v-model="priceShown"
              :label="$t('exchange_screen_i_want_to_spend')"
              type="text"
              class="
              MBMWidget_d-inline-block
              MBMWidget_inputWithSelect
              MBMWidget_Exchange_fiat_amount
            "
              :min="config.minOrder"
              @focus="[onFocus('Price'), (changed = false)]"
              @click="onFocus('Price')"
              @input="onInput(false, true, true)"
              @blur="onInput(true)"
          />
          <vue-multi-select
              class="MBMWidget_full-width MBMWidget_Phaze_Select_Country_Dropdown"
              v-model="selectedCurrencyValue"
              id="fiatSearch"
              search
              :btnLabel="fiatLabel"
              @open="searchFocus('fiatSearch')"
              :selectOptions="computedCurrentLocaleFiat"
              style="align-content: center; display: flex"
              @close="[isMultiSelectOpen = false, needUpdate = true]"
          >
            <template v-slot:option="{ option }">
              <div
                  style="display: flex; align-items: center; margin-left: 10px"
                  class="MBMWidget_Exchange_fiat_selector_option"
              >
                <span
                    class="fiat_select_icon_symbol"
                    :style="'background-color:' + option.color + ';'"
                    v-if="option.color"
                >{{ option.symbol }}</span
                >
                <span
                    class="fiat_select_icon_flag"
                    :style="
                    'background-image:url(' +
                      getFlag(option.code) +
                      ');' +
                      'background-position-x:' +
                      option.flagPos +
                      ';'
                  "
                    v-else
                ></span>
                <div>
                  <span>{{ option.name }}</span>
                </div>
              </div>
            </template>
          </vue-multi-select>
        </div>
        <div
            class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_text-right
            MBMWidget_Exchange_preview
            MBMWidget_Exchange_preview_unit_price
            MBMWidget_py-0
            MBMWidget_mb-2
          ">
        </div>
        <div
          class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_Exchange_crypto
            MBMWidget_GeneralInput
            MBMWidget_GeneralSelect
          "
        >
          <AppInputSimple
            v-model="Amount"
            class="
              MBMWidget_d-inline-block
              MBMWidget_inputWithSelect
              MBMWidget_Exchange_crypto_amount
            "
            
            type="number"
            data-rules=""
            @blur="amountBlur()"
            :label="$t('exchange_screen_i_will_buy')"
            @focus="[onFocus('Amount'), (changed = false)]"
            @click="onFocus('Amount')"
            @input="onInput(false, true, true)"
          />
          <vue-multi-select
            v-if="computedCryptoCurrenciesOptions.length"
            class="MBMWidget_full-width MBMWidget_Phaze_Select_Country_Dropdown"
            id="cryptoSearch"
            v-model="selectedCryptoCurrencyValue"
            search
            :btnLabel="cryptoLabel"
            :selectOptions="computedCryptoCurrenciesOptions"
            style="align-content: center; display: flex"
            @open="searchFocus('cryptoSearch')"
            :block-keys="['Enter']"
            @close="[isMultiSelectOpen = false, needUpdate = true]"
            :popoverClass="'MBMWidget_multiselect'"
          >
            <template v-slot:option="{ option }">
              <div
                style="display: flex; align-items: center; margin-left: 10px"
                class="MBMWidget_Exchange_fiat_selector_option"
              >
                <span
                  class="fiat_select_icon_flag"
                  :style="
                    'background-image:url(' +
                      getCryptoIcon(option.ticker) +
                      ');'
                  "
                ></span>
                <div>
                  <span>{{ option.name }}</span>
                </div>
              </div>
            </template>
          </vue-multi-select>
        </div>
        <div
          class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_text-right
            MBMWidget_Exchange_preview
            MBMWidget_py-0
          "
        >
          <!--<span v-if="loading" class="MBMWidget_Exchange_preview_loading">
            {{ $t('loading') }}...
          </span>
          <span
            class="MBMWidget_Exchange_FormExchangeSelectedCryptoCurrency"
            v-if="!loading"
          >
            {{ $t('exchange_screen_fee', feeTextShort) }} {{ getFeeDetails() }}
          </span>-->
        </div>

        
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_ShowDetails" @click="showFees = !showFees">
            {{$t('exchange_order_details')}}<span><ChevronUpIcon fillColor="#444" :size="20" v-if="showFees" /><ChevronDownIcon fillColor="#444" :size="20" v-if="!showFees" /></span>
          </div>
          <div class="MBMWidget_Purchase_Summary_Details_Block">
            <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order" v-if="loading">{{ $t('loading') }}...</span>
            <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order" v-if="!loading">{{$t('exchange_order_details_you_get')}}<span> {{Amount}}</span> {{SelectedCryptoCurrency}} {{$t('exchange_order_details_for')}} <span>{{ getFiatSymbol(SelectedCurrency) }} {{Price.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}}</span></span>
          </div>
  
          <div class="MBMWidget_Purchase_Summary_Details_Block MBMWidget_Purchase_Summary_Details_Block_Additional" :style="showFees ? 'max-height: 90px;' : 'max-height: 0px;'">
            <span class="MBMWidget_Purchase_Summary_Price" v-if="loading">{{ $t('loading') }}...</span>
            <span class="MBMWidget_Purchase_Summary_Price" v-if="!loading">
              <strong>{{data.CryptoValues.cryptoQuantity}} {{ SelectedCryptoCurrency }}</strong> @
              {{ getFiatSymbol(SelectedCurrency) }} 
              {{
                data.CryptoValues.unitPriceQuoted.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 5,
                })
              }}
              
              
              <span>{{getFiatSymbol(SelectedCurrency)}}{{
                (Price - (data.ProcessingFees.flatRate + data.ProcessingFees.AsLowAsProcessingFee)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }} {{SelectedCurrency}}</span>
            </span>
            <span class="MBMWidget_Purchase_Summary_Fees" v-if="!loading"><strong>{{$t('purchase_summary_gas_fee')}}<span> {{$t('purchase_summary_fee_depends')}}</span></strong><span><span>{{$t('purchase_summary_fee_as_low')}} </span>{{ $t('exchange_screen_fee', feeTextShort) }} {{SelectedCurrency}}<!-- {{ getFeeDetails() }}--></span></span>
            <span class="MBMWidget_Purchase_Summary_Fees MBMWidget_Purchase_Summary_Fees_Total" v-if="!loading"><strong>{{$t('purchase_summary_total_cost')}}</strong><span>{{ getFiatSymbol(SelectedCurrency) }}{{Price.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}} {{SelectedCurrency}}</span></span>
          </div>
        </div>
        
        <div
          class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_Exchange_Swap
            MBMWidget_py-0
          "
          v-if="isFeature(Feature.Swap)"
        >
          <div class="MBMWidget_mt-2 MBMWidget_d-flex">
            <span class="MBMWidget_Exchange_Swap_Title">{{
              $t('exchange_swap_title')
            }}</span>
            <div
              class="MBMWidget_Exchange_Buy_Sell_Togle MBWidget-nofloat"
              @click="swap = !swap"
            >
              <span
                :class="
                  swap ? 'MBMWidget_Exchange_Swap' : 'MBMWidget_Exchange_Noswap'
                "
              ></span>
            </div>
          </div>
          <div class="MBMWidget_mt-2 MBMWidget_Exchange_Swap_Input" v-if="swap">
            <span class="MBMWidget_Exchange_Swap_On">{{
              $t('exchange_swap_on')
            }}</span>
            <AppInputSimple
              v-model="SwapAmount"
              class="
                MBMWidget_d-inline-block
                MBMWidget_inputWithSelect
                MBMWidget_Exchange_swap_amount
              "
              readonly
              type="text"
              data-rules=""
              :label="$t('exchange_swap_receive')"
            />
            <vue-multi-select
              v-if="config.cryptoCurrencies.length"
              class="
                MBMWidget_full-width MBMWidget_Phaze_Select_Country_Dropdown
              "
              v-model="selectedSwapValue"
              openDirection="top"
              :maxHeight="100"
              :btnLabel="swapLabel"
              :selectOptions="SwapOptions"
              style="align-content: center; display: flex"
            >
              <template v-slot:option="{ option }">
                <div
                  style="display: flex; align-items: center; margin-left: 10px"
                  class="MBMWidget_Exchange_fiat_selector_option"
                >
                  <span
                    class="fiat_select_icon_flag"
                    :style="getSwapIcon(option.name)"
                  ></span>
                  <div>
                    <span>{{ option.name }}</span>
                  </div>
                </div>
              </template>
            </vue-multi-select>
          </div>
        </div>
        <AppButton
          class="MBWidget_fake_submit"
          @click="nextScreen()"
        ></AppButton>
      </form>
    </div>
    <div v-if="activeScreen == 1 && stateBuy">
      <form
        @submit.prevent
        ref="form2"
        class="MBMWidget_Exchange_form"
        novalidate
      >
        <div
          class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_Exchange_Wallet
            MBMWidget_GeneralInput
            MBMWidget_mt-4
          "
          v-bind:class="[
            SelectedCryptoCurrency != 'BTC' &&
            SelectedCryptoCurrency != 'BCH' &&
            SelectedCryptoCurrency != 'ADA'
              ? 'MBMWidget_external_wallet'
              : '',
          ]"
        >
          <AppInputSimple
            class="MBMWidget_Exchange_wallet_input"
            v-model="store.state.userWallet"
            :autoComplete="'nope'"
            :readonly="!!prefillOrder.userWallet"
            v-if="!!!prefillOrder.userWallet"
            required
            @input="anyInput()"
            :label="$t('exchange_screen_wallet_address_label')"
            :placeholder="
              $t('exchange_screen_wallet_address_placeholder1') +
                ' ' +
                (!swap ? SelectedCryptoCurrency : 'KLIMA') +
                ' ' +
                $t('exchange_screen_wallet_address_placeholder2')
            "
          />
          <button type="button" @click="scanQrCode" class="qr_scan_button">
            <IconQrCode />
          </button>
          <button
            tabindex="-1"
            class="connect_wallet_button"
            type="button"
            @click="showModalWallet"
            v-if="
              SelectedCryptoCurrency != 'BTC' &&
                SelectedCryptoCurrency != 'BCH' &&
                SelectedCryptoCurrency != 'ADA'
            "
          >
            {{ $t('external_wallet_connect_wallet') }}
          </button>
        </div>
        <div id="external_wallet_container" class="external_wallet_container">
          <div
            v-if="
              showModal &&
                SelectedCryptoCurrency != 'BTC' &&
                SelectedCryptoCurrency != 'BCH' &&
                SelectedCryptoCurrency != 'ADA'
            "
            class="external_wallet_modal"
          >
            <div class="modal_mask" @click="hideModalWallet($event)">
              <div class="modal_wrapper">
                <transition name="fade" appear>
                  <div class="modal_container">
                    <button
                      type="button"
                      class="modal-close-button"
                      aria-label="Close"
                      @click="showModal = false"
                    >
                      <span aria-hidden="true">?</span>
                    </button>
                    <div class="modal_header">
                      <h4>{{ $t('external_wallet_modal_header') }}</h4>
                    </div>
                    <div class="modal_body">
                      <div class="modal_body_row">
                        <div
                          class="modal_body_col"
                          v-if="
                            SelectedCryptoCurrency != 'BTC' &&
                              !this.$store.state.isMobile
                          "
                        >
                          <button
                            :disabled="walletButton"
                            type="button"
                            @click="getWalletMetamask"
                            class="external_wallet_button metamask_button"
                          >
                            <IconMetaMask />
                            <span>Metamask</span>
                          </button>
                        </div>
                        <div class="modal_body_col">
                          <button
                            :disabled="walletButton"
                            type="button"
                            @click="getWalletWalletConnect"
                            class="external_wallet_button walletconnect_button"
                          >
                            <IconWalletConnect />
                            <span>WalletConnect</span>
                          </button>
                        </div>
                      </div>
                      <div class="modal_error" v-if="modalError">
                        {{ $t(modalError) }}
                      </div>
                      <div class="lds-ring modal_loader" v-if="modalLoader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div v-if="openScanQr">
          <QrCodeModal
            @hideQrCodeModal="hideQrCodeModal"
            @contentScanned="onContentScanned"
          />
        </div>
        <div
          class="
            MBMWidget_col-12
            MBMWidget_col-xs-12
            MBMWidget_mt-4
            MBMWidget_Exchange_Email
            MBMWidget_GeneralInput
          "
        >
          <AppInputSimple
            class="MBMWidget_Exchange_wallet_input"
            v-model="UserEmail"
            :type="'email'"
            @input="anyInput()"
            :label="$t('exchange_screen_email_address_label')"
            :placeholder="$t('exchange_screen_email_address_placeholder')"
            required
            v-if="!store.state.loggedIn"
          />
          <div
            class="MBMWidget_col-12"
            v-for="(extraField, counter) in getExtraFields"
            v-bind:key="counter"
          >
            <AppInput
              class="MBMWidget_Exchange_General"
              v-model="extraFieldsValues[counter]"
              :placeholder="extraField.placeholder"
              :required="extraField.required"
            />
          </div>
        </div>
        <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0">
          <span v-if="recaptchaError">
            {{ $t('exchange_screen_recaptcha_error') }}
          </span>
        </div>
        <AppButton class="MBWidget_fake_submit" @click="validate()"></AppButton>
      </form>
    </div>
    <div
      class="
        MBMWidget_col-12
        MBMWidget_col-xs-12
        MBMWidget_py-0
        MBMWidget_text-center
      "
      style="margin:20px 0 -25px 0"
    >
      <ErrorMessage></ErrorMessage>
    </div>
    <div
      class="
        MBMWidget_col-np-12 MBMWidget_col-np-xs-12
        MBWidget_footer_actions
        MBMWidget_px-auto
      "
    >
      <div
        class="
          MBMWidget_col-12
          MBMWidget_col-md-12
          MBMWidget_col-xs-12
          MBMWidget_mx-auto
          MBWidget-nofloat
        "
      >
        <AppButton
          v-if="activeScreen == 0"
          :disabled="
            disabled ||
              store.state.personalizedErrors.length > 0 ||
              Amount == '' ||
              Price == '' ||
              loading
          "
          class="
            MBMWidget_full-width
            MBMWidget_Exchange_ButtonCheckout
            MBMWidget_defaultAppButton
          "
          color="primary"
          @click="nextScreen"
        >
          {{ $t('exchange_screen_checkout_button') }}
        </AppButton>
        <AppButton
          v-if="activeScreen == 1"
          :disabled="
            disabled ||
              (UserEmail == '' && !store.state.loggedIn) ||
              store.state.userWallet == '' ||
              store.state.personalizedErrors.length > 0
          "
          class="
            secondButton
            MBMWidget_full-width
            MBMWidget_Exchange_ButtonCheckout
            MBMWidget_defaultAppButton
          "
          color="primary"
          @click="validate"
        >
          {{ $t('exchange_screen_checkout_button') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Input from '../General/InputSimple.vue';
import Button from '../General/Button.vue';
import {
  checkKycVerified,
  createOrder,
  gerOrderDetails,
  getBestPrice,
  getBestPriceByPrice,
  getCountries,
  sendAnalytics,
  shouldPerformKyc,
} from '@/api';
import { Routes } from '@/store/modules/Router';
import { mapGetters, mapState, mapMutations } from 'vuex';
// @ts-ignore
import WAValidator from 'multicoin-address-validator';
import {
  processDecimalPlaces,
  processDecimalPlacesForce,
  isFeature,
} from '@/utils';
// @ts-ignore
import debounce from 'debounce';
import ErrorMessage from '@/components/Components/ErrorMessage.vue';
// @ts-ignore
//import CurrencyList from 'currency-list'
import CurrencyList from './../Components/CurrencyList.json';
import CountryToCurrencyMap from './../Components/CountryToCurrencyMap.json';
import store from '@/store';
import { Feature } from '@/components/Enums/StepType';

/// external wallets
// @ts-ignore
import Web3 from 'web3';
// walletconnect
// @ts-ignore
import WalletConnectProvider from '@walletconnect/web3-provider';
// @ts-ignore
import IconMetaMask from '../../assets/external_wallets/metamask.svg';
// @ts-ignore
import IconQrCode from '../../assets/external_wallets/qrCode.svg';
// @ts-ignore
import IconWalletConnect from '../../assets/external_wallets/walletconnect.svg';
// @ts-ignore
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';
import QrCodeModal from '../Components/QrCodeModal.vue';
import moment from 'moment';

export default Vue.extend({
  components: {
    AppInputSimple: Input,
    AppButton: Button,
    ErrorMessage,
    IconMetaMask,
    IconWalletConnect,
    vueMultiSelect,
    ChevronLeftIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    QrCodeModal,
    IconQrCode,
  },
  data() {
    return {
      activeScreen: 0,
      isFeature,
      Feature,
      isVisible: true,
      store,
      Routes,
      Amount:
        this.$store.state.prefillOrder.htmlCryptoAmount ||
        this.$store.state.prefillOrder.CryptoValues?.cryptoQuantity ||
        1,
      SelectedCurrency: '',
      SelectedCryptoCurrency: '',

      Price:
        this.$store.state.prefillOrder.htmlFiatAmount ||
        this.$store.state.prefillOrder.TransactionTotal?.TransactionPrice ||
        1,
      UserWallet: this.$store.state.userWallet || '',
      terms: '',
      UserEmail: '',
      recaptchaError: false,
      data: {
        CryptoValues: {
          symbol: "",
          unitPriceQuoted: 0,
          cryptoQuantity: 0,
          fiatCurrency: "",
          cryptoValue: 0
        },
        ProcessingFees: {
          flatRate: 0,
          AsLowAsProcessingFee: 0,
          pct_ALA_ProcessingFee: 0
        },
        TransactionTotal: {
          TransactionPrice: 0
        },
      },
      priceShown: '1',
      amountShown: '0',
      extraFieldsValues: this.$store.state.prefillOrder?.extraFieldsValues || [],
      btcProgression: 'btc_green',
      interval: null as any,
      disabled: false,
      lastFocus: this.$store.state.lastFocus || 'Amount',
      loading: true,
      fiatCurrencyList: [] as any[],
      cryptoCurrencyList: [] as any[],
      countries: [] as any[],
      maticNotice: false,
      kycStartedInterval: null as any,
      kycStarted: true,
      stateBuy: true,
      changed: false,
      showModal: false,
      walletButton: false,
      modalError: false as boolean | string,
      modalLoader: false,
      stateMatic: true,
      isMultiSelectOpen: false,
      SwapOptions: [
        {
          id: 1,
          name: 'UniSwap',
          value: 1,
        },
        {
          id: 2,
          name: 'Sushiswap',
          value: 2,
        },
        {
          id: 3,
          name: 'QuickSwap',
          value: 3,
        },
      ],
      SwapAmount: '0.00',
      swap: true,
      selectedSwapValue: [{ id: 0, name: '', value: 0 }],
      selectedCurrencyValue: [
        { code: '', name: '', color: '', symbol: '', value: '' },
      ],
      selectedCryptoCurrencyValue: [
        {
          additionalFields: '',
          name: '',
          ticker: '',
          validationRegex: '',
          validationType: '',
        },
      ],
      openScanQr: false,
      blockedUntil: 0 as any,
      minutesLeft: 0,
      currentDate: moment.utc(),
      timeInterval: null as any,
      showFees: true,
      retryCount: 0,
      needUpdate: false
    };
  },
  computed: {
    computedCryptoCurrenciesOptions(): any {
      return this.config.cryptoCurrencies.map((option: any) => {
        const custom = `${option.name} ${option.ticker}`;
        return { ...option, name: custom };
      });
    },
    computedCurrentLocaleFiat(): any {
      return this.currentLocaleFiat.map((option: any) => {
        const custom = `${option.name} ${option.code}`;
        return { ...option, name: custom };
      });
    },
    fiatLabel(): any {
      if (
        !this.selectedCurrencyValue.length ||
        this.selectedCurrencyValue[0].code == ''
      )
        return () => '';
      const selectedItem = this.computedCurrentLocaleFiat.find(
        (item: any) => item.code === this.selectedCurrencyValue[0].code,
      );
      const spanToDisplay = selectedItem?.color
        ? ` <span class="fiat_select_icon_symbol"
        style="background-color:${selectedItem.color};"
        >${selectedItem.symbol}</span>`
        : `<span
        class="fiat_select_icon_flag"
        style=background-image:url(${this.getFlag(selectedItem.code)});
      ></span>`;
      return () => `<div class="MBMWidget_Exchange_fiat_selector_slot">
          <b>${selectedItem.code}</b>
          ${spanToDisplay}
         </div>`;
    },
    cryptoLabel(): any {
      if (
        !this.selectedCryptoCurrencyValue.length ||
        this.selectedCryptoCurrencyValue[0].name == ''
      )
        return () => '';
      const selectedItem = this.computedCryptoCurrenciesOptions.find(
        (item: { ticker: string }) =>
          item.ticker === this.selectedCryptoCurrencyValue[0].ticker,
      );
      return () => `<div class="MBMWidget_Exchange_fiat_selector_slot">
           <b>${selectedItem.ticker}</b>
      <span
        class="fiat_select_icon_flag"
        style=background-image:url(${this.getCryptoIcon(
          selectedItem.ticker,
        )});></span>
         </div>`;
    },
    swapLabel(): any {
      if (
        !this.selectedSwapValue.length ||
        this.selectedSwapValue[0].name == ''
      )
        return () => '';
      const selectedItem = this.SwapOptions.find(
        (item: { name: string }) =>
          item.name === this.selectedSwapValue[0].name,
      );
      return () => `<span class="MBMWidget_Exchange_Swap_Token">
              <img src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/Klima_logo_round.png">
              <span>${this.$t('exchange_swap_token')}</span>
            </span>
            <div class="MBMWidget_Exchange_fiat_selector_slot">
           <b>${selectedItem?.name}</b>
      <span
        class="fiat_select_icon_flag"
        style=background-image:url('https://s3.eu-west-1.amazonaws.com/com.mobilum.public/${
          selectedItem?.name
        }.png');></span>
         </div>`;
    },
    valid(): boolean {
      return this.checkValidity();
    },
    currentLocaleFiat(): any[] {
      const list = this.$store.state.config.fiatCurrencies;
      const l = [] as any[];
      const arr = [] as any[];
      // @ts-ignore
      const currencyList = CurrencyList[this.$i18n.locale];
      list.forEach((i: any) => {
        if (currencyList[i.currency] !== undefined)
          if (currencyList[i.currency].code === 'CAD') {
            //fill the array with the predefined fiat currencies if they are available
            arr[3] = currencyList[i.currency];
          } else if (currencyList[i.currency].code === 'USD') {
            arr[0] = currencyList[i.currency];
          } else if (currencyList[i.currency].code === 'EUR') {
            arr[1] = currencyList[i.currency];
          } else if (currencyList[i.currency].code === 'GBP') {
            arr[2] = currencyList[i.currency];
          } else {
            //push the other fiat currencies here
            l.push(currencyList[i.currency]);
          }
      });
      //sort by name the other currencies
      l.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      //filter out the empty predefined currencies
      const pred = arr.filter(element => {
        if (Object.keys(element).length !== 0) {
          return true;
        }
        return false;
      });
      //concat together the two arrays
      const fiatList = pred.concat(l);

      return fiatList;
    },
    ...mapState(['config', 'prefillOrder', 'errors']),
    ...mapGetters(['isLoggedIn']),
    getExtraFields(): any {
      const item = this.computedCryptoCurrenciesOptions.find(
        (item: { ticker: string }) =>
          item.ticker === this.SelectedCryptoCurrency,
      );
      if (item) {
        item.validationType;
        return item.additionalFields || [];
      }
      return [];
    },
    feeText(): any {
      return {
        amount: processDecimalPlaces(this.Amount, 8),
        cryptocurrency: this.SelectedCryptoCurrency,
        amount_fiat: processDecimalPlaces(this.Price, 2),
        fiat_currency: this.SelectedCurrency,
        amount_fee:
          processDecimalPlaces(
            this.data.ProcessingFees.flatRate + this.data.ProcessingFees.AsLowAsProcessingFee,
            2,
          ) +
          ' ' +
          this.SelectedCurrency +
          (this.data.ProcessingFees.flatRate ? '*' : ''),
        amount_fee_percent: this.data.ProcessingFees.flatRate
          ? ''
          : '(' +
            processDecimalPlaces(this.data.ProcessingFees.pct_ALA_ProcessingFee, 2) +
            '%*)',
      };
    },
    feeTextShort(): any {
      return {
        amount_fee:
          this.getFiatSymbol(this.SelectedCurrency) +
          processDecimalPlaces(
            this.data.ProcessingFees.flatRate + this.data.ProcessingFees.AsLowAsProcessingFee,
            2,
          )
      };
    },
    fee_explanation_texts(): any {
      return {
        baseCurrency: this.config.fees.baseCurrency,
        percentage: processDecimalPlaces(this.config.fees.percentage, 2),
        threshold: processDecimalPlaces(this.config.fees.threshold, 2),
        eth_flatrate: 25,
        fixed_flatrate: 5,
      };
    },
  },
  methods: {
    ...mapMutations([
      'modifyLastFocus',
      'modifyUserWallet',
      'modifyOrder',
      'modifyExtraFieldsValues',
    ]),
    calculateTimeLeft(blockedDate?: any) {
      const diffMinutes =
        blockedDate?.diff(this.currentDate, 'minutes') ??
        this.blockedUntil?.diff(this.currentDate, 'minutes') ??
        0;
      this.minutesLeft = Math.round(diffMinutes);
      if (this.minutesLeft > 0) {
        this.$store.commit('personalizedErrors', [
          `Too many failed attempts. Please try again in: ${this.minutesLeft} minutes`,
        ]);
      } else {
        this.$store.commit('personalizedErrors', []);
      }
    },
    getSwapIcon(name: string) {
      return (
        "background-image: url('https://s3.eu-west-1.amazonaws.com/com.mobilum.public/" +
        name +
        ".png');"
      );
    },
    nextScreen() {
      if (this.isMultiSelectOpen) return;
      if (this.loading || this.disabled) return;
      this.$store.state.hideBackButton = true;
      if((this.$store.state.prefillOrder.userWallet != null && this.$store.state.prefillOrder.userWallet != "") && this.$store.state.loggedIn) {
        this.validate();
      } else {
        this.activeScreen++;
      }
      
    },
    searchFocus(id: string) {
      this.isMultiSelectOpen = true;
      setTimeout(() => {
        // @ts-ignore
        document
          .getElementById(id)
          .getElementsByTagName('input')[0]
          .focus();
      }, 5);
    },
    visibilityChanged(isVisible: boolean, entry: any) {
      this.isVisible = isVisible;
    },
    amountBlur() {
      if (this.Amount == '') this.Amount = 0;
      this.Amount = parseFloat(this.Amount).toFixed(5);
    },
    anyInput() {
      this.$store.commit('personalizedErrors', []);
      console.log("any input...")
    },
    onFocus(payload: string) {
      this.lastFocus = payload;
      this.modifyLastFocus(payload);
    },
    getFiatSymbol(code: string) {
      let fiatSymbol = '';
      this.currentLocaleFiat.forEach(i => {
        if (code == i.code) fiatSymbol = i.symbol;
      });
      return fiatSymbol;
    },
    clearNumber(num: string) {
      return num.replace(/\D/g, '');
    },
    formatNumber(num: string) {
      return this.clearNumber(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    onInput(blur: boolean | null, clearErrors: boolean | null, needUpdate: boolean | null) {
      if (clearErrors) this.anyInput();
      if(needUpdate) this.needUpdate = true;

      const regex = new RegExp(/^\d*(\.\d{0,5})?$/);
      while(!regex.test(this.Amount)) {
        this.Amount = this.Amount.substring(0, this.Amount.length - 1);
      }
     
      
      this.changed = false;
      if (!blur) this.loading = true;
      const fiatSymbol = this.getFiatSymbol(this.SelectedCurrency);

      if (this.priceShown.indexOf('.') >= 0) {
        const decimalPos = this.priceShown.indexOf('.');
        let leftSide = this.priceShown.substring(0, decimalPos);
        let rightSide = this.priceShown.substring(decimalPos);
        const newPrice =
          this.clearNumber(leftSide) +
          '.' +
          this.clearNumber(rightSide).substring(0, 2);
        leftSide = this.formatNumber(leftSide);
        rightSide = this.formatNumber(rightSide);
        rightSide = rightSide.substring(0, 2);
        if (blur) {
          if (rightSide.length == 1) rightSide += '0';
          const dot = rightSide.length > 0 ? '.' : '.00';
          this.priceShown = fiatSymbol + leftSide + dot + rightSide;
        } else {
          this.priceShown = fiatSymbol + leftSide + '.' + rightSide;
        }

        if (this.Price != Number(newPrice)) this.Price = Number(newPrice);
      } else {
        if (this.Price != Number(this.clearNumber(this.priceShown)))
          this.Price = Number(this.clearNumber(this.priceShown));
        if (this.clearNumber(this.priceShown) === '' && blur)
          this.priceShown = '0';
        const zeros = blur ? '.00' : '';
        this.priceShown =
          fiatSymbol + this.formatNumber(this.priceShown) + zeros;
      }
    },
    switchState() {
      this.$store.commit('personalizedErrors', []);
      this.stateBuy = !this.stateBuy;
    },
    switchMatic() {
      this.$store.commit('personalizedErrors', []);
      this.stateMatic = !this.stateMatic;
      if (this.stateMatic) {
        this.SelectedCryptoCurrency = 'MATIC ERC20';
      } else {
        this.SelectedCryptoCurrency = 'MATIC Native';
      }
    },
    getFeeDetails() {
      let str = '(';
      let hasPerecent = false;
      if (this.data.ProcessingFees.AsLowAsProcessingFee > 0) {
        str += this.data.ProcessingFees.pct_ALA_ProcessingFee + '%';
        hasPerecent = true;
      }
      if (this.data.ProcessingFees.flatRate > 0) {
        str += hasPerecent
          ? ' + ' +
            processDecimalPlaces(this.data.ProcessingFees.flatRate, 2) +
            ' ' +
            this.SelectedCurrency +
            ' ' +
            this.$t('fixed_flat_rate')
          : processDecimalPlaces(this.data.ProcessingFees.flatRate, 2) +
            ' ' +
            this.SelectedCurrency +
            ' ' +
            this.$t('fixed_flat_rate');
      }
      str += ')';
      return str;
    },
    getDefaultCrypto(): string {
      return this.$store.state.config.cryptoCurrencies[0].ticker;
    },
    async setFiatCurrencies() {
      if (!this.$store.state.countries.length) {
        const response = await getCountries();
        this.$store.state.countries = response.data.result.Countries;
        this.countries = response.data.result.Countries;
      } else {
        this.countries = this.$store.state.countries;
      }
      /*
      IN CASE WE'LL NEED TO UPDATE SAVED CURRENCY LIST FROM MODULE
      const o = CurrencyList.getAll('zh');
      const i = {};
      for(const key in o) {
        const v = o[key];
        i[key] = {
          code: v.code,
          name: v.name,
          symbol: v.symbol
        }
      }
      */
      this.setDefaultFiat();
    },
    setDefaultFiat() {
      // @ts-ignore
      const countryFiatObj: { [key: string]: string } = CountryToCurrencyMap;

      let countryFiat: string | null =
        countryFiatObj[this.$store.state.config.countryCode];

      countryFiat === null ? (countryFiat = 'USD') : true;
      let fiat = 'USD';
      if (
        this.$store.state.config.fiatCurrencies.find(
          (e: any) => e.currency == countryFiat,
        ) != null
      ) {
        fiat = countryFiat;
      } else if (
        this.$store.state.config.fiatCurrencies.find(
          (e: any) => e.currency == fiat,
        ) == null
      ) {
        fiat = this.$store.state.config.fiatCurrencies[0].currency;
      }
      this.SelectedCurrency =
        this.$store.state.prefillOrder?.htmlFiatCurrency ||
        this.$store.state.prefillOrder.CryptoValues?.fiatCurrency ||
        fiat;

      let selectedItem = this.computedCurrentLocaleFiat.find(
        (item: any) => item.code === this.SelectedCurrency,
      );
      if (!selectedItem) {
        selectedItem = this.currentLocaleFiat.find(
          item => item.code === fiat,
        );
      }
      this.selectedCurrencyValue = [
        {
          code: selectedItem.code,
          name: selectedItem.name,
          color: selectedItem?.color,
          symbol: selectedItem?.symbol,
          value: selectedItem.code,
        },
      ];
    },
    getFlag(fiat: string): any {
      const countryCode = Object.keys(CountryToCurrencyMap).find(
        // @ts-ignore
        key => CountryToCurrencyMap[key] === fiat,
      );
      return (
        'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/' +
        countryCode +
        '.png'
      );
    },
    getCryptoIcon(ticker: string | undefined): any {
      let t = ticker?.replace(' ', '%20');
      t = t?.replace('(', '%28');
      t = t?.replace(')', '%29');
      return (
        'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/cryptoicons/' +
        t +
        '.png'
      );
    },
    setMinimumAmount(getPrice: boolean, hasAmount?: boolean, inputLastFocus?: string) {
      this.$store.state.config.cryptoCurrencies.forEach((i: any) => {
        if (i.ticker == this.SelectedCryptoCurrency) {
          if(!hasAmount) {
            this.Amount = i.defaultDisplayAmount;
          }
          this.Amount = parseFloat(this.Amount).toFixed(5);
          if (getPrice) this.getBestPriceData(inputLastFocus)
        }
      });
    },
    async getBestPriceData(inputLastFocus?: string, hasAmount = false) {
      try {
        if (!isFeature(Feature.Swap)) this.swap = false;
        //TODO: Find out, why clearInterval() in beforeDestroy() sometimes isn't called and this.interval keeps on getting called in other screens.
        // @ts-ignore
        if (this.Amount === '' || this.store.state.router.route != 'Exchange' || this.activeScreen > 0) {
          return;
        }

        if (this.changed && inputLastFocus === 'Amount') {
          this.changed = false;
          this.setMinimumAmount(true, hasAmount, 'Amount');
          return;
        }
        
        const clone = this.SelectedCurrency;
        const cloneCrypto = this.SelectedCryptoCurrency;
        const lastFocus = inputLastFocus === 'Amount';

        const symbol = `${this.SelectedCryptoCurrency}-${this.SelectedCurrency}`;
        const amount = lastFocus ? this.Amount : this.Price;

        let response;
        if (inputLastFocus === 'Amount') {
          response = await getBestPrice(
            symbol,
            amount,
            this.$store.state.config.maxOrder,
            this.swap ? 'KLIMA' : null,
            this.swap ? this.selectedSwapValue[0].name.toLowerCase() : null,
          );
        } else {
          //if (this.Price < this.config.minOrder) return;
          response = await getBestPriceByPrice(
            symbol,
            amount,
            this.$store.state.config.maxOrder,
            this.swap ? 'KLIMA' : null,
            this.swap ? this.selectedSwapValue[0].name.toLowerCase() : null,
          );
        }
        
        
        if(this.$store.state.router.route != "Exchange")
          return;
        
        if (response.data.result != null)
          this.SwapAmount = response.data.result.CryptoValues.deliveredAmount;

        
        const errors = response.data.errors;
        if (errors.length && !this.changed) {

          if(response.data.result != null && response.data.result.CryptoValues != null) {
            
            this.data = response.data.result;
            if (inputLastFocus === 'Amount') {
              this.Price = Number(this.data.TransactionTotal.TransactionPrice.toFixed(2));
              this.priceShown = this.Price.toString();
              this.onInput(null, false, false);
            } else if (inputLastFocus === 'Price') {
              this.Amount = this.data.CryptoValues.cryptoQuantity > 0 ? this.data.CryptoValues.cryptoQuantity : 0;
              //this.priceShown = this.Price.toString();
              this.onInput(null, false, false);
            }
            this.$store.commit('personalizedErrors', [
              { message: errors[0].message },
            ]);
            this.loading = false;
            return;
          
          }
          
          
          
          if(response.data.errors[0].code == 1004) {
            const msg = this.$t('exchange_max_price_error') + '' + this.getFiatSymbol(this.SelectedCurrency) + processDecimalPlaces(response.data.result.Price, 2) + " " + this.SelectedCurrency;
            this.$store.commit('personalizedErrors', [
              { message: msg },
            ]);
            this.onInput(true, false, false);
          } else {
            this.$store.commit('personalizedErrors', [
              { message: errors[0].message },
            ]);
          }
          if (
            inputLastFocus === 'Amount' &&
            response.data.result != null &&
            response.data.result.PriceShown != null
          ) {
            this.Price = Number(response.data.result.PriceShown.toFixed(2));
            this.priceShown = this.Price.toString();
            this.onInput(true, false, false);
          }
          return;
        } else if (
          errors.length &&
          response.data.result != null &&
          this.changed
        ) {
          if(response.data.result != null && response.data.result.CryptoValues != null) {

            this.data = response.data.result;
            if (inputLastFocus === 'Amount') {
              this.Price = Number(this.data.TransactionTotal.TransactionPrice.toFixed(2));
              this.priceShown = this.Price.toString();
              this.onInput(null, false, false);
            } else if (inputLastFocus === 'Price') {
              this.Amount = this.data.CryptoValues.cryptoQuantity > 0 ? this.data.CryptoValues.cryptoQuantity : 0;
              //this.priceShown = this.Price.toString();
              this.onInput(null, false, false);
            }
            this.$store.commit('personalizedErrors', [
              { message: errors[0].message },
            ]);
            this.loading = false;
            this.changed = false;
            return;
          }

        } else if (errors.length) {
          if(response.data.errors[0].code == 1004) {
            const msg = this.$t('exchange_max_price_error') + '' + this.getFiatSymbol(this.SelectedCurrency) + processDecimalPlaces(response.data.result.Price, 2) + " " + this.SelectedCurrency;
            this.$store.commit('personalizedErrors', [
              { message: msg },
            ]);
            this.onInput(true, false, false);
          } else {
            this.$store.commit('personalizedErrors', [
              { message: errors[0].message },
            ]);
          }
         
        }

        if (
          this.SelectedCurrency !== clone ||
          this.SelectedCryptoCurrency !== cloneCrypto
        )
          return;

        this.data = response.data.result;

        if (inputLastFocus === 'Amount') {
          if (
            processDecimalPlacesForce(this.data.CryptoValues.cryptoQuantity, 8) !==
            processDecimalPlacesForce(this.Amount, 8)
          )
            return;
          this.Price = Number(this.data.TransactionTotal.TransactionPrice.toFixed(2));
          this.priceShown = this.Price.toString();
          this.onInput(null, true, false);
        } else if (inputLastFocus === 'Price') {
          
          this.Amount = this.data.CryptoValues.cryptoQuantity;
          //this.priceShown = this.Price.toString();
          this.onInput(null, true, false);
        }
        this.checkMaticNotice();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    checkMaticNotice() {
      this.maticNotice =
        this.data.CryptoValues.symbol == 'MATIC Native' ||
        this.data.CryptoValues.symbol == 'MATIC ERC20';
    },
    reportValidity(): boolean {
      const form: HTMLFormElement = this.$refs.form2 as HTMLFormElement;
      if(form == null) {
        return true;
      }
      return form.reportValidity();
    },
    checkValidity() {
      return (this.$refs.form2 as HTMLFormElement).checkValidity();
    },
    async validate() {
      if (!this.reportValidity()) return;

      this.$store.commit('personalizedErrors', []);
      if (!this.$store.state.loggedIn) {
        const validEmail = new RegExp(/^\S+@\S+\.\S+$/).test(this.UserEmail);
        if (!validEmail) {
          this.$store.commit('personalizedErrors', [
            { code: '27', message: 'Invalid Email!' },
          ]);
          return;
        }
      }
      const errors = [];
      const regex = /^(([^<>()[\]\\.`,{//=!}*^&%#;:\s@"]+(\.[^<>()[\]\\.`,{//=!}*^&%#;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let cryptoValidation = '';
      try {
        let crypto = 'BTC';

        const item = this.config.cryptoCurrencies.find(
          (item: { ticker: string, validationType: string }) =>
            item.ticker === this.selectedCryptoCurrencyValue[0].ticker && item.validationType === this.selectedCryptoCurrencyValue[0].validationType
        );
        if (item) {
          crypto = item.validationType;
          if (crypto == null || crypto == '') crypto = item.ticker;
          cryptoValidation = item.validationType;
        }
        let valid = false;
        if (item?.validationRegex) {
          const regexp = new RegExp(item.validationRegex as string);
          valid = regexp.test(this.$store.state.userWallet);
        } else {
          valid = WAValidator.validate(this.$store.state.userWallet, crypto, 'prod', [
            'cashaddr',
          ]);
        }
        if (!valid) {
          const msg = this.SelectedCryptoCurrency == 'USDT' ? cryptoValidation == 'ETH' ? 'Please enter a valid ETH address' : 'Please enter a valid TRON address' : `Must be a valid ${this.SelectedCryptoCurrency} wallet address`;
          regex.test(this.$store.state.userWallet) ||
            errors.push(
              msg
            );
        }
      } catch (error) {
        const msg = this.SelectedCryptoCurrency == 'USDT' ? cryptoValidation == 'ETH' ? 'Please enter a valid ETH address' : 'Please enter a valid TRON address' : `Must be a valid ${this.SelectedCryptoCurrency} wallet address`;
        regex.test(this.$store.state.userWallet) ||
          errors.push(
            msg
          );
      }
      this.$store.commit('personalizedErrors', errors);
      if (errors.length) {
        return;
      }
      try {
        this.disabled = true;
        const body = {
          Amount: 0,
          SelectedCurrency: this.SelectedCurrency,
          SelectedCryptoCurrency: this.SelectedCryptoCurrency,
          Price: 0,
          UserWallet: this.$store.state.userWallet,
          DeliveredCryptoCurrency: this.swap ? 'KLIMA' : null,
          DeliveredExchange: this.swap
            ? this.selectedSwapValue[0].name.toLowerCase()
            : null,
        } as { [key: string]: string | number | boolean | any };
        if (this.lastFocus === 'Price') {
          body.KeepConstantAmount = true;
          body.Price = this.Price;
        } else {
          body.Amount = this.Amount;
        }
        this.modifyLastFocus(this.lastFocus);
        //this.modifyUserWallet(this.UserWallet);
        this.modifyExtraFieldsValues(this.extraFieldsValues);

        const additionalFields = [];

        for (let i = 0; i < this.getExtraFields.length; ++i) {
          additionalFields.push({
            Name: this.getExtraFields[i].name,
            Value: this.extraFieldsValues[i],
          });
        }

        if (additionalFields.length > 0) {
          body.AdditionalFields = additionalFields;
        }

        const response = await createOrder(
          body,
          this.isLoggedIn ? null : this.UserEmail,
        );

        sendAnalytics({
          EventCategory: 'general',
          EventAction: 'order',
          EventLabel: 'order placed',
        });

        this.$store.commit('order', { uuid: response.data.result.orderUuid, cryptoValidation: cryptoValidation });
        if (!response.data.success && response.data.errors.length) {
          if (response.data.result.BlockedUntil) {
            this.blockedUntil = moment.utc(
              response.data.result.BlockedUntil,
              'YYYY-MM-DD HH:mm:ss',
            );
            this.calculateTimeLeft(
              moment.utc(
                response.data.result.BlockedUntil,
                'YYYY-MM-DD HH:mm:ss',
            ));
          } else {
            this.$store.commit('personalizedErrors', response.data.errors.map((item: { message: string }) => item.message));
          }
        } else {
        if (response.data.success)
          this.$store.commit('profile', {
            email: this.UserEmail,
            blockedRegion: response.data.result.RegionName,
          });

        this.$store.state.currentOrderWallet = this.$store.state.userWallet;
        if (this.$store.state.fromCards) {
          this.$store.commit('resumePurchase');
          //return;
        }
        this.$store.state.swap = this.swap;
        this.$store.state.swapExchange = this.selectedSwapValue;
        //const redirect = redirectView(response.data);
        //if (!redirect) {
        this.$store.state.hideBackButton = false;
        if (this.isLoggedIn) {
          if (response.data.redirectUrl == 'PerformKYC') {
            const kyc = await checkKycVerified(this.$store.state.order.uuid);
            if (kyc.data) {
              if (kyc.data.result.canPay) {
                if (
                  this.$store.state.userCards.length ||
                  this.$store.state.unsavedCard != null
                ) {
                  this.$store.commit('router/goTo', Routes.PurchaseSummary);
                } else {
                  this.$store.commit('router/goTo', Routes.SelectMethod);
                }
              } else {
                this.$store.commit('router/goTo', Routes.Kyc);
              }
            } else {
              this.$store.commit('router/goTo', Routes.Kyc);
            }
          } else if (
            this.$store.state.userCards.length ||
            this.$store.state.unsavedCard != null
          ) {
            this.$store.commit('router/goTo', Routes.PurchaseSummary);
          } else {
            this.$store.commit('router/goTo', Routes.CardList);
          }
        } else {
          this.$store.commit('router/goTo', Routes.Tfa);
        }
      }
        //}
      } finally {
        this.disabled = false;
      }
    },
    switchOrder(route : Routes) {
      this.$store.state.pageQueue.pop();
      this.$store.commit('router/goTo', route);
    },
    async shouldPerformKyc() {
      const kyc = await shouldPerformKyc();
      if (kyc.data != null) {
        if (kyc.data == true) {
          this.kycStarted = false;
        } else {
          this.kycStarted = true;
          if (this.kycStartedInterval) {
            clearInterval(this.kycStartedInterval);
          }
        }
      }
    },
    async scanQrCode() {
      this.openScanQr = true;
    },
    onContentScanned(content: string) {
      const walletAddress = content ? content.split(':').pop() : '';
      this.$store.state.userWallet = walletAddress;
      this.UserWallet = walletAddress;
      this.hideQrCodeModal();
      this.showModal = false;
    },
    hideQrCodeModal() {
      this.openScanQr = false;
    },
    async getWalletWalletConnect() {
      this.walletButton = true;
      this.modalError = false;
      this.modalLoader = true;
      localStorage.removeItem('walletconnect');

      const providerWalletConnect: any = new WalletConnectProvider({
        rpc: {
          1: 'https://eth.getblock.io/mainnet/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          3: 'https://eth.getblock.io/ropsten/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          5: 'https://eth.getblock.io/goerli/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          42: 'https://eth.getblock.io/kovan/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
          4: 'https://eth.getblock.io/rinkeby/?api_key=2e7ed331-3164-46c9-ba87-5035d6b1a8fc',
        },
      });
      let web3;

      try {
        await providerWalletConnect.enable();
        web3 = new Web3(providerWalletConnect);
      } catch (error) {
        this.modalError = 'external_wallet_error_walletconnect_provider';
        this.modalLoader = false;
        this.walletButton = false;
        return;
      }

      // detect wallet network
      const networkId = await web3.eth.net.getId();
      // development env requierd testnet
      if (process.env.NODE_ENV === 'development' && networkId === 1) {
        this.modalError = 'external_wallet_testnet_requierd';
        this.modalLoader = false;
        this.walletButton = false;
        return;
      }
      // production env requierd mainent
      if (process.env.NODE_ENV !== 'development' && networkId > 1) {
        this.modalError = 'external_wallet_mainnet_requierd';
        this.modalLoader = false;
        this.walletButton = false;
        return;
      }
      // production env requierd mainent
      if (networkId !== 1 && networkId !== 3) {
        this.modalError =
          process.env.NODE_ENV !== 'development'
            ? 'external_wallet_net_supporting'
            : 'external_wallet_net_supporting_dev';
        this.modalLoader = false;
        this.walletButton = false;
        return;
      }

      try {
        const accounts = await web3.eth.getAccounts();
        // setting states
        this.$store.state.userWallet = accounts[0];
        this.UserWallet = accounts[0];
      } catch (e) {
        this.modalError = 'external_wallet_error_walletconnect_not_allow';
      }
      this.walletButton = false;
      this.showModal = false;
      this.modalLoader = false;
      return true;
    },
    //// external wallets connect
    async getWalletMetamask() {
      this.walletButton = true;
      this.modalError = false;
      this.modalLoader = true;

      let web3: Web3 | undefined = undefined; // Will hold the web3 instance

      if (!(window as any).ethereum || !(window as any).ethereum.isMetaMask) {
        this.modalError = 'external_wallet_error_metamask_provider';
        this.modalLoader = false;
        this.walletButton = false;
        return;
      }

      if (!web3) {
        try {
          await (window as any).ethereum.enable();
          web3 = new Web3((window as any).ethereum);
        } catch (error) {
          this.modalError = 'external_wallet_error_metamask_not_allow';
          this.modalLoader = false;
          this.walletButton = false;
          return;
        }
      }

      // detect wallet network
      const networkId = await web3.eth.net.getId();
      // development env requierd testnet
      if (process.env.NODE_ENV === 'development' && networkId === 1) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(3) }],
          });
        } catch (e) {
          this.modalError = 'external_wallet_testnet_requierd';
          this.modalLoader = false;
          this.walletButton = false;
          return;
        }
      }
      // production env requierd mainent
      if (process.env.NODE_ENV !== 'development' && networkId > 1) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(1) }],
          });
        } catch (e) {
          this.modalError = 'external_wallet_mainnet_requierd';
          this.modalLoader = false;
          this.walletButton = false;
          return;
        }
      }
      // production env requierd mainent
      if (networkId !== 1 && networkId !== 3) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(1) }],
          });
        } catch (e) {
          this.modalError =
            process.env.NODE_ENV !== 'development'
              ? 'external_wallet_net_supporting'
              : 'external_wallet_net_supporting_dev';
          this.modalLoader = false;
          this.walletButton = false;
          return;
        }
      }

      try {
        const account = await web3.eth.getAccounts();
        // setting states
        this.$store.state.userWallet = account[0];
        this.UserWallet = account[0];
      } catch (e) {
        window.alert('Something went wrong. Please try again.');
      }
      this.walletButton = false;
      this.showModal = false;
      this.modalLoader = false;
      return true;
    },
    showModalWallet() {
      this.showModal = true;
    },
    hideModalWallet(event: any) {
      if (event.target.className === 'modal_wrapper') {
        this.showModal = false;
      }
    },
    debounce: debounce((value: number, vm: any) => {
      console.log();
      /*const errors = [] as object[];
      if (value < vm.config.minOrder) {
        errors.push(
          {
            message : vm.$t('min_order_must_be_greater_than', { value: (vm.config.minOrder as number).toLocaleString()}),
            code : '00'
          }
        );
      }
      vm.$store.commit('personalizedErrors', errors);*/
    }, 600),
  },
  watch: {
    Price(value: number) {
      this.debounce(value, this);
    },
    SelectedCryptoCurrency: function(val, oldVal) {
      this.extraFieldsValues = [];
      //this.UserWallet = '';
    },
    selectedCryptoCurrencyValue() {
      if (this.selectedCryptoCurrencyValue.length) {
        this.loading = true;
        this.changed = true;
        this.SelectedCryptoCurrency = this.selectedCryptoCurrencyValue[0].ticker;
        this.$store.state.selectedCryptoChain = this.selectedCryptoCurrencyValue[0].validationType || this.selectedCryptoCurrencyValue[0].ticker;
        this.$store.state.selectedCryptoToken = this.selectedCryptoCurrencyValue[0].ticker;
      }
    },
    selectedCurrencyValue() {
      if (this.selectedCurrencyValue.length) {
        this.loading = true;
        this.changed = true;
        this.SelectedCurrency = this.selectedCurrencyValue[0].code;
      }
    },
    Lang(val: string) {
      this.setFiatCurrencies();
    },
    async currentDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.minutesLeft > 0) {
          this.calculateTimeLeft();
        } else {
          clearInterval(this.timeInterval);
          this.minutesLeft = 0;
          this.$store.commit('personalizedErrors', []);
        }
      }
    },
  },
  async mounted() {
    this.$store.commit('setPreviousPage', 'Order');
    if(this.$store.state.config.defaultWalletAddress != '') {
      this.$store.state.userWallet = this.$store.state.config.defaultWalletAddress;
      this.$store.state.config.defaultWalletAddress = '';
    }
    this.$store.state.selectedPhazeCountry = '';
    if (this.isLoggedIn) {
      this.UserEmail = this.$store.state.profile.email;
    }
    let constantAmount = true;
    if (this.$store.state.order.uuid != '') {
      this.selectedSwapValue = this.$store.state.swapExchange;
      const body_tx = {
        cardNumber : null,
        cardId : null
      }
      const response = await gerOrderDetails(body_tx);
      constantAmount = response.data.result.KeepConstantAmount;
      this.swap = this.$store.state.swap;
      if (this.$store.state.config.showFees) {
        this.modifyOrder(response.data.result);
      } else {
        this.modifyOrder(response.data.result.order);
      }
      this.Amount =
        this.$store.state.prefillOrder.htmlCryptoAmount ||
        this.$store.state.prefillOrder.CryptoValues?.cryptoQuantity;
      this.Price =
        this.$store.state.prefillOrder.htmlFiatAmount ||
        this.$store.state.prefillOrder.TransactionTotal?.TransactionPrice;
    }
    if (
      !constantAmount ||
      (this.$store.state.prefillOrder.htmlFiatAmount != '' &&
        this.$store.state.prefillOrder.htmlFiatAmount !== undefined &&
        this.$store.state.prefillOrder.htmlFiatAmount != null)
    ) {
      this.lastFocus = 'Price';
      this.modifyLastFocus('Price');
    } else if (this.$store.state.order.uuid == '') {
      this.lastFocus = 'Amount';
      this.setMinimumAmount(false, true, 'Amount');
    }
    this.priceShown = this.Price.toString();
    this.onInput(null, false, false);
    await this.setFiatCurrencies();
    this.SelectedCryptoCurrency =
      this.$store.state.prefillOrder?.CryptoValues?.symbol ||
      this.$store.state.prefillOrder.htmlCryptoCurrency ||
      this.$store.state.prefillOrder.Price?.symbol ||
      this.$store.state.selectedCryptoToken ||
      this.getDefaultCrypto();

    
    const selectedItem = this.computedCryptoCurrenciesOptions.find(
      (item: { ticker: string }) => item.ticker === this.SelectedCryptoCurrency,
    );
    this.selectedCryptoCurrencyValue = [
      {
        additionalFields: selectedItem.additionalFields,
        name: selectedItem.name,
        ticker: selectedItem.ticker,
        validationRegex: selectedItem.validationRegex,
        validationType: selectedItem.validationType,
      },
    ];
    this.$store.state.selectedCryptoChain = selectedItem.validationType || selectedItem.ticker;
    if (this.$store.state.order.uuid == '')
      this.selectedSwapValue = [{ id: 2, name: 'Sushiswap', value: 2 }];

    //if (this.isVisible) await this.getBestPriceData();

    if (this.isLoggedIn) {
      this.shouldPerformKyc();
      this.kycStartedInterval = setInterval(async () => {
        this.shouldPerformKyc();
      }, 5000);
    }
    await this.getBestPriceData(
      this.lastFocus,
      !!(this.lastFocus === 'Amount' && this.Price),
    );
    let waiting_for_request = false;
    this.interval = setInterval(async () => {
      if (waiting_for_request) {
        console.log('waiting....');
        return;
      }
      if(!this.needUpdate && this.retryCount < 10) {
        this.retryCount++;
        return;
      }
      this.needUpdate = false;
      this.retryCount = 0;
      
      waiting_for_request = true;
      if (this.isVisible) await this.getBestPriceData(this.lastFocus);
      waiting_for_request = false;
    }, 2000);
    this.timeInterval = window.setInterval(() => {
      this.currentDate = moment.utc();
    }, 60000);
  },
  beforeDestroy() {
    store.state.hideBackButton = false;
	this.$store.commit('personalizedErrors', []);
    if (this.interval) {
      clearInterval(this.interval);
      if (this.kycStartedInterval) clearInterval(this.kycStartedInterval);
    }
  },
});
</script>

<style scoped lang="scss">
::v-deep .select {
  .checkboxLayer {
    width: 99%;
  }
  .btn-select {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: none;
  }
  .buttonLabel {
    display: flex;
    align-items: center;
  }
}
.MBMWidget_Exchange_Swap .select .checkboxLayer {
  box-shadow: none !important;
}
.MBMWidget_Exchange .select .checkBoxContainer {
  max-height: 75px !important;
  min-height: auto !important;
}
</style>
