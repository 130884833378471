<template>
  <div class="MBMWidget_KycUrl">
    <div class="MBMWidget_col-np-6 MBMWidget_col-np-md-6 MBMWidget_col-np-xs-12 MBMWidget_KycUrl_QR_Block MBMWidget_py-0">
      <span class="MBMWidget_KycUrl_Title">{{$t('kyc_phone')}}</span>
      <AppQrCode class="MBMWidget_Qrcode" :value="url" size="150" />
    </div>
    <div class="MBMWidget_col-np-6 MBMWidget_col-np-md-6 MBMWidget_col-np-xs-12 MBMWidget_py-0">
      <div class="MBMWidget_col-np-8 MBMWidget_col-np-md-10 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <span class="MBMWidget_KycUrl_Title">{{$t('kyc_desktop')}}</span>
        <span class="MBMWidget_KycUrl_Subtitle"> {{ $t('kyc_url_click_link') }}</span>
        <AppButton
          color="primary"
          class="MBMWidget_ButtonLink MBMWidget_full-width MBMWidget_my-5"
          @click="onClick()"
        >
          {{ $t('kyc_url_button_text') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Qrcode from 'qrcode.vue';
import Button from './Button.vue';
export default Vue.extend({
  props: {
    url: String,
  },
  components: {
    AppQrCode: Qrcode,
    AppButton: Button,
  },
  methods: {
    onClick() {
      window.open(this.url, "_blank");
    },
  },
});
</script>
