import store from '@/store';
import { Routes } from '@/store/modules/Router';

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  console.log('Cookies:' + decodedCookie);
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function displayErrors(errors: { message: string }[]) {
  const parsedErrors = errors.map(item => item.message);
  store.state.errors = parsedErrors;
  return parsedErrors;
}

export function redirectViewString(redirectUrl: string): boolean {
  if (redirectUrl) {
    const routes = {
      Login: Routes.Login,
      Order: Routes.Exchange,
      TwoFactorAuth: Routes.Tfa,
      VerifyAccount: Routes.Verify,
      ForgotPassword: Routes.Forgot,
      Card: Routes.AddCard,
      VerifyEmail: Routes.Tfa,
      VerifySMS: Routes.Tfa,
      PerformKYC: Routes.Kyc,
      RegionBlocked: Routes.RegionBlocked,
      AddCard: Routes.AddCard,
      CardList: Routes.CardList,
      PurchaseSummary: Routes.PurchaseSummary,
      PurchaseSuccess: Routes.PurchaseSuccess,
      TransactionHistory: Routes.TransactionHistory,
      CardRedirect: Routes.CardRedirect,
      CheckKyc: Routes.CheckKyc,
      Logout: Routes.Logout,
      PhazeCardList: Routes.PhazeCardList,
      KycInProgress: Routes.KycInProgress,
      PhazeCardCheckout: Routes.PhazeCardCheckout,
      PhazeCardHistory: Routes.PhazeCardHistory,
      PhazeCardPurchased: Routes.PhazeCardPurchased,
      PhazeCardPendingDelivery: Routes.PhazeCardPendingDelivery,
      PhazeCardSummary: Routes.PhazeCardSummary,
      Checkout: Routes.Checkout,
      OrderDetails: Routes.OrderDetails,
      ChooseCrypto: Routes.ChooseCrypto,
      PhazeCardShoppingCart: Routes.PhazeCardShoppingCart,
      Profile: Routes.Profile,
      ProfileCryptoFlow: Routes.ProfileCryptoFlow,
      ProfileGiftcardFlow: Routes.ProfileGiftcardFlow,
      JwtExpired: Routes.JwtExpired,
      Bridge: Routes.Bridge,
      SelectMethod: Routes.SelectMethod
    } as { [key: string]: string };

    const nextRoute = routes[redirectUrl];
    if (nextRoute) {
      store.state.backPage = '';
      store.commit('router/goTo', nextRoute);
      return true;
    }
  }
  store.commit('router/goTo', 'Exchange');
  return false;
}

export function redirectView(data: any): boolean {
  return redirectViewString(data.redirectUrl);
}

export function processDecimalPlaces(value: number, places: number, min = 2): string {
  return parseFloat(
    value.toString()
  ).toLocaleString(
    undefined,
    {
      minimumFractionDigits: min,
      maximumFractionDigits: places
    }
  );
}

export function processDecimalPlacesForce(value: number, places: number): string {
  return parseFloat(
    value.toString()
  ).toLocaleString(
    undefined,
    {
      minimumFractionDigits: places,
      maximumFractionDigits: places
    }
  );
}

export function zeroPad(num: any, places: any): string {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export function isFeature(feature: string) {
  if (store.state.config.features == null) return false;

  store.state.config.features = store.state.config.features.replace(" ", "");
  const currFeatures: string[] = store.state.config.features.split(',');
  return currFeatures.includes(feature)
}
