<template>
  <div :class="$attrs.class" class="MBMWidget_GeneralInput">
    <label :class="getClass()">{{ label }} <span v-if="tooltip" v-tooltip.bottom-start="tooltip">i</span></label>
    <br v-if="label" />
    <input
      class="MBMWidget_mt-2 MBMWidget_full-width"
      v-bind="$attrs"
      :class="inputClass"
      ref="inp"
      @click="$emit('click', $event)"
      @input="$emit('input', $event.target.value)"
      @keydown="$emit('keydown', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({
    props: {
      label: String,
      inputClass: String,
      tooltip: String
    },
    methods: {
      getClass(){
        return this.$attrs.value != '' ? '' : 'input_empty_label';
      },
      doFocus(val: boolean){
        const el = this.$refs.inp as HTMLInputElement;
        if(val)
          el.focus();
        else
          el.blur();
      },
    }
  });
</script>
