<template>
  <div class="MBMWidget_Kyc MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t('kyc_screen_title') }}
    </div>
    
    <!--<div class="MBWidget_verify_wrap">
      
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0">
      <ErrorMessage></ErrorMessage>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12">
      <span class="MBMWidget_KycUrl_Step_Title">{{$t('kyc_step1')}}</span>
      <AppKycUrl v-if="url" :url="url" />
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12">
      <span class="MBMWidget_KycUrl_Step_Title">{{$t('kyc_step2')}}</span>
      <div class="MBMWidget_subtitle MBMWidget_Kyc_Subtitle">
        {{ $t('kyc_screen_kyc_process_expl') }}
      </div>
    </div>-->
    <div id="sumsub-websdk-container"></div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-md-12 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat" v-if="canContinue">
        <AppButton
          class="MBMWidget_full-width MBMWidget_Exchange_ButtonCheckout MBMWidget_defaultAppButton"
          color="primary"
          @click="forward()"
        >
          {{ $t('kyc_progress_continue') }}
        </AppButton>
      </div>
    </div>
    
  </div>
</template>
<script lang="ts">
  import {checkKyc, isKycComplete, performKyc, sendAnalytics, StartKyc} from '@/api';
import Button from '@/components/General/Button.vue';
import { Routes } from '@/store/modules/Router';
  import {redirectViewString, setCookie} from '@/utils';
import snsWebSdk from '@sumsub/websdk'
  
import Vue from 'vue';
import KycUrl from '../General/KycUrl.vue';
  import ErrorMessage from "@/components/Components/ErrorMessage.vue";
export default Vue.extend({
  components: {
    AppButton: Button
    //AppKycUrl: KycUrl,
    //ErrorMessage: ErrorMessage
  },
  data() {
    return {
      waiting: false,
      completed: false,
      interval: null as any,
      Routes: Routes,
      started: false,
      loading: false,
      url: '',
      canContinue: false,
    };
  },
  methods: {
    async kycStart() {
      this.loading = true;
      await sendAnalytics({
        EventCategory: 'general',
        EventAction: 'kyc-init',
        EventLabel: 'KYC initiated',
      });
      this.initSumsub();
    },
    forward() {
      let isGiftCards = false;
      for(let i = this.$store.state.pageQueue.length-1; i > 0; i--){
        if(this.$store.state.pageQueue[i].includes('Exchange'))
          break;
        if(this.$store.state.pageQueue[i].includes('Phaze')){
          isGiftCards = true;
          break;
        }
      }
      if(isGiftCards){
        this.$store.commit('router/goTo',  Routes.Checkout);
      } else {
        this.$store.commit('router/goTo', Routes.SelectMethod);
      }
      
      this.$store.state.loggedIn = true;
    },
    async redirectFurther() {
      await sendAnalytics({
        EventCategory: 'general',
        EventAction: 'kyc-passed',
        EventLabel: 'KYC passed',
      });
      this.canContinue = true;
    },
    async requestToken(): Promise<string> {
      const response = await performKyc();
      if(response.data) {
        this.started = true;
        return new Promise((resolve) => {
          resolve(response.data.kycRequestId)
        })
      } else {
        this.loading = false;
        return '';
      }
    },
    async getNewAccessToken(): Promise<string> {
      return await this.requestToken();
    },
    async initSumsub() {
      const accessToken =  await this.getNewAccessToken();
      const applicantEmail = '';
      const applicantPhone = '';
      const customI18nMessages = undefined;
      
      if(accessToken == '' || accessToken == undefined) return;
      
      const snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        () => this.getNewAccessToken()
      )
        .withBaseUrl(this.$store.state.config.sumsubUrl)
        .withConf({
          lang: this.$i18n.locale, //language of WebSDK texts and comments (ISO 639-1 format)
          /*email: applicantEmail,
          phone: applicantPhone,
          i18n: customI18nMessages,*/ 
          uiConf: {
            customCss: undefined//"https://...styles.css"
          },
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true})
        // see below what kind of messages WebSDK generates
        .on('idCheck.stepCompleted', (payload: any) => {
          console.log('stepCompleted', payload)
        })
        .on('idCheck.onError', (error: any) => {
          console.log('onError', error)
        })
        .onMessage((type: any, payload: any) => {
          console.log('onMessage', type, payload)
          if(type == 'idCheck.applicantStatus') {
            if(payload.reviewStatus == 'completed'){
              if(payload.reviewResult.reviewAnswer == 'GREEN'){
                this.redirectFurther();
              }
            }
            if(payload.reviewStatus == 'pending'){
              this.canContinue = true;
            }
          }
        })
        .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    }
  },
  mounted() {
    this.$store.commit('setPreviousPage', 'Kyc');
    this.kycStart();
  },
  beforeDestroy() {
    this.$store.state.pageQueue.pop();
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
});
</script>
