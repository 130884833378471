import Vue from "vue";
import {Routes} from "@/store/modules/Router";
import store from "@/store";
//import { getChainConfig } from "caip-api";
//import {getSupportedChains} from "caip-api/dist/cjs/helpers/utils";
//import {UniversalProvider} from "@walletconnect/universal-provider";
//import Web3Modal from "web3modal";
export const DEFAULT_MAIN_CHAINS = [
    // mainnets
    "eip155:1",
    "eip155:10",
    "eip155:100",
    "eip155:137",
    "eip155:42161",
    "eip155:42220",
];

export const DEFAULT_TEST_CHAINS = [
    // testnets
    "eip155:42",
    "eip155:69",
    "eip155:80001",
    "eip155:421611",
    "eip155:44787",
];

export const DEFAULT_CHAINS = [...DEFAULT_MAIN_CHAINS, ...DEFAULT_TEST_CHAINS];
const DEFAULT_PROJECT_ID = "1eccdcef1fec662a8e65ca062f39ed04";
const DEFAULT_RELAY_URL = "wss://relay.walletconnect.com";

export default Vue.extend({
    data() {
        return {
            chainData : {} as any,
            loading: true,
            
            provider : null as any,
            client: null as any,
            web3Modal : null as any
        };
    },
    methods: {
        getAllChainNamespaces() {
            const namespaces: string[] = [];
            DEFAULT_CHAINS.forEach((chainId) => {
                const [namespace] = chainId.split(":");
                if (!namespaces.includes(namespace)) {
                    namespaces.push(namespace);
                }
            });
            return namespaces;
        },
        /*async loadChainData() {
            const namespaces = this.getAllChainNamespaces();
            
            await Promise.all(
                namespaces.map(async (namespace) => {
                    let chains;
                    try {
                        chains = getSupportedChains(namespace);
                    } catch (e) {
                        // ignore error
                        console.log(e);
                    }
                    if (typeof chains !== "undefined") {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        this.chainData = chains;
                    }
                })
            );
            //setChainData(chainData);
        },*/
        /*async createClient() {
            try {
                this.loading = true;

                const provider = await UniversalProvider.init({
                    projectId: DEFAULT_PROJECT_ID,
                    logger: "debug",
                    relayUrl: DEFAULT_RELAY_URL,
                });

                const web3Modal = new Web3Modal();

                this.provider = provider;
                this.client = provider.client;
                this.web3Modal = web3Modal;
                
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        }*/
    }
});