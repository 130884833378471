<template>
  <div class="MBMWidget_Purchase_Summary MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Purchase_Summary_Fraud_Error"  v-if="fraudRuleError">
      <div class="MBMWidget_Phaze_Card_List_Expired_Icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 50 50" fill="none">
          <path d="M26.9749 32C25.6436 32.0063 24.3624 31.475 23.4186 30.5313L15.9999 23.1063L8.58111 30.5313C6.62486 32.5 3.44361 32.5125 1.47486 30.5563C-0.493886 28.6 -0.512636 25.4188 1.44361 23.45C1.45611 23.4375 1.46236 23.4313 1.47486 23.4188L8.89361 16L1.47486 8.58125C-0.481386 6.60625 -0.468886 3.425 1.50611 1.46875C3.46236 -0.474997 6.62486 -0.474997 8.58111 1.46875L15.9999 8.89375L23.4186 1.46875C25.3749 -0.499997 28.5561 -0.512497 30.5311 1.44375C32.5061 3.4 32.5186 6.58125 30.5624 8.55C30.5499 8.5625 30.5436 8.56875 30.5311 8.58125L23.1124 16L30.5311 23.4188C32.4936 25.3813 32.4936 28.5625 30.5311 30.525C29.5874 31.4688 28.3061 32 26.9749 32Z" fill="#EB5757"/>
        </svg>
        <!--<WindowCloseIcon :size="50" fillColor="#EB5757"></WindowCloseIcon>-->
      </div>
      <span v-html="fraudRuleError"></span>
    </div>
    <div
      class="
          MBMWidget_col-np-12
          MBMWidget_col-np-xs-12
          MBMWidget_Loading
          MBMWidget_text-center
          loading_style
        "
      v-if="!loadFirstTime && !fraudRuleError"
    >
      {{ $t('loading') }}
    </div>
    <span v-if="!fraudRuleError">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-1" v-if="loadFirstTime">
      {{ $t('purchase_summary_title') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center MBMWidget_mt-4" v-if="store.state.personalizedErrors.length > 0">
      <ErrorMessage></ErrorMessage>
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12">
      
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_KYC MBMWidget_mb-3" v-if="!kycVerified && !canPay">
        <span><strong>{{$t('purchase_summary_kyc_title')}}</strong></span>
        <span>{{$t('purchase_summary_kyc_description')}}</span>
      </div>
      
      <!--<div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_Payment MBMWidget_py-2" v-if="loadFirstTime && !loadingPayment && canPay">
        <div class="MBMWidget_col-np-8 MBMWidget_col-np-xs-8 MBMWidget_mb-1" v-if="cardList.length">
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_py-0 MBMWidget_Select_Label">{{$t('profile_country')}}</div>
        <cool-select
          v-model="card"
          :items="cardList"
          :menuItemsMaxHeight="'220px'"
          item-value="hiddenCardNumber"
          item-text="hiddenCardNumber"
          :disableSearch="true"
          class="MBMWidget_full-width MBMWidget_AddCard_Select_Country_Dropdown"
        >
          <template slot="item" slot-scope="{ item: i }">
            <div style="display: flex; align-items: center;">
              <span :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+i.alpha2Code+'.png)'" class="country-flag"></span>
              <div><b>{{ i.hiddenCardNumber }}</b></div>
            </div>
          </template>

          <template slot="selection" slot-scope="{ item: i }">
            <span :style="'background-image: url(https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/'+i.alpha2Code+'.png)'" class="country-flag"></span>
            <div><b>{{ i.hiddenCardNumber }}</b></div>
            <ChevronDownIcon :size="22" fillColor="#444"></ChevronDownIcon>
          </template>
        </cool-select>
      </div>
      </div>-->
      
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_Payment MBMWidget_py-2" v-if="loadFirstTime && !loadingPayment && canPay">
        <div class="MBMWidget_col-np-8 MBMWidget_col-np-xs-8 MBMWidget_col-np-md-8" v-if="!store.state.wirePayment">
          <span class="MBMWidget_Purchase_Summary_Payment_Title">{{$t('payment_method')}}</span>
          <span class="MBMWidget_Purchase_Summary_Payment_Card">
            <span :class="'MBMWidget_card_'+getCardIcon(card.hiddenCardNumber)+' MBMWidget_Purchase_Summary_Card_Icon'"></span>
            **** **** **** {{card.hiddenCardNumber.substr(card.hiddenCardNumber.length - 4)}} 
            <ChevronDownIcon :size="28" fillColor="#222" @click="store.commit('router/goTo', Routes.CardList)"></ChevronDownIcon>
          </span>
        </div>
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_col-np-md-12" v-else>
          <span class="MBMWidget_Purchase_Summary_Payment_Title">{{$t('payment_method')}}</span>
          <span class="MBMWidget_Purchase_Summary_Payment_Wire">
            {{$t('purchase_summary_transfer')}}
            <a @click="store.commit('router/goTo', Routes.SelectMethod)">{{ $t('purchase_summary_edit_payment_method_short') }}</a>
          </span>
        </div>
        <div class="MBMWidget_col-np-4 MBMWidget_col-np-xs-4 MBMWidget_col-np-md-4" v-if="!store.state.wirePayment">
          <span class="MBMWidget_cvv">
              <form @submit.prevent>
                <AppInput
                  class="MBMWidget_Login_AppInputPassword"
                  :label="$t('purchase_summary_enter_cvv')"
                  v-model="CVV"
                  required
                  :placeholder="'000'"
                  ref="CVV"
                  :max-length="3"
                  :focus="true"
                  type="password"
                />
              </form>
          </span>
        </div>
      </div>
      
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_Payment MBMWidget_Purchase_Summary_Wallet MBMWidget_py-2" v-if="loadFirstTime && !loadingPayment && canPay && !store.state.config.fiatOnlyMode">
        <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_col-np-md-12">
          <span class="MBMWidget_Purchase_Summary_Payment_Title">{{ order.CryptoValues.symbol == "USDT" ? store.state.order.cryptoValidation == "TRX" ? "Tether USDT (TRC-20)" : "Tether USDT (ERC-20)" : order.CryptoValues.symbol}} {{$t('will_be_delivered')}}</span>
          <span class="MBMWidget_Purchase_Summary_Payment_Card">{{store.state.userWallet.length > 35 ? store.state.userWallet.slice(0, 10) + "..." + store.state.userWallet.substr(store.state.userWallet.length - 10) : store.state.userWallet}} 
          <AppButton
            @click="copy(store.state.userWallet)"
            style="background-color: transparent; height: 22px; width: 22px"
          >
              <PasteIcon/></AppButton>
          </span>
          <!--<a @click="store.commit('router/goTo', Routes.CardList)">{{ $t('purchase_summary_edit_button') }}</a>-->
        </div>
      </div>

      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12" v-if="loadFirstTime && !loadingPayment">
          <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Purchase_Summary_ShowDetails" @click="showFees = !showFees">
            {{$t('exchange_order_details')}}<span><ChevronUpIcon fillColor="#444" :size="20" v-if="showFees" /><ChevronDownIcon fillColor="#444" :size="20" v-if="!showFees" /></span>
          </div>
          <div class="MBMWidget_Purchase_Summary_Details_Block">
            <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order" v-if="!store.state.config.fiatOnlyMode">{{$t('exchange_order_details_you_get')}}<strong> {{order.CryptoValues.cryptoQuantity}} {{order.CryptoValues.symbol}}</strong> {{$t('exchange_order_details_for')}} <strong>{{ getFiatSymbol(order.CryptoValues.fiatCurrency) }} {{order.TransactionTotal.TransactionPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}}</strong>
            <a @click="store.commit('router/goTo', Routes.Exchange)" v-if="!store.state.config.fiatOnlyMode">{{ $t('purchase_summary_edit_button') }}</a>
            </span>
            <span class="MBMWidget_Purchase_Summary_Price MBMWidget_Purchase_Summary_Price_order" v-if="store.state.config.fiatOnlyMode">{{$t('purchase_summary_you_get_a')}} 
              
              <strong>{{getFiatSymbol(order.CryptoValues.fiatCurrency)}}{{
                (order.TransactionTotal.TransactionPrice - (order.ProcessingFees.flatRate + order.ProcessingFees.processingFee)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                }} {{order.CryptoValues.fiatCurrency}}</strong> {{$t('purchase_summary_credit')}} {{$t('exchange_order_details_for')}} 
              <strong>{{ getFiatSymbol(order.CryptoValues.fiatCurrency) }} {{order.TransactionTotal.TransactionPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}} {{order.CryptoValues.fiatCurrency}}</strong>
            </span> 
          </div>
  
          <div class="MBMWidget_Purchase_Summary_Details_Block MBMWidget_Purchase_Summary_Details_Block_Additional" :style="showFees ? 'max-height: 120px;' : 'max-height: 0px;'">
            <span class="MBMWidget_Purchase_Summary_Price" v-if="!store.state.config.fiatOnlyMode">
              <strong>{{order.CryptoValues.cryptoQuantity}} {{ order.CryptoValues.symbol }}</strong> @
              {{ getFiatSymbol(order.CryptoValues.fiatCurrency) }} 
              {{
                order.CryptoValues.unitPriceQuoted.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 5,
                })
              }}
              <span>{{getFiatSymbol(order.CryptoValues.fiatCurrency)}}{{
                (order.TransactionTotal.TransactionPrice - (order.ProcessingFees.flatRate + order.ProcessingFees.processingFee)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }} {{order.CryptoValues.fiatCurrency}}</span>
            </span>
            <span class="MBMWidget_Purchase_Summary_Price" v-if="store.state.config.fiatOnlyMode">
              <strong>{{$t('purchase_summary_credit_for')}}</strong>
              <span>{{getFiatSymbol(order.CryptoValues.fiatCurrency)}}{{
                (order.TransactionTotal.TransactionPrice - (order.ProcessingFees.flatRate + order.ProcessingFees.processingFee)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }} {{order.CryptoValues.fiatCurrency}}</span>
            </span>
            <span class="MBMWidget_Purchase_Summary_Fees"><strong>{{$t('purchase_summary_fee')}}</strong><span>{{ getFiatSymbol(order.CryptoValues.fiatCurrency) }}{{(order.ProcessingFees.flatRate + order.ProcessingFees.processingFee)}} {{order.CryptoValues.fiatCurrency}}<!-- {{ getFeeDetails() }}--></span></span>
            <span class="MBMWidget_Purchase_Summary_Fees MBMWidget_Purchase_Summary_Fees_Total"><strong>{{$t('purchase_summary_total_cost')}}</strong><span>{{ getFiatSymbol(order.CryptoValues.fiatCurrency) }}{{order.TransactionTotal.TransactionPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}} {{order.CryptoValues.fiatCurrency}}</span></span>
          </div>
        </div>
      

      <AppCheckbox
        v-model="agreeCheck"
        v-if="loadFirstTime"
        v-show="!loadingPayment"
        :disabled="!canPay"
        class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_cursor-pointer MBWidget_multiline_checkbox MBMWidget_mt-4"
      >
        <template #label>
          {{ checkText1() }}
          <a @click="showIframe('terms_and_conditions')" target="_blank">{{ $t('card_screen_terms_and_conditions') }}</a>
          and
          <a @click="showIframe('privacy_policy')" target="_blank">{{ $t('card_screen_privacy_policy') }}</a>
          {{ checkText2() }}
          <span v-if="!canPay && !kycVerified">{{$t('card_screen_kyc_notification')}}</span>
        </template>
      </AppCheckbox>
      
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-0 MBMWidget_full-width">
      <div class="MBMWidget_col-12 MBMWidget_col-md-12 MBMWidget_col-xs-12" v-if="loadFirstTime && !loadingPayment" :class="store.state.config.fiatOnlyMode ? 'MBWidget-nofloat MBMWidget_mx-auto' : ''">
        <AppButton
          :disabled="disabled || ((CVV == '' || CVV.length != 3) && !store.state.wirePayment) || complete || loadingPayment || !agreeCheck || !canPay"
          color="primary"
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Purchase_Summary_AppButtonSummary"
         @click="process"
        >
          {{ $t('purchase_summary_buy_button') }}
        </AppButton>
      </div>
    </div>
    </span>
  </div>
</template>

<script lang="ts">
  import Button from '../General/Button.vue';
  import './../CardScreen/cards.css';
  import {checkKycVerified, gerOrderDetails, shouldPerformKyc} from "@/api";
  import PencilOutlineIcon from 'vue-material-design-icons/PencilOutline.vue';
  import ContentCopyIcon from 'vue-material-design-icons/ContentCopy.vue';
  import ErrorMessage from "@/components/Components/ErrorMessage.vue";
  import {mapMutations} from "vuex";
  import Input from '../General/Input.vue';
  import Snack from "@/components/General/Snack.vue";
  import Checkbox from "@/components/General/Checkbox.vue";
  import {processDecimalPlaces} from "../../utils";
  import PaymentProcessor from "../CardScreen/PaymentProcessor";
  import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
  import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';
  import './../Components/CoolSelect.css';
  import {CoolSelect} from "vue-cool-select";
  // @ts-ignore
  import PasteIcon from '../../assets/gift-cards-icons/paste-icon.svg';
  import CurrencyList from "@/components/Components/CurrencyList.json";

  export default PaymentProcessor.extend({
    props: ['showIframe'],
    components: {
      AppButton: Button,
      PencilOutline: PencilOutlineIcon,
      ErrorMessage: ErrorMessage,
      AppInput: Input,
      //AppSnack: Snack,
      AppCheckbox: Checkbox,
      ContentCopyIcon: ContentCopyIcon,
      ChevronDownIcon,
      ChevronUpIcon,
      CoolSelect,
      PasteIcon: PasteIcon,
    },
    data() {
      return {
        showFees: false,
        kycInterval: null as any,
        kycStartedInterval: null as any,
        kycVerified: true,
        kycStarted: true,
        canPay: false,
        order: {} as {
          amount: number;
          selectedCryptoCurrency: string;
          price: number;
          selectedCurrency: string;
          CryptoValues: {
            symbol: string,
            cryptoQuantity: number,
            fiatCurrency: string,
            unitPriceQuoted: number,
          };
          TransactionTotal: {
            TransactionPrice: number,
          };
          ProcessingFees: {
            id: number,
            flatRate: number,
            processingFee: number,
            pct_ProcessingFee: number
          };
        },
        fraudRuleError: null as any,
        cardList: [] as any[]
      }
    },
    mounted(){
      this.loadData();
    },
    methods: {
      ...mapMutations(['modifyOrder']),
      feeTextShort(): any {
        return {
          amount_fee:
            this.getFiatSymbol(this.order.CryptoValues.fiatCurrency) +
            processDecimalPlaces(
              this.order.ProcessingFees.flatRate + this.order.ProcessingFees.processingFee,
              2,
            )
        };
      },
      getFiatSymbol(fiatCode: string) {
        // @ts-ignore
        if (CurrencyList.en[fiatCode] !== undefined) {
          // @ts-ignore
          if (CurrencyList.en[fiatCode] !== undefined) {
            // @ts-ignore
            return CurrencyList.en[fiatCode].symbol;
          }
        }
      },
      formatCurrency(amount : any, min = 2){
        return processDecimalPlaces(amount, 5, min);
      },
      copy(val: string){
        navigator.clipboard.writeText(val)
      },
      checkText1(): string {
        /*if (this.$store.state.config.showFees) {
          if (this.$store.state.config.fiatOnlyMode) {
            return this.$t('card_screen_confirm_payment_with_fee1_fiat_only_mode', this.declare_text_with_fee()).toString();
          } else {
            return this.$t('card_screen_confirm_payment_with_fee1', this.declare_text_with_fee()).toString();
          }
        } else {
          if (this.$store.state.config.fiatOnlyMode) {
            return this.$t('card_screen_confirm_payment1_fiat_only_mode', this.declare_text_without_fee()).toString();
          } else {
            return this.$t('card_screen_confirm_payment1', this.declare_text_without_fee()).toString();
          }
        }*/
        return this.$t('purchase_summary_agree_1').toString();
      },
      checkText2(): string {
        /*if (this.$store.state.config.showFees) {
          return this.$t('card_screen_confirm_payment_with_fee2', this.declare_text_with_fee()).toString();
        } else {
          return this.$t('card_screen_confirm_payment2', this.declare_text_without_fee()).toString();
        }*/
        return this.$t('purchase_summary_agree_2').toString();

      },
      declare_text_with_fee(): any {
        return {
          terms_and_conditions: this.$store.state.config.termsAndConditionsUrl,
          privacy_policy: this.$store.state.config.privacyPolicyUrl,
          amount: processDecimalPlaces(this.order.CryptoValues.cryptoQuantity , 8),
          amount_fee_amount: processDecimalPlaces(this.order.TransactionTotal.TransactionPrice + this.order.ProcessingFees.flatRate + this.order.ProcessingFees.processingFee, 2),
          selectedCryptoCurrency: this.order.CryptoValues.symbol,
          selectedCurrency: this.order.CryptoValues.fiatCurrency,
          price: processDecimalPlaces(this.order.TransactionTotal.TransactionPrice, 2),
          fee_amount:
            processDecimalPlaces(this.order.ProcessingFees.flatRate + this.order.ProcessingFees.processingFee, 2) +
            ' ' +
            this.order.CryptoValues.fiatCurrency,
          fee_percent: this.order.ProcessingFees.flatRate
            ? ''
            : '(' +
            processDecimalPlaces(this.order.ProcessingFees.pct_ProcessingFee, 2) +
            '%)',
        };
      },
      declare_text_without_fee(): any {
        return {
          terms_and_conditions: this.$store.state.config.termsAndConditionsUrl,
          privacy_policy: this.$store.state.config.privacyPolicyUrl,
          amount: processDecimalPlaces(this.order.amount, 8),
          selectedCryptoCurrency: this.order.selectedCryptoCurrency,
          price: processDecimalPlaces(this.order.price, 2),
          selectedCurrency: this.order.selectedCurrency,
          amount_charged: processDecimalPlaces(this.order.price, 2),
        };
      },
      getFiatClass() {
        return this.$store.state.config.fiatOnlyMode ? "Fiat_Only" : "";
      },
      process() {
        this.$store.commit('personalizedErrors', []);
        this.processPayment3DS(this.$store.state.order.uuid);
      },
      getCardIcon(t: string) {
        let re = new RegExp('^4');
        if (t.match(re) != null) return 'visa';

        re = new RegExp('^5[1-5]');
        if (t.match(re) != null) return 'mastercard';

        return 'mastercard';
      },
      /*setCard() {
        if(this.$store.state.unsavedCard != null && this.$store.state.defaultCardId == -2) {
          this.card = this.$store.state.unsavedCard;
          this.unsavedCard = true;
        } else {
          this.unsavedCard = false;
          this.$store.state.userCards.forEach((value: any) => {
            if(this.$store.state.selectedCardId == value.cardId)
              this.card = value;
          })
        }
      },*/
      populateCardList() {
        this.$store.state.userCards.forEach((value: any) => {
          this.cardList.push(value);
        })
        if(this.$store.state.unsavedCard != null) {
          this.cardList.push(this.$store.state.unsavedCard);
        }
      },
      async loadData(){
        this.setCard();
        this.populateCardList();
        this.$store.state.currentTxId = '';
        const body = {
          // @ts-ignore
          cardNumber : this.unsavedCard && this.card != null ? this.card.Number : null,
          cardId : !this.unsavedCard ? this.$store.state.defaultCardId.toString() : null
        }
        // @ts-ignore
        const response = await gerOrderDetails(body);
        if (this.$store.state.config.showFees) {
          this.order = response.data.result;
        } else {
          this.order = response.data.result.order;
        }

        const i : InstanceType<typeof Input> = this.$refs['CVV'] as InstanceType<typeof Input>;
        if(i !== undefined) {
          // @ts-ignore
          i.doFocus(true);
        }

        await this.checkKyc();
        
        this.loadFirstTime = true;
        this.modifyOrder(this.order);
        this.kycInterval = setInterval(async () => {
          this.checkKyc();
        }, 3000);
        this.shouldPerformKyc();
        this.kycStartedInterval = setInterval(async () => {
          this.shouldPerformKyc();
        }, 3000);

        this.interval = setInterval(async () => {
          const body = {
            // @ts-ignore
            cardNumber : this.unsavedCard && this.card != null ? this.card.Number : null,
            cardId : !this.unsavedCard ? this.$store.state.defaultCardId.toString() : null
          }
          
          console.log(body)
          // @ts-ignore
          const response = await gerOrderDetails(body);
          if (this.$store.state.config.showFees) {
            this.order = response.data.result;
          } else {
            this.order = response.data.result.order;
          }
        }, 6000);
        this.dateInterval = setInterval(async () => {
          const date = new Date();
          this.currentDate = date.toLocaleString('en-GB');
        }, 1000);
      },
      async checkKyc() {
        const kyc = await checkKycVerified(this.$store.state.order.uuid);
        if (kyc.data) {
          this.canPay = kyc.data.result.canPay;
          const code = kyc.data.result.errorCode;
          if(code){
            switch(code) {
              case "1008":
                this.fraudRuleError = this.$t('fraud_rule_max_cards', { hours : kyc.data.result.rollingPeriod});
                break;
              case "1007":
                this.fraudRuleError = this.$t('fraud_rule_max_failed', { hours : kyc.data.result.rollingPeriod, limitValue :  kyc.data.result.limitValue});
                break;
              case "1006":
                this.fraudRuleError = this.$t('fraud_rule_max_number', { hours : kyc.data.result.rollingPeriod});
                break;
              case "1005":
                this.fraudRuleError = this.$t('fraud_rule_max_amount', { hours : kyc.data.result.rollingPeriod, limitValue: processDecimalPlaces(kyc.data.result.limitValue, 2),  symbol: this.getFiatSymbol(kyc.data.result.currency),  currency: kyc.data.result.currency});
                break;
              case "1004":
                this.fraudRuleError =
                  this.$t('exchange_max_price_error') +
                  '' +
                  this.getFiatSymbol(kyc.data.result.currency) +
                  processDecimalPlaces(kyc.data.result.limitValue, 2) +
                  ' ' +
                  kyc.data.result.currency;
                break;
            }
          }
          else if(kyc.data.result.waitForKyc){
            this.kycVerified = false;
          }
          else {
            this.kycVerified = true;
            if (this.kycInterval) {
              clearInterval(this.kycInterval);
            }
          }
        }
      },
      async shouldPerformKyc() {
        const kyc = await shouldPerformKyc();
        if (kyc.data != null) {
          if(kyc.data == true){
            this.kycStarted = false;
          }
          else {
            this.kycStarted = true;
            if (this.kycStartedInterval) {
              clearInterval(this.kycStartedInterval);
            }
          }
        }
      },
      routeToSuccess(){
        this.$store.commit('order', { uuid: '', cryptoValidation: '' });
        this.$store.state.lastFocus = 'Amount';
        this.$store.state.swap = false;
        this.$store.state.swapExchange = [{ id: 2, name: 'Sushiswap', value: 2 }];
        this.$store.state.prefillOrder = {
          userWallet: '',
          htmlFiatCurrency: null,
          htmlFiatAmount: null,
          htmlCryptoCurrency: null,
          htmlCryptoAmount: null,
          extraFieldsValues: null
        };
        this.$store.state.pageQueue = [] as string[];

        this.$store.commit('setPreviousPage', 'Order');
        if(this.$store.state.currentTxId != '') {
          setTimeout(() => {
            this.$store.commit('router/goTo', 'PurchaseSuccess');
          }, 50)
        }
      }
    },
    created(){
      this.$store.commit('setPreviousPage', 'PurchaseSummary');
    },
    beforeDestroy() {
      if (this.kycInterval)
        clearInterval(this.kycInterval);
      if (this.kycStartedInterval)
        clearInterval(this.kycStartedInterval);
    },
  });
</script>
<style lang="scss" scoped>
  .MBMWidget_Phaze_Card_List_Expired_Icon {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    border: 10px solid #EB5757;
    margin: 165px auto 30px;
  }
  .MBMWidget_Phaze_Card_List_Expired_Icon svg {
    margin: 27px;
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
  }
  .loading_style {
    margin-top: 100px;
  }
</style>