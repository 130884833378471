<template>
  <div :class="$attrs.class" class="MBMWidget_GeneralInput">
    <label :class="getClass()">{{ label }} <p v-if="subLabel">{{subLabel}}</p><span v-if="tooltip" v-tooltip.bottom-start="tooltip">i</span></label>
    <br v-if="label && !subLabel" />
    <input
      class="MBMWidget_mt-2 MBMWidget_full-width"
      v-bind="$attrs"
      v-model="val"
      :class="inputClass"
      :maxlength="maxLength"
      :minlength="minLength"
      :autocomplete="autoComplete"
      :type="type" 
      :min="min"
      :max="max"
      
      ref="input"
      @click="$emit('click', $event)"
      @input="onInput"
      @focus="$emit('focus', $event)"
      @paste="onPaste"
      @validate="onValidate"
      @blur="$emit('blur', $event)"
      @keydown="$emit('keydown', $event.target.value)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    label: String,
    subLabel: String,
    inputClass: String,
    focus: Boolean,
    maxLength: Number,
    minLength: Number,
    autoComplete: String,
    type: String,
    min: Number,
    max: Number,
    value: String,
    id: String,
    tooltip: String
  },
  data() {
    return {
      autoFill : "",
      val: "",
      pasted: false,
    }
  },
  mounted(): void {
    this.val = this.value !== undefined ? this.value.toString() : '';
    if(this.focus) {
      const el = this.$refs.input as HTMLInputElement;
      el.focus();
    }
  },
  methods: {
    getClass(){
      return this.$attrs.value != '' ? '' : 'input_empty_label';
    },
    setValue(val: string){
      this.val = val;
    },
    doFocus(val: boolean){
      const el = this.$refs.input as HTMLInputElement;
      if(val)
        el.focus();
      else
        el.blur();
    },
    onPaste(evt: any) {
      this.pasted = true;
      setTimeout(() => {
        this.validate();
      }, 2);
    },
    onInput(evt: any){
      this.$emit('input', evt.target.value);
      if(!this.pasted)
        this.validate();
    },
    validate(){
      if(this.val == null || this.val == "")
        return;
      
      if(this.type !== undefined && this.type == 'number'){
        
        if(isNaN(parseFloat(this.val))) {
          this.val = "";
        }
        else if(this.pasted) {
          this.$emit('paste', this.val);
          this.pasted = false;
        }
        if(this.maxLength !== undefined && this.val.length > this.maxLength){
          this.val = this.val.substring(0, this.maxLength);
        }
        if(this.min !== undefined && parseFloat(this.val) < this.min){
          this.val = this.min.toString();
        }
        if(this.max !== undefined && parseFloat(this.val) > this.max){
          this.val = this.max.toString();
        }
        const el = this.$refs.input as HTMLInputElement;
        el.innerText = this.val;
        this.onValidate();
      }
    },
    onValidate(){
      this.$emit('validate', this.val);
    }
  },
});
</script>
