<template>
  <div class="MBMWidget_Select-Method MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_title MBMWidget_Header">
      {{ $t('card_list_title') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mb-4"></div>
    
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Select-Method-Wrap">
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBMWidget_Select-Method-Item" @click="goTo('CardList')">
        <CreditCardOutline fillColor="#666" :size="64"></CreditCardOutline>
      </div>
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBMWidget_Select-Method-Item" @click="goTo('wire')">
        <BankOutline fillColor="#666" :size="64"></BankOutline>
      </div>
    </div>
  </div>
  
</template>
<script lang="ts">
import Vue from "vue";
import BankOutlineIcon from "vue-material-design-icons/BankOutline.vue";
import CreditCardOutlineIcon from "vue-material-design-icons/CreditCardOutline.vue";
import store from "@/store";
import {Routes} from "@/store/modules/Router";
import {isFeature} from "@/utils";
import {Feature} from "@/components/Enums/StepType";

export default Vue.extend({
  
  components: {
    BankOutline: BankOutlineIcon,
    CreditCardOutline: CreditCardOutlineIcon
  },
  data() {
    return {
      loading: true,
      
    };
  },
  methods: {
    goTo(path: string) {
      if(path == 'wire') {
        this.$store.state.wirePayment = true;
        if(this.$store.state.order.uuid != '') {
          this.$store.commit('router/goTo', Routes.PurchaseSummary);
        }
        else {
          this.$store.commit('router/goTo', Routes.Exchange);
        }
      } else {
        this.$store.commit('router/goTo', path); 
      }
    }
  },
  mounted(){
    if(!isFeature(Feature.WirePayment)) {
      this.$store.commit('router/goTo', Routes.CardList);
      return;
    }
    this.$store.commit('setPreviousPage', 'SelectMethod');
  },
});
</script>
<style scoped>

</style>