<template>
  <div class="MBMWidget_Recover">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t('recover_screen_recover_password_button') }}
    </div>
    <div v-if="success" class="MBMWidget_text-center MBMWidget_my-4 MBMWidget_title MBMWidget_title_success MBMWidget_accent--text">
      {{ $t('recover_screen_everything_went_ok_text') }}
    </div>
    <div class="MBMWidget_text-center MBMWidget_mx-10 MBMWidget_my-4 MBMWidget_subtitle">
      {{ $t('recover_screen_please_enter_your_new_password_text') }}
    </div>
    <form ref="form" @submit.prevent>
      <div class="MBMWidget_col-6 MBMWidget_col-xs-12">
        <AppInput
          v-model="crud.emailCode"
          required
          :placeholder="$t('recover_screen_email_code')"
          class="MBMWidget_full-width MBMWidget_FormRecoverAppInputEmailCode"
        />
      </div>
      <div class="MBMWidget_col-6 MBMWidget_col-xs-12">
        <AppInput
          v-model="crud.phoneCode"
          required
          :placeholder="$t('recover_screen_phone_code')"
          class="MBMWidget_full-width MBMWidget_FormRecoverAppInputPhoneCode"
        />
      </div>
      <div class="MBMWidget_col-12">
        <AppInput
          required
          class="MBMWidget_full-width MBMWidget_FormRecoverAppInputPassword"
          v-model="password"
          autocomplete="new-password"
          type="password"
          :placeholder="$t('recover_screen_password_placeholder')"
        />
        <Password
          v-model="password"
          :strength-meter-only="true"
          @score="score = $event"
        />
      </div>

      <div class="MBMWidget_col-12">
        <AppInput
          class="MBMWidget_FormRecoverAppInputRePassword"
          v-model="repassword"
          required
          :placeholder="$t('recover_screen_re_password_placeholer')"
          type="password"
        />
      </div>

      <div class="MBMWidget_col-12">
        <AppButton
          class="MBMWidget_my-4 MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Recover_FormRecoverButtonReover"
          color="primary"
          :disabled="disabled"
          @click="recover"
        >
          {{ $t('recover_screen_reset_password_button') }}
        </AppButton>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { recoverPassword } from '@/api';
import { Routes } from '@/store/modules/Router';
import { redirectView } from '@/utils';
import md5 from 'blueimp-md5';
import Vue from 'vue';
// @ts-ignore
import Password from 'vue-password-strength-meter';
import Button from '../General/Button.vue';
import Input from '../General/Input.vue';

export default Vue.extend({
  components: {
    AppButton: Button,
    AppInput: Input,
    Password: Password,
  },
  data() {
    return {
      score: 1,
      password: '',
      repassword: '',
      disabled: false,
      Routes: Routes,
      success: false,
      crud: {
        emailCode: '',
        phoneCode: '',
      },
    };
  },
  methods: {
    async recover() {
      const form = this.$refs.form as HTMLFormElement;
      if (!form.checkValidity()) return;
      const errors = [];
      this.password === this.repassword || errors.push('Passwords must match');
      this.score > this.$store.state.passwordStrength ||
        errors.push('Password should be stronger');
      this.$store.commit('errors', errors);
      if (errors.length) return;
      try {
        this.disabled = true;
        const response = await recoverPassword({
          password: md5(this.password),
          email: this.$store.state.profile.email,
          ...this.crud,
        });
        const errors = response.data.errors.map(
          (item: { message: string }) => item.message,
        );
        this.$store.commit('errors', errors);
        if (errors.length) {
          return;
        }
        this.success = true;
        setTimeout(() => {
          const redirect = redirectView(response.data);
          if (!redirect) {
            this.$store.commit('router/goTo', Routes.Login);
          }
        });
      } finally {
        this.disabled = false;
      }
    },
  },
});
</script>
