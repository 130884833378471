<template>
  <div class="MBMWidget_Login MBMWidget_mx-auto MBWidget_block">
    <div
      class="
        MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4
        custom_title
      "
    >
      {{ $t('login_screen_title') }}
    </div>
    <RedirectToBasketButton class="basket_button" />
    <div class="MBMWidget_subtitle">
      {{ $t('login_screen_subtitle') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
      <ErrorMessage></ErrorMessage>
    </div>
    <form ref="form" @submit.prevent class="MBMWidget_Login_form">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12">
        <AppInput
          v-model="email"
          type="email"
          required
          :label="$t('login_screen_email')"
          :focus="true"
          :placeholder="$t('login_screen_email')"
          class="MBMWidget_Login_AppInputEmail"
        />
      </div>
      <!--<div class="MBMWidget_col-12 MBMWidget_col-xs-12">
        <AppInput
          class="MBMWidget_Login_AppInputPassword"
          v-model="password"
          :placeholder="$t('login_screen_password')"
          :label="$t('login_screen_password')"
          required
          type="password"
        />
      </div>-->
      <div class="MBMWidget_col-6 MBMWidget_col-xs-6">
        <!--<AppCheckbox
          v-model="remember"
          class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_cursor-pointer"
          >
          <template #label>
            Remember me
          </template>
        </AppCheckbox>-->
      </div>

      <!--<div class="MBMWidget_col-6 MBMWidget_col-xs-6 MBMWidget_text-right">
        <a class="MBMWidget_Login_AppInputForgot"
           @click.prevent="forgotPassword"
        >
          {{$t('login_screen_forgot_password',) }}
        </a>
      </div>-->
      <AppButton class="MBWidget_fake_submit" @click="login"></AppButton>
    </form>
    <div
      class="
        MBMWidget_col-np-12 MBMWidget_col-np-xs-12
        MBWidget_footer_actions
        MBMWidget_px-auto
      "
    >
      <div
        class="
          MBMWidget_col-12
          MBMWidget_col-md-12
          MBMWidget_col-np-xs-12
          MBMWidget_mx-auto
          MBWidget-nofloat
        "
      >
        <AppButton
          :disabled="disabled || email == ''"
          color="primary"
          class="
            MBMWidget_full-width
            MBMWidget_defaultAppButton
            MBMWidget_Login_AppButtonLogin
          "
          @click="login"
        >
          {{ $t('login_screen_login') }}
        </AppButton>
      </div>
      <!--<div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center MBWidget-nofloat MBMWidget_mx-auto">
        <a class="MBMWidget_Login_Register"
           href="/register"
           @click.prevent="$store.commit('router/goTo', Routes.Register)"
        >
          {{
          $t(
          'login_screen_dont_have_an_account_click_here_to_register_a_new_account',
          )
          }}
          <span>{{$t('login_screen_dont_have_an_account_click_here_to_register_a_new_account2')}}</span>
        </a>
      </div>-->
    </div>
  </div>
</template>
<script lang="ts">
import { loginUser, sendAnalytics } from '@/api';
import Vue from 'vue';
import Button from '../General/Button.vue';
import Input from '../General/Input.vue';
import { Routes } from '@/store/modules/Router';
import { redirectViewString } from '@/utils';
import { mapState } from 'vuex';
import ErrorMessage from '@/components/Components/ErrorMessage.vue';
import RedirectToBasketButton from '../RedirectToBasketButton.vue';

export default Vue.extend({
  components: {
    AppInput: Input,
    AppButton: Button,
    //AppCheckbox: Checkbox,
    RedirectToBasketButton: RedirectToBasketButton,
    ErrorMessage: ErrorMessage,
  },
  data() {
    return {
      email: '',
      password: '',
      disabled: false,
      Routes: Routes,
      disabledForgot: false,
      remember: true,
    };
  },
  computed: {
    ...mapState(['config']),
  },
  methods: {
    async forgotPassword() {
      this.$store.commit('router/goTo', Routes.Forgot);
    },
    async login() {
      if (!(this.$refs.form as HTMLFormElement).checkValidity()) return;
      try {
        this.disabled = true;
        const response = await loginUser(this.email);
        this.$store.commit(
          'personalizedErrors',
          response.data.errors.map((item: { message: string }) => item.message),
        );
        if (!response.data.errors.length) {
          this.$store.commit('profile', {
            userUUid: '',
            phoneNumber: '',
            email: this.email,
            blockedRegion: response.data.result?.RegionName,
          });
          await sendAnalytics({
            EventCategory: 'general',
            EventAction: 'kyc-loggedin',
            EventLabel: 'User Logged In',
          });
          /*this.$gtag.event('user-loggedin', {
            'event_label': 'User Logged In',
            'event_domain': window.location.host
          });*/

          if (!this.config.twoFa) {
            this.$store.commit('router/goTo', Routes.Profile);
          } else {
            this.$store.commit('router/goTo', Routes.Tfa);
          }

          //TODO: Implement API Journey Service
          //const redirect = redirectView(response.data);
          //if (!redirect) {

          if (!this.config.twoFa) {
            if (response.data.redirectUrl == 'PerformKYC') {
              this.$store.commit('router/goTo', Routes.Kyc);
            } else if (
              this.$store.state.order.uuid != '' ||
              this.$store.state.phazeOrder.uuid != ''
            ) {
              if (this.$store.state.order.uuid != '') {
                this.$store.commit('router/goTo', Routes.PurchaseSummary);
                /*if(this.$store.state.userCards.length) {
                    this.$store.commit('router/goTo', Routes.PurchaseSummary); // crypto order and has payment methods - continue with order
                  } else {
                    this.$store.commit('router/goTo', Routes.AddCard); // crypto order and no payment methods - add card
                  }*/
              } else {
                this.$store.commit('router/goTo', Routes.PhazeCardCheckout);
                /*if(this.$store.state.selectedPhazeCurrency != 'Card') {
                    this.$store.commit('router/goTo', Routes.PhazeCardCheckout); // phaze order for crypto - continue to order
                  } else {
                    if(this.$store.state.userCards.length) {
                      this.$store.commit('router/goTo', Routes.PhazeCardCheckout); // phaze order with card and has payment methods - continue with order
                    } else {
                      this.$store.commit('router/goTo', Routes.AddCard); // phaze order with card and no payment methods - add card
                    }
                  }*/
              }
              this.$store.state.loggedIn = true;
            } else {
              if (this.$store.state.config.firstPage != 'Login') {
                redirectViewString(this.$store.state.config.firstPage); // no order (shouldn't happen, tho), so start with first page from config
              } else {
                this.$store.commit('router/goTo', Routes.Exchange); // default to exchange, while we don't think of smth better
              }
              this.$store.state.loggedIn = true;
            }
            this.$store.commit('setPreviousPage', 'Order');
          } else {
            //this.$store.commit('router/goTo', Routes.CardList);
            this.$store.commit('router/goTo', Routes.Tfa);
          }
          //}
        }
      } finally {
        this.disabled = false;
      }
    },
  },
  mounted() {
    // Hide menu button
    this.$store.state.hideMenuButton = true;

    this.$store.commit('setPreviousPage', 'Login');
  },
  beforeDestroy() {
    // Show menu button
    this.$store.state.hideMenuButton = false;

    if (this.$store.state.pageQueue.length > 1)
      this.$store.state.pageQueue.pop();
  },
});
</script>
<style scoped>
.basket_button {
  position: relative;
  float: right;
  top: -36px;
  right: 0px;
}
</style>
