<template>
  <div class="MBMWidget_Phaze_Card_List MBMWidget_mx-auto MBWidget_block custom_Widget_Phaze_Card_List">

    <div
      class="
        MBMWidget_Phaze_Card_List_Logo
      "
    >
    <img
      src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/MBLogo.png"
    />
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Top_Menu MBMWidget_mt-4" v-if="!isFeature(Feature.Polygon)" >
      <span v-if="isFeature(Feature.MenuCrypto)" @click="switchOrder(Routes.Exchange)">
        {{$t('top_menu_buy')}}
        <img width="12" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAASCAYAAACEnoQPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADGSURBVHgB7ZOxDcIwEEXvHAagYQeSihEoKBgAKZSMQBe6mA6LghlSElNAjwRTQMkANEzgw2cpEoHEiUSFxCtc/NP7usIHUEOo1SbMV3vw0KkbCBIDAwbBg4Av+Mu/I4e5uvW1StsKkV6n7DgZCTJBKNsUsAhEEgwdnHyJE2mDZVNBIRJRdp0u5k7mp6mgJMbJrMhLHz/aKgmIqUGSAYkhHwaiOFWJH/JrgZ08gKDLWZXIBO/BfXc89yYjW4pjn+iFN3BbeHgChuBn+Rl2+OcAAAAASUVORK5CYII=" alt="">
      </span>
      <span v-if="isFeature(Feature.MenuPhaze)" class="MBMWidget_Top_Menu_Selected">
        {{$t('top_menu_sell')}}
        <img width="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACJSURBVHgB7dTBCYAwDIXhpzhAR3AER3EDR9UNdANHECeoQXKosQqljXjIDw+kUD68FLC08kBH66EVAxvN0waUTgC+OBQAK2/mlYEE0NJG2kRzRSAJ8NmJ8HceFAMkkg3xxQsQQySElPhPXOT8hoR38FATO6yABYm93anxQYYY8h9kh3b8EjtYmh3F32mQhLJUFAAAAABJRU5ErkJggg==" alt="">
      </span>
      <span v-if="isFeature(Feature.Bridge)" @click="switchOrder(Routes.Bridge)">
        {{$t('top_menu_bridge')}}
        <img width="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAPCAYAAAARZmTlAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALHSURBVHgBnVTPaxNBFH5v9kfTH5BdMIKnbPEPaD0Jas2GttBjvFQ8SLPgpSfTvyBtbz01BaX2lNbgSdAWL0ULSaRIb+lVPDQFRbCpuxGbmGR3nrOhCUltoPGDZWbevDffvPfmW4AO3JuyTdO0NfgP/Hj1fLzXHutaEcQ8ia1Cn7DTaU3mLHuSXjcv28c70/Y4I9DAFQtJjIhpAkyhx3JELu7n9Fzvw1dF1gEtoCp0Bo0o8zCpqhBrVKvHurXgtEnuT/88Ao5hREASuQgCMT3PC0hYoSgRJLJ7+k778MyLp4h8EZEFiQjbhaBmEDDWHPwFEvJbODPz3WwaZBk8F42aK60qCliK5DrgujCgUlqcYiiE5ut3N/JfNjJJCV1BQHmF0bYoePPGClJEkV2z1mBLHpc0YhAEztFlcgo705+YcrIIlP+4py+2bPHYkSYP8ixDbkSMr9GboZOCIEjdTjxc6OwJSTXfbumP53MXy9rVeMmlB50EPja3Rx19oGqFhitBWa4XSr9HihVHXer00S3L6UXQ7AlcEc8WPm0G1PqcKHTiyUp0DfoAu4pTIl7QyrWBiF0dhBHVjUGf6CKZmLRjE1Plf3TieYPJk7OhcFiz10IjlUh+5c3cRZ/TzEaylyC7e+JBTtQ2dnfyV/uQ2di35GllMFGqBpZnl2cSoaFKXh+upj5vbM0VRMNbfkT8WPakt3aHrQWMTpfEy2Fh8BVBQJxA9wA18TyLIlTzPwn51vvd63E/4CidNgLoZYXdaOlJjGWhCFv4jRIwh3M4FyKBqkim7HrKoQe8iH5SyIUScUyoUfvTYDlhcYjxnf29a7nWrUYtq+gPdmbdZIyPibnOSAoSkuZfUIizKCn80BepAHn1SrnrdYmfo8EVzNYlih7s6kXoA6WX63HGMIkiVn803xUrdy7qCmiSR0sHH/oj8CGyNy4j8PEXCrQyybcOdUIAAAAASUVORK5CYII=" alt="">
      </span>
    </div>
    <div
      class="
         MBMWidget_title
         title_width
      "
      :class="
        store.state.pageQueue.length > 1 ? 'MBMWidget_Header_Logo_right' : ''
      "
    >
      <span>{{ $t('phaze_card_list_title') }}</span>
      <!--      <span-->
      <!--        class="MBMWidget_Header_Swith"-->
      <!--        @click="switchOrder()"-->
      <!--        v-if="-->
      <!--          store.state.profile.userUUid ==-->
      <!--            '9a2e8814-0bb1-4eb9-b9b2-7e17ce02cb10' ||-->
      <!--            store.state.profile.userUUid ==-->
      <!--              'f7232991-0f1e-49b7-ae25-a4684ed7f433' ||-->
      <!--            store.state.profile.userUUid ==-->
      <!--              '6d2b7162-35de-4a07-b33b-78fcf8ae4357'-->
      <!--        "-->
      <!--      >-->
      <!--        <img-->
      <!--          src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/icon_btc.png"-->
      <!--        />-->
      <!--        <span>{{ $t('icon_buy_crypto') }}</span>-->
      <!--      </span>-->
    </div>
    <RedirectToBasketButton class="basket_button" />
    <div class=" MBMWidget_col-12 MBMWidget_col-xs-12" style="position:relative;">
      <img
        style="position: absolute; right: 15px; bottom: 19px;"
        src="https://s3.eu-west-1.amazonaws.com/com.mobilum.public/gift_card_banner.png"
      />
      <div class="MBMWidget_banner">
        <span class="title">{{ $t('phaze_card_banner_title') }} </span>
        <p class="paragraph">
          {{ $t('phaze_card_banner_description') }}
        </p>
      </div>
    </div>
    <div
      class="MBMWidget_col-12 MBMWidget_col-xs-12 custom_list_filters"
      v-if="loadedFirstTime && phazeCountries.length"
    >
      <input
        type="text"
        v-model="search"
        align="middle"
        placeholder="Search for a Brand"
        class="MBMWidget_full-width MBMWidget_search"
        :style="{
          backgroundImage: `url('https://s3.eu-west-1.amazonaws.com/com.mobilum.public/search.png')`,
        }"
      />
      <vue-multi-select
        class="MBMWidget_full-width MBMWidget_Phaze_Select_Country_Dropdown"
        v-model="selectedCountryValue"
        search
        :btnLabel="btnLabel"
        :selectOptions="phazeCountries"
        style="align-content: center; display: flex"
      >
        <template v-slot:option="{ option }">
          <div style="display: flex; align-items: center; padding: 10px">
            <span
              :style="'background-image: url(' + option.flag + ');'"
              class="country-flag"
            ></span>
            <div>
              <b>{{ option.name }}</b>
            </div>
          </div>
        </template>
      </vue-multi-select>
    </div>
    <div
      class="
        MBMWidget_col-np-12
        MBMWidget_col-np-xs-12
        MBMWidget_Loading
        MBMWidget_text-center

      "
      v-if="!loadedFirstTime"
    >
      {{ $t('loading') }}
    </div>
    <div
      class="
        MBMWidget_col-np-12
        MBMWidget_col-np-xs-12
        MBMWidget_Loading
        MBMWidget_text-center
      "
      v-if="loadedFirstTime && !filteredGiftCards.length && !loading"
    >
      {{ $t('phaze_card_list_view_no_more') }}
    </div>
    <div
      class="
        MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Phaze_Country_List_Wrap
      "
      ref="outerScroll"
      v-if="loadedFirstTime && filteredGiftCards.length"
    >
      <div
        @click="next(i.productId)"
        v-for="(i, index) in filteredGiftCards"
        v-bind:key="i.productId"
        class='custom_list'
      >
        <div
          class="MBMWidget_Phaze_Card_List_Item_Inner"
          :ref="'e' + i.productId"
        >
          <div
            class="
              MBMWidget_col-np-6
              MBMWidget_col-np-xs-6
              MBMWidget_Phaze_Card_List_Item_Image
            "
          >
            <span
              :style="{
                backgroundImage: 'url(' + i.productImage + ')',
                marginLeft: index % 2 == 0 ? '' : '8px',
              }"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  getCountries,
  getPhazeCountries,
  ListAvailableDeliveries,
} from '@/api';
import { Routes } from '@/store/modules/Router';
import Vue from 'vue';
import store from '@/store';
import { CoolSelect } from 'vue-cool-select';
import './../Components/CoolSelect.css';
import {isFeature, redirectViewString} from '@/utils';
import CurrencyList from './../Components/CurrencyList.json';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import PhazeCountriesISO from './../Components/PhazeCountriesISO.json';
import RedirectToBasketButton from '../RedirectToBasketButton.vue';
// @ts-ignore
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import {Feature} from "@/components/Enums/StepType";

export default Vue.extend({
  components: {
    vueMultiSelect,
    // CoolSelect: CoolSelect,
    // AppButton: Button,
    // LoadingIcon: LoadingIcon,
    RedirectToBasketButton: RedirectToBasketButton,
    // ChevronDownIcon: ChevronDownIcon,
  },
  data() {
    return {
      isFeature,
      Feature,
      loading: false,
      loadedFirstTime: false,
      store,
      selectedCountry: '',
      countries: [],
      fullCountries: [],
      phazeCountries: [] as any[],
      selectedCardId: -1,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          locking: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
        },
        rail: {},
        bar: {},
      },
      giftCards: [] as any[],
      noMoreCards: false,
      loadingPages: false,
      search: '',

      selectedCountryValue: [{ name: '', value: '' }],
    };
  },

  methods: {
    setSelected(val: any) {
      this.selectedCardId = val;
    },
    getFiatSymbol(fiatCode: string) {
      // @ts-ignore
      if (CurrencyList.en[fiatCode] !== undefined) {
        // @ts-ignore
        return CurrencyList.en[fiatCode].symbol;
      }
    },
    async onChangedCountry() {
      this.selectedCountry = this.selectedCountryValue[0]?.name;
      this.search = '';
      if (!this.loadedFirstTime) return;
      this.loading = true;
      this.noMoreCards = false;
      this.giftCards = [];
      this.$store.state.giftCardPage = 0;
      this.$store.state.selectedPhazeCountry = this.selectedCountry;
      this.$store.state.selectedGiftCardValue = '';
      this.selectedCardId = -1;
      const del = await ListAvailableDeliveries();
      if (del.data) {
        this.giftCards = del.data;
      }

      this.loading = false;
      const parent: HTMLDivElement = this.$refs[
        'outerScroll'
      ] as HTMLDivElement;
      parent?.scrollTo(0, 0);
    },
    filterOut() {
      for (let i = 0; i < this.giftCards.length; i++) {
        if (
          !this.giftCards[i].denominations?.length &&
          this.giftCards[i].valueRestrictions == null
        ) {
          this.giftCards.splice(i, 1);
        }
      }
    },
    next(productId: any) {
      this.$store.state.giftCardList = this.giftCards;
      this.$store.state.selectedGiftCardId = productId;
      redirectViewString(Routes.PhazeCardSummary);
    },
    async loadData() {
      this.loading = true;
      if (!this.$store.state.countries.length) {
        const response = await getCountries();
        this.fullCountries = response.data.result.Countries;
        this.$store.state.countries = response.data.result.Countries;
      } else {
        this.fullCountries = this.$store.state.countries;
      }

      if (!this.$store.state.phazeCountries.length) {
        const response = await getPhazeCountries();
        this.$store.state.phazeCountries = response.data;
      }

      let userCountry = false;
      if (this.$store.state.selectedPhazeCountry != '') {
        this.selectedCountry = this.$store.state.selectedPhazeCountry;
      } else if (
        this.$store.state.config.countryCode != null &&
        this.$store.state.phazeCountries.includes(
          // @ts-ignore
          PhazeCountriesISO[this.$store.state.config.countryCode],
        )
      ) {
        this.selectedCountry =
          // @ts-ignore
          PhazeCountriesISO[this.$store.state.config.countryCode];
        userCountry = true;
      } else {
        if (
          this.$store.state.phazeCountries != null &&
          this.$store.state.phazeCountries.length
        ) {
          this.setDefaultCountry();
        }
      }
      this.$store.state.selectedPhazeCountry = this.selectedCountry;
      this.selectedCountryValue = [{ name: this.selectedCountry, value: this.selectedCountry }];
      this.setCountries();

      if (this.$store.state.giftCardList.length) {
        this.giftCards = this.$store.state.giftCardList;
        this.selectedCardId = this.$store.state.selectedGiftCardId;
        // Bottom code is to scroll to previously opened gift card, when user gets back to this page from viewing details
        // Maybe will need in future, but will need to update to match the new structure of card list
        // for now just throwing errors in console
        /*setTimeout(() => {
          const arr: HTMLDivElement[] = this.$refs[
            'e' + this.$store.state.selectedGiftCardId
          ] as HTMLDivElement[];
          const el: HTMLDivElement = arr[0] as HTMLDivElement;
          const parent: HTMLDivElement = this.$refs[
            'outerScroll'
          ] as HTMLDivElement;
          const childPos = el.offsetTop;
          const parentPos = parent.offsetTop;
          const top = childPos - parentPos;

          parent.scrollTo(0, top - 7);
        }, 10);*/
      } else {
        const del = await ListAvailableDeliveries();
        if (del.data) {
          this.giftCards = del.data;
        } else if ((!del.data || !del.data.length) && userCountry) {
          if (
            this.$store.state.phazeCountries != null &&
            this.$store.state.phazeCountries.length
          ) {
            this.setDefaultCountry();
            const del = await ListAvailableDeliveries();
            if (del.data) {
              this.giftCards = del.data;
            }
          }
        }
        this.filterOut();
      }

      this.loading = false;
      setTimeout(() => {
        this.loadedFirstTime = true;
      }, 2);
    },
    setDefaultCountry() {
      if (this.$store.state.phazeCountries.includes('USA')) {
        this.selectedCountry = 'USA';
      } else {
        // @ts-ignore
        this.selectedCountry = this.$store.state.phazeCountries[0];
      }
      this.$store.state.selectedPhazeCountry = this.selectedCountry;
      this.selectedCountryValue = [{ name: this.selectedCountry, value: this.selectedCountry }];
    },
    getFlag(name: string) {
      const code = this.getPhazeCountryIsoCode(name);
      return (
        'https://s3.eu-west-1.amazonaws.com/com.mobilum.public/flags/' +
        code +
        '.png'
      );
    },
    getPhazeCountryIsoCode(name: string) {
      return Object.keys(PhazeCountriesISO).find(
        // @ts-ignore
        key => PhazeCountriesISO[key] === name,
      );
    },
    setCountries() {
      const c = [] as any[];
      this.$store.state.phazeCountries.forEach((i: any) => {
        c.push({
          name: i,
          value: i,
          flag: this.getFlag(i),
        });
      });
      this.phazeCountries = c;
    },
    switchOrder(route : Routes) {
      this.$store.commit('router/goTo', route);
      this.$store.state.pageQueue.pop();
    },
  },
  mounted() {
    this.$store.state.selectedGiftCardValue = '';
    this.$store.commit('setPreviousPage', 'PhazeCardList');
    this.loadData();
  },
  computed: {
    Routes() {
      return Routes
    },
    btnLabel() : any {
      if(!this.selectedCountryValue.length || this.selectedCountryValue[0].name == '')
        return () => '';
      const flag = this.phazeCountries.find(
        item => item.name === this.selectedCountryValue[0].name,
      ).flag;
      return () =>
        `<div class="MBMWidget_dropdown_label"><span style="background-image: url(${flag});" class="country-flag-phaze"></span> <span>${this.selectedCountryValue[0].name}</span></div>`;
    },
    filteredGiftCards(): any[] {
      if (this.search === '') {
        return this.giftCards;
      }
      // @ts-ignore
      return this.giftCards.filter(item => {
        return item?.name?.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    selectedCountryValue() {
      this.onChangedCountry();
    },
  },
  beforeDestroy(): void {
    this.loadingPages = false;
  },
});
</script>

<style scoped lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 500;
  line-height: 18px;
  font-size: 16px;
  /* Light Blue */
  color: #a3b0bf;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Futura, serif;
  font-weight: 500;
  line-height: 18px;
  font-size: 16px;
  /* Light Blue */
  color: #a3b0bf;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Futura, serif;
  font-weight: 500;
  line-height: 18px;
  font-size: 16px;
  /* Light Blue */
  color: #a3b0bf;
}
::v-deep .IZ-select__input {
  border-color: transparent;
}
::v-deep .select {
  > .checkboxLayer {
    width: 99%;
    min-width: 0px;
    margin-top: 50px;
  }
  > .btn-select {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    border: none;

    > .buttonLabel {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

input[type='text'] {
  font-size: 16px;
  color: #07121b;
}

.basket_button {
  position: absolute;
  float: right;
  top: 27px;
  right: 61px;
}

.title_width{
  width: calc(100% - 150px) !important;
}

</style>
