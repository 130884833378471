<template>
  <div class="MBMWidget_Phaze_Card_List MBMWidget_Phaze_Card_History MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{$t('phaze_card_success_purchased_list_title')}}
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Loading MBMWidget_text-center" v-if="!loadedFirstTime">
      {{$t('loading')}}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Phaze_Card_List_Wrap" ref="outerScroll" v-dragscroll v-if="loadedFirstTime">
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Phaze_Card_List_Item" v-for="i in giftCards" v-bind:key="i.id">
        <div class="MBMWidget_Phaze_Card_List_Item_Inner" @click="viewCard(i.orderUUID)">
          <div class="MBMWidget_col-np-9 MBMWidget_col-np-xs-9">
            <span class="MBMWidget_Phaze_Card_List_Item_Title">{{i.productName}}</span>
            <span class="MBMWidget_Phaze_Card_List_Item_Price">
              <span class="MBMWidget_Phaze_Card_List_symbol">{{getFiatSymbol(i.voucherCurrency)}}</span>{{i.faceValue}}
            </span>
            <span class="MBMWidget_Phaze_Card_List_Item_Date">
              <span class="MBMWidget_Phaze_Card_List_symbol">{{$t('phaze_card_success_purchased')}}</span>{{i.created_at}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {getCardsForUser, getCountries, getPhazeCountries, getPhazeOrder, ListAvailableDeliveries} from "@/api";
  import {Routes} from "@/store/modules/Router";
  import Vue from "vue";
  import store from "@/store";
  import { CoolSelect } from "vue-cool-select";
  import './../Components/CoolSelect.css';
  import Button from '../General/Button.vue';
  // @ts-ignore
  import { dragscroll } from 'vue-dragscroll'
  import {redirectViewString} from "@/utils";
  import LoadingIcon from 'vue-material-design-icons/Loading.vue';
  import CurrencyList from './../Components/CurrencyList.json';
  
  export default Vue.extend({
    components: {
      
    },
    directives: {
      dragscroll
    },
    data() {
      return {
        loading: false,
        loadedFirstTime: false,
        store,
        ops: {
          vuescroll: {
            mode: 'native',
            sizeStrategy: 'percent',
            detectResize: true,
            locking: true,
          },
          scrollPanel: {
            initialScrollY: false,
            initialScrollX: false,
            scrollingX: false,
            scrollingY: true,
            speed: 300,
            easing: undefined,
            verticalNativeBarPos: 'right'
          },
          rail: {},
          bar: {}
        },
        giftCards: []
      }
    },
    methods: {
      getFiatSymbol(fiatCode: string) {
        // @ts-ignore
        if(CurrencyList.en[fiatCode] !== undefined) {
          // @ts-ignore
          return CurrencyList.en[fiatCode].symbol;
        }
      },
      async loadData() {
        this.loading = true;
        const del = await getPhazeOrder(null);
        if(del.data) {
          this.giftCards = del.data;
        }
        this.loading = false;
        this.loadedFirstTime = true;
      },
      viewCard(id: string) {
        this.$store.state.phazePurchaseSuccess = false;
        this.$store.state.currentPhazeOrderUuid = id;
        this.$store.commit('router/goTo', 'PhazeCardPurchased');
      }
    },
    mounted() {
      this.$store.commit('setPreviousPage', 'PhazeCardHistory');
      this.loadData();
    }
  });
</script>

<style scoped>

</style>