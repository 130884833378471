export const namespaced = true;

export enum Routes {
  Exchange = 'Exchange',
  Login = 'Login',
  Register = 'Register',
  Verify = 'Verify',
  CardScreen = 'CardScreen',
  AddCard = 'AddCard',
  Tfa = 'Tfa',
  Kyc = 'Kyc',
  Forgot = 'Forgot',
  Recover = 'Recover',
  RegionBlocked = 'RegionBlocked',
  CardList = 'CardList',
  PurchaseSummary = 'PurchaseSummary',
  PurchaseSuccess = 'PurchaseSuccess',
  TransactionHistory = 'TransactionHistory',
  CardRedirect = 'CardRedirect',
  CheckKyc = 'CheckKyc',
  Logout = 'Logout',
  KycInProgress = 'KycInProgress',
  PhazeCardList = 'PhazeCardList',
  PhazeCardCheckout = 'PhazeCardCheckout',
  PhazeCardHistory = 'PhazeCardHistory',
  PhazeCardPurchased = 'PhazeCardPurchased',
  PhazeCardPendingDelivery = 'PhazeCardPendingDelivery',
  PhazeCardSummary = 'PhazeCardSummary',
  Profile = 'Profile',
  Checkout = 'Checkout',
  OrderDetails = 'OrderDetails',
  ChooseCrypto = 'ChooseCrypto',
  PhazeCardShoppingCart = 'PhazeCardShoppingCart',
  ProfileCryptoFlow = 'ProfileCryptoFlow',
  ProfileGiftcardFlow = 'ProfileGiftcardFlow',
  JwtExpired = 'JwtExpired',
  Bridge = 'Bridge',
  SelectMethod = 'SelectMethod',
}

export const state = {
  route: Routes.Exchange,
};

export const mutations = {
  goTo(state: { route: Routes }, payload: Routes) {
    state.route = payload;
  },
};

export const getters = {};

export const actions = {};
