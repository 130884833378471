<template>
  <div class="MBMWidget_Phaze_Card_Breadcrumb MBMWidget_mx-auto MBWidget_block">
    <div class="MBWidget_general_back_button" v-if="store.state.popup == ''">
      <a class="MBWidget_general_back" @click.prevent="goToPreviousScreen()">
        <ChevronLeftIcon fillColor="#444" :size="40" />
      </a>
    </div>
    <div class="_div">
      <div
        class="
          MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4
        "
      >
        {{
          activeScreen === 1
            ? $t('order_details_title')
            : activeScreen === 2
            ? $t('order_details_choose_crypto')
            : cryptoName + ' (' + cryptoTicker + ')'
        }}
      </div>
      <RedirectToBasketButton class="basket_button" />
    </div>
    <div
      class="
        MBMWidget_col-12
        MBMWidget_col-xs-12
        MBMWidget_Exchange_crypto
        MBMWidget_GeneralInput
        MBMWidget_GeneralSelect
        email
      "
    >
      <div class="MBWidget_breadcrumb">
        <div class="breadcrumb_item">
          <div style="background-color: #458fe6" class="circle">1</div>
          <span style="color: #458fe6">{{
            $t('phaze_card_breadcrumb_contact_info')
          }}</span>
          <RightArrow
            class="button_icon"
            
            alt="chevron-right"
          />
        </div>
        <div class="breadcrumb_item">
          <div
            :style="{
              backgroundColor:
                activeScreen === 2 || activeScreen === 3
                  ? '#458FE6'
                  : '#A3B0BF',
            }"
            class="circle"
          >
            2
          </div>
          <span
            :style="{
              color:
                activeScreen === 2 || activeScreen === 3
                  ? '#458FE6'
                  : '#A3B0BF',
            }"
            >{{ $t('phaze_card_breadcrumb_cryptocurrency') }}</span
          >
          <RightArrow
            class="button_icon"
            
            alt="chevron-right"
          />
        </div>
        <div class="breadcrumb_item">
          <div
            :style="{
              backgroundColor: activeScreen === 3 ? '#458FE6' : '#A3B0BF',
            }"
            class="circle"
          >
            3
          </div>
          <span
            :style="{ color: activeScreen === 3 ? '#458FE6' : '#A3B0BF' }"
            >{{ $t('phaze_card_breadcrumb_payment') }}</span
          >
        </div>
      </div>
    </div>
    <order-details @nextScreen="goToNextScreen" v-if="activeScreen === 1" />
    <choose-crypto
      @nextScreen="goToNextScreen"
      v-else-if="activeScreen === 2"
    />
    <review-transaction :cryptoTicker="cryptoTicker" v-else />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import OrderDetails from '@/components/Checkout/OrderDetails.vue';
import ChooseCrypto from '@/components/Checkout/ChooseCrypto.vue';
import ReviewTransaction from '@/components/Checkout/ReviewTransaction.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import { redirectView } from '@/utils';
import store from '@/store';
import RedirectToBasketButton from '../RedirectToBasketButton.vue';
// @ts-ignore
import RightArrow from '../../assets/gift-cards-icons/right_arrow_icon.svg';

export default Vue.extend({
  components: {
    OrderDetails: OrderDetails,
    ChooseCrypto: ChooseCrypto,
    ReviewTransaction: ReviewTransaction,
    ChevronLeftIcon: ChevronLeftIcon,
    RedirectToBasketButton: RedirectToBasketButton,
    RightArrow: RightArrow
  },
  data() {
    return { activeScreen: 1, cryptoName: '', cryptoTicker: '', store };
  },
  methods: {
    goToNextScreen(data: any) {
      this.activeScreen = data.screenPosition;
      this.cryptoName = data.selectedCrypto.name;
      this.cryptoTicker = data.selectedCrypto.ticker;
    },
    goToPreviousScreen() {
      if (this.activeScreen == 1) {
        this.$store.state.pageQueue.pop();

        redirectView({
          redirectUrl:
            this.$store.state.pageQueue[this.$store.state.pageQueue.length - 1],
        });
        return;
      }
      this.activeScreen--;
    },
  },
  mounted() {
    this.$store.state.hideMenuButton = true;
    this.$store.state.hideBackButton = true;
  },
  beforeDestroy() {
    this.$store.state.hideMenuButton = false;
    this.$store.state.hideBackButton = false;
  },
});
</script>

<style lang="scss" scoped>
  .MBWidget_breadcrumb {
    display: block;
    margin-top: -29px;
  }
  .breadcrumb_item {
    margin-top: 5px;
    display: block;

    > span {
      margin-left: 6px;
      font-size: 17px;
      font-weight: 500;
      white-space: nowrap;
      cursor: default;
    }

    .button_icon {
      margin: 2px 8px 0px 8px;
      display: none;
    }
    .circle {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      font-weight: 700;
      align-items: center;
      display: inline-block;
      justify-content: center;
      line-height: 22px;
    }
    .MBMWidget_Phaze_Card_Checkout {
      height: calc(100% - 120px);
    }
  }
  @media screen and ( min-width: 500px ) {
    .MBWidget_breadcrumb {
      display: flex;
      flex-direction: row;
      margin-top: 29px;
    }
    .breadcrumb_item {
      margin-top: -40px;
      display: flex;
      flex-direction: row;
      align-items: center;

      > span {
        margin-left: 6px;
        font-size: 17px;
        font-weight: 500;
        white-space: nowrap;
        cursor: default;
      }

      .button_icon {
        margin: 2px 8px 0px 8px;
        display: inherit;
      }
    }
    .circle {
      border-radius: 50%;
      line-height: 22px;
      width: 22px;
      height: 22px;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      font-weight: 700;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .MBMWidget_Phaze_Card_Checkout {
      height: calc(100% - 140px);
    }
  }

.basket_button {
  position: relative;
  float: right;
  right: 0px;
  top: -36px;
}


</style>
