import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=4d5c1890&scoped=true"
import script from "./ErrorMessage.vue?vue&type=script&lang=ts"
export * from "./ErrorMessage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d5c1890",
  null
  
)

export default component.exports