<template>
  <div class="MBMWidget_Verify">
    <AppSnack :messages="messages" :colors="colors" />
    <div class="MBMWidget_col-xs-12" v-if="!success.email">
      <div class="MBMWidget_text-center MBMWidget_my-4 MBMWidget_Verify_VerifyEmailText">
        {{ $t('verify_email_text') }}
      </div>
      <AppInput
        :value="profile.email"
        readonly
        class="MBMWidget_d-inline-block MBMWidget_Verify_EmailInput MBMWidget_inputWithButton MBMWidget_full-width"
      />
      <AppButton
        class="MBMWidget_customButton MBMWidget_d-inline-block MBMWidget_EmailButton MBMWidget_defaultAppButton MBMWidget_full-width"
        color="primary"
        :disabled="disableds.email"
        @click="requestEmail"
      >
        {{ $t('verify_screen_send_code_by_email') }}
      </AppButton>
      <div class="MBMWidget_my-2" />
      <form ref="formEmail" @submit.prevent class="MBMWidget_Verify_form_email">
        <AppInput
          v-model="crud.emailCode"
          required
          class="MBMWidget_smallInputWithButton MBMWidget_EmailCodeInput MBMWidget_d-inline-block MBMWidget_full-width"
          :class="{ successInput: success.email }"
        />
        <AppButton
          :disabled="disableds.verifyEmail"
          class="MBMWidget_smallCustomButton MBMWidget_EmailCodeButton MBMWidget_d-inline-block MBMWidget_defaultAppButton MBMWidget_full-width"
          color="primary"
          @click="verifyEmail"
        >
          {{ $t('verify_screen_verify_button_email') }}
        </AppButton>
      </form>
    </div>
    <!--
	<div v-else-if="!success.phone" class="MBMWidget_col-xs-12">
      <div class="MBMWidget_text-center MBMWidget_my-4 MBMWidget_VerifyPhoneText">
        {{ $t('verify_phone_text') }}
      </div>
      <AppInput
        :value="profile.phoneNumber"
        class="MBMWidget_d-inline-block MBMWidget_SMSInput MBMWidget_inputWithButton MBMWidget_full-width"
        readonly
      />
      <AppButton
        :disabled="disableds.phone || !success.email"
        class="MBMWidget_customButton MBMWidget_d-inline-block MBMWidget_SMSButton MBMWidget_defaultAppButton MBMWidget_full-width"
        color="primary"
        @click="requestSMS"
      >
        {{ $t('verify_screen_send_code_by_sms') }}
      </AppButton>
      <div class="my-2" />
      <form ref="formPhone" @submit.prevent class="MBMWidget_Verify_form_phone">
        <AppInput
          v-model="crud.phoneCode"
          required
          :disabled="!success.email"
          class="MBMWidget_smallInputWithButton MBMWidget_SMSCodeInput MBMWidget_d-inline-block MBMWidget_full-width"
          :class="{ successInput: success.phone }"
        />
        <AppButton
          :disabled="disableds.verifyPhone || !success.email"
          class="MBMWidget_smallCustomButton MBMWidget_d-inline-block MBMWidget_SMSCodeButton MBMWidget_defaultAppButton MBMWidget_full-width"
          color="primary"
          @click="verifyPhone"
        >
          {{ $t('verify_screen_verify_button_sms') }}
        </AppButton>
      </form>
    </div>
	-->
    <AppKycUrl v-if="url" :url="url" />
  </div>
</template>
<script lang="ts">
import {
  checkKyc, isKycComplete,
  performKyc,
  requestEmailCode,
  requestSMSCode,
  verifyEmailCode,
  verifyPhoneCode,
} from '@/api';
import { Routes } from '@/store/modules/Router';
import Vue from 'vue';
import { mapState } from 'vuex';
import Button from '../General/Button.vue';
import Input from '../General/Input.vue';
import Snack from '../General/Snack.vue';
import { displayErrors, setCookie } from '@/utils';
import KycUrl from '../General/KycUrl.vue';

export default Vue.extend({
  components: {
    AppInput: Input,
    AppButton: Button,
    AppSnack: Snack,
    AppKycUrl: KycUrl,
  },
  data() {
    return {
      disableds: {
        email: false,
        phone: false,
        verifyEmail: false,
        verifyPhone: false,
      },
      crud: {
        emailCode: '',
        phoneCode: '',
      },
      success: {
        phone: true,
        email: false,
      },
      interval: null as any,
      colors: 'info',
      messages: [] as string[],
      url: '',
    };
  },
  computed: {
    ...mapState(['profile']),
  },
  methods: {
    async requestSMS() {
      try {
        this.disableds.phone = true;
        const response = await requestSMSCode();
        displayErrors(response.data.errors);
      } finally {
        this.disableds.phone = false;
      }
    },
    async verifyPhone() {
      const form = this.$refs.formPhone as HTMLFormElement;
      if (!form.checkValidity()) return;
      try {
        this.disableds.verifyPhone = true;
        const response = await verifyPhoneCode(this.crud.phoneCode);
        if (response.data === true) {
          this.success.phone = true;
          this.$store.commit('errors', []);
        } else {
          this.$store.commit('errors', ['Wrong phone code']);
        }
      } catch {
        this.$store.commit('errors', ['Error verifying, please try again.']);
      } finally {
        this.disableds.verifyPhone = false;
      }
    },
    async requestEmail() {
      try {
        this.disableds.email = true;
        const response = await requestEmailCode();
        displayErrors(response.data.errors);
      } finally {
        this.disableds.email = false;
      }
    },
    async verifyEmail() {
      const form = this.$refs.formEmail as HTMLFormElement;
      if (!form.checkValidity()) return;
      try {
        this.disableds.verifyEmail = true;
        const response = await verifyEmailCode(this.crud.emailCode);
        if (response.data === true) {
          this.success.email = true;
          this.$store.commit('errors', []);
        } else {
          this.$store.commit('errors', ['Wrong email code']);
        }
      } catch {
        this.$store.commit('errors', ['Error verifying, please try again.']);
      } finally {
        this.disableds.verifyEmail = false;
      }
    },
  },
  watch: {
    success: {
      deep: true,
      async handler() {
        if (this.success.email && this.success.phone) {
          this.$store.commit('router/goTo', Routes.CardScreen);
          /*const response = await performKyc();
          this.url = response.data.kycUrl;
          this.messages = ['Waiting for kyc verify completion'];
          setCookie('kycReqId', response.data.kycFriendlyRequestId, 2);
          this.interval = setInterval(async () => {
            const response = await isKycComplete();
            if (response.data === true) {
              this.$store.commit('router/goTo', Routes.CardScreen);
              this.messages = [];
              if (this.interval) {
                clearInterval(this.interval);
              }
            }
          }, 5000);*/
        }
      },
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
});
</script>
