<template>
  <div class="MBMWidget_col-xs-12 MBMWidget_col-12 MBMWidget_px-0 MBWidget_Error_Message" v-if="store.state.personalizedErrors.length">
    <div v-for="(t, index) in store.state.personalizedErrors" :key="index">
      <span v-if="t.code">
        <span v-if="$te('api_error_'+t.code)">{{$t('api_error_'+t.code)}}</span>
        <span v-else-if="$te('widget_error_'+t.code)">{{$t('widget_error_'+t.code)}}</span>
        <span v-else>{{t.message}}</span>
      </span>
      <span v-else-if="t.message">
        {{ t.message }}
      </span>
      <span v-else>
        {{t}}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import store from "@/store";

  export default Vue.extend({
    components: {

    },
    props: {

    },
    computed: {
      val() {
        return this.$store.state.personalizedErrors;
      }
    },
    data() {
      return {
        store,
        text: []
      }
    },
    watch: {
      val(val: []) {
        this.text = val;
      }
    }

  });
</script>

<style scoped>

</style>