<template>
  <div class="MBMWidget_Register MBMWidget_mx-auto MBWidget_block">
    <div class="MBMWidget_text-center MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
      {{ $t('register_screen_title') }}
    </div>
    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_py-0 MBMWidget_text-center">
      <ErrorMessage></ErrorMessage>
    </div>
    <!-- STEP 0 -->
    <form @submit.prevent ref="form_name" v-if="stepStatus == StepType.None">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Register_CoverFormRecoverAppInputName">
        <AppInput
          v-model="crud.Name"
          required
          :minlength="2"
          :maxLength="45"
          :placeholder="$t('register_screen_name_placeholer')"
          :label="$t('register_screen_name_placeholer')"
        />
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Register_CoverFormRecoverAppInputSurName">
        <AppInput
          v-model="crud.Surname"
          required
          :minlength="2"
          :maxLength="45"
          :placeholder="$t('register_screen_surname_placeholer')"
          :label="$t('register_screen_surname_placeholer')"
        />
      </div>
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBWidget-nofloat MBMWidget_mx-auto">
        <AppCheckbox
          v-model="acceptTerms"
          required
          class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_cursor-pointer MBWidget_multiline_checkbox"
        >
          <template #label>
            {{$t('register_i_accept')}}
            <a @click="showIframe('terms_and_conditions')" target="_blank">
              {{ $t('card_screen_terms_and_conditions') }}
            </a>
            &
            <a @click="showIframe('privacy_policy')" target="_blank">
              {{ $t('card_screen_privacy_policy') }}.
            </a>
          </template>
        </AppCheckbox>
      </div>
      <div class="MBMWidget_col-8 MBMWidget_col-xs-12 MBWidget-nofloat MBMWidget_mx-auto">
        <AppCheckbox
          v-model="declareAge"
          required
          class="MBMWidget_body-2 MBMWidget_defaultAppCheckbox MBMWidget_cursor-pointer MBWidget_multiline_checkbox"
        >
          <template #label>
            {{$t('register_declare')}}
          </template>
        </AppCheckbox>
      </div>
      <AppButton
        class="MBWidget_fake_submit"
        @click="performAction"
      ></AppButton>
    </form>
    <form @submit.prevent ref="form_phone" v-if="stepStatus == StepType.BasicUserInfoRegistered">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12">
        <AppVueCountryCode
          ref="select"
          class="MBMWidget_vue-country-select"
          disabledFetchingCountry
          enabledCountryCode
          v-model="countryCode"
          @onSelect="onSelect"
        />
        <AppSelect
          v-model="crud.Country"
          :items="countries"
          class="MBMWidget_Register_CoverFormRecoverAppInputCountry"
        />
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_Register_country_div">
        <AppInput
          v-model="crud.PhoneNumber"
          class="MBMWidget_Register_CoverFormRecoverAppInputPhoneNumber"
          required
          :type="'number'"
          :label="$t('register_screen_phone_placeholder')"
        />
      </div>
      <AppButton
        class="MBWidget_fake_submit"
        @click="performAction"
      ></AppButton>
    </form>
    <div class="MBWidget_verify_wrap" v-show="stepStatus == StepType.PhoneNumberVerification">
      <form @submit.prevent ref="form_verifyPhone" class="MBWidget_verify_v-middle">
        <VerificationCode ref="validationInput1" @codeFilled="codeFilled"></VerificationCode>
        <AppButton
          class="MBWidget_fake_submit"
          @click="performAction"
        ></AppButton>
      </form>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-right MBMWidget_text-center">
        <a class="MBMWidget_Tfa_Resend_Code"
           @click.prevent="requestPhone"
        >
          {{$t('verify_resend_phone',) }}
        </a>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center" v-show="codeSent">
        {{$t('register_screen_code_sent')}}
      </div>
    </div>
    <form @submit.prevent ref="form_email" v-if="stepStatus == StepType.PhoneNumberVerified">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12">
        <AppInput
          class="MBMWidget_Register_CoverFormRecoverAppInputEmail"
          v-model="crud.Email"
          :type="'email'"
          required
          :label="$t('register_screen_email_placeholer')"
        />
        <AppButton
          class="MBWidget_fake_submit"
          @click="performAction"
        ></AppButton>
      </div>
    </form>
    <div class="MBWidget_verify_wrap" v-show="stepStatus == StepType.EmailVerification">
      <form @submit.prevent ref="form_email" class="MBWidget_verify_v-middle">
        <VerificationCode ref="validationInput2" @codeFilled="codeFilled"></VerificationCode>
        <AppButton
          class="MBWidget_fake_submit"
          @click="performAction"
        ></AppButton>
      </form>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-right MBMWidget_text-center">
        <a class="MBMWidget_Tfa_Resend_Code"
           @click.prevent="requestEmail"
        >
          {{$t('verify_resend_email',) }}
        </a>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center" v-show="codeSent">
        {{$t('register_screen_code_sent')}}
      </div>
    </div>
    <form @submit.prevent ref="form_password" v-if="stepStatus == StepType.EmailVerified">
      <div class="MBMWidget_col-12">
        <AppInput
          required
          class="MBMWidget_full-width MBMWidget_FormRecoverAppInputPassword"
          v-model="crud.Password"
          autocomplete="new-password"
          type="password"
          :label="$t('register_screen_password_placeholder')"
        />
        <Password
          v-model="crud.Password"
          :strength-meter-only="true"
          @score="score = $event"
        />
      </div>

      <div class="MBMWidget_col-12">
        <AppInput
          class="MBMWidget_FormRecoverAppInputRePassword"
          v-model="crud.RePassword"
          required
          :label="$t('register_screen_re_password_placeholer')"
          type="password"
        />
      </div>
      <AppButton
        class="MBWidget_fake_submit"
        @click="performAction"
      ></AppButton>
    </form>

    <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-8 MBMWidget_col-md-8 MBMWidget_col-np-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          class="MBMWidget_full-width MBMWidget_defaultAppButton MBMWidget_Forgot_Recover_button"
          color="primary"
          :disabled="disabled"
          @click="performAction"
          v-show="stepStatus != StepType.PhoneNumberVerification && stepStatus != StepType.EmailVerification"
        >
          {{ $t(getButton()) }}
        </AppButton>
      </div>
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_text-center MBWidget-nofloat MBMWidget_mx-auto" v-show="stepStatus != StepType.EmailVerified">
        <a class="MBWidget_Register_back"
           href="/login"
           @click.prevent="$store.commit('router/goTo', Routes.Login)"
        >
          {{$t('register_sreen_already_have_an_account')}}
          <span>{{$t('register_sreen_sign_in')}}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Button from '../General/Button.vue';
  import Input from '../General/Input.vue';
  import Select from '../General/Select.vue';
  import Checkbox from "@/components/General/Checkbox.vue";
  import {
    checkEmailExists,
    getCountries,
    registerResendEmail, registerResendPhone,
    registerUser,
    RegisterUserByStep,
    requestEmailCode, sendAnalytics
  } from '@/api';
  import md5 from 'blueimp-md5';
  import {Routes} from '@/store/modules/Router';
  import VerificationCode from '../General/VerificationCode.vue';

  // @ts-ignore
  import Password from 'vue-password-strength-meter';
  // @ts-ignore
  import VueCountryCode from 'vue-country-code';
  import {redirectView} from '@/utils';
  import {StepType} from "@/components/Enums/StepType";
  import ErrorMessage from "@/components/Components/ErrorMessage.vue";


  export default Vue.extend({
    props: ['showIframe'],
    components: {
      AppInput: Input,
      AppButton: Button,
      AppSelect: Select,
      AppVueCountryCode: VueCountryCode,
      AppCheckbox: Checkbox,
      Password,
      VerificationCode: VerificationCode,
      ErrorMessage: ErrorMessage
    },
    data() {
      return {
        crud: {
          Name: '',
          Surname: '',
          Email: '',
          Password: '',
          RePassword: '',
          Address: '',
          PhoneNumber: '',
          PhoneNumberVerificationCode: '',
          EmailVerificationCode: '',
          Country: '',
          StepId: ''
        },
        code: '',
        acceptTerms: false,
        countryCode: '',
        declareAge: false,
        countries: [] as object[],
        codes: [] as string[],
        loading: false,
        Routes: Routes,
        selectedCountryCode: '+004',
        score: 0,
        steps: 5,
        currentStep: 0,
        disabled: false,
        data: [] as { name: string; alpha2Code: string, telPref: any }[],
        stepStatus: StepType.None,
        StepType,
        codeSent: false
      };
    },
    methods: {
      getButton(){
        if(this.stepStatus == StepType.None)
          return 'register_button_number_verify';
        if(this.stepStatus == StepType.BasicUserInfoRegistered)
          return 'register_button_send_otp';
        if(this.stepStatus == StepType.PhoneNumberVerified)
          return 'register_button_send_email';
        if(this.stepStatus == StepType.EmailVerified)
          return 'register_button_sign_up';
      },
      performAction() {
        switch (this.stepStatus) {
          case StepType.None:
            this.SendName();
            break;
          case StepType.BasicUserInfoRegistered:
            this.SendPhone();
            break;
          case StepType.PhoneNumberVerification:
            this.VerifyPhone();
            break;
          case StepType.PhoneNumberVerified:
            this.SendEmail();
            break;
          case StepType.EmailVerification:
            this.VerifyEmail();
            break;
          case StepType.EmailVerified:
            this.SetPasswords();
            break;
        }
        //this.currentStep++;
      },
      preRequest(form: string) : boolean {
        this.$store.commit('personalizedErrors', []);
        if(form != '')
          if (!(this.$refs[form] as HTMLFormElement).checkValidity()) return false;
        this.disabled = true;
        return true;
      },
      showErrors(errors: []) : boolean {
        this.disabled = false;
        if(errors.length) {
          this.$store.commit('personalizedErrors', errors.map((item: { message: string }) => item.message));
          return true;
        }
        return false;
      },
      codeFilled(val: string){
        if(this.stepStatus == StepType.PhoneNumberVerification) {
          this.VerifyPhone();
        } else {
          this.VerifyEmail();
        }
      },
      //=====STEP 0
      async SendName() {
        if(!this.preRequest('form_name')) return;

        const response = await RegisterUserByStep(
          {...this.crud}
        );
        if(!this.showErrors(response.data.errors) && response.data.success) {
          this.crud.StepId = response.data.result.StepId;
          this.stepStatus = response.data.result.StepStatus;
        }
      },
      //=====STEP 1
      async SendPhone() {
        if(!this.preRequest('form_phone')) return;
        const response = await RegisterUserByStep(
          {
            ...this.crud,
            PhoneNumber:
              '+' + this.getCountryCode() + ' ' + this.crud.PhoneNumber
          }
        );
        if(!this.showErrors(response.data.errors) && response.data.success) {
          this.stepStatus = response.data.result.StepStatus;
        }
      },
      //=====STEP 2
      async VerifyPhone() {
        if(!this.get('validationInput1')) return;
        this.preRequest('');

        this.crud.PhoneNumberVerificationCode = this.code;
        const response = await RegisterUserByStep(
          {...this.crud,
            PhoneNumber:
              '+' + this.getCountryCode() + ' ' + this.crud.PhoneNumber}
        );
        if(!this.showErrors(response.data.errors) && response.data.success) {
          this.stepStatus = response.data.result.StepStatus;
        }
      },
      //=====STEP 3
      async SendEmail() {
        if(!this.preRequest('form_email')) return;
        const response = await RegisterUserByStep(
          {...this.crud,
            PhoneNumber:
              '+' + this.getCountryCode() + ' ' + this.crud.PhoneNumber}
        );
        if(!this.showErrors(response.data.errors) && response.data.success) {
          this.stepStatus = response.data.result.StepStatus;
        }
      },
      //=====STEP 3
      async VerifyEmail() {
        if(!this.get('validationInput2')) return;
        this.preRequest('');

        this.crud.EmailVerificationCode = this.code;
        const response = await RegisterUserByStep(
          {...this.crud,
            PhoneNumber:
              '+' + this.getCountryCode() + ' ' + this.crud.PhoneNumber}
        );
        if(!this.showErrors(response.data.errors) && response.data.success) {
          this.stepStatus = response.data.result.StepStatus;
        }
      },
      //=====STEP 3
      async SetPasswords() {
        if(!this.preRequest('form_password')) return;
        const errors = [];
        if (this.crud.Password !== this.crud.RePassword) {
          errors.push('Passwords must match');
        }
        if (this.score < this.$store.state.passwordStrength) {
          errors.push('Password should be stronger');
        }
        this.$store.commit('personalizedErrors', errors);
        if (errors.length) {
          this.loading = false;
          this.disabled = false;
          return;
        }

        this.crud.Password = md5(this.crud.Password);
        this.crud.RePassword = md5(this.crud.RePassword);
        this.loading = true;
        const response = await RegisterUserByStep(
          {...this.crud,
            PhoneNumber:
              '+' + this.getCountryCode() + ' ' + this.crud.PhoneNumber}
        );
        this.loading = false;
        if(!this.showErrors(response.data.errors) && response.data.success) {
          this.stepStatus = response.data.result.StepStatus;

        /*this.$gtag.event('user-registered', {
          'event_label': 'User Registered',
          'event_domain': window.location.host
        });*/
          sendAnalytics({
            EventCategory: 'general',
            EventAction: 'user-registered',
            EventLabel: 'User Registered',
          });
          //const redirect = redirectView(response.data);
          //if (!redirect) {
          if(response.data.result.User != null) {
            this.$store.commit('profile', {
              userUUid: response.data.result.User.userUUid,
              phoneNumber: response.data.result.User.phoneNumber,
              email: response.data.result.User.email,
              blockedRegion: ''
            });
            if(response.data.redirectUrl == 'PerformKYC') {
              this.$store.commit('router/goTo', Routes.Kyc);
            } else {
              if(this.$store.state.order.uuid != '' || this.$store.state.phazeOrder.uuid != '') {
                  if(this.$store.state.order.uuid != ''){
                    this.$store.commit('setPreviousPage', 'Order');
                    this.$store.commit('router/goTo', Routes.PurchaseSummary);
                  } else if(this.$store.state.phazeOrder.uuid != '') {
                    this.$store.commit('setPreviousPage', 'PhazeCardList');
                    this.$store.commit('router/goTo', Routes.PhazeCardCheckout);
                  } else {
                    this.$store.commit('router/goTo', Routes.Exchange); // default to exchange, while we don't think of smth better
                  }
              }
              this.$store.state.loggedIn = true;
            }

          } else {
            this.$store.commit('router/goTo', Routes.Login);
          }

         // }
        }
      },
      get(ref: string): boolean {
        const i: InstanceType<typeof VerificationCode> = this.$refs[ref] as InstanceType<typeof VerificationCode>;
        // @ts-ignore
        this.code = i.getCode();
        return this.code.length > 0;
      },
      getCountryCode(): string {

        let code = '';
        this.data.forEach((item) => {

          if(item.name == this.crud.Country){
              code = item.telPref;
          }
        });
        return code;
      },
      onSelect(data: any) {
        this.selectedCountryCode = data.dialCode;
      },
      onChanged(country: string) {
        this.selectedCountryCode = this.getCountryCode();
        /*const select = this.$refs.select as any;
        const countries = select.filteredCountries;
        const data = countries.find((item: { name: string }) =>
          //item.name.includes(country),
          country.includes(item.name),
        );
        if (data) {
          this.selectedCountryCode = data.dialCode;
          select.activeCountry = data;
        }*/
      },
      async requestEmail() {
        try {
          this.codeSent = true;
          const response = await registerResendEmail(this.crud.StepId);

          this.$store.commit(
            'personalizedErrors',
            response.data.errors.map((item: { message: string }) => item.message),
          );
        } finally {
          //this.disableds.email = false;
        }
      },
      async requestPhone() {
        try {
          this.codeSent = true;
          const response = await registerResendPhone(this.crud.StepId);

          this.$store.commit(
            'personalizedErrors',
            response.data.errors.map((item: { message: string }) => item.message),
          );
        } finally {
          //this.disableds.email = false;
        }
      },
    },
    watch: {
      'crud.Country'(value: string) {
        this.onChanged(value);
      },
    },
    async mounted() {
      if(!this.$store.state.countries.length) {
        const response = await getCountries();
        this.data = response.data.result.Countries;
        this.$store.state.countries = response.data.result.Countries;
      } else {
        this.data = this.$store.state.countries;
      }
      this.countries = [];
      const codes = [] as string[];
      this.$store.state.countries.forEach(
        (item: { name: string; alpha2Code: string, telPref: string}) => {
          let name = item.name;
          if (item.alpha2Code) {
            codes.push('+' + item.alpha2Code);
            if(this.$store.state.config.countryCode == item.alpha2Code) {
              this.crud.Country = item.name;
            }
          }
          name = name+' (+'+item.telPref+')';
          this.countries.push({text: name, value:item.name});
        },
      );
      if(this.crud.Country == '')
        this.crud.Country = 'Afghanistan';
      this.codes = codes.sort();

    },
  });


</script>
